// import { queryOneParamValue } from "../api/queries";
import { SKeys } from "../param/selectKeys";
// import { PKeys } from "../param/parametersKeys";

import { validFloat } from "./helpers"

export const PignonRdcParams = Object.freeze({
  PRIX_STRUCTURE:          35.1,
  PRIX_STRUCTURE_SPECIALE: 35.1,
  PRIX_ARASE:              8,
  //OLD PARAMS
  CoeffTypeToit: {
    RECTANGLE: 1,
    L: 1.5,
    V: 1,
    U: 2,
    L_EVOLUTION: 1.5
  },
  CosInclinaison: {
    DEGRES_35: 0.83,
    DEGRES_40: 0.78,
    DEGRES_45: 0.71
  },
  TanInclinaison: {
    DEGRES_35: 0.71,
    DEGRES_40: 0.84,
    DEGRES_45: 1
  }
});

export async function computeParamsPignonRdc(targetObj, typeToit, inclinaisonToit, facadeToit, pignonToit, decrocheRdc) {
  //PIGNON 2 PENTES
  let coeffTypeToit = 0;
  if (typeToit === SKeys.TypesToit.RECTANGLE) {
    coeffTypeToit = PignonRdcParams.CoeffTypeToit.RECTANGLE;
  }
  else if (typeToit === SKeys.TypesToit.L) {
    coeffTypeToit = PignonRdcParams.CoeffTypeToit.L;
  }
  else if (typeToit === SKeys.TypesToit.V) {
    coeffTypeToit = PignonRdcParams.CoeffTypeToit.V;
  }
  else if (typeToit === SKeys.TypesToit.U) {
    coeffTypeToit = PignonRdcParams.CoeffTypeToit.U;
  }
  else if (typeToit === SKeys.TypesToit.L_EVOLUTION) {
    coeffTypeToit = PignonRdcParams.CoeffTypeToit.L_EVOLUTION;
  }
  
  let coeffTanInclinaison = 0;
  if (inclinaisonToit === SKeys.InclinaisonsToit.DEGRES_35) {
    coeffTanInclinaison = PignonRdcParams.TanInclinaison.DEGRES_35;
  }
  else if (inclinaisonToit === SKeys.InclinaisonsToit.DEGRES_40) {
    coeffTanInclinaison = PignonRdcParams.TanInclinaison.DEGRES_40;
  }
  else if (inclinaisonToit === SKeys.InclinaisonsToit.DEGRES_45) {
    coeffTanInclinaison = PignonRdcParams.TanInclinaison.DEGRES_45;
  }

  if (validFloat(pignonToit)) {
    targetObj.pignonRdc.pignon2Pentes = validFloat(coeffTypeToit) * (validFloat(pignonToit) + 0.5) * (validFloat(pignonToit) + 0.5) * validFloat(coeffTanInclinaison) / validFloat(2);
  }
  //PIGNON BAC ACIER
  if (typeToit === SKeys.SpeciauxToit.BAC_ACIER && facadeToit) {
    targetObj.pignonRdc.pignonBacAcier = validFloat(coeffTanInclinaison) * (validFloat(pignonToit) + 0.5) * (validFloat(pignonToit) + 0.5 + validFloat(facadeToit));    
  }
  //PIGNON TOIT PLAT
  if (typeToit === SKeys.SpeciauxToit.BITUME && facadeToit) {
    targetObj.pignonRdc.pignonToitPlat = 2 * (validFloat(facadeToit) + validFloat(pignonToit) + 0.5 + decrocheRdc) * 0.75;    
  }
}

export function addStructurePignonRdc(targetObj, nombrePentes) {
  if (nombrePentes && nombrePentes === SKeys.PentesToit.UNE_PENTE || SKeys.PentesToit.DEUX_PENTES) {
    let tmpStructure = validFloat(targetObj.pignonRdc.pignon2Pentes) + validFloat(targetObj.pignonRdc.pignonBacAcier) + validFloat(targetObj.pignonRdc.pignonToitPlat);
    targetObj.pignonRdc.structure = tmpStructure;
    targetObj.pignonRdc.ttlPrixPignonRdc += validFloat(PignonRdcParams.PRIX_STRUCTURE) * validFloat(tmpStructure);
  }
}

// export async function addStructureSpePignonRdc(targetObj, typeToitSpe, facadeSpeToit, pignonSpeToit) {
//   const tanInclinaisonKey = PKeys.TAN_INCLINAISON_TOIT;
//   let tmpStructureSpe;
//   if (typeToitSpe != SKeys.SpeciauxToit.BITUME) {
//     if (tanInclinaisonKey) {
//       let coeffTanInclinaison = await queryOneParamValue(tanInclinaisonKey, SKeys.SpeciauxToit.BAC_ACIER);
//       tmpStructureSpe = validFloat(coeffTanInclinaison) * validFloat(pignonSpeToit) * (validFloat(pignonSpeToit) + validFloat(facadeSpeToit));
//       targetObj.pignonRdc.structureSpe = parseFloat(tmpStructureSpe.toFixed(2));
//       targetObj.pignonRdc.ttlPrixPignonRdc += validFloat(PignonRdcParams.PRIX_STRUCTURE_SPECIALE) * validFloat(tmpStructureSpe);
//     }
//   }
//   else {
//     tmpStructureSpe = 0.75 * 2 * (validFloat(pignonSpeToit) + validFloat(facadeSpeToit));
//     targetObj.pignonRdc.structureSpe = parseFloat(tmpStructureSpe.toFixed(2));
//     targetObj.pignonRdc.ttlPrixPignonRdc += validFloat(PignonRdcParams.PRIX_STRUCTURE_SPECIALE) * validFloat(tmpStructureSpe);
//   }  
// }

export async function addArase(targetObj, inclinaisonToit, pignonCombles) {
  let coeffCosInclinaison = 0;
  if (inclinaisonToit === SKeys.InclinaisonsToit.DEGRES_35) {
    coeffCosInclinaison = PignonRdcParams.CosInclinaison.DEGRES_35;
  }
  else if (inclinaisonToit === SKeys.InclinaisonsToit.DEGRES_40) {
    coeffCosInclinaison = PignonRdcParams.CosInclinaison.DEGRES_40;
  }
  else if (inclinaisonToit === SKeys.InclinaisonsToit.DEGRES_45) {
    coeffCosInclinaison = PignonRdcParams.CosInclinaison.DEGRES_45;
  }
  targetObj.pignonRdc.ttlPrixPignonRdc += validFloat(PignonRdcParams.PRIX_ARASE) * 4 * (validFloat(pignonCombles) + 0.4) / (validFloat(coeffCosInclinaison) * 2);
}