import { SKeys } from "../param/selectKeys";
import { PKeys } from "../param/parametersKeys";
import { queryOneParamValue } from "../api/queries";

import { validFloat } from "./helpers"

export const ChauffageParams = Object.freeze({
  PRIX_RADIATEUR: 273,
  PRIX_CONDUIT_POLYCOMB: 1250
});

export async function computeParamsChauffage(targetObj, typeChauffage) {
  const chauffageParamKey = PKeys.TYPE_CHAUFFAGE_PRIX;
  let chauffageParamValue;
  let nombreRadiateurs = 0;
  switch (typeChauffage) {
    case SKeys.TypesChauffage.CLIM_RADIATEUR_ELEC:
      //CHAUFFAGE
      if (validFloat(targetObj.electricite.pieces.surfacePdv) > 45) {
        chauffageParamValue = SKeys.TypesChauffagePrix.CLIM_MAX_65;
      }
      else if (validFloat(targetObj.electricite.pieces.surfacePdv) > 35) {
        chauffageParamValue = SKeys.TypesChauffagePrix.CLIM_MAX_45;
      }
      else if (validFloat(targetObj.electricite.pieces.surfacePdv)) {
        chauffageParamValue = SKeys.TypesChauffagePrix.CLIM_MAX_35;
      }
      break;

    case SKeys.TypesChauffage.POELE_RADIATEUR_ELEC:
      //CHAUFFAGE
      chauffageParamValue = SKeys.TypesChauffagePrix.POELE;
      break;

    case SKeys.TypesChauffage.PAC_PLANCHER_CHAUFFANT:
      //CHAUFFAGE
      if (validFloat(targetObj.videSanitaire.surfaceHabitable) < 100) {
        chauffageParamValue = SKeys.TypesChauffagePrix.PAC_1Z_100;
      }
      else if (validFloat(targetObj.videSanitaire.surfaceHabitable) <= 120) {
        chauffageParamValue = SKeys.TypesChauffagePrix.PAC_2Z_120;
      }
      else if (validFloat(targetObj.videSanitaire.surfaceHabitable) <= 140) {
        chauffageParamValue = SKeys.TypesChauffagePrix.PAC_2Z_140;
      }
      else if (validFloat(targetObj.videSanitaire.surfaceHabitable) <= 170) {
        chauffageParamValue = SKeys.TypesChauffagePrix.PAC_2Z_170;
      }
      else {
        chauffageParamValue = SKeys.TypesChauffagePrix.PAC_2Z_190;
      }
      break;

    case SKeys.TypesChauffage.PAC_RADIATEUR_EAU:
      //CHAUFFAGE
      if (validFloat(targetObj.videSanitaire.surfaceHabitable) < 100) {
        chauffageParamValue = SKeys.TypesChauffagePrix.PAC_1Z_100;
      }
      else if (validFloat(targetObj.videSanitaire.surfaceHabitable) <= 120) {
        chauffageParamValue = SKeys.TypesChauffagePrix.PAC_2Z_120;
      }
      else if (validFloat(targetObj.videSanitaire.surfaceHabitable) <= 140) {
        chauffageParamValue = SKeys.TypesChauffagePrix.PAC_2Z_140;
      }
      else if (validFloat(targetObj.videSanitaire.surfaceHabitable) <= 170) {
        chauffageParamValue = SKeys.TypesChauffagePrix.PAC_2Z_170;
      }
      else {
        chauffageParamValue = SKeys.TypesChauffagePrix.PAC_2Z_190;
      }
      //RADIATEURS
      nombreRadiateurs += 2 * validFloat(targetObj.electricite.pieces.nombrePdv);
      nombreRadiateurs += validFloat(targetObj.electricite.pieces.nombreChambres);
      break;

    case SKeys.TypesChauffage.GAZ_PLANCHER_CHAUFFANT:
      //CHAUFFAGE
      if (validFloat(targetObj.videSanitaire.surfaceHabitable) < 121) {
        if (validFloat(targetObj.electricite.pieces.nombreSde) < 2) {
          chauffageParamValue = SKeys.TypesChauffagePrix.GAZ_1Z_1SDB;
        }
        else if (validFloat(targetObj.electricite.pieces.nombreSde) == 2) {
          chauffageParamValue = SKeys.TypesChauffagePrix.GAZ_1Z_2SDB;
        }
        else if (validFloat(targetObj.electricite.pieces.nombreSde) > 2) {
          chauffageParamValue = SKeys.TypesChauffagePrix.GAZ_1Z_XSDB;
        }
      }
      else if (validFloat(targetObj.videSanitaire.surfaceHabitable) >= 121) {
        if (validFloat(targetObj.electricite.pieces.nombreSde) < 2) {
          chauffageParamValue = SKeys.TypesChauffagePrix.GAZ_2Z_1SDB;
        }
        else if (validFloat(targetObj.electricite.pieces.nombreSde) == 2) {
          chauffageParamValue = SKeys.TypesChauffagePrix.GAZ_2Z_2SDB;
        }
        else if (validFloat(targetObj.electricite.pieces.nombreSde) > 2) {
          chauffageParamValue = SKeys.TypesChauffagePrix.GAZ_2Z_XSDB;
        }
      }
      break;

    case SKeys.TypesChauffage.GAZ_RADIATEUR_EAU:
      //CHAUFFAGE
      if (validFloat(targetObj.videSanitaire.surfaceHabitable) < 121) {
        if (validFloat(targetObj.electricite.pieces.nombreSde) < 2) {
          chauffageParamValue = SKeys.TypesChauffagePrix.GAZ_1Z_1SDB;
        }
        else if (validFloat(targetObj.electricite.pieces.nombreSde) == 2) {
          chauffageParamValue = SKeys.TypesChauffagePrix.GAZ_1Z_2SDB;
        }
        else if (validFloat(targetObj.electricite.pieces.nombreSde) > 2) {
          chauffageParamValue = SKeys.TypesChauffagePrix.GAZ_1Z_XSDB;
        }
      }
      else if (validFloat(targetObj.videSanitaire.surfaceHabitable) >= 121) {
        if (validFloat(targetObj.electricite.pieces.nombreSde) < 2) {
          chauffageParamValue = SKeys.TypesChauffagePrix.GAZ_2Z_1SDB;
        }
        else if (validFloat(targetObj.electricite.pieces.nombreSde) == 2) {
          chauffageParamValue = SKeys.TypesChauffagePrix.GAZ_2Z_2SDB;
        }
        else if (validFloat(targetObj.electricite.pieces.nombreSde) > 2) {
          chauffageParamValue = SKeys.TypesChauffagePrix.GAZ_2Z_XSDB;
        }
      }
      //RADIATEURS
      nombreRadiateurs += 2 * validFloat(targetObj.electricite.pieces.nombrePdv);
      nombreRadiateurs += validFloat(targetObj.electricite.pieces.nombreChambres);
      break;

    case SKeys.TypesChauffage.PAC_GAINABLE:
      //CHAUFFAGE
      if (validFloat(targetObj.videSanitaire.surfaceHabitable)) {
        if (validFloat(targetObj.videSanitaire.surfaceHabitable) < 100) {
          chauffageParamValue = SKeys.TypesChauffagePrix.GAINABLE_7_1_100;
        }
        else if (validFloat(targetObj.videSanitaire.surfaceHabitable) < 130) {
          chauffageParamValue = SKeys.TypesChauffagePrix.GAINABLE_10_130;
        }
        else {
          chauffageParamValue = SKeys.TypesChauffagePrix.GAINABLE_12_5_190;
        }
      }
      break;
  
    default:
      break;
  }
  //CHAUFFAGE
  let prixChauffage = 0;
  if (chauffageParamValue) {
    prixChauffage = await queryOneParamValue(chauffageParamKey, chauffageParamValue);
  }
  prixChauffage = validFloat(prixChauffage);
  // targetObj.chauffage.prixChauffage = parseFloat(prixChauffage.toFixed(2));
  targetObj.chauffage.prixChauffage = prixChauffage;
  //RADIATEURS
  targetObj.chauffage.prixRadiateurs = validFloat(nombreRadiateurs) * validFloat(ChauffageParams.PRIX_RADIATEUR);
  targetObj.chauffage.prixRadiateurs = parseFloat(targetObj.chauffage.prixRadiateurs.toFixed(2));
}

export function addChauffage(targetObj, typeConduit) {
  targetObj.chauffage.ttlPrixChauffage += validFloat(targetObj.chauffage.prixChauffage);
  targetObj.chauffage.ttlPrixChauffage += validFloat(targetObj.chauffage.prixRadiateurs);
  if (typeConduit === SKeys.TypesConduit.POLYCOMBUSTIBLE) {
    targetObj.chauffage.ttlPrixChauffage += validFloat(ChauffageParams.PRIX_CONDUIT_POLYCOMB)
  }
}