<template>
  <div class="generation single-view flex-cnt-col">
    <div class="view-header flex-cnt-row align-center" v-if="!flagDone">
      <div class="flx1 flex-cnt-row flex-center">
        <h2>
          {{('Génération du devis...').toUpperCase()}}
        </h2>
      </div>
    </div>

    <div class="tab-section flex-cnt-row align-center" v-else-if="isAdmin">
      <div 
        class="tab-elmt flx1 flex-cnt-row flex-center" 
        v-bind:class="{'active-tab': selectOnglet === 'devis'}" 
        v-on:click="selectOnglet = 'devis'"
      >
        DEVIS
      </div>
      <div 
        class="tab-elmt flx1 flex-cnt-row flex-center" 
        v-bind:class="{'active-tab': selectOnglet === 'recap'}" 
        v-on:click="selectOnglet = 'recap'"
      >
        RECAP
      </div>
    </div>


    <div class="devis-cnt flx1 flex-cnt-row" v-bind:class="{'active-section': selectOnglet === 'devis', 'unactive-section': selectOnglet != 'devis'}">
      <iframe class="flx1" ref="pdfFrame" src="" frameborder="0"></iframe>
    </div>

    <div class="recap-cnt flx1 flex-cnt-row overflow-auto" v-bind:class="{'active-section': selectOnglet === 'recap', 'unactive-section': selectOnglet != 'recap'}">
      <RecapTable
        v-bind:cData="cData"
        v-bind:gForm="gForm"
        v-bind:paramHausseDePrix="paramHausseDePrix"
        v-bind:paramDepartement="paramDepartement"
      />
    </div>


  </div>
</template>

<script>
import RecapTable from '../components/forms/RecapTable.vue'

import { SKeys } from '../param/selectKeys';
import { queryGet, queryGetByParamKey } from '../api/queries';

import { generateDevisPdf } from '../pdf/devis'

//DEVIS DATA
import { ImagesBase64 } from "../pdf/base64images";
import TextesDevis from "../pdf/texts";

//***********************************************************************************
//***********************************************************************************
// 1. IMPORT TERRASSEMENT
import { 
  addDecaissement,
  addFondation,
  addExtra,
  addBranchement
} from "../compute/terrassement";
// 2. IMPORT VIDE SANITAIRE / SOUS-SOL
import { 
  addBetonPlancher,
  addEnduitVS,
  addFondationBeton,
  addMac,
  addPlancherChauffant,
  addPlancherIso,
  addPlancherNonIso,
  addPlancherTerrePlein,
  computeParamsVS,
  computeParpaingsSupp
} from "../compute/videSanitaire";
// 3. RDC
import { 
  // addPlancherBois,
  addPoteaux,
  addPoutres,
  addSeuilAppuis,
  addStructureRdc,
  addVolets,
  computeParamsRdc,
} from "../compute/rdc";
// 4. COMBLES
import { 
  addEncuvement,
} from "../compute/combles";
// 5. PIGNON RDC
import { 
  computeParamsPignonRdc,
  addStructurePignonRdc,
  // addStructureSpePignonRdc,
  addArase
} from "../compute/pignonRdc";
// 6. TOITURE RDC
import { 
  addAccrotere,
  addCharpenteTradi,
  addComblesAmenageables,
  addComblesPerdus,
  addCouverture,
  addDescente,
  addGouttiere,
  addNombreCharpenteTradi,
  addNombreComblesAmenageables,
  addNombreComblesPerdus,
  addPenetration,
  addTaillageLamelleCollee,
  // addToitSpecial,
  addTransport,
  computeParamsToitureRdc,
} from "../compute/toitureRdc";
// 7. IMPORT OUVERTURE
import { 
  ouvertureAddPorteEntree, 
  ouvertureAddTierce, 
  ouvertureAddPorteService, 
  ouvertureAddFenetre,
  getFenetreLargeur,
  getFenetreHauteur,
  ouvertureAddNombreMenuiserie,
  ouvertureAddPorteGarage,
  computeFenetres,
  computeSurfaceVitreeNord,
  computeSurfaceVitreeSud,
  computeSurfaceVitreeOuest,
  computeSurfaceVitreeEst,
  computeSurfaceVitreeColor,
  // ouvertureAddPrixABF
} from "../compute/ouverture";
// 8. IMPORT PLACO
import { 
  addBandes,
  addBandesArmees,
  addBlocPorte,
  addCloison,
  addDoublage,
  addPlafondPose,
  addPlafondSouffle,
  addPlafondSousPlancherBois,
  computeParamsPlaco
} from "../compute/placo";
// 9. IMPORT REVETEMENT INT
import { 
  addCarrelageInit, 
  addCarrelagePrestige, 
  addChape, 
  addDoucheItal, 
  addFaience, 
  addParquet, 
  addPlintheInit, 
  addPlintheParquet, 
  addPlinthePrestige, 
  addReposeBP, 
  addTrappeAimant, 
  computeParamsRevetementInt 
} from "../compute/revetementInt";
// 10. IMPORT ELECTRICITE
import { 
  // addChambres, 
  addElectriciteGlobale, 
  // addGarage, 
  // addPdv, 
  // addSde, 
  // addWc, 
  computeParamsElectricite 
} from "../compute/electricite";
// 11. IMPORT REVETEMENT EXT
import { 
  addBardage, 
  addEnduit, 
  addEntoilage, 
  computeParamsRevetementExt 
} from "../compute/revetementExt";
// 12. IMPORT REVETEMENT EXT
import { 
  addSanitaires, 
  computeParamsPlomberie 
} from "../compute/plomberie";
// 13. IMPORT CHAUFFAGE
import { 
  addChauffage,
  computeParamsChauffage
} from "../compute/chauffage";
// 14. IMPORT PEINTURE
import { 
  addPeinture, 
  computeParamsPeinture 
} from "../compute/peinture";
// --. AUTRE
import { 
  addAjustement,
  addOrigineProspect,
  addArchitecte
} from "../compute/autre";
// XX. IMPORT RECAP
import { 
  addHaussesPrix,
  addOptions,
  addRecap,
} from "../compute/recap";
import { mapGetters } from 'vuex';
import { PKeys } from '../param/parametersKeys';
//***********************************************************************************
//***********************************************************************************

export default {
  components: {
    RecapTable
  },
  data: function () {
    return {
      clientId: null,
      qParameters: [],
      gForm: {},
      cData: {
        //FORMULES
        terrassement: {
          decaissement: 0,
          fondation: 0,
          ttlPrixTerrassement: 0
        },
        videSanitaire: {
          plancherBeton: 0,
          shonRdc: 0,
          surfaceHabitable: 0,
          ligneParpaingsSupp: 0,
          ttlPrixVideSanitaire: 0
        },
        rdc: {
          structure: 0,
          nombreOuvertures: 0,
          nombreVolets: 0,
          ttlPrixRdc: 0
        },
        combles: {
          encuvement: 0,
          ttlPrixCombles: 0
        },
        pignonRdc: {
          pignon2Pentes: 0,
          pignonBacAcier: 0,
          pignonToitPlat: 0,
          structure: 0,
          structureSpe: 0,
          ttlPrixPignonRdc: 0
        },
        toitureRdc : {
          surfaceToitureRdc: 0,
          comblesPerdus: 0,
          nombrePerdus: 0,
          comblesAmenageables: 0,
          nombreAmenageables: 0,
          charpenteTradi: 0,
          ttlPrixToitureRdc: 0
        },
        ouverture: {
          nombreBaiesCoulissants: 0,
          surfaceVitreeAvant: 0,
          surfaceVitreeDroite: 0,
          surfaceVitreeArriere: 0,
          surfaceVitreeGauche: 0,
          surfaceVitreeNord: 0,
          ttlSurfaceVitree: 0,
          ttlPrixOuverture: 0
        },
        placo: {
          surfacePerdue: 0,
          plafondSouffle: 0,
          plafondPose: 0,
          plafondSousPlancherBois: 0,
          doublage: 0,
          cloison: 0,
          bandes: 0,
          ttlPrixPlaco: 0
        },
        revetementInt: {
          //
          carrelageInitial: 0,
          carrelagePrestige: 0,
          faience: 0,
          chape: 0,
          parquet: 0,
          ttlPrixRevetementInt: 0,
          //OPTION
          carrelageInitialOption: 0,
          carrelagePrestigeOption: 0,
          faienceOption: 0,
          ttlPrixCarrelageOption: 0,
          ttlPrixDoucheItalOption: 0,
          ttlPrixFaienceOption: 0,
          ttlPrixParquetOption: 0
        },
        electricite: {
          pieces: {
            surfacePdv: 0,
            nombrePdv: 0,
            surfaceCuisine: 0,
            nombreCuisine: 0,
            surfaceCellier: 0,
            nombreCellier: 0,
            surfaceDgt: 0,
            nombreDgt: 0,
            surfaceWc: 0,
            nombreWc: 0,
            surfaceSde: 0,
            nombreSde: 0,
            surfaceChambres: 0,
            nombreChambres: 0,
            surfaceGarage: 0,
            nombreGarage: 0,
            surfaceTerrasse: 0,
            nombreTerrasse: 0,
          },
          ttlPrixElectricite: 0
        },
        revetementExt: {
          enduit: 0,
          ttlPrixRevetementExt: 0
        },
        plomberie: {
          baignoire: {
            nombre: 0,
            nombreOption: 0,
            nombreClient: 0
          },
          doucheItal: {
            nombre: 0,
            nombreOption: 0,
            nombreClient: 0
          },
          bacADouche: {
            nombre: 0,
            nombreOption: 0,
            nombreClient: 0
          },
          doucheExtraPlate: {
            nombre: 0,
            nombreOption: 0,
            nombreClient: 0
          },
          wcSimple: {
            nombre: 0,
            nombreOption: 0,
            nombreClient: 0
          },
          wcSuspendu: {
            nombre: 0,
            nombreOption: 0,
            nombreClient: 0
          },
          lavabo: {
            nombre: 0,
            nombreOption: 0,
            nombreClient: 0
          },
          laveMains: {
            nombre: 0,
            nombreOption: 0,
            nombreClient: 0
          },
          ttlFaience: {
            nombre: 0,
            nombreOption: 0,
            nombreClient: 0
          },
          ttlSanitaires: {
            nombre: 0,
            nombreOption: 0,
            nombreClient: 0
          },
          //
          ttlPrixPlomberie: 0,
          //OPTION
          ttlPrixPlomberieOption: 0
        },
        chauffage: {
          prixChauffage: 0,
          prixRadiateurs: 0,
          ttlPrixChauffage: 0
        },
        peinture: {
          murPlafond: 0,
          portes: 0,
          murPlafondOption: 0,
          portesOption: 0,
          ttlPrixPeinture: 0,
          ttlPrixPeintureOption: 0
        },
        //PRE-RECAP,
        diagnostiqueBBCFraisDivers: {
          ttlPrixDiagnostiqueBBCFraisDivers: 1300
        },
        origineProspect: {
          ttlPrixOrigineProspect: 0
        },
        ajustement: {
          ttlPrixAjustement: 0
        },
        architecte: {
          ttlPrixArchitecte: 0
        },
        //RECAP
        recap: {
          //interne
          ttlHtRevient: 0,
          ttlHtGain: 0,
          pourcentageGain: 0,
          ttlHtInterne: 0,
          tvaInterne: 0,
          ttlTtcGlInterne: 0,
          ttcRcpInterne: 0,
          dommageOuvrableInterne: 0,
          ttlCoutProjetInterne: 0,
          //agco
          ttlHtAgco: 0,
          tvaAgco: 0,
          ttlTtcGlAgco: 0,
          ttcRcpAgco: 0,
          dommageOuvrableAgco: 0,
          ttlCoutProjetAgco: 0,
          commissionCommerciale: 0,
          supplementRemy: 0,
          //options
          options: {
            carrelage: 0,
            ttlCarrelage: 0,
            faience: 0,
            ttlFaience: 0,
            parquet: 0,
            ttlParquet: 0,
            sanitaires: 0,
            ttlSanitaires: 0,
            peinture: 0,
            ttlPeinture: 0,
            parpaings: 0,
            ttlParpaings: 0
          }
        }
      },
      qTexts: {},
      listTextes: TextesDevis,
      flagDone: false,
      selectOnglet: 'devis',
      paramHausseDePrix: '0',
      paramDepartement: '0'
    }
  },
  watch: {
    clientId: async function (val) {
      this.gForm = {};
      if (Number.parseInt(val) > 0) {
        await this.formatTextes();
        let response = await queryGet('descriptif', this.clientId);
        if (response && Object.keys(response).length > 0 && response.constructor === Object) {
          this.gForm = response;
          this.qParameters = await queryGet('parameters');
          if (this.qParameters && this.qParameters.length) {
            this.qParameters.forEach(param => {
              if (param.key === PKeys.HAUSSE_DE_PRIX_COEFF) {
                this.paramHausseDePrix = param.value;
              }
              else if (param.key === PKeys.DEPARTEMENT_COEFF) {
                this.paramDepartement = param.value;
              }
            });
            this.computeSequence();
          }
        }
      }
    },
    flagDone: function (val) {
      if (val) {
        this.generateDevis();
      }
    },
  },
  computed: {
    isAdmin: function () {
      return this.getAuthData.isAdmin === 1 || this.getAuthData.isAdmin === 2;
    },
    ...mapGetters(['getAuthData'])
  },
  methods: {
    generateDevis() {
      if (this.flagDone) {
        generateDevisPdf(this.$refs.pdfFrame, this.gForm, this.cData, ImagesBase64, this.qTexts);
      }
    },
    //TEXTS
    async formatTextes() {
      this.listTextes.forEach(async (text) => {
        let qResultText = await queryGetByParamKey('texts', text.key);
        if (qResultText && qResultText.length && qResultText[0]) {
          this.$set(this.qTexts, text.key, qResultText[0].value);
        }
        else {
          this.$set(this.qTexts, text.key, text.initValue);
        }
      });
    },
    //COMPUTE
    async computeSequence() {
      // 1. TERRASSEMENT
      await this.computeTerrassement();
      // 2. VIDE SANITAIRE / SOUS-SOL
      await this.computeVideSanitaire();
      // 3. RDC
      await this.computeRdc();
      // 4. COMBLES
      await this.computeCombles();
      // 5. PIGNON RDC
      await this.computePignonRdc();
      // 6. TOITURE RDC 
      await this.computeToitureRdc();
      // 7. OUVERTURE
      await this.computeOuverture();
      // 8. PLACO
      await this.computePlaco();
      // 9. REVETEMENT INT
      await this.computeRevetementInt();
      // 10. ELECTRICITE
      await this.computeElectricite();
      // 11. REVETEMENT EXT
      await this.computeRevetementExt();
      // 12. PLOMBERIE
      await this.computePlomberie();
      // 13. CHAUFFAGE
      await this.computeChauffage();
      // 14. PEINTURE
      await this.computePeinture();
      // --. AUTRE
      await this.computeAutre();
      // XX. RECAP
      await this.computeRecap();
      // !!. FLAG END
      this.flagDone = true;
    },
    //FORMULES
    async computeTerrassement() {
      await addDecaissement(
        this.cData, 
        this.gForm.constructions.empriseSol,
        this.gForm.videSanitaires.nombreRangs
      );
      await addFondation(
        this.cData, 
        this.gForm.videSanitaires.lngGdFacade, 
        this.gForm.videSanitaires.lngGdPignon, 
        this.gForm.videSanitaires.lngRefend, 
        this.gForm.videSanitaires.lngDecroche
      );
      await addExtra(
        this.cData
      );
      await addBranchement(
        this.cData, 
      );
      this.cData.terrassement.ttlPrixTerrassement = parseFloat(this.cData.terrassement.ttlPrixTerrassement.toFixed(2));
    },
    async computeVideSanitaire() {
      await computeParamsVS(
        this.cData, 
        this.gForm.constructions.empriseSol, 
        this.gForm.pieces,
        this.gForm.videSanitaires.nombreRangs
      );
      await addFondationBeton(
        this.cData, 
        this.cData.terrassement.fondation, 
        this.cData.videSanitaire.plancherBeton
      );
      await addMac(
        this.cData, 
        this.cData.terrassement.fondation, 
        this.gForm.videSanitaires.nombreRangs
      );
      await addPlancherTerrePlein(
        this.cData, 
        this.gForm.constructions.empriseSol, 
        this.gForm.videSanitaires.nombreRangs
      );
      await addPlancherIso(
        this.cData, 
        this.cData.videSanitaire.shonRdc
      );
      await addPlancherNonIso(
        this.cData, 
        this.gForm.constructions.empriseSol, 
        this.cData.videSanitaire.shonRdc
      );
      await addPlancherChauffant(
        this.cData, 
        this.gForm.chauffages.chauffage,
        this.cData.videSanitaire.surfaceHabitable
      );
      await addEnduitVS(
        this.cData,
        this.gForm.videSanitaires.nombreRangs,
        this.gForm.videSanitaires.lngGdFacade,
        this.gForm.videSanitaires.lngGdPignon,
        this.gForm.videSanitaires.lngDecroche
      );
      await addBetonPlancher(
        this.cData,
        this.cData.videSanitaire.plancherBeton
      );
      await computeParpaingsSupp(
        this.cData,
        this.cData.terrassement.fondation,
        this.gForm.constructions.empriseSol
      );
      this.cData.videSanitaire.ttlPrixVideSanitaire = parseFloat(this.cData.videSanitaire.ttlPrixVideSanitaire.toFixed(2));
    },
    async computeRdc() {
      await computeParamsRdc(
        this.cData,
        this.gForm.portes.nombreEntree,
        this.gForm.portes.nombreService,
        this.gForm.portes.nombreGarage,
        this.gForm.fenetres
      );
      await addStructureRdc(
        this.cData, 
        this.gForm.placos.videSousPlafondRdc, 
        this.gForm.detailRdcs.lngGdFacade, 
        this.gForm.detailRdcs.lngGdPignon, 
        this.gForm.detailRdcs.lngDecroche
      );
      await addSeuilAppuis(
        this.cData,
        this.cData.rdc.nombreOuvertures
      );
      await addVolets(
        this.cData, 
        this.cData.rdc.nombreVolets
      )
      await addPoutres(
        this.cData,
        this.gForm.detailRdcs.nombrePoutres
      );
      await addPoteaux(
        this.cData,
        this.gForm.detailRdcs.nombrePoteaux
      );
      // await addPlancherBois(
      //   this.cData,
      //   this.gForm.combles.type,
      //   this.gForm.toitRdcs.lngGdFacade,
      //   this.gForm.combles.lrgPlancher
      // );
      this.cData.rdc.ttlPrixRdc = parseFloat(this.cData.rdc.ttlPrixRdc.toFixed(2));      
    },
    async computeCombles() {
      await addEncuvement(
        this.cData, 
        this.gForm.combles.encuvement, 
        this.gForm.detailRdcs.lngGdFacade, 
        this.gForm.detailRdcs.lngGdPignon, 
        this.gForm.combles.lngDecroche
      );
      this.cData.combles.ttlPrixCombles = parseFloat(this.cData.combles.ttlPrixCombles.toFixed(2));      
    },
    async computePignonRdc() {
      await computeParamsPignonRdc(
        this.cData, 
        this.gForm.toitRdcs.type, 
        this.gForm.toitRdcs.inclinaison, 
        this.gForm.toitRdcs.lngFacade, 
        this.gForm.toitRdcs.lngPignon, 
        this.gForm.detailRdcs.lngDecroche
      );
      await addStructurePignonRdc(this.cData);
      // await addStructureSpePignonRdc(
      //   this.cData, 
      //   this.gForm.toitRdcs.typeToitSpecial,
      //   this.gForm.toitRdcs.facadeSpecial,
      //   this.gForm.toitRdcs.pignonSpecial
      // );
      await addArase(
        this.cData,
        this.gForm.toitRdcs.inclinaison,
        this.gForm.detailRdcs.lngGdPignon
      );
      this.cData.pignonRdc.ttlPrixPignonRdc = parseFloat(this.cData.pignonRdc.ttlPrixPignonRdc.toFixed(2));      
    },
    async computeToitureRdc() {
      await computeParamsToitureRdc(
        this.cData,
        this.gForm.toitRdcs.type,
        this.gForm.toitRdcs.inclinaison,
        this.gForm.toitRdcs.lngFacade,
        this.gForm.toitRdcs.lngPignon,
        this.gForm.toitRdcs.facadeL,
        this.gForm.toitRdcs.pignonL,
        this.gForm.toitRdcs.facadeCoyaux,
        this.gForm.toitRdcs.pignonCoyaux,
        this.gForm.toitRdcs.facadeSpe,
        this.gForm.combles.ratioComblesAmenageables,
        this.gForm.toitRdcs.typeCouverture,
        this.gForm.toitRdcs.nombrePentes
      );
      await addComblesPerdus(this.cData);
      await addNombreComblesPerdus(this.cData);
      await addComblesAmenageables(this.cData);
      await addNombreComblesAmenageables(this.cData);
      await addTaillageLamelleCollee(this.cData, 
        this.gForm.toitRdcs.lngFacade
      );
      await addCharpenteTradi(this.cData);
      await addNombreCharpenteTradi(
        this.cData, 
        this.gForm.toitRdcs.nombrePentes, 
        this.gForm.toitRdcs.lngFacade, 
        this.gForm.toitRdcs.facadeL
      );
      await addCouverture(
        this.cData, 
        this.gForm.toitRdcs.typeCouverture
      );
      // await addToitSpecial(
      //   this.cData, 
      //   this.gForm.toitRdcs.typeToitSpecial, 
      //   this.gForm.toitRdcs.facadeSpecial, 
      //   this.gForm.toitRdcs.pignonSpecial
      // );
      await addGouttiere(
        this.cData, 
        this.gForm.toitRdcs.lngFacade,
        this.gForm.toitRdcs.facadeL,
        this.gForm.toitRdcs.lngPignon,
        this.gForm.toitRdcs.pignonL
      );
      await addDescente(
        this.cData, 
        this.gForm.toitRdcs.type,
        this.gForm.toitRdcs.lngFacade,
        this.gForm.toitRdcs.facadeCoyaux,
        this.gForm.toitRdcs.facadeL,
        this.gForm.toitRdcs.pignonL
      );
      await addTransport(this.cData);
      await addPenetration(
        this.cData, 
        this.gForm.toitRdcs.type
      );
      await addAccrotere(
        this.cData,
        this.gForm.toitRdcs.lngFacade,
        this.gForm.toitRdcs.lngPignon
      );
      this.cData.toitureRdc.ttlPrixToitureRdc = parseFloat(this.cData.toitureRdc.ttlPrixToitureRdc.toFixed(2));      
    },
    async computeOuverture() {
      await computeFenetres(
        this.cData, 
        this.gForm.fenetres
      );
      await ouvertureAddPorteEntree(
        this.cData, 
        this.gForm.portes.nombreEntree,
        this.gForm.portes.facadeEntree, 
        this.gForm.portes.modeleEntree, 
        //
        //
        SKeys.MateriauxMenuiserie.ALU,
        //this.gForm.menuiseries.materiau
        this.gForm.menuiseries.couleur
      );
      await ouvertureAddTierce( 
        this.cData, 
        this.gForm.portes.ouvrantEntree,
        this.gForm.portes.nombreEntree,
        this.gForm.portes.facadeEntree,
        this.gForm.menuiseries.couleur
      );
      await ouvertureAddPorteService(
        this.cData, 
        this.gForm.portes.nombreService,
        this.gForm.portes.facadeService, 
        this.gForm.portes.modeleService,
        this.gForm.menuiseries.materiau,
        this.gForm.menuiseries.couleur
      );
      await ouvertureAddPorteGarage(
        this.cData,
        this.gForm.portes.couleurGarage,
        this.gForm.portes.tailleGarage,
        this.gForm.portes.modeleGarage,
        this.gForm.portes.nombreGarage,
        this.gForm.portes.typeGarage,
        this.gForm.menuiseries.couleur
      );
      this.gForm.fenetres.forEach(async (fenetre) => {
        await ouvertureAddFenetre(
          this.cData, 
          fenetre.type, 
          fenetre.nombre, 
          getFenetreHauteur(fenetre.dimensions), 
          getFenetreLargeur(fenetre.dimensions), 
          fenetre.emplacement,
          fenetre.dimensions, 
          this.gForm.menuiseries.materiau,
          this.gForm.menuiseries.couleur
      );
      });
      await ouvertureAddNombreMenuiserie(this.cData);
      await computeSurfaceVitreeNord(this.cData, this.gForm.orientations.orientation);
      await computeSurfaceVitreeEst(this.cData, this.gForm.orientations.orientation);
      await computeSurfaceVitreeOuest(this.cData, this.gForm.orientations.orientation);
      await computeSurfaceVitreeSud(this.cData, this.gForm.orientations.orientation);
      await computeSurfaceVitreeColor(this.cData);
      // await ouvertureAddPrixABF(this.cData, this.gForm.menuiseries.couleur);
      this.cData.ouverture.ttlSurfaceVitree = parseFloat(this.cData.ouverture.ttlSurfaceVitree.toFixed(2));
      this.cData.ouverture.ttlPrixOuverture = parseFloat(this.cData.ouverture.ttlPrixOuverture.toFixed(2));
    },
    async computePlaco() {
      await computeParamsPlaco(
        this.cData,
        this.gForm.placos.plafond,
        this.gForm.combles.type,
        this.gForm.combles.ratioComblesAmenageables,
        this.gForm.detailRdcs.lngGdFacade,
        this.gForm.detailRdcs.lngGdPignon,
        this.gForm.videSanitaires.lngGdFacade,
        this.gForm.videSanitaires.lngGdPignon,
        this.gForm.constructions.empriseSol,
        this.gForm.pieces,
        this.gForm.placos.doublageRdc,
        this.gForm.placos.cloisonRdc,
        this.gForm.placos.videSousPlafondRdc
      )
      await addPlafondSouffle(
        this.cData
      );
      await addPlafondPose(
        this.cData
      );
      await addPlafondSousPlancherBois(
        this.cData
      );
      await addDoublage(
        this.cData,
      );
      await addCloison(
        this.cData,
        this.gForm.placos.cloison
      );
      await addBandes(
        this.cData
      );
      await addBandesArmees(
        this.cData
      );
      await addBlocPorte(
        this.cData,
        this.gForm.placos.gammeBP,
        this.gForm.placos.modelePorte1,
        this.gForm.placos.modelePorte2,
        this.gForm.placos.nombrePorte1,
        this.gForm.placos.nombrePorte2,
        this.gForm.placos.nombreGalandage
      );
      this.cData.placo.ttlPrixPlaco = parseFloat(this.cData.placo.ttlPrixPlaco.toFixed(2));
    },
    async computeRevetementInt() {
      await computeParamsRevetementInt(
        this.cData,
        this.gForm.pieces,
        this.gForm.revetementsSols.gammePrincipales,
        this.gForm.revetementsSols.optionPrincipales,
        this.gForm.revetementsSols.surfaceAutre,
        this.gForm.revetementsSols.gammeAutre,
        this.gForm.revetementsSols.optionAutre,
        this.gForm.sanitaires,
        this.gForm.chapes.surfaceGarage,
        this.gForm.parquets.option,
        this.gForm.parquets.surface
      );
      await addCarrelageInit(
        this.cData
      );
      await addCarrelagePrestige(
        this.cData
      );
      await addPlintheInit(
        this.cData
      );
      await addPlinthePrestige(
        this.cData
      );
      await addFaience(
        this.cData
      );
      await addChape(
        this.cData
      );
      await addParquet(
        this.cData
      );
      await addPlintheParquet(
        this.cData
      );
      await addTrappeAimant(
        this.cData, 
        this.gForm.sanitaires
      );
      await addDoucheItal(
        this.cData,
        this.gForm.sanitaires
      );
      await addReposeBP(
        this.cData
      );
      this.cData.revetementInt.ttlPrixRevetementInt = parseFloat(this.cData.revetementInt.ttlPrixRevetementInt.toFixed(2));
      this.cData.revetementInt.ttlPrixCarrelageOption = parseFloat(this.cData.revetementInt.ttlPrixCarrelageOption.toFixed(2));
      this.cData.revetementInt.ttlPrixFaienceOption = parseFloat(this.cData.revetementInt.ttlPrixFaienceOption.toFixed(2));
      this.cData.revetementInt.ttlPrixParquetOption = parseFloat(this.cData.revetementInt.ttlPrixParquetOption.toFixed(2));
      this.cData.revetementInt.ttlPrixDoucheItalOption = parseFloat(this.cData.revetementInt.ttlPrixDoucheItalOption.toFixed(2));
    },
    async computeElectricite() {
      await computeParamsElectricite(
        this.cData, 
        this.gForm.pieces
      );
      await addElectriciteGlobale(
        this.cData,
        this.gForm.electricites.type,
        this.gForm.chauffages.chauffage,
        this.gForm.portes.nombreGarage,
        this.gForm.combles.type,
        this.gForm.fenetres,
        this.gForm.portes.typeGarage
      );
      /*
      await addPdv(
        this.cData,
        this.gForm.chauffages.chauffage
      );
      await addChambres(
        this.cData,
        this.gForm.chauffages.chauffage
      );
      await addSde(
        this.cData
      );
      await addWc(
        this.cData
      );
      await addGarage(
        this.cData
      );
      */
      this.cData.electricite.ttlPrixElectricite = parseFloat(this.cData.electricite.ttlPrixElectricite.toFixed(2));
    },
    async computeRevetementExt() {
      await computeParamsRevetementExt(
        this.cData,
        this.gForm.revetementFacades.revetementFacade
      );
      await addEnduit(
        this.cData
      );
      await addEntoilage(
        this.cData
      );
      await addBardage(
        this.cData,
        this.gForm.revetementFacades.revetementFacade
      );
      this.cData.revetementExt.ttlPrixRevetementExt = parseFloat(this.cData.revetementExt.ttlPrixRevetementExt.toFixed(2));
    },
    async computePlomberie() {
      await computeParamsPlomberie(
        this.cData,
        this.gForm.sanitaires
      );
      await addSanitaires(
        this.cData
      );
      this.cData.plomberie.ttlPrixPlomberie = parseFloat(this.cData.plomberie.ttlPrixPlomberie.toFixed(2));
      this.cData.plomberie.ttlPrixPlomberieOption = parseFloat(this.cData.plomberie.ttlPrixPlomberieOption.toFixed(2));
    },
    async computeChauffage() {
      await computeParamsChauffage(
        this.cData,
        this.gForm.chauffages.chauffage
      );
      await addChauffage(
        this.cData,
        this.gForm.toitRdcs.conduit
      );
      this.cData.chauffage.ttlPrixChauffage = parseFloat(this.cData.chauffage.ttlPrixChauffage.toFixed(2));
    },
    async computePeinture() {
      await computeParamsPeinture(
        this.cData,
        this.gForm.peintures.peinture,
        this.gForm.placos.nombrePorte1,
        this.gForm.placos.nombrePorte2,
        this.gForm.placos.nombreGalandage
      );
      await addPeinture(
        this.cData
      );
      this.cData.peinture.ttlPrixPeinture = parseFloat(this.cData.peinture.ttlPrixPeinture.toFixed(2));
      this.cData.peinture.ttlPrixPeintureOption = parseFloat(this.cData.peinture.ttlPrixPeintureOption.toFixed(2));
    },
    //PRE-RECAP
    async computeAutre() {
      await addOrigineProspect(
        this.cData,
        this.gForm.constructions.origineProspect
      );
      await addAjustement(
        this.cData,
        this.gForm.ajustements.montant
      );
      await addArchitecte(
        this.cData
      );
    },
    //RECAP
    async computeRecap() {
      await addHaussesPrix(this.cData);
      await addRecap(
        this.cData,
        this.gForm.constructions.departement,
        this.gForm.constructions.origineProspect,
        this.gForm.clients.surfaceVitreeNord,
      );
      await addOptions(
        this.cData,
        this.gForm.constructions.departement,
        this.gForm.constructions.origineProspect,
        this.gForm.clients.surfaceVitreeNord,
      );
      if (!this.cData.recap.isSurfaceVitreeValid) {
        this.$router.push({name: 'Documents'});
      }
    }
  },
  async mounted() {
    let clientId = this.$route.params.clientId;
    if (Number.parseInt(clientId) && Number.parseInt(clientId) > 0) {
      this.clientId = clientId;
    }
    else {
      this.clientId = null;
      //alert("Erreur lors du chargement des données... Vous allez être redirigé vers votre espace personnel...");
      this.$router.push({name: 'Documents'});
    }
  }
}
</script>

<style>
.generation {
  min-height: 400px;
  overflow: auto;
}

.tab-section {
  margin: 10px 50px;
  border: 1px solid #b0bac0;
  border-radius: 1px;
}
.tab-elmt {
  text-align: center;
  height: 40px;
  background: #fafafa;
  color: #424749;
  font-size: 15px;
  font-weight: 500;
  font-family: "OpenSans400";
  text-transform: uppercase;
  transition: 0.25s;
  white-space: nowrap;
  overflow: hidden;
}
.tab-elmt:hover {
  background: #d9e1e6;
  cursor: pointer;
  transition: 0.25s;
}

.active-tab {
  background: #e9f2f5;
}
.active-tab:hover {
  background: #e9f2f5;
  cursor: pointer;
  transition: 0.25s;
}

.active-section {
  display: flex;
}

.unactive-section {
  display: none;
}

.devis-cnt {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  padding: 20px 50px;
  min-height: 300px;
}

.devis-btn {
  font-style: normal;
  text-transform: uppercase;
  width: 100%;
  min-height: 50px;
  border-radius: 2px;
}

.overflow-auto pre {
  overflow: auto;
}

.overflow-h {
  overflow: hidden;
}
.flx2 {
  flex: 2
}

.recap-cnt {
  padding: 20px 50px;
}
</style>