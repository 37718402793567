<template>
  <div class="documents single-view flex-cnt-col">
    <div class="view-header flex-cnt-row flex-center">
      <div class="flex-cnt-row flex-center">
        <h2 class="view-title">
          BIENVENUE SUR VOTRE ESPACE PERSONNEL
        </h2>
        <img 
          class="reset-icon reset-btn" 
          src="@/assets/icons/refresh-icon.png" 
          alt="refresh"
          v-on:click="queryAllDocuments" 
        >
      </div>
    </div>
    <div class="main-section flx1 flex-cnt-col">
      <div class="addbtn-cnt">
        <button class="addel-btn" v-if="stateDoc === 'version' && isCurrentUser" v-on:click="createDocument">Ajout nouvelle version</button>
        <button class="addel-btn" v-if="stateDoc === 'client' && isCurrentUser" v-on:click="createClient">Ajout nouveau client</button>
      </div>
      <DocumentContainer
        v-bind:stateDoc="stateDoc"
        v-bind:listDocuments="listDocuments"
        v-bind:listUsers="listUsers"
        v-on:refreshDocuments="refreshDocuments"
        v-on:updateState="updateState"
        v-on:updateUser="updateUser"
        v-on:updateClient="updateClient"
        v-on:updateNextVersion="updateNextVersion"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DocumentContainer from "../components/docs/DocumentContainer.vue";
import { queryGet } from "../api/queries"
import { orderBy } from "lodash"

export default {
  components: {
    DocumentContainer
  },
  data: function () {
    return {
      stateDoc: 'user',
      selectUserName: null,
      selectUserId: null,
      selectClientName: null,
      nextVersion: null,
      listDocuments: [],
      listUsers: [],
      listClients: [],
    }
  },
  computed: {
    ...mapGetters(['getAuthData']),
    isCurrentUser: function () {
      if (this.getAuthData.userId === this.selectUserId && this.getAuthData.userName === this.selectUserName) {
        return true;
      }
      else {
        return false;
      }
    }
  },
  methods: {
    updateState(state) {
      this.stateDoc = state;
    },
    updateUser(id, name) {
      this.selectUserId = id;
      this.selectUserName = name;
    },
    updateClient(name) {
      this.selectClientName = name;
    },
    updateNextVersion(version) {
      this.nextVersion = version;
    },
    formatListUsers() {
      this.listUsers = [];
      this.listDocuments.forEach(client => {
        const condition = (element) => element.userId === client.userId && element.userName === client.fk_username;
        if (!this.listUsers.some(condition)) {
          this.listUsers.push({
            userId: client.userId,
            userName: client.fk_username
          });
        }
      });
      this.listDocuments = orderBy(this.listDocuments, ['nomDocument', 'desc']);
    },
    async queryAllDocuments() {
      this.listDocuments = [];
      let response  = await queryGet('clients');
      if (response) {
        this.listDocuments = response;
        this.formatListUsers();
      }
    },
    refreshDocuments() {
      this.queryAllDocuments();
    },
    async createDocument() {
      this.$router.push({name: 'Descriptif', params: {isEditMode: false, nomDocument: this.selectClientName, version: this.nextVersion}});
    },
    async createClient() {
      this.$router.push({name: 'Descriptif', params: {isEditMode: false, version: 1}});
    }
  },
  mounted() {
    this.queryAllDocuments();
  }
}
</script>

<style scoped>
.documents {
  box-sizing: border-box;
  overflow: auto;
  align-items: center;
  min-width: 805px;
}

.main-section {
  padding: 20px 20px 0px 20px;
  box-sizing: border-box;
  width: 100%;
  max-width: 1200px;
}

.view-title {
  margin-left: 35px;
}

.reset-btn:hover {
  transform: scale(1.175);
  transition: 0.1s;
}

.reset-icon {
  max-height: 20px;
  margin-left: 10px;
}

.addbtn-cnt {
  min-height: 25px;
}

.addel-btn {
  width: fit-content;
}
</style>
