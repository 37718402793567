<template>
  <div class="param-table flx1 flex-cnt-col align-center justify-start">
    <h4 class="param-key" v-if="parameter && parameter.title">
      {{parameter.title}}
    </h4>
    <table v-if="parameter">
      <thead>
        <tr>
          <th v-if="hasChoices">
            Choix
          </th>
          <th v-if="hasTypes">
            Type
          </th>
          <th>
            Value
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="input in listInputs" :key="input.index">
          <td v-if="hasChoices">
            {{input.choiceTitle}}
          </td>
          <td v-if="hasTypes">
            {{input.typeTitle}}
          </td>
          <td>
            <ParamInput
              v-bind:isEditMode="true"
              v-bind:queryTable="'parameters'"
              v-bind:queryId="input.queryId"
              v-bind:initialValue="input.value"
              v-bind:queryKey="'value'"
              v-bind:validType="parameter.validType"
              v-bind:inputType="parameter.inputType"
              v-bind:placeholder="parameter.placeholder"
              v-bind:inputUnit="parameter.inputUnit"
              v-bind:showUnitOnly="true"

            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import ParamInput from "../forms/FormInput.vue";

import { queryGetByParamKey, queryPost } from '../../api/queries';


export default {
  components: {
    ParamInput
  },
  data: function () {
    return {
      listInputs: null
    }
  },
  props: {
    parameter: {
      type: Object,
      required: false,
      default: null
    }
  },
  watch: {
    parameter: function () {
      this.formatlistInputs();
      this.queryInputsValue();
    }
  },
  computed: {
    isValidChoices: function () {
      return this.parameter && this.parameter.listChoices && this.parameter.listChoices.length;
    },
    hasChoices: function () {
      if (this.isValidChoices) {
        return true;
      }
      return false;
    },
    hasTypes: function () {
      let flag = false;
      if (this.isValidChoices) {
        this.parameter.listChoices.forEach(choice => {
          if (choice.listTypes && choice.listTypes.length) {
            flag = true;
          }
        });
      }
      return flag;
    }
  },
  methods: {
    formatlistInputs() {
      this.listInputs = [];
      let indexCnt = 0;
      if (this.hasChoices) {
        if (this.hasTypes) {
          this.parameter.listChoices.forEach(choice => {
            if (choice.listTypes && choice.listTypes.length) {
              for (let cnt = 0; cnt < choice.listTypes.length; cnt++) {
                this.listInputs.push({
                  index: indexCnt++, 
                  paramKey: this.parameter.key, 
                  paramTitle: this.parameter.title,
                  choiceKey: choice.key,
                  choiceTitle: choice.title,
                  typeKey: choice.listTypes[cnt].key,
                  typeTitle: choice.listTypes[cnt].title
                });
              }
            }
          });
        }
        else {
          for (let cnt = 0; cnt < this.parameter.listChoices.length; cnt++) {
            this.listInputs.push({
              index: indexCnt++, 
              paramKey: this.parameter.key, 
              paramTitle: this.parameter.title,
              choiceKey: this.parameter.listChoices[cnt].key,
              choiceTitle: this.parameter.listChoices[cnt].title
            });
          }
        }
      }
      else {
        this.listInputs.push({
          index: 0, 
          paramKey: this.parameter.key, 
          paramTitle: this.parameter.title
        });
      }
    },
    queryInputsValue() {
      this.listInputs.forEach(async input => {
        let qResult = await queryGetByParamKey('parameters', this.parameter.key, {
          choice: input.choiceKey ? input.choiceKey : null,
          type: input.typeKey ? input.typeKey : null
        });
        if (qResult && qResult.length && qResult[0]) {
          this.$set(input, 'queryId', qResult[0].id);
          this.$set(input, 'value', qResult[0].value);
        }
        else {
          let cResult = await queryPost('parameters', {
            key: this.parameter.key,
            choice: input.choiceKey ? input.choiceKey : null,
            type: input.typeKey ? input.typeKey : null
          });
          if (Number.parseInt(cResult)) {
            this.$set(input, 'queryId', cResult);
            this.$set(input, 'value', null);
          }
        }
      });
    }
  }
}
</script>

<style scoped>
.param-table {
  margin-top: 20px;
}

.param-key {
  margin-bottom: 20px;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #c6d2d9;
  background: #fff;
  text-align: center;
  text-transform: uppercase;
}

table {
  border-collapse: collapse;
  border: 1px solid #c6d2d9;
}

thead {
  min-height: 30px;
}

th {
  min-width: 150px;
  padding: 5px 10px;
  box-sizing: border-box;
  border: 1px solid #c6d2d9;
  background: rgb(228, 234, 238);
  font-size: 14px;
  font-family: "OpenSans400";
  text-transform: uppercase;
}

tr:nth-child(2n+1) {
  background: #fff;
}
tr:nth-child(2n) {
  background: transparent;
}

td {
  padding: 10px 10px;
  font-size: 13px;
  font-family: "OpenSans400";
  border-left: 1px solid #c6d2d9;
}
</style>