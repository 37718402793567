<template>
  <div class="descriptif single-view flex-cnt-row">
    <NavSideBar
      v-bind:listElements="listForms"
      v-bind:activeIndex="activeIndex"
      v-bind:class="'nav-form'"
    />
    <div class="main-form flex-cnt-col justify-between">
      <div class="view-header flex-cnt-row align-center">
        <div class="flx1">

        </div>
        <div class="flx1 flex-cnt-row flex-center">
          <h2>DESCRIPTIF</h2>
        </div>
        <div class="flx1 flex-cnt-row align-center justify-end">
          <button
            class="title-btn" 
            v-bind:class="{'hide-btn': !isEditMode}" 
            :disabled="!isEditMode" 
            v-on:click="consultDocument(clientId)"
          >
            Récapitulatif >
          </button>
        </div>
      </div>
      <div class="unit-form flx1 flex-cnt-col align-center">
        <FormContainer
          v-bind:class="{'active-form': activeIndex === 0}"

          v-bind:formIndex="0"
          v-bind:isEditMode="isEditMode"
          v-bind:formTitle="'client'"
          v-bind:clientId="clientId"
          v-bind:queryTable="'clients'"
          v-bind:listeInputs="inputsClient"
          v-bind:activeIndex="activeIndex"
          v-bind:blockedNomDoc="nomDoc"
          v-bind:blockedVersion="versionDoc"

          v-on:updateForm="updateForm"
          v-on:enableNext="enableNext"
        />
        <FormContainer
          v-bind:class="{'active-form': activeIndex === 1}"
          
          v-bind:formIndex="1"
          v-bind:isEditMode="isEditMode"
          v-bind:formTitle="'construction'"
          v-bind:clientId="clientId"
          v-bind:queryTable="'constructions'"
          v-bind:listeInputs="inputsConstruction"
          v-bind:activeIndex="activeIndex"

          v-on:updateForm="updateForm"
          v-on:updatePreOption="updatePreOption"
          v-on:enableNext="enableNext"
        />
        <FormMultiple
          v-bind:class="{'active-form': activeIndex === 2}"
          
          v-bind:formIndex="2"
          v-bind:isEditMode="isEditMode"
          v-bind:formTitle="'surface plancher rdc'"
          v-bind:clientId="clientId"
          v-bind:queryTable="'pieces'"
          v-bind:listeInputs="inputsPiece"
          v-bind:activeIndex="activeIndex"
          v-bind:indicData="surfaceHabitable"

          v-on:updateForm="updateForm"
          v-on:enableNext="enableNext"
        />
        <FormContainer
          v-bind:class="{'active-form': activeIndex === 3}"
          
          v-bind:formIndex="3"
          v-bind:isEditMode="isEditMode"
          v-bind:formTitle="'vide sanitaire'"
          v-bind:clientId="clientId"
          v-bind:queryTable="'videSanitaires'"
          v-bind:listeInputs="inputsVideSanitaire"
          v-bind:activeIndex="activeIndex"

          v-on:updateForm="updateForm"
          v-on:enableNext="enableNext"
        />
        <FormContainer
          v-bind:class="{'active-form': activeIndex === 4}"
          
          v-bind:formIndex="4"
          v-bind:isEditMode="isEditMode"
          v-bind:formTitle="'rdc'"
          v-bind:clientId="clientId"
          v-bind:queryTable="'detailRdcs'"
          v-bind:listeInputs="inputsDetailRdc"
          v-bind:activeIndex="activeIndex"

          v-on:updateForm="updateForm"
          v-on:enableNext="enableNext"
        />
        <FormContainer
          v-bind:class="{'active-form': activeIndex === 5}"
          
          v-bind:formIndex="5"
          v-bind:isEditMode="isEditMode"
          v-bind:formTitle="'combles'"
          v-bind:clientId="clientId"
          v-bind:queryTable="'combles'"
          v-bind:listeInputs="inputsCombles"
          v-bind:hasPreOptionForm="gForm['combles']"
          v-bind:resetFlag="gReset['combles'].resetFlag"
          v-bind:resetKeys="gReset['combles'].resetKeys"
          v-bind:activeIndex="activeIndex"

          v-on:updateForm="updateForm"
          v-on:updatePreOption="updatePreOption"
          v-on:enableNext="enableNext"
        />
        <FormContainer
          v-bind:class="{'active-form': activeIndex === 6}"
          
          v-bind:formIndex="6"
          v-bind:isEditMode="isEditMode"
          v-bind:formTitle="'toit rdc'"
          v-bind:clientId="clientId"
          v-bind:queryTable="'toitRdcs'"
          v-bind:listeInputs="inputsToitRdc"
          v-bind:hasPreOptionForm="gForm['constructions']"
          v-bind:resetFlag="gReset['toitRdcs'].resetFlag"
          v-bind:resetKeys="gReset['toitRdcs'].resetKeys"
          v-bind:activeIndex="activeIndex"
          
          v-on:updateForm="updateForm"
          v-on:updatePreOption="updatePreOption"
          v-on:enableNext="enableNext"
        />
        <FormContainer
          v-bind:class="{'active-form': activeIndex === 7}"
          
          v-bind:formIndex="7"
          v-bind:isEditMode="isEditMode"
          v-bind:formTitle="'orientation de la maison'"
          v-bind:clientId="clientId"
          v-bind:queryTable="'orientations'"
          v-bind:listeInputs="inputsOrientation"
          v-bind:activeIndex="activeIndex"

          v-on:updateForm="updateForm"
          v-on:enableNext="enableNext"
        />
        <FormContainer
          v-bind:class="{'active-form': activeIndex === 8}"
          
          v-bind:formIndex="8"
          v-bind:isEditMode="isEditMode"
          v-bind:formTitle="'menuiserie'"
          v-bind:clientId="clientId"
          v-bind:queryTable="'menuiseries'"
          v-bind:listeInputs="inputsMenuiserie"
          v-bind:hasPreOptionForm="gForm['menuiseries']"
          v-bind:resetFlag="gReset['menuiseries'].resetFlag"
          v-bind:resetKeys="gReset['menuiseries'].resetKeys"
          v-bind:activeIndex="activeIndex"
          v-bind:indicData="validSurfaceVitree"

          v-on:updateForm="updateForm"
          v-on:updatePreOption="updatePreOption"
          v-on:enableNext="enableNext"
        />
        <FormContainer
          v-bind:class="{'active-form': activeIndex === 9}"
          
          v-bind:formIndex="9"
          v-bind:isEditMode="isEditMode"
          v-bind:formTitle="'portes'"
          v-bind:clientId="clientId"
          v-bind:queryTable="'portes'"
          v-bind:listeInputs="inputsPortes"
          v-bind:activeIndex="activeIndex"
          v-bind:indicData="validSurfaceVitree"

          v-on:updateForm="updateForm"
          v-on:enableNext="enableNext"
        />
        <FormMultiple
          v-bind:class="{'active-form': activeIndex === 10}"
          
          v-bind:formIndex="10"
          v-bind:isEditMode="isEditMode"
          v-bind:formTitle="'ouvertures'"
          v-bind:clientId="clientId"
          v-bind:queryTable="'fenetres'"
          v-bind:listeInputs="inputsFenetre"
          v-bind:hasPreOptionForm="gForm['fenetres']"
          v-bind:resetFlag="gReset['fenetres'].resetFlag"
          v-bind:resetKeys="gReset['fenetres'].resetKeys"
          v-bind:resetIndex="gReset['fenetres'].resetIndex"
          v-bind:activeIndex="activeIndex"
          v-bind:indicData="validSurfaceVitree"

          v-on:updateForm="updateForm"
          v-on:updatePreOptionMultiple="updatePreOptionMultiple"
          v-on:enableNext="enableNext"
        />
        <!--
        <FormMultiple
          v-bind:class="{'active-form': activeIndex === 11}"
          
          v-bind:formIndex="11"
          v-bind:isEditMode="isEditMode"
          v-bind:formTitle="'ouvertures spéciales'"
          v-bind:clientId="clientId"
          v-bind:queryTable="'fenetreSpeciales'"
          v-bind:listeInputs="inputsFenetreSpeciale"
          v-bind:activeIndex="activeIndex"
          v-bind:indicData="validSurfaceVitree"

          v-on:updateForm="updateForm"
          v-on:enableNext="enableNext"
        />
        -->
        
        <FormSurfaceVitree
          v-bind:class="{'active-form': activeIndex === 11}"
          
          v-bind:formIndex="11"
          v-bind:isEditMode="isEditMode"
          v-bind:clientId="gForm.clients.id"
          v-bind:formTitle="'Répartition surface vitrée'"
          v-bind:activeIndex="activeIndex"
          
          v-bind:surfaceVitreeNord="surfaceVitreeNord"
          v-bind:surfaceVitreeSud="surfaceVitreeSud"
          v-bind:surfaceVitreeEst="surfaceVitreeEst"
          v-bind:surfaceVitreeOuest="surfaceVitreeOuest"

          v-on:updateSurfaceVitreeColorInt="updateSurfaceVitreeColorInt"
          v-on:enableNext="enableNext"
        />
        <FormContainer
          v-bind:class="{'active-form': activeIndex === 12}"
          
          v-bind:formIndex="12"
          v-bind:isEditMode="isEditMode"
          v-bind:formTitle="'placo'"
          v-bind:clientId="clientId"
          v-bind:queryTable="'placos'"
          v-bind:listeInputs="inputsPlaco"
          v-bind:activeIndex="activeIndex"

          v-on:updateForm="updateForm"
          v-on:enableNext="enableNext"
        />
        <FormContainer
          v-bind:class="{'active-form': activeIndex === 13}"
          
          v-bind:formIndex="13"
          v-bind:isEditMode="isEditMode"
          v-bind:formTitle="'electricite'"
          v-bind:clientId="clientId"
          v-bind:queryTable="'electricites'"
          v-bind:listeInputs="inputsElectricite"
          v-bind:activeIndex="activeIndex"

          v-on:updateForm="updateForm"
          v-on:enableNext="enableNext"
        />
        <FormContainer
          v-bind:class="{'active-form': activeIndex === 14 || activeIndex === 15 || activeIndex === 16}"
          
          v-bind:formIndex="14"
          v-bind:isEditMode="isEditMode"
          v-bind:formTitle="'Carrelage'"
          v-bind:clientId="clientId"
          v-bind:queryTable="'revetementsSols'"
          v-bind:listeInputs="inputsCarrelage"
          v-bind:activeIndex="activeIndex"
          v-bind:indicData="surfacePrincipales"

          v-on:updateForm="updateForm"
          v-on:enableNext="enableNext"
        />
        <FormContainer
          v-bind:class="{'active-form': activeIndex === 14 || activeIndex === 15 || activeIndex === 16}"
          
          v-bind:formIndex="15"
          v-bind:isEditMode="isEditMode"
          v-bind:formTitle="'chape'"
          v-bind:clientId="clientId"
          v-bind:queryTable="'chapes'"
          v-bind:listeInputs="inputsChape"
          v-bind:activeIndex="activeIndex"
          v-bind:indicData="surfaceChapeChambres"

          v-on:updateForm="updateForm"
          v-on:enableNext="enableNext"
        />
        <FormContainer
          v-bind:class="{'active-form': activeIndex === 14 || activeIndex === 15 || activeIndex === 16}"
          
          v-bind:formIndex="16"
          v-bind:isEditMode="isEditMode"
          v-bind:formTitle="'parquet'"
          v-bind:clientId="clientId"
          v-bind:queryTable="'parquets'"
          v-bind:listeInputs="inputsParquet"
          v-bind:activeIndex="activeIndex"

          v-on:updateForm="updateForm"
          v-on:enableNext="enableNext"
        />
        <FormMultiple
          v-bind:class="{'active-form': activeIndex === 17}"
          
          v-bind:formIndex="17"
          v-bind:isEditMode="isEditMode"
          v-bind:formTitle="'sanitaires'"
          v-bind:clientId="clientId"
          v-bind:queryTable="'sanitaires'"
          v-bind:listeInputs="inputsSanitaires"
          v-bind:hasPreOptionForm="gForm['sanitaires']"
          v-bind:resetFlag="gReset['sanitaires'].resetFlag"
          v-bind:resetKeys="gReset['sanitaires'].resetKeys"
          v-bind:resetIndex="gReset['sanitaires'].resetIndex"
          v-bind:activeIndex="activeIndex"

          v-on:updateForm="updateForm"
          v-on:updatePreOptionMultiple="updatePreOptionMultiple"
          v-on:enableNext="enableNext"
        />
        <FormContainer
          v-bind:class="{'active-form': activeIndex === 18}"
          
          v-bind:formIndex="18"
          v-bind:isEditMode="isEditMode"
          v-bind:formTitle="'chauffage'"
          v-bind:clientId="clientId"
          v-bind:queryTable="'chauffages'"
          v-bind:listeInputs="inputsChauffage"
          v-bind:activeIndex="activeIndex"

          v-on:updateForm="updateForm"
          v-on:enableNext="enableNext"
        />
        <FormContainer
          v-bind:class="{'active-form': activeIndex === 19}"
          
          v-bind:formIndex="19"
          v-bind:isEditMode="isEditMode"
          v-bind:formTitle="'peinture'"
          v-bind:clientId="clientId"
          v-bind:queryTable="'peintures'"
          v-bind:listeInputs="inputsPeinture"
          v-bind:activeIndex="activeIndex"

          v-on:updateForm="updateForm"
          v-on:enableNext="enableNext"
        />
        <FormContainer
          v-bind:class="{'active-form': activeIndex === 20}"
          
          v-bind:formIndex="20"
          v-bind:isEditMode="isEditMode"
          v-bind:formTitle="'revetement façade'"
          v-bind:clientId="clientId"
          v-bind:queryTable="'revetementFacades'"
          v-bind:listeInputs="inputsRevetementFacade"
          v-bind:activeIndex="activeIndex"
          
          v-on:updateForm="updateForm"
          v-on:enableNext="enableNext"
        />
        <FormContainer
          v-bind:class="{'active-form': activeIndex === 21}"
          
          v-bind:formIndex="21"
          v-bind:isEditMode="isEditMode"
          v-bind:formTitle="'ajustement prix (ttc)'"
          v-bind:clientId="clientId"
          v-bind:queryTable="'ajustements'"
          v-bind:listeInputs="inputsAjustement"
          v-bind:activeIndex="activeIndex"
          
          v-on:updateForm="updateForm"
          v-on:enableNext="enableNext"
        />
      </div>
      <div class="foot-form flex-cnt-row justify-between">
        <button
          class="form-btn" 
          v-bind:class="{'hide-btn': activeIndex <= 0}" 
          :disabled="activeIndex <= 0" 
          v-on:click="decrementIndex"
        >
          Précédent
        </button>
        <button
          class="input-btn save-btn" 
          v-if="!isEditMode && isLastForm" 
          v-on:click="saveForm"
        >
          Enregistrer
        </button>
        <button
          class="form-btn" 
          v-bind:class="{'disabled-btn': !authNext}"
          v-if="!isLastForm"
          :disabled="isLastForm || !authNext" 
          v-on:click="incrementIndex"
        >
          Suivant
        </button>
      </div>
    </div>
  </div>
</template>

<script>
//components
import NavSideBar from "../components/NavSideBar.vue"
import FormContainer from '../components/forms/FormContainer.vue'
import FormMultiple from '../components/forms/FormMultiple.vue'
import FormSurfaceVitree from '../components/forms/FormSurfaceVitree.vue'

//functions
import { queryPost, queryPut } from '../api/queries';
//parameters & constants
import { InputTypes } from "../api/validation";
//select keys
import { SKeys } from '../param/selectKeys';
//input list
import { 
  InputsClient, 
  InputsConstruction, 
  InputsVideSanitaire, 
  InputsDetailRdc, 
  InputsCombles, 
  InputsToitRdc, 
  InputsOrientation, 
  InputsMenuiserie,
  InputsPortes,
  InputsPlaco,
  InputsElectricite,
  InputsCarrelage,
  InputsChape,
  InputsParquet,
  InputsChauffage,
  InputsPeinture,
  InputsRevetementFacade,
  InputsPiece,
  InputsFenetre,
  // InputsFenetreSpeciale,
  InputsSanitaires,
  InputsAjustement
} from "../param/inputList";

export default {
  components: {
    NavSideBar,
    FormContainer,
    FormMultiple,
    FormSurfaceVitree
  },
  data: function () {
    return {
      authNext: false,
      clientId: 0,
      activeIndex: 0,
      listForms: [
        {index: 0 , title: 'Client'         },
        {index: 1 , title: 'Construction'   },
        {index: 2 , title: 'Surface RDC'    },
        {index: 3 , title: 'Vide sanitaire' },
        {index: 4 , title: 'RDC'            },
        {index: 5 , title: 'Combles'        },
        {index: 6 , title: 'Toit'           },
        {index: 7 , title: 'Orientation'    },
        {index: 8 , title: 'Menuiserie'     },
        {index: 9, title: 'Portes'          },
        {index: 10, title: 'Ouvertures'     },
        // {index: 11, title: 'Spéciales'      },
        {index: 11, title: 'Surface vitrée' },
        {index: 12, title: 'Placo'          },
        {index: 13, title: 'Electricité'    },
        {index: 14, title: 'Revêtements'     },
        // {index: 15, title: 'Chape'          },
        // {index: 16, title: 'Parquet'        },
        {index: 17, title: 'Sanitaires'     },
        {index: 18, title: 'Chauffage'      },
        {index: 19, title: 'Peinture'       },
        {index: 20, title: 'Façade'         },
        {index: 21, title: 'Ajustement prix'}
      ],
      gForm: {
        clients:           {},
        constructions:     {},
        pieces:            [],
        videSanitaires:    {},
        detailRdcs:        {},
        combles:           {},
        toitRdcs:          {},
        orientations:      {},
        menuiseries:       {},
        portes:            {},
        fenetres:          [],
        fenetreSpeciales:  [],
        placos:            {},
        electricites:      {},
        revetementsSols:   {},
        chapes:            {},
        parquets:          {},
        sanitaires:        [],
        chauffages:        {},
        peintures:         {},
        revetementFacades: {},
        ajustements:    {}
      },
      gReset: {
        combles:     {resetFlag: 0, resetKeys: null, resetIndex: null},
        toitRdcs:    {resetFlag: 0, resetKeys: null, resetIndex: null},
        menuiseries: {resetFlag: 0, resetKeys: null, resetIndex: null},
        fenetres:    {resetFlag: 0, resetKeys: null, resetIndex: null},
        sanitaires:  {resetFlag: 0, resetKeys: null, resetIndex: null}
      },
      isEditMode: null,
      versionDoc: null,
      nomDoc: null,
      validTypes: InputTypes,
      //forms inputs
      inputsClient:           InputsClient          ,
      inputsConstruction:     InputsConstruction    ,
      inputsPiece:            InputsPiece           ,
      inputsVideSanitaire:    InputsVideSanitaire   ,
      inputsDetailRdc:        InputsDetailRdc       ,
      inputsCombles:          InputsCombles         ,
      inputsToitRdc:          InputsToitRdc         ,
      inputsOrientation:      InputsOrientation     ,
      inputsMenuiserie:       InputsMenuiserie      ,
      inputsPortes:           InputsPortes          ,
      inputsFenetre:          InputsFenetre         ,
      // inputsFenetreSpeciale:  InputsFenetreSpeciale ,
      inputsPlaco:            InputsPlaco           ,
      inputsElectricite:      InputsElectricite     ,
      inputsCarrelage:        InputsCarrelage       ,
      inputsChape:            InputsChape           ,
      inputsParquet:          InputsParquet         ,
      inputsSanitaires:       InputsSanitaires      ,
      inputsChauffage:        InputsChauffage       ,
      inputsPeinture:         InputsPeinture        ,
      inputsRevetementFacade: InputsRevetementFacade,
      inputsAjustement:       InputsAjustement
    }
  },
  watch: {
    // surfaceVitreeNord: function () {
    //   this.$set(this.gForm.clients, "surfaceVitreeNord", this.surfaceVitreeNord);
    //   if (this.isEditMode === true && this.gForm.clients.id) {
    //     let data = {
    //       id: this.gForm.clients.id,
    //       surfaceVitreeNord: this.surfaceVitreeNord
    //     }
    //     queryPut("clients", this.gForm.clients.id, data);
    //   }
    // },
    validSurfaceVitree: function () {
      this.$set(this.gForm.clients, "validSurfaceVitree", this.validSurfaceVitree);
      if (this.isEditMode === true && this.gForm.clients.id) {
        let data = {
          id: this.gForm.clients.id,
          validSurfaceVitree: this.validSurfaceVitree
        }
        queryPut("clients", this.gForm.clients.id, data);
      }
    }
  },
  computed: {
    isLastForm: function () {
      return this.activeIndex >= this.listForms.length + 1 ? true : false;
    },
    //PRE-COMPUTE
    surfaceHabitable: function () {
      let sum = 0;
      this.gForm.pieces.forEach(piece => {
        if (piece.type && piece.surface && (piece.type === SKeys.TypesPieces.PIECE_DE_VIE || piece.type === SKeys.TypesPieces.CUISINE || piece.type === SKeys.TypesPieces.CELLIER || piece.type === SKeys.TypesPieces.DGT || piece.type === SKeys.TypesPieces.WC || piece.type === SKeys.TypesPieces.SDE || piece.type === SKeys.TypesPieces.CHAMBRES)) {
          sum += parseFloat(piece.surface);
        }
      });
      return parseFloat(sum).toFixed(2);
    },
    surfacePrincipales: function () {
      let sum = 0;
      this.gForm.pieces.forEach(piece => {
        if (piece.type && piece.surface && (piece.type === SKeys.TypesPieces.PIECE_DE_VIE || piece.type === SKeys.TypesPieces.CUISINE || piece.type === SKeys.TypesPieces.CELLIER || piece.type === SKeys.TypesPieces.DGT || piece.type === SKeys.TypesPieces.WC || piece.type === SKeys.TypesPieces.SDE)) {
          sum += parseFloat(piece.surface);
        }
      });
      return parseFloat(sum).toFixed(2);
    },
    surfaceChapeChambres: function () {
      let surface = 0;
      this.gForm.pieces.forEach(piece => {
        if (piece.type && piece.surface) {
          if (piece.type === SKeys.TypesPieces.CHAMBRES) {
            surface += parseFloat(piece.surface);
          }
        }
      });
      if (this.gForm.revetementsSols.surfaceAutre) {
        surface -= parseFloat(this.gForm.revetementsSols.surfaceAutre);
      }
      return parseFloat(surface).toFixed(2);
    },
    //
    validSurfaceVitree: function () {
      return ( parseFloat(this.ttlSurfaceVitree) - (parseFloat(this.surfaceHabitable)/6) ).toFixed(2);
    },
    ttlSurfaceVitree: function () {
      return ( parseFloat(this.surfaceVitreeAvant) + parseFloat(this.surfaceVitreeArriere) + parseFloat(this.surfaceVitreeGauche) + parseFloat(this.surfaceVitreeDroite) ).toFixed(2);
    },
    surfaceVitreeAvant: function () {
      let sum = 0;
      //ENTREE
      if (this.gForm.portes.nombreEntree && this.gForm.portes.facadeEntree) {
        if (this.gForm.portes.facadeEntree === SKeys.Facades.AVANT) {
          sum += parseFloat(0.9 * 2.15 * Number.parseFloat(this.gForm.portes.nombreEntree));
          if (this.gForm.portes.ouvrantEntree === SKeys.OuvrantsPorte.TIERCE) {
            sum += parseFloat(0.4 * 2.15);
          }
        }
      }
      //SERVICE
      if (this.gForm.portes.nombreService && this.gForm.portes.facadeService && this.gForm.portes.emplacementService) {
        if (this.gForm.portes.facadeService === SKeys.Facades.AVANT && this.gForm.portes.emplacementService === SKeys.EmplacementsPorte.HABITABLE) {
          sum += parseFloat(0.9 * 2.15 * Number.parseFloat(this.gForm.portes.nombreService));
        }
      }
      //FENETRES
      this.gForm.fenetres.forEach(fenetre => {
        if (fenetre.type && fenetre.dimensions && fenetre.emplacement && fenetre.nombre) {
          if (fenetre.emplacement === SKeys.Facades.AVANT) {
            let splitDim = fenetre.dimensions.split('X');
            if (splitDim.length === 2 && Number.parseInt(splitDim[0]) && Number.parseInt(splitDim[1])) {
              if (fenetre.type === SKeys.TypesFenetre.OEIL_DE_BOEUF) {
                sum += (parseFloat(splitDim[0]) / 200) * (parseFloat(splitDim[0]) / 200) * Math.PI * parseFloat(fenetre.nombre);
              }
              else {
                sum += (parseFloat(splitDim[0]) / 100) * (parseFloat(splitDim[1]) / 100) * parseFloat(fenetre.nombre);
              }
            }
          }
        }
      });
      //SPE
      // this.gForm.fenetreSpeciales.forEach(fenetre => {
      //   if (fenetre.largeur && fenetre.hauteur && fenetre.emplacement && fenetre.nombre && fenetre.prix) {
      //     if (fenetre.emplacement === SKeys.Facades.AVANT) {
      //       if (Number.parseInt(fenetre.largeur) && Number.parseInt(fenetre.hauteur)) {
      //         sum += (parseFloat(fenetre.largeur) / 100) * (parseFloat(fenetre.hauteur) / 100) * parseFloat(fenetre.nombre);
      //       }
      //     }
      //   }
      // });
      return parseFloat(sum).toFixed(2);
    },
    surfaceVitreeArriere: function () {
      let sum = 0;
      //ENTREE
      if (this.gForm.portes.nombreEntree && this.gForm.portes.facadeEntree) {
        if (this.gForm.portes.facadeEntree === SKeys.Facades.ARRIERE) {
          sum += parseFloat(0.9 * 2.15 * Number.parseFloat(this.gForm.portes.nombreEntree));
          if (this.gForm.portes.ouvrantEntree === SKeys.OuvrantsPorte.TIERCE) {
            sum += parseFloat(0.4 * 2.15);
          }
        }
      }
      //SERVICE
      if (this.gForm.portes.nombreService && this.gForm.portes.facadeService && this.gForm.portes.emplacementService) {
        if (this.gForm.portes.facadeService === SKeys.Facades.ARRIERE && this.gForm.portes.emplacementService === SKeys.EmplacementsPorte.HABITABLE) {
          sum += parseFloat(0.9 * 2.15 * Number.parseFloat(this.gForm.portes.nombreService));
        }
      }
      //FENETRES
      this.gForm.fenetres.forEach(fenetre => {
        if (fenetre.type && fenetre.dimensions && fenetre.emplacement && fenetre.nombre) {
          if (fenetre.emplacement === SKeys.Facades.ARRIERE) {
            let splitDim = fenetre.dimensions.split('X');
            if (splitDim.length === 2 && Number.parseInt(splitDim[0]) && Number.parseInt(splitDim[1])) {
              if (fenetre.type === SKeys.TypesFenetre.OEIL_DE_BOEUF) {
                sum += (parseFloat(splitDim[0]) / 200) * (parseFloat(splitDim[0]) / 200) * Math.PI * parseFloat(fenetre.nombre);
              }
              else {
                sum += (parseFloat(splitDim[0]) / 100) * (parseFloat(splitDim[1]) / 100) * parseFloat(fenetre.nombre);
              }
            }
          }
        }
      });
      //SPE
      // this.gForm.fenetreSpeciales.forEach(fenetre => {
      //   if (fenetre.type && fenetre.largeur && fenetre.hauteur && fenetre.emplacement && fenetre.nombre && fenetre.prix) {
      //     if (fenetre.emplacement === SKeys.Facades.ARRIERE) {
      //       if (Number.parseInt(fenetre.largeur) && Number.parseInt(fenetre.hauteur)) {
      //         sum += (parseFloat(fenetre.largeur) / 100) * (parseFloat(fenetre.hauteur) / 100) * parseFloat(fenetre.nombre);
      //       }
      //     }
      //   }
      // });
      return parseFloat(sum).toFixed(2);
    },
    surfaceVitreeGauche: function () {
      let sum = 0;
      //ENTREE
      if (this.gForm.portes.nombreEntree && this.gForm.portes.facadeEntree) {
        if (this.gForm.portes.facadeEntree === SKeys.Facades.GAUCHE) {
          sum += parseFloat(0.9 * 2.15 * Number.parseFloat(this.gForm.portes.nombreEntree));
          if (this.gForm.portes.ouvrantEntree === SKeys.OuvrantsPorte.TIERCE) {
            sum += parseFloat(0.4 * 2.15);
          }
        }
      }
      //SERVICE
      if (this.gForm.portes.nombreService && this.gForm.portes.facadeService && this.gForm.portes.emplacementService) {
        if (this.gForm.portes.facadeService === SKeys.Facades.GAUCHE && this.gForm.portes.emplacementService === SKeys.EmplacementsPorte.HABITABLE) {
          sum += parseFloat(0.9 * 2.15 * Number.parseFloat(this.gForm.portes.nombreService));
        }
      }
      //FENETRES
      this.gForm.fenetres.forEach(fenetre => {
        if (fenetre.type && fenetre.dimensions && fenetre.emplacement && fenetre.nombre) {
          if (fenetre.emplacement === SKeys.Facades.GAUCHE) {
            let splitDim = fenetre.dimensions.split('X');
            if (splitDim.length === 2 && Number.parseInt(splitDim[0]) && Number.parseInt(splitDim[1])) {
              if (fenetre.type === SKeys.TypesFenetre.OEIL_DE_BOEUF) {
                sum += (parseFloat(splitDim[0]) / 200) * (parseFloat(splitDim[0]) / 200) * Math.PI * parseFloat(fenetre.nombre);
              }
              else {
                sum += (parseFloat(splitDim[0]) / 100) * (parseFloat(splitDim[1]) / 100) * parseFloat(fenetre.nombre);
              }
            }
          }
        }
      });
      // //SPE
      // this.gForm.fenetreSpeciales.forEach(fenetre => {
      //   if (fenetre.type && fenetre.largeur && fenetre.hauteur && fenetre.emplacement && fenetre.nombre && fenetre.prix) {
      //     if (fenetre.emplacement === SKeys.Facades.GAUCHE) {
      //       if (Number.parseInt(fenetre.largeur) && Number.parseInt(fenetre.hauteur)) {
      //         sum += (parseFloat(fenetre.largeur) / 100) * (parseFloat(fenetre.hauteur) / 100) * parseFloat(fenetre.nombre);
      //       }
      //     }
      //   }
      // });
      return parseFloat(sum).toFixed(2);
    },
    surfaceVitreeDroite: function () {
      let sum = 0;
      //ENTREE
      if (this.gForm.portes.nombreEntree && this.gForm.portes.facadeEntree) {
        if (this.gForm.portes.facadeEntree === SKeys.Facades.DROITE) {
          sum += parseFloat(0.9 * 2.15 * Number.parseFloat(this.gForm.portes.nombreEntree));
          if (this.gForm.portes.ouvrantEntree === SKeys.OuvrantsPorte.TIERCE) {
            sum += parseFloat(0.4 * 2.15);
          }
        }
      }
      //SERVICE
      if (this.gForm.portes.nombreService && this.gForm.portes.facadeService && this.gForm.portes.emplacementService) {
        if (this.gForm.portes.facadeService === SKeys.Facades.DROITE && this.gForm.portes.emplacementService === SKeys.EmplacementsPorte.HABITABLE) {
          sum += parseFloat(0.9 * 2.15 * Number.parseFloat(this.gForm.portes.nombreService));
        }
      }
      //FENETRES
      this.gForm.fenetres.forEach(fenetre => {
        if (fenetre.type && fenetre.dimensions && fenetre.emplacement && fenetre.nombre) {
          if (fenetre.emplacement === SKeys.Facades.DROITE) {
            let splitDim = fenetre.dimensions.split('X');
            if (splitDim.length === 2 && Number.parseInt(splitDim[0]) && Number.parseInt(splitDim[1])) {
              if (fenetre.type === SKeys.TypesFenetre.OEIL_DE_BOEUF) {
                sum += (parseFloat(splitDim[0]) / 200) * (parseFloat(splitDim[0]) / 200) * Math.PI * parseFloat(fenetre.nombre);
              }
              else {
                sum += (parseFloat(splitDim[0]) / 100) * (parseFloat(splitDim[1]) / 100) * parseFloat(fenetre.nombre);
              }
            }
          }
        }
      });
      //SPE
      // this.gForm.fenetreSpeciales.forEach(fenetre => {
      //   if (fenetre.type && fenetre.largeur && fenetre.hauteur && fenetre.emplacement && fenetre.nombre && fenetre.prix) {
      //     if (fenetre.emplacement === SKeys.Facades.DROITE) {
      //       if (Number.parseInt(fenetre.largeur) && Number.parseInt(fenetre.hauteur)) {
      //         sum += (parseFloat(fenetre.largeur) / 100) * (parseFloat(fenetre.hauteur) / 100) * parseFloat(fenetre.nombre);
      //       }
      //     }
      //   }
      // });
      return parseFloat(sum).toFixed(2);
    },
    surfaceVitreeNord: function () {
      let sum = 0;
      if (this.gForm.orientations.orientation) {
        switch (this.gForm.orientations.orientation) {
          case SKeys.Orientations.NORD:
            sum += parseFloat(this.surfaceVitreeAvant);
            break;
        
          case SKeys.Orientations.OUEST:
            sum += parseFloat(this.surfaceVitreeGauche);
            break;
        
          case SKeys.Orientations.SUD:
            sum += parseFloat(this.surfaceVitreeArriere);
            break;
        
          case SKeys.Orientations.EST:
            sum += parseFloat(this.surfaceVitreeDroite);
            break;
        
          case SKeys.Orientations.NORD_OUEST:
            sum += parseFloat(this.surfaceVitreeAvant) + parseFloat(this.surfaceVitreeGauche);
            break;
        
          case SKeys.Orientations.SUD_OUEST:
            sum += parseFloat(this.surfaceVitreeArriere) + parseFloat(this.surfaceVitreeGauche);
            break;
        
          case SKeys.Orientations.NORD_EST:
            sum += parseFloat(this.surfaceVitreeAvant) + parseFloat(this.surfaceVitreeDroite);
            break;
        
          case SKeys.Orientations.SUD_EST:
            sum += parseFloat(this.surfaceVitreeArriere) + parseFloat(this.surfaceVitreeDroite);
            break;
        
          default:
            break;
        }
      }
      return (parseFloat(sum) * 100 / parseFloat(this.ttlSurfaceVitree)).toFixed(0);
    },
    surfaceVitreeSud: function () {
      let sum = 0;
      if (this.gForm.orientations.orientation) {
        switch (this.gForm.orientations.orientation) {
          case SKeys.Orientations.NORD:
            sum += parseFloat(this.surfaceVitreeArriere);
            break;
        
          case SKeys.Orientations.OUEST:
            sum += parseFloat(this.surfaceVitreeDroite);
            break;
        
          case SKeys.Orientations.SUD:
            sum += parseFloat(this.surfaceVitreeAvant);
            break;
        
          case SKeys.Orientations.EST:
            sum += parseFloat(this.surfaceVitreeGauche);
            break;
        
          case SKeys.Orientations.NORD_OUEST:
            sum += parseFloat(this.surfaceVitreeArriere) + parseFloat(this.surfaceVitreeDroite);
            break;
        
          case SKeys.Orientations.SUD_OUEST:
            sum += parseFloat(this.surfaceVitreeAvant) + parseFloat(this.surfaceVitreeDroite);
            break;
        
          case SKeys.Orientations.NORD_EST:
            sum += parseFloat(this.surfaceVitreeArriere) + parseFloat(this.surfaceVitreeGauche);
            break;
        
          case SKeys.Orientations.SUD_EST:
            sum += parseFloat(this.surfaceVitreeAvant) + parseFloat(this.surfaceVitreeGauche);
            break;
        
          default:
            break;
        }
      }
      return (parseFloat(sum) * 100 / parseFloat(this.ttlSurfaceVitree)).toFixed(0);
    },
    surfaceVitreeEst: function () {
      let sum = 0;
      if (this.gForm.orientations.orientation) {
        switch (this.gForm.orientations.orientation) {
          case SKeys.Orientations.NORD:
            sum += parseFloat(this.surfaceVitreeGauche);
            break;
        
          case SKeys.Orientations.OUEST:
            sum += parseFloat(this.surfaceVitreeArriere);
            break;
        
          case SKeys.Orientations.SUD:
            sum += parseFloat(this.surfaceVitreeDroite);
            break;
        
          case SKeys.Orientations.EST:
            sum += parseFloat(this.surfaceVitreeAvant);
            break;
        
          default:
            break;
        }
      }
      return (parseFloat(sum) * 100 / parseFloat(this.ttlSurfaceVitree)).toFixed(0);
    },
    surfaceVitreeOuest: function () {
      let sum = 0;
      if (this.gForm.orientations.orientation) {
        switch (this.gForm.orientations.orientation) {
          case SKeys.Orientations.NORD:
            sum += parseFloat(this.surfaceVitreeDroite);
            break;
        
          case SKeys.Orientations.OUEST:
            sum += parseFloat(this.surfaceVitreeAvant);
            break;
        
          case SKeys.Orientations.SUD:
            sum += parseFloat(this.surfaceVitreeGauche);
            break;
        
          case SKeys.Orientations.EST:
            sum += parseFloat(this.surfaceVitreeArriere);
            break;
        
          default:
            break;
        }
      }
      return (parseFloat(sum) * 100 / parseFloat(this.ttlSurfaceVitree)).toFixed(0);
    },
  },
  methods: {
    updateForm(formKey, formData) {
      this.$set(this.gForm, formKey, formData);
    },
    updatePreOption(formKey, inputKeys) {
      inputKeys.forEach(key => {
        this.$set(this.gForm[formKey], key, null);
      });
      this.$set(this.gReset[formKey], 'resetKeys', inputKeys);
      this.$set(this.gReset[formKey], 'resetFlag', this.gReset[formKey].resetFlag + 1);
    },
    updatePreOptionMultiple(formKey, inputKeys, inputIndex) {
      inputKeys.forEach(key => {
        this.$set(this.gForm[formKey], key, null);
      });
      this.$set(this.gReset[formKey], 'resetKeys', inputKeys);
      this.$set(this.gReset[formKey], 'resetIndex', inputIndex);
      this.$set(this.gReset[formKey], 'resetFlag', this.gReset[formKey].resetFlag + 1);
    },
    async saveForm() {
      if (!this.isEditMode) {
        this.$el.querySelector('.save-btn').disabled = true;
        let response = await queryPost('descriptif', this.gForm);
        if (Number.parseInt(response)) {
          this.isEditMode = true;
          this.clientId = response;
        }
        else {
          this.$el.querySelector('.save-btn').disabled = false;
        }
      }
    },
    consultDocument(clientId) {
      this.$router.push({name: 'Recapitulatif', params: {clientId: clientId}})
    },
    incrementIndex() {
      if (!this.isLastForm && this.authNext) {
        if (this.activeIndex === 14) {
          this.activeIndex = 17;
        }
        else {
          this.activeIndex++;
        }
      }
    },
    decrementIndex() {
      if (this.activeIndex > 0) {
        if (this.activeIndex === 17) {
          this.activeIndex = 14;
        }
        else {
          this.activeIndex--;
        }
      }
    },
    activateForm(index) {
      this.activeIndex = index;
    },
    enableNext(auth) {
      if (auth) {
        this.authNext = true;
      }
      else {
        this.authNext = false;
      }
    },
    updateSurfaceVitreeColorInt(intVal) {
      this.$set(this.gForm.clients, "surfaceVitreeNord", intVal);
    }
  },
  mounted() {
    if (this.$route.params.isEditMode === true) {
      this.isEditMode = true;
      let clientId = this.$route.params.clientId;
      if (Number.parseInt(clientId) && Number.parseInt(clientId) > 0) {
        this.clientId = clientId;
      }
      else {
        alert("Erreur lors du chargement des données... Vous allez être redirigé vers votre espace personnel...");
        this.$router.push({name: 'Documents'});
      }
    }
    else if (this.$route.params.isEditMode === false) {
      this.isEditMode = false;
    }
    else {
      this.$router.push({name: 'Documents'});
    }
    if (this.$route.params.nomDocument) {
      this.nomDoc = this.$route.params.nomDocument;
    }
    if (this.$route.params.version) {
      this.versionDoc = this.$route.params.version;
    }
  }
}
</script>

<style scoped>
.descriptif {
  min-width: 840px;
}

h2 {
  text-align: center;
}

.single-view {
  padding: 0;
}

.main-form {
  width: calc(100% - 200px);
  box-sizing: border-box;
  padding: 20px 40px;
  overflow: auto;
}

.unit-form{
  justify-content: start;
  padding-top: 20px;
  box-sizing: border-box;
}

.foot-form {
  padding: 20px;
}

.hide-btn {
  visibility: hidden;
}

.nav-form {
  padding: 20px 20px;
  width: 240px;
  box-sizing: border-box;
  background: #fff;
  overflow: auto;
  border-right: 1px solid #c6d2d9;
}

.link-form {
  margin-bottom: 5px;
  border: 1px solid #a4aeb4;
  border-radius: 2px;
  padding: 1px 10px;
  width: 200px;
  box-sizing: border-box;
  background-color: rgb(253, 253, 253);
  cursor: pointer;
  transition: 0.25s;
}
.link-form:hover {
  background-color: rgb(228, 234, 238);
}

.active-link {
  padding: 20px 10px;
  background-color: rgb(228, 234, 238);
  transition: 0.25s;
  padding-left: 40px;
}

.name-form {
  max-width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 15px;
}

.form-container, .form-multiple, .form-indic {
  display: none;
}

.active-form {
  display: flex;
}

.disabled-btn {
  background: #dee3e6;
  border: 1px solid rgb(136, 136, 136);
  opacity: 0.4;
  color: black;
}
.disabled-btn:hover {
  background: #dee3e6;
  border: 1px solid rgb(136, 136, 136);
  opacity: 0.4;
  color: black;
}
</style>