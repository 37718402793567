<template>
  <div class="login single-view flex-cnt-col justify-center">
    <div class="main-section flx1 flex-cnt-col flex-center">
      <div class="auth-cnt flex-cnt-col flex-center">
        <h3 class="auth-title">
          AUTHENTIFICATION
        </h3>
        <div class="input-cnt flex-cnt-row align-center" v-bind:class="{'input-invalid': isUsernameValid === false}">
          <input 
            type="text" 
            name="username" 
            placeholder="Identifiant" 
            v-model="username" 
            class="input-usr"
            v-on:keyup.enter="connect"
          >
        </div>
        <div class="input-cnt flex-cnt-row align-center justify-between" v-bind:class="{'input-invalid': isPasswordValid === false}">
          <input 
            type="password" 
            name="password" 
            ref="inputPw"
            placeholder="Mot de passe" 
            v-model="password" 
            class="input-pw"
            v-on:keyup.enter="connect"
          >
          <div class="show-cnt flex-cnt-row justify-center">
            <img 
              src="../assets/icons/show-pw.png" 
              alt="show" 
              class="show-icon"
              v-on:mousedown="showPassword"
              v-on:mouseup="hidePassword"
            >
          </div>
        </div>
        <div class="btn-cnt flex-cnt-row align-center">
          <button 
            class="auth-btn" 
            v-bind:class="{'auth-invalid': !(isUsernameValid && isPasswordValid)}"
            :disabled="!(isUsernameValid && isPasswordValid)"
            v-on:click="connect"
          >
            Connexion
          </button>
          <div class="error-cnt flex-cnt-row align-center justify-start">
            {{errorMsg}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  data: function () {
    return {
      username: '',
      password: '',
      isUsernameValid: null,
      isPasswordValid: null,
      wrongAttempt : false,
    }
  },
  watch: {
    username: function () {
      this.updateUsername();
    },
    password: function () {
      this.updatePassword();
    }
  },
  computed: {
    errorMsg: function () {
      let regexUsr = /^\w{3,}$/;
      let regexPw = /^([a-zA-Z0-9!@#$%^&*]{6,32})$/;
      if (this.isUsernameValid === false) {
        if (this.username.length < 3 || this.username.length > 255) {
          return "Doit contenir entre 3 et 255 caractères.";
        }
        else if (!regexUsr.test(this.username)) {
          return "Doit contenir seulement des lettres, chiffres, et/ou le caractère  _."
        }
      }
      else if (this.isPasswordValid === false) {
        if (this.password.length < 6 || this.password.length > 32) {
          return "Doit contenir entre 6 et 32 caractères.";
        }
        else if (!regexPw.test(this.password)) {
          return "Doit contenir seulement des lettres, chiffres, et/ou les caractères !@#$%^&*."
        }
      }
      else if (this.wrongAttempt) {
        return "Erreur de connexion, veuillez vérifier vos identifiant et mot de passe puis réessayer..."
      }
      return '';
    },
    ...mapGetters(['getLoginStatus'])
  },
  methods: {
    updateUsername() {
      this.wrongAttempt = false;
      let regex = /^\w{3,}$/;
      if (this.username) {
        if (this.username.length > 2 && this.username.length < 256 && regex.test(this.username)) {
          this.isUsernameValid = true;
        }
        else {
          this.isUsernameValid = false;
        }
      }
      else {
        this.isUsernameValid = null;
      }
    },
    updatePassword() {
      this.wrongAttempt = false;
      let regex = /^([a-zA-Z0-9!@#$%^&*]{6,32})$/;
      if (this.password) {
        if (this.password.length >= 6 && this.password.length <= 32 && regex.test(this.password)) {
          this.isPasswordValid = true;
        }
        else {
          this.isPasswordValid = false;
        }
      }
      else {
        this.isPasswordValid = null;
      }
    },
    showPassword() {
      this.$refs.inputPw.type = 'text';
    },
    hidePassword() {
      this.$refs.inputPw.type = 'password';
    },
    async connect() {
      if (this.isUsernameValid && this.isPasswordValid) {

        //request
        await this.login({
          username: this.username,
          password: this.password
        });
        if (this.getLoginStatus) {
          this.$router.push('/');
        }
        else {
          this.wrongAttempt = true;
        }
      }
    },
    ...mapActions(['login'])
  }
}
</script>

<style scoped>
.login {
  margin: 0;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.single-view {
  overflow: auto;
  padding: 20px;
  box-sizing: border-box;
}

.main-section {
  background-image: url("../assets/logos/back-mav.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  max-height: 816px;
}

.auth-cnt {
  background: #fdfdfd88;
  width: 320px;
  height: 280px;
  min-height: 280px;
  border-radius: 2px;
  border: 1px solid #c6d2d9;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
}

.auth-title {
  margin-bottom: 35px;
}

.input-cnt {
  position: relative;
  padding-right: 1px;
  margin: 3px 0px;
  width: 240px;
  background-color: rgb(250, 250, 250);
  border-radius: 2px;
  border: 1px solid #bccad1;
  box-sizing: border-box;
  height: 36px;
}
.input-cnt:focus-within {
  border: 2px solid #005fcc;
  padding-right: 0;
}

.input-usr {
  height: 100%;
  width: 240px;
  box-sizing: border-box;
  padding-left: 13px;
  border: 0;
  background-color: rgb(250, 250, 250);
  outline: 0;
}

.input-usr:focus {
  padding-left: 12px;
}

.input-pw {
  height: 100%;
  width: 200px;
  box-sizing: border-box;
  padding-left: 13px;
  border: 0;
  background-color: rgb(250, 250, 250);
  outline: 0;
}

.input-pw:focus {
  padding-left: 12px;
}

.show-cnt {
  width:44px;
}

.show-icon {
  height: 14px;
  width: 19px;
  cursor: pointer;
  background: rgb(250, 250, 250);
  padding: 2px;

}

.input-invalid {
  border: 2px solid red!important;
  outline-color: red;
}

.btn-cnt {
  position: relative;
  margin-top: 10px;
}

.auth-btn {
  margin: 3px;
  height: 30px;
  width: 240px;
  border-radius: 2px;
  border: 0;
  color: #fff;
  box-sizing: border-box;
  padding: 0px 10px;
  background-color: #37bb4c;
  font-weight: 700;
  font-family: 'OpenSans400';
  cursor: pointer;
  padding-bottom: 2px;
}
.auth-btn:hover {
  background-color: #3fa24f;
  cursor: pointer;
}

.auth-invalid {
  background-color: #9be4a7;
}
.auth-invalid:hover {
  background-color: #9be4a7;
  cursor: default;
}

.error-cnt {
  position: absolute;
  top: 35px;
  padding: 0px 4px;
  font-size: 10px;
  color: red;
  font-family: 'OpenSans400';
}
</style>
