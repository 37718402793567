export function validFloat(val) {
  if (val === null) {
    return 0;
  }
  else if (parseFloat(val)) {
    return parseFloat(val);
  }
  else {
    return 0;
  }
}