import jwtInterceptor from './interceptor';

import {urls} from './serverInfo.js'

const baseUrlApi = urls.baseApi;  
const baseUrlUsers = urls.baseUsers;  
const baseUrlUploads = urls.baseUploads;


export async function queryLogin(data) {
  let response;
  try {
      response = await jwtInterceptor.post(`${baseUrlUsers}/login`, data);
  } catch (error) {
    console.log(error)
    if (error.response) {
      if (error.response.data) {
        console.log(error.response.data)
      }
      if (error.response.status === 400) {
        return {
          status:  error.response.status,
          data:    null,
          message: error.response.data
        };
      }
      else {
        console.log(error)
        return {
          status: error.response.status,
          data:   null
        }
      }
    }
  }
  if (response && response.data) {
    return {
      status: response.status,
      data:   response.data
    };
  }
}

export async function queryUploadImage(data) {
  let response;
  try {
      response = await jwtInterceptor.post(`${baseUrlUploads}/image`, data);
  } catch (error) {
    console.log(error)
    if (error.response) {
      if (error.response.data) {
        console.log(error.response.data)
      }
      if (error.response.status === 400) {
        return {
          status:  error.response.status,
          data:    null,
          message: error.response.data
        };
      }
      else {
        console.log(error)
        return {
          status: error.response.status,
          data:   null
        }
      }
    }
  }
  if (response && response.data) {
    return response.data;
  }
}

export async function queryGet(table, id = '', params = null) {
  let response;
  try {
    response = await jwtInterceptor.get(`${baseUrlApi}/${table}/${id}`, {params});
  } catch (error) {
    console.log(error);
    if (error.response && error.response.data) {
      console.log(error.response.data)
    }
  }
  if (response && response.data) {
    return response.data;
  }
}

export async function queryGetByClientId(table, clientId, params = null) {
  let response;
  try {
      response = await jwtInterceptor.get(`${baseUrlApi}/${table}-cId/${clientId}`, {params});
  } catch (error) {
      console.log(error);
  }
  if (response && response.data) {
      return response.data;
  }
}

export async function queryGetByParamKey(table, key, params = null) {
  let response;
  try {
      response = await jwtInterceptor.get(`${baseUrlApi}/${table}-pKey/${key}`, {params});
  } catch (error) {
      console.log(error);
  }
  if (response && response.data) {
      return response.data;
  }
}

export async function queryOneParamValue(key, choice, type) {
  let result = await queryGetByParamKey('parameters', key, {
    choice: choice, 
    type: type
  });
  if (result && result.length === 1 && result[0].value) {
    return result[0].value;
  }
  else if (result && result.length > 1) {
    console.log(result)
    console.log(`Duplicate parameter : '${key}'. Please check and try again...`);
    return null;
  }
  else {
    console.log(`Parameter missing : '${key}'. Please check and try again...`);
    return null;
  }
}

export async function queryOneTextValue(key) {
  let result = await queryGetByParamKey('texts', key);
  if (result && result.length === 1 && result[0].value) {
    return result[0].value;
  }
  else if (result && result.length > 1) {
    console.log(`Duplicate text : '${key}'. Please check and try again...`);
    return null;
  }
  else {
    console.log(`Text missing : '${key}'. Please check and try again...`);
    return null;
  }
}

export async function queryPost(table, data = null) {
  let response;
  try {
      response = await jwtInterceptor.post(`${baseUrlApi}/${table}`, data);
  } catch (error) {
    console.log(error);
    if (error.response.status === 403 && table === 'users') {
      alert("Cet identifiant existe déjà.");
    }
    else if (error.response.status === 403 && table === 'descriptif') {
      alert("Ce nom de document existe déjà.");
    }
    else {
      alert("Une erreur est survenur lors de l'enregistrement. Veuillez réessayer...")
    }
  }
  if (response && response.data) {
    return await response.data;
  }
}

export async function queryPut(table, id, data = null) {
  if (id) {
    let response;
    try {
        response = await jwtInterceptor.put(`${baseUrlApi}/${table}/${id}`, data);
    } catch (error) {
        console.log(error);
    }
    if (response && response.data) {
        return await response.data;
    }
  }
}

export async function queryDelete(table, id) {
  let response;
  try {
      response = await jwtInterceptor.delete(`${baseUrlApi}/${table}/${id}`);
  } catch (error) {
      console.log(error);
  }
  if (response && response.data) {
      return response.data;
  }
}