import { InputTypes } from "../api/validation";
import { SKeys } from "./selectKeys";
//select options
import { 
  Departements, 
  Civilites, 
  // BranchementFournisseurs,
  // BranchementLongueurs, 
  OriginesProspect, 
  Orientations, 
  MaisonModeles,
  // StructureTypes, 
  VideSanitaireRangs, 
  // DetailRdcTypes, 
  ComblesTypes, 
  ToitRdcInclinaisons, 
  ToitRdcPentes, 
  ToitRdcTypes, 
  ToitRdcRatios, 
  // ToitRdcSpeciaux, 
  ToitRdcCouvertures, 
  GouttiereTypes, 
  ConduitTypes, 
  MenuiserieCouleurs, 
  MenuiserieCouleursGlobal, 
  MenuiserieMateriaux,
  Facades,
  FacadesEntree,
  PortesEntreeModeles,
  PortesOuvrants,
  PortesServiceModeles,
  PortesEmplacements,
  PortesGarageTailles,
  PortesGarageModeles,
  PortesGarageTypes,
  PlacoPlafondTypes,
  PlacoPlafondCloisonEpaisseurs,
  PlacoVidesSousPlafond,
  ElectriciteTypes,
  BlocPortesGammes,
  BlocPortesModeles,
  CarrelageGammes,
  OuiNon,
  ChauffageTypes,
  FacadeRevetements,
  PieceTypes,
  FenetreDimensions,
  FenetreEmplacements,
  FenetreTypes,
  FenetreOptions,
  SanitaireTypes,
  SanitaireFournisseurs,
  FaienceOptions
} from "./selectOptions";

export const InputsClient = Object.freeze([
  {
    index: 0,
    inputTag: 'input',
    queryKey: 'nomDocument',
    inputType: 'string',
    validType: InputTypes.STRING,
    label: 'Titre document',
    placeholder: 'Titre',
    showLabel: true,
    required: true,
  },
  {
    index: 1,
    inputTag: 'input',
    queryKey: 'version',
    inputType: 'number',
    validType: InputTypes.INTEGER_POS,
    label: 'Numéro de version',
    placeholder: 'Version',
    showLabel: true,
    required: true
  },
  {
    index: 2,
    inputTag: 'select',
    queryKey: 'civiliteA',
    validType: InputTypes.STRING,
    label: 'Civilité A',
    placeholder: 'Civilité',
    showLabel: true,
    options: Civilites,
    spaceBefore: true,
    required: true
  },
  {
    index: 3,
    inputTag: 'input',
    queryKey: 'nomA',
    inputType: 'string',
    validType: InputTypes.STRING,
    label: 'Nom A',
    placeholder: 'Nom',
    showLabel: true,
    required: true
  },
  {
    index: 4,
    inputTag: 'select',
    queryKey: 'civiliteB',
    validType: InputTypes.STRING,
    label: 'Civilité B',
    placeholder: 'Civilité',
    showLabel: true,
    //SELECT ***
    options: Civilites
    // ***
  },
  {
    index: 5,
    inputTag: 'input',
    queryKey: 'nomB',
    inputType: 'string',
    validType: InputTypes.STRING,
    label: 'Nom B',
    placeholder: 'Nom',
    showLabel: true,
  },
  {
    index: 6,
    inputTag: 'input',
    queryKey: 'adresse',
    inputType: 'string',
    validType: InputTypes.STRING,
    label: 'Adresse',
    placeholder: 'Adresse',
    showLabel: true,
  },
  {
    index: 7,
    inputTag: 'input',
    queryKey: 'codePostal',
    inputType: 'string',
    validType: InputTypes.CODE_POSTAL,
    label: 'Code Postal',
    placeholder: 'Code Postal',
    showLabel: true,
  },
  {
    index: 8,
    inputTag: 'input',
    queryKey: 'ville',
    inputType: 'string',
    validType: InputTypes.STRING,
    label: 'Ville',
    placeholder: 'Ville',
    showLabel: true,
  },
  {
    index: 9,
    inputTag: 'input',
    queryKey: 'tel',
    inputType: 'string',
    validType: InputTypes.TELEPHONE,
    label: 'Téléphone',
    placeholder: 'Téléphone',
    showLabel: true,
    required: true
  }
]);

export const InputsConstruction = Object.freeze([
  {
    index: 0,
    inputTag: 'input',
    queryKey: 'adresse',
    inputType: 'string',
    validType: InputTypes.STRING,
    label: 'Adresse',
    placeholder: 'Adresse',
    showLabel: true
  },
  {
    index: 1,
    inputTag: 'input',
    queryKey: 'sectionCadastrale',
    inputType: 'string',
    validType: InputTypes.STRING,
    label: 'Section cadastrale',
    placeholder: 'Section cadastrale',
    showLabel: true,
  },
  {
    index: 2,
    inputTag: 'input',
    queryKey: 'codePostal',
    inputType: 'string',
    validType: InputTypes.CODE_POSTAL,
    label: 'Code Postal',
    placeholder: 'Code Postal',
    showLabel: true,
  },
  {
    index: 3,
    inputTag: 'input',
    queryKey: 'ville',
    inputType: 'string',
    validType: InputTypes.STRING,
    label: 'Ville',
    placeholder: 'Ville',
    showLabel: true,
  },
  {
    index: 4,
    inputTag: 'input',
    queryKey: 'numTerrain',
    inputType: 'string',
    validType: InputTypes.STRING,
    label: 'Numéro terrain',
    placeholder: 'Numéro terrain',
    showLabel: true,
  },
  {
    index: 5,
    inputTag: 'input',
    queryKey: 'surfaceTerrain',
    inputType: 'number',
    validType: InputTypes.FLOAT,
    label: 'Surface terrain',
    placeholder: 'Surface terrain',
    inputUnit: 'm²',
    showLabel: true,
  },
  // {
  //   index: 6,
  //   inputTag: 'select',
  //   queryKey: 'branchementType',
  //   validType: InputTypes.STRING,
  //   label: 'Branchement',
  //   placeholder: 'Type',
  //   showLabel: true,
  //   options: BranchementFournisseurs
  // },
  // {
  //   index: 7,
  //   inputTag: 'select',
  //   queryKey: 'branchementLongueur',
  //   validType: InputTypes.INTEGER,
  //   label: 'Branchement',
  //   placeholder: 'Longueur',
  //   inputUnit: 'ml',
  //   showLabel: true,
  //   options: BranchementLongueurs
  // },
  {
    index: 8,
    inputTag: 'select',
    queryKey: 'departement',
    validType: InputTypes.STRING,
    label: 'Département',
    placeholder: 'Choix',
    showLabel: true,
    options: Departements,
    defaultValue: SKeys.Departements.LOIR_ET_CHER_41,
    showResetIconIfSelect: false
  },
  {
    index: 9,
    inputTag: 'select',
    queryKey: 'origineProspect',
    validType: InputTypes.STRING,
    label: 'Origine prospect',
    placeholder: 'Choix',
    showLabel: true,
    options: OriginesProspect,
    required: true
  },
  {
    index: 10,
    inputTag: 'select',
    queryKey: 'modele',
    validType: InputTypes.STRING,
    label: 'Modèle',
    placeholder: 'Choix',
    showLabel: true,
    options: MaisonModeles,
    isPreOptionTable: 'toitRdcs',
    isPreOptionKeys: [
      'type',
      'facadeL',
      'pignonL'
    ],
    required: true
  },
  {
    index: 11,
    inputTag: 'input',
    queryKey: 'empriseSol',
    inputType: 'number',
    validType: InputTypes.FLOAT,
    label: 'Emprise au sol',
    placeholder: 'Surface',
    inputUnit: 'm²',
    showLabel: true,
    spaceBefore: true
  }
]);

export const InputsPiece = Object.freeze([
  {
    index: 0,
    inputTag: 'select',
    queryKey: 'type',
    validType: InputTypes.STRING,
    label: 'Pièce',
    placeholder: 'Type',
    showLabel: true,
    options: PieceTypes
  },
  {
    index: 1,
    inputTag: 'input',
    queryKey: 'surface',
    inputType: 'number',
    validType: InputTypes.FLOAT,
    label: 'Surface',
    placeholder: 'Surface',
    inputUnit: 'm²',
    showLabel: true,
  }
]);

// export const InputsStructure = Object.freeze([
//   {
//     index: 0,
//     inputTag: 'select',
//     queryKey: 'type',
//     validType: InputTypes.STRING,
//     label: 'Structure',
//     placeholder: 'Type',
//     showLabel: true,
//     options: StructureTypes
//   }
// ]);

export const InputsVideSanitaire = Object.freeze([
  {
    index: 0,
    inputTag: 'select',
    queryKey: 'nombreRangs',
    validType: InputTypes.STRING,
    label: 'Nombre rangs',
    placeholder: 'Choix',
    showLabel: true,
    options: VideSanitaireRangs,
    defaultValue: SKeys.RangsVideSanitaire.TROIS_RANGS,
    showResetIconIfSelect: false
  },
  {
    index: 1,
    inputTag: 'input',
    queryKey: 'lngGdFacade',
    inputType: 'number',
    validType: InputTypes.FLOAT,
    label: 'Grande façade',
    placeholder: 'Longueur',
    inputUnit: 'ml',
    showLabel: true,
    helpImgUrl: 'help-vs-facade-pignon.png'
  },
  {
    index: 2,
    inputTag: 'input',
    queryKey: 'lngGdPignon',
    inputType: 'number',
    validType: InputTypes.FLOAT,
    label: 'Grand pignon',
    placeholder: 'Longueur',
    inputUnit: 'ml',
    showLabel: true,
    helpImgUrl: 'help-vs-facade-pignon.png'
  },
  {
    index: 3,
    inputTag: 'input',
    queryKey: 'lngRefend',
    inputType: 'number',
    validType: InputTypes.FLOAT,
    label: 'Refend',
    placeholder: 'Longueur',
    inputUnit: 'ml',
    showLabel: true,
  },
  {
    index: 4,
    inputTag: 'input',
    queryKey: 'lngDecroche',
    inputType: 'number',
    validType: InputTypes.FLOAT,
    label: 'Décroche',
    placeholder: 'Longueur',
    inputUnit: 'ml',
    showLabel: true,
    helpImgUrl: 'help-vs-decroche.png'
  }
]);

export const InputsDetailRdc = Object.freeze([
  // {
  //   index: 0,
  //   inputTag: 'select',
  //   queryKey: 'type',
  //   validType: InputTypes.STRING,
  //   label: 'Type',
  //   placeholder: 'Choix',
  //   showLabel: true,
  //   options: DetailRdcTypes
  // },
  {
    index: 1,
    inputTag: 'input',
    queryKey: 'lngGdFacade',
    inputType: 'number',
    validType: InputTypes.FLOAT,
    label: 'Grande façade',
    placeholder: 'Longueur',
    inputUnit: 'ml',
    showLabel: true,
  },
  {
    index: 2,
    inputTag: 'input',
    queryKey: 'lngGdPignon',
    inputType: 'number',
    validType: InputTypes.FLOAT,
    label: 'Grand pignon',
    placeholder: 'Longueur',
    inputUnit: 'ml',
    showLabel: true,
  },
  {
    index: 3,
    inputTag: 'input',
    queryKey: 'lngDecroche',
    inputType: 'number',
    validType: InputTypes.FLOAT,
    label: 'Décroche',
    placeholder: 'Longueur',
    inputUnit: 'ml',
    showLabel: true,
  },
  {
    index: 4,
    inputTag: 'input',
    queryKey: 'nombrePoutres',
    inputType: 'number',
    validType: InputTypes.INTEGER,
    label: 'Nombre poutres',
    placeholder: 'Nombre',
    inputUnit: 'U',
    showLabel: true,
  },
  {
    index: 5,
    inputTag: 'input',
    queryKey: 'nombrePoteaux',
    inputType: 'number',
    validType: InputTypes.INTEGER,
    label: 'Nombre poteaux',
    placeholder: 'Nombre',
    inputUnit: 'U',
    showLabel: true,
  }
]);

export const InputsCombles = Object.freeze([
  {
    index: 0,
    inputTag: 'select',
    queryKey: 'type',
    validType: InputTypes.STRING,
    label: 'Type',
    placeholder: 'Combles',
    showLabel: true,
    options: ComblesTypes,
    defaultValue: SKeys.TypesCombles.PERDUS,
    showResetIconIfSelect: false,
    isPreOptionTable: 'combles',
    isPreOptionKeys: [
      'ratioComblesAmenageables'
    ]
  },
  // {
  //   index: 1,
  //   inputTag: 'input',
  //   queryKey: 'lngGdFacade',
  //   inputType: 'number',
  //   validType: InputTypes.FLOAT,
  //   label: 'Grande façade',
  //   placeholder: 'Longueur',
  //   inputUnit: 'ml',
  //   showLabel: true,
  // },
  // {
  //   index: 2,
  //   inputTag: 'input',
  //   queryKey: 'lngGdPignon',
  //   inputType: 'number',
  //   validType: InputTypes.FLOAT,
  //   label: 'Grand pignon',
  //   placeholder: 'Longueur',
  //   inputUnit: 'ml',
  //   showLabel: true,
  // },
  {
    index: 3,
    inputTag: 'input',
    queryKey: 'encuvement',
    inputType: 'number',
    validType: InputTypes.FLOAT,
    label: 'Encuvement',
    placeholder: 'Longueur',
    inputUnit: 'HT en ml',
    showLabel: true,
  },
  {
    index: 4,
    inputTag: 'input',
    queryKey: 'lngDecroche',
    inputType: 'number',
    validType: InputTypes.FLOAT,
    label: 'Décroche',
    placeholder: 'Longueur',
    inputUnit: 'ml',
    showLabel: true,
  },
  // {
  //   index: 5,
  //   inputTag: 'input',
  //   queryKey: 'lrgPlancher',
  //   inputType: 'number',
  //   validType: InputTypes.FLOAT,
  //   label: 'Largeur plancher',
  //   placeholder: 'Largeur',
  //   inputUnit: 'ml',
  //   showLabel: true,
  // },
  {
    index: 6,
    inputTag: 'select',
    queryKey: 'ratioComblesAmenageables',
    validType: InputTypes.STRING,
    label: 'Combles aménageables',
    placeholder: 'Choix',
    inputUnit: '%',
    showLabel: true,
    options: ToitRdcRatios,
    defaultValue: '0',
    showResetIconIfSelect: false,
    hasPreOptionKey: 'type'
  },
]);

export const InputsToitRdc = Object.freeze([
  {
    index: 0,
    inputTag: 'select',
    queryKey: 'inclinaison',
    validType: InputTypes.STRING,
    label: 'Inclinaison',
    placeholder: 'Choix',
    showLabel: true,
    options: ToitRdcInclinaisons,
    defaultValue: SKeys.InclinaisonsToit.DEGRES_35,
    showResetIconIfSelect: false
  },
  {
    index: 1,
    inputTag: 'select',
    queryKey: 'nombrePentes',
    validType: InputTypes.STRING,
    label: 'Pentes',
    placeholder: 'Nombre',
    showLabel: true,
    options: ToitRdcPentes,
    defaultValue: SKeys.PentesToit.DEUX_PENTES,
    showResetIconIfSelect: false
  },
  {
    index: 2,
    inputTag: 'input',
    queryKey: 'lngFacade',
    inputType: 'number',
    validType: InputTypes.FLOAT,
    label: 'Façade',
    placeholder: 'Longueur',
    inputUnit: 'ml',
    showLabel: true,
    helpImgUrl: 'help-toit-facade.png'
  },
  {
    index: 3,
    inputTag: 'input',
    queryKey: 'lngPignon',
    inputType: 'number',
    validType: InputTypes.FLOAT,
    label: 'Pignon',
    placeholder: 'Longueur',
    inputUnit: 'ml',
    showLabel: true,
    helpImgUrl: 'help-toit-pignon.png'
  },
  {
    index: 4,
    inputTag: 'select',
    queryKey: 'type',
    validType: InputTypes.STRING,
    label: 'Type',
    placeholder: 'Choix',
    showLabel: true,
    options: ToitRdcTypes,
    hasPreOptionKey: 'modele'
  },
  {
    index: 5,
    inputTag: 'input',
    queryKey: 'facadeL',
    inputType: 'number',
    validType: InputTypes.FLOAT,
    label: 'Façade L',
    placeholder: 'Longueur',
    inputUnit: 'ml',
    showLabel: true,
    hasPreOptionKey: 'modele',
    hasPreOptionMatchValue: SKeys.ModelesMaison.PANORAMA,
    helpImgUrl: 'help-toit-facadeL.png'
  },
  {
    index: 6,
    inputTag: 'input',
    queryKey: 'pignonL',
    inputType: 'number',
    validType: InputTypes.FLOAT,
    label: 'Pignon L',
    placeholder: 'Longueur',
    inputUnit: 'ml',
    showLabel: true,
    hasPreOptionKey: 'modele',
    hasPreOptionMatchValue: SKeys.ModelesMaison.PANORAMA,
    helpImgUrl: 'help-toit-pignonL.png'
  },
  {
    index: 7,
    inputTag: 'input',
    queryKey: 'facadeCoyaux',
    inputType: 'number',
    validType: InputTypes.FLOAT,
    label: 'Façade coyaux',
    placeholder: 'Longueur',
    inputUnit: 'ml',
    showLabel: true,
    helpImgUrl: 'help-toit-facadeCoyaux.png'
  },
  {
    index: 8,
    inputTag: 'input',
    queryKey: 'pignonCoyaux',
    inputType: 'number',
    validType: InputTypes.FLOAT,
    label: 'Pignon coyaux',
    placeholder: 'Longueur',
    inputUnit: 'ml',
    showLabel: true,
    helpImgUrl: 'help-toit-pignonCoyaux.png'
  },
  {
    index: 9,
    inputTag: 'select',
    queryKey: 'typeCouverture',
    validType: InputTypes.STRING,
    label: 'Couverture',
    placeholder: 'Type',
    showLabel: true,
    options: ToitRdcCouvertures,
    showResetIconIfSelect: false
  },
  // {
  //   index: 11,
  //   inputTag: 'select',
  //   queryKey: 'typeToitSpecial',
  //   validType: InputTypes.STRING,
  //   label: 'Toit spécial',
  //   placeholder: 'Choix',
  //   showLabel: true,
  //   options: ToitRdcSpeciaux,
  // },
  // {
  //   index: 12,
  //   inputTag: 'input',
  //   queryKey: 'facadeSpecial',
  //   inputType: 'number',
  //   validType: InputTypes.FLOAT,
  //   label: 'Façade spécial',
  //   placeholder: 'Longueur',
  //   inputUnit: 'ml',
  //   showLabel: true,
  // },
  // {
  //   index: 13,
  //   inputTag: 'input',
  //   queryKey: 'pignonSpecial',
  //   inputType: 'number',
  //   validType: InputTypes.FLOAT,
  //   label: 'Pignon spécial',
  //   placeholder: 'Longueur',
  //   inputUnit: 'ml',
  //   showLabel: true
  // },
  {
    index: 14,
    inputTag: 'select',
    queryKey: 'gouttiere',
    validType: InputTypes.STRING,
    label: 'Gouttière',
    placeholder: 'Type',
    showLabel: true,
    options: GouttiereTypes,
    defaultValue: SKeys.TypesGouttiere.ALU,
    showResetIconIfSelect: false
  },
  {
    index: 15,
    inputTag: 'select',
    queryKey: 'conduit',
    validType: InputTypes.STRING,
    label: 'Conduit',
    placeholder: 'Choix',
    showLabel: true,
    options: ConduitTypes,
    defaultValue: SKeys.TypesConduit.NON,
    showResetIconIfSelect: false
  }

]);

export const InputsOrientation = Object.freeze([
  {
    index: 0,
    inputTag: 'select',
    queryKey: 'orientation',
    validType: InputTypes.STRING,
    label: 'Orientation porte d\'entrée',
    placeholder: 'Choix',
    showLabel: true,
    options: Orientations,
    defaultValue: SKeys.Orientations.NORD,
    showResetIconIfSelect: false
  }
]);

export const InputsMenuiserie = Object.freeze([
  {
    index: 0,
    inputTag: 'select',
    queryKey: 'materiau',
    validType: InputTypes.STRING,
    label: 'Matériau',
    placeholder: 'Choix',
    showLabel: true,
    options: MenuiserieMateriaux,
    defaultValue: SKeys.MateriauxMenuiserie.PVC,
    isPreOptionTable: 'menuiseries',
    isPreOptionKeys: [
      'couleur'
    ],
    showResetIconIfSelect: false
  },
  {
    index: 1,
    inputTag: 'select',
    queryKey: 'couleur',
    validType: InputTypes.STRING,
    label: 'Couleur',
    placeholder: 'Choix',
    showLabel: true,
    options: MenuiserieCouleursGlobal,
    hasPreOptionKey: 'materiau',
    defaultValue: SKeys.CouleursMenuiserie.BLANC,
    showResetIconIfSelect: false
  }
]);

export const InputsPortes = Object.freeze([
  {
    index: 0,
    inputTag: 'input',
    queryKey: 'nombreEntree',
    inputType: 'number',
    validType: InputTypes.INTEGER,
    label: 'Porte d\'entrée',
    placeholder: 'Nombre',
    showLabel: true
  },
  {
    index: 1,
    inputTag: 'select',
    queryKey: 'facadeEntree',
    validType: InputTypes.STRING,
    label: 'Façade',
    placeholder: 'Choix',
    showLabel: true,
    options: FacadesEntree,
    defaultValue: SKeys.Facades.AVANT,
    showResetIconIfSelect: false
  },
  {
    index: 2,
    inputTag: 'select',
    queryKey: 'modeleEntree',
    validType: InputTypes.STRING,
    label: 'Modèle',
    placeholder: 'Choix',
    showLabel: true,
    options: PortesEntreeModeles,
    defaultValue: SKeys.ModelesPorteEntree.GAMME_1,
    showResetIconIfSelect: false
  },
  {
    index: 3,
    inputTag: 'select',
    queryKey: 'ouvrantEntree',
    validType: InputTypes.STRING,
    label: 'Ouvrant',
    placeholder: 'Choix',
    showLabel: true,
    options: PortesOuvrants,
    defaultValue: SKeys.OuvrantsPorte.NORMAL,
    showResetIconIfSelect: false
  },
  {
    index: 4,
    inputTag: 'select',
    queryKey: 'couleurEntree',
    validType: InputTypes.STRING,
    label: 'Couleur',
    placeholder: 'Choix',
    showLabel: true,
    options: MenuiserieCouleurs,
    defaultValue: SKeys.CouleursMenuiserie.BLANC,
    showResetIconIfSelect: false
  },
  {
    index: 5,
    inputTag: 'input',
    queryKey: 'nombreService',
    inputType: 'number',
    validType: InputTypes.INTEGER,
    label: 'Porte de service',
    placeholder: 'Nombre',
    showLabel: true,
    spaceBefore: true
  },
  {
    index: 6,
    inputTag: 'select',
    queryKey: 'facadeService',
    validType: InputTypes.STRING,
    label: 'Façade',
    placeholder: 'Choix',
    showLabel: true,
    options: Facades,
    defaultValue: SKeys.Facades.AVANT,
    showResetIconIfSelect: false
  },
  {
    index: 7,
    inputTag: 'select',
    queryKey: 'modeleService',
    validType: InputTypes.STRING,
    label: 'Modèle',
    placeholder: 'Choix',
    showLabel: true,
    options: PortesServiceModeles,
    defaultValue: SKeys.ModelesPorteService.GAMME_1_PVC,
    showResetIconIfSelect: false
  },
  {
    index: 8,
    inputTag: 'select',
    queryKey: 'emplacementService',
    validType: InputTypes.STRING,
    label: 'Emplacement',
    placeholder: 'Choix',
    showLabel: true,
    options: PortesEmplacements,
    defaultValue: SKeys.EmplacementsPorte.HABITABLE,
    showResetIconIfSelect: false
  },
  {
    index: 9,
    inputTag: 'input',
    queryKey: 'nombreGarage',
    inputType: 'number',
    validType: InputTypes.INTEGER,
    label: 'Porte de garage',
    placeholder: 'Nombre',
    showLabel: true,
    spaceBefore: true
  },
  {
    index: 10,
    inputTag: 'select',
    queryKey: 'tailleGarage',
    validType: InputTypes.STRING,
    label: 'Taille',
    placeholder: 'Choix',
    showLabel: true,
    options: PortesGarageTailles,
    defaultValue: SKeys.TaillesPorteGarage._240,
    showResetIconIfSelect: false
  },
  {
    index: 11,
    inputTag: 'select',
    queryKey: 'modeleGarage',
    validType: InputTypes.STRING,
    label: 'Modèle',
    placeholder: 'Choix',
    showLabel: true,
    options: PortesGarageModeles,
    defaultValue: SKeys.ModelesPorteGarage._902,
    showResetIconIfSelect: false
  },
  {
    index: 12,
    inputTag: 'select',
    queryKey: 'typeGarage',
    validType: InputTypes.STRING,
    label: 'Type',
    placeholder: 'Choix',
    showLabel: true,
    options: PortesGarageTypes,
    defaultValue: SKeys.TypesPorteGarage.NON_MOTORISEE,
    showResetIconIfSelect: false
  },
  {
    index: 13,
    inputTag: 'select',
    queryKey: 'couleurGarage',
    validType: InputTypes.STRING,
    label: 'Couleur',
    placeholder: 'Choix',
    showLabel: true,
    options: MenuiserieCouleurs,
    defaultValue: SKeys.CouleursMenuiserie.BLANC,
    showResetIconIfSelect: false
  },
]);

export const InputsFenetre = Object.freeze([
  {
    index: 0,
    inputTag: 'select',
    queryKey: 'emplacement',
    validType: InputTypes.STRING,
    label: 'Emplacement',
    placeholder: 'Choix',
    showLabel: true,
    options: FenetreEmplacements
  },
  {
    index: 1,
    inputTag: 'select',
    queryKey: 'type',
    validType: InputTypes.STRING,
    label: 'Type de fenêtre',
    placeholder: 'Choix',
    showLabel: true,
    options: FenetreTypes,
    isPreOptionTable: 'fenetres',
    isPreOptionKeys: [
      'dimensions',
      'option'
    ]
  },
  {
    index: 2,
    inputTag: 'select',
    queryKey: 'dimensions',
    validType: InputTypes.STRING,
    label: 'Dimensions',
    placeholder: 'H X L',
    showLabel: true,
    options: FenetreDimensions,
    hasPreOptionKey: 'type'
  },
  {
    index: 3,
    inputTag: 'input',
    queryKey: 'nombre',
    inputType: 'number',
    validType: InputTypes.INTEGER,
    label: 'Nombre',
    placeholder: 'Nombre',
    inputUnit: 'U',
    showLabel: true
  },
  {
    index: 4,
    inputTag: 'select',
    queryKey: 'option',
    validType: InputTypes.STRING,
    label: 'Option',
    placeholder: 'Choix',
    showLabel: true,
    options: FenetreOptions,
    defaultValue: 'normal',
    hasPreOptionKey: 'type'
  },
]);

export const InputsFenetreSpeciale = Object.freeze([
  {
    index: 0,
    inputTag: 'input',
    queryKey: 'largeur',
    inputType: 'number',
    validType: InputTypes.INTEGER,
    label: 'Largeur',
    placeholder: 'Largeur',
    inputUnit: 'cm',
    showLabel: true
  },
  {
    index: 1,
    inputTag: 'input',
    queryKey: 'hauteur',
    inputType: 'number',
    validType: InputTypes.INTEGER,
    label: 'Hauteur',
    placeholder: 'Hauteur',
    inputUnit: 'cm',
    showLabel: true
  },
  {
    index: 2,
    inputTag: 'input',
    queryKey: 'nombre',
    inputType: 'number',
    validType: InputTypes.INTEGER,
    label: 'Nombre',
    placeholder: 'Nombre',
    inputUnit: 'U',
    showLabel: true
  },
  {
    index: 3,
    inputTag: 'select',
    queryKey: 'emplacement',
    validType: InputTypes.STRING,
    label: 'Façade',
    placeholder: 'Choix',
    showLabel: true,
    options: Facades
  },
  {
    index: 4,
    inputTag: 'input',
    queryKey: 'prix',
    inputType: 'number',
    validType: InputTypes.INTEGER,
    label: 'Prix',
    placeholder: 'Prix',
    inputUnit: '€',
    showLabel: true
  }
]);

export const InputsPlaco = Object.freeze([
  {
    index: 0,
    inputTag: 'select',
    queryKey: 'plafond',
    validType: InputTypes.STRING,
    label: 'Plafond',
    placeholder: 'Type',
    showLabel: true,
    options: PlacoPlafondTypes,
    defaultValue: SKeys.TypesPlafondPlaco.SOUFFLE,
    showResetIconIfSelect: false
  },
  {
    index: 1,
    inputTag: 'select',
    queryKey: 'videSousPlafondRdc',
    validType: InputTypes.FLOAT,
    label: 'Vide sous plafond RDC',
    placeholder: 'Longueur',
    inputUnit: 'ml',
    showLabel: true,
    options: PlacoVidesSousPlafond,
    defaultValue: SKeys.HauteursVideSousPlafond._2_5,
    showResetIconIfSelect: false
  },
  {
    index: 2,
    inputTag: 'select',
    queryKey: 'cloison',
    validType: InputTypes.INTEGER,
    label: 'Cloison',
    placeholder: 'Choix',
    showLabel: true,
    options: PlacoPlafondCloisonEpaisseurs,
    defaultValue: SKeys.EpaisseursPlaco._50,
    showResetIconIfSelect: false
  },
  {
    index: 3,
    inputTag: 'input',
    queryKey: 'doublageRdc',
    inputType: 'number',
    validType: InputTypes.FLOAT,
    label: 'Doublage RDC',
    placeholder: 'Longueur',
    inputUnit: 'ml',
    showLabel: true,
  },
  {
    index: 4,
    inputTag: 'input',
    queryKey: 'cloisonRdc',
    inputType: 'number',
    validType: InputTypes.FLOAT,
    label: 'Cloison RDC',
    placeholder: 'Longueur',
    inputUnit: 'ml',
    showLabel: true,
  },
  {
    index: 5,
    inputTag: 'select',
    queryKey: 'gammeBP',
    validType: InputTypes.STRING,
    label: 'Bloc-porte',
    placeholder: 'Gamme',
    showLabel: true,
    spaceBefore: true,
    options: BlocPortesGammes,
    defaultValue: SKeys.GammesBlocPorte.MF_GAMME_1,
    showResetIconIfSelect: false
  },
  {
    index: 6,
    inputTag: 'select',
    queryKey: 'modelePorte1',
    validType: InputTypes.STRING,
    label: 'Modèle 1',
    placeholder: 'Choix',
    showLabel: true,
    options: BlocPortesModeles,
    defaultValue: SKeys.ModelesBlocPorte._73,
    showResetIconIfSelect: false
  },
  {
    index: 7,
    inputTag: 'input',
    queryKey: 'nombrePorte1',
    inputType: 'number',
    validType: InputTypes.INTEGER,
    label: 'Nombre 1',
    placeholder: 'Nombre',
    inputUnit: 'U',
    showLabel: true,
  },
  {
    index: 8,
    inputTag: 'select',
    queryKey: 'modelePorte2',
    validType: InputTypes.STRING,
    label: 'Modèle 2',
    placeholder: 'Choix',
    showLabel: true,
    options: BlocPortesModeles,
    defaultValue: SKeys.ModelesBlocPorte._73_ISO,
    showResetIconIfSelect: false
  },
  {
    index: 9,
    inputTag: 'input',
    queryKey: 'nombrePorte2',
    inputType: 'number',
    validType: InputTypes.INTEGER,
    label: 'Nombre 2',
    placeholder: 'Nombre',
    inputUnit: 'U',
    showLabel: true,
  },
  {
    index: 10,
    inputTag: 'input',
    queryKey: 'nombreGalandage',
    inputType: 'number',
    validType: InputTypes.INTEGER,
    label: 'Galandage',
    placeholder: 'Nombre',
    inputUnit: 'U',
    showLabel: true,
  }
]);

export const InputsElectricite = Object.freeze([
  {
    index: 0,
    inputTag: 'select',
    queryKey: 'type',
    validType: InputTypes.STRING,
    label: 'Appareillage électrique',
    placeholder: 'Modèle',
    showLabel: true,
    options: ElectriciteTypes,
    defaultValue: SKeys.TypesElectricite.DOOXIE,
    showResetIconIfSelect: false
  }
]);

export const InputsCarrelage = Object.freeze([
  {
    index: 0,
    inputTag: 'select',
    queryKey: 'gammePrincipales',
    validType: InputTypes.STRING,
    label: 'Gamme',
    placeholder: 'Choix',
    showLabel: true,
    options: CarrelageGammes,
    defaultValue: SKeys.GammesCarrelage.INITIALE,
    showResetIconIfSelect: false
  },
  {
    index: 1,
    inputTag: 'select',
    queryKey: 'optionPrincipales',
    validType: InputTypes.STRING,
    label: 'Option',
    placeholder: 'Choix',
    showLabel: true,
    options: OuiNon,
    defaultValue: SKeys.OuiNon.NON,
    showResetIconIfSelect: false
  },
  {
    index: 2,
    inputTag: 'input',
    queryKey: 'surfaceAutre',
    inputType: 'number',
    validType: InputTypes.FLOAT,
    label: 'Chambres',
    placeholder: 'Surface',
    inputUnit: 'm²',
    showLabel: true,
    spaceBefore: true
  },
  {
    index: 3,
    inputTag: 'select',
    queryKey: 'gammeAutre',
    validType: InputTypes.STRING,
    label: 'Gamme',
    placeholder: 'Choix',
    showLabel: true,
    options: CarrelageGammes,
    defaultValue: SKeys.GammesCarrelage.INITIALE,
    showResetIconIfSelect: false
  },
  {
    index: 4,
    inputTag: 'select',
    queryKey: 'optionAutre',
    validType: InputTypes.STRING,
    label: 'Option',
    placeholder: 'Choix',
    showLabel: true,
    options: OuiNon,
    defaultValue: SKeys.OuiNon.NON,
    showResetIconIfSelect: false
  }
]);

export const InputsChape = Object.freeze([
  // {
  //   index: 0,
  //   inputTag: 'input',
  //   queryKey: 'surfaceChambre',
  //   inputType: 'number',
  //   validType: InputTypes.FLOAT,
  //   label: 'Chambre',
  //   placeholder: 'Surface',
  //   inputUnit: 'm²',
  //   showLabel: true
  // },
  {
    index: 0,
    inputTag: 'input',
    queryKey: 'surfaceGarage',
    inputType: 'number',
    validType: InputTypes.FLOAT,
    label: 'Garage',
    placeholder: 'Surface',
    inputUnit: 'm²',
    showLabel: true
  }
]);

export const InputsParquet = Object.freeze([
  {
    index: 0,
    inputTag: 'input',
    queryKey: 'surface',
    inputType: 'number',
    validType: InputTypes.FLOAT,
    label: 'Chambre',
    placeholder: 'Surface',
    inputUnit: 'm²',
    showLabel: true
  },
  {
    index: 1,
    inputTag: 'select',
    queryKey: 'option',
    validType: InputTypes.STRING,
    label: 'Option',
    placeholder: 'Choix',
    showLabel: true,
    options: OuiNon,
    defaultValue: SKeys.OuiNon.NON,
    showResetIconIfSelect: false
  }
]);

export const InputsSanitaires = Object.freeze([
  {
    index: 0,
    inputTag: 'select',
    queryKey: 'type',
    validType: InputTypes.STRING,
    label: 'Sanitaires',
    placeholder: 'Choix',
    showLabel: true,
    options: SanitaireTypes,
    isPreOptionTable: 'sanitaires',
    isPreOptionKeys: [
      'faience'
    ]
  },
  {
    index: 1,
    inputTag: 'input',
    queryKey: 'nombre',
    inputType: 'number',
    validType: InputTypes.INTEGER,
    label: 'Nombre',
    placeholder: 'Nombre',
    inputUnit: 'U',
    showLabel: true
  },
  {
    index: 2,
    inputTag: 'select',
    queryKey: 'fournisseur',
    validType: InputTypes.STRING,
    label: 'Prise en charge par',
    placeholder: 'Choix',
    showLabel: true,
    options: SanitaireFournisseurs,
    defaultValue: SKeys.Fournisseurs.MAV,
    showResetIconIfSelect: false
  },
  {
    index: 3,
    inputTag: 'select',
    queryKey: 'faience',
    validType: InputTypes.STRING,
    label: 'Faience',
    placeholder: 'Choix',
    showLabel: true,
    options: FaienceOptions,
    hasPreOptionKey: 'type',
  }
]);

export const InputsChauffage = Object.freeze([
  {
    index: 0,
    inputTag: 'select',
    queryKey: 'chauffage',
    validType: InputTypes.STRING,
    label: 'Chauffage',
    placeholder: 'Choix',
    showLabel: true,
    options: ChauffageTypes,
    defaultValue: SKeys.TypesChauffage.CLIM_RADIATEUR_ELEC,
    showResetIconIfSelect: false
  }
]);

export const InputsPeinture = Object.freeze([
  {
    index: 0,
    inputTag: 'select',
    queryKey: 'peinture',
    validType: InputTypes.STRING,
    label: 'Peinture',
    placeholder: 'Choix',
    showLabel: true,
    options: OuiNon,
    defaultValue: SKeys.OuiNon.NON,
    showResetIconIfSelect: false
  }
]);

export const InputsRevetementFacade = Object.freeze([
  {
    index: 0,
    inputTag: 'select',
    queryKey: 'revetementFacade',
    validType: InputTypes.STRING,
    label: 'Revêtement façade',
    placeholder: 'Choix',
    showLabel: true,
    options: FacadeRevetements,
    defaultValue: SKeys.RevetementsFacade.ENDUIT,
    showResetIconIfSelect: false
  }
]);

export const InputsAjustement = Object.freeze([
  {
    index: 0,
    inputTag: 'input',
    queryKey: 'montant',
    inputType: 'number',
    validType: InputTypes.FLOAT,
    label: 'Ajustement prix',
    placeholder: 'Prix TTC',
    inputUnit: '€',
    showLabel: true
  },
]);