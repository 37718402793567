<template>
  <div class="parameters single-view flex-cnt-row">
    <NavSideBar
      v-bind:listElements="listParams"
      v-bind:activeIndex="activeIndex"
      v-bind:activeSection="activeSection"
      v-bind:sectionIsOpen="sectionIsOpen"
      v-bind:class="'nav-param'"
      v-on:activateElement="activateParam"
      v-on:activateSection="activateSection"
    />
    <div class="main-param flex-cnt-col justify-between">
      <div class="view-header flex-cnt-row flex-center">
        <h2>{{("Paramètres").toUpperCase()}}</h2>
      </div>
      <div class="unit-param flx1 flex-cnt-col justify-start align-center">
        <ParamTable
          v-bind:parameter="listParams[activePositionInArray]"
        />
      </div>
    </div>
  </div>
</template>

<script>
import NavSideBar from "../components/NavSideBar.vue"
import ParamTable from "../components/forms/ParamTable.vue"

import { ListParameters } from "../param/parameterList";

export default {
  components: {
    NavSideBar,
    ParamTable
  },
  data: function () {
    return {
      activeIndex: -1,
      activePositionInArray: -1,
      activeSection: null,
      sectionIsOpen: {},
      listParams: ListParameters
    }
  },
  methods: {
    activateParam(index, section) {
      this.activeIndex = index;
      this.activePositionInArray = this.getPositionByParamIndex(index);
      this.activeSection = section;
    },
    activateSection(section) {
      this.$set(this.sectionIsOpen, section, !this.sectionIsOpen[section]);
    },
    getPositionByParamIndex(index) {
      let condition = (element) => element.index === index;
      return this.listParams.findIndex(condition);
    }
  },
  mounted() {
    this.listParams.forEach(param => {
      if (!this.sectionIsOpen[param.section]) {
        this.$set(this.sectionIsOpen, param.section, false);
      }
    });
  }
}
</script>

<style scoped>
.single-view {
  padding: 0;
}

.main-param {
  width: calc(100% - 200px);
  box-sizing: border-box;
  padding: 20px 40px;
  overflow: auto;
}

.unit-param{
  justify-content: start;
  box-sizing: border-box;
}

.foot-param {
  padding: 20px;
}

.hide-btn {
  visibility: hidden;
}
</style>
