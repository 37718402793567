import { SKeys } from "../param/selectKeys";
// import { PKeys } from "../param/parametersKeys"

import { validFloat } from "./helpers"

// import { queryOneParamValue } from "../api/queries";

export const ElectriciteParams = Object.freeze({
  PRIX_PDV_1:            2477,
  PRIX_PDV_2:            130,
  PRIX_PDV_3:            257,
  PRIX_PDV_GAZ:          50,
  PRIX_CHAMBRE_1:        151,
  PRIX_CHAMBRE_2:        32,
  PRIX_CHAMBRE_RAD_ELEC: 192,
  PRIX_SDE_1:            270,
  PRIX_SDE_2:            22,
  PRIX_WC_1:             32,
  PRIX_WC_2:             11,
  PRIX_GARAGE_1:         108,
  PRIX_GARAGE_2:         16,
  //
  APPAREILLAGES:         9.8,
  SECHE_SERVIETTES:      54.8,
  MICRO_PV:              54.8,
  CONSUEL:               130,
});


export async function computeParamsElectricite(targetObj, pieces) {
  pieces.forEach(piece => {
    switch (piece.type) {
      case SKeys.TypesPieces.PIECE_DE_VIE:
        targetObj.electricite.pieces.surfacePdv += validFloat(piece.surface);
        targetObj.electricite.pieces.nombrePdv++;
        break;

      case SKeys.TypesPieces.CUISINE:
        targetObj.electricite.pieces.surfaceCuisine += validFloat(piece.surface);
        targetObj.electricite.pieces.nombreCuisine++;
        break;

      case SKeys.TypesPieces.CELLIER:
        targetObj.electricite.pieces.surfaceCellier += validFloat(piece.surface);
        targetObj.electricite.pieces.nombreCellier++;
        break;

      case SKeys.TypesPieces.DGT:
        targetObj.electricite.pieces.surfaceDgt += validFloat(piece.surface);
        targetObj.electricite.pieces.nombreDgt++;
        break;

      case SKeys.TypesPieces.WC:
        targetObj.electricite.pieces.surfaceWc += validFloat(piece.surface);
        targetObj.electricite.pieces.nombreWc++;
        break;

      case SKeys.TypesPieces.SDE:
        targetObj.electricite.pieces.surfaceSde += validFloat(piece.surface);
        targetObj.electricite.pieces.nombreSde++;
        break;

      case SKeys.TypesPieces.CHAMBRES:
        targetObj.electricite.pieces.surfaceChambres += validFloat(piece.surface);
        targetObj.electricite.pieces.nombreChambres++;
        break;

      case SKeys.TypesPieces.GARAGE:
        targetObj.electricite.pieces.surfaceGarage += validFloat(piece.surface);
        targetObj.electricite.pieces.nombreGarage++;
        break;

      case SKeys.TypesPieces.TERASSE_BETON:
        targetObj.electricite.pieces.surfaceTerrasse += validFloat(piece.surface);
        targetObj.electricite.pieces.nombreTerrasse++;
        break;

      default:
        break;
    }
  });
}

export async function addElectriciteGlobale(targetObj, typeElec, typeChauffage, nombrePorteGarage, typeCombles, fenetres, typePorteGarage) {
  let nombreAppareillages = 0;
  let nombreSecheServiettes = 0;
  let nombreMicroPv = 0;
  if (typeElec === SKeys.TypesElectricite.DOOXIE) {
    // -- GTL --
    //tableau abonne
    if (targetObj.videSanitaire.surfaceHabitable >= 100) {
      targetObj.electricite.ttlPrixElectricite += 330.86;
    }
    else {
      targetObj.electricite.ttlPrixElectricite += 312.57;
    }
    //reseau terre
    targetObj.electricite.ttlPrixElectricite += 23.79;
    //pc modulaire
    targetObj.electricite.ttlPrixElectricite += 61.31;
    
    // -- ALIM DIVERSES --
    let ajoutRadElec = 0;
    switch (typeChauffage) {
      case SKeys.TypesChauffage.PAC_PLANCHER_CHAUFFANT:
      case SKeys.TypesChauffage.PAC_RADIATEUR_EAU:
        //alim pac duo
        targetObj.electricite.ttlPrixElectricite += 130.14;
        nombreAppareillages++;
        break;

      case SKeys.TypesChauffage.CLIM_RADIATEUR_ELEC:
        ajoutRadElec = 1;
        //alim pac mono
        targetObj.electricite.ttlPrixElectricite += 101.97;
        //alim chauffe eau
        targetObj.electricite.ttlPrixElectricite += 63.05;
        nombreAppareillages += 2;
        break;
      
      case SKeys.TypesChauffage.POELE_RADIATEUR_ELEC:
        ajoutRadElec = 1;
        break;

      case SKeys.TypesChauffage.GAZ_RADIATEUR_EAU:
      case SKeys.TypesChauffage.GAZ_PLANCHER_CHAUFFANT:
        //alim chaudiere lignes thermostat sonde ext
        targetObj.electricite.ttlPrixElectricite += 56.72;
        nombreAppareillages++;
        nombreMicroPv++;
        break;
  
      default:
        break;
    }
    //alim porte garage
    if (typePorteGarage === SKeys.TypesPorteGarage.MOTORISEE) {
      targetObj.electricite.ttlPrixElectricite += 28.17 * validFloat(nombrePorteGarage);
      nombreAppareillages += validFloat(nombrePorteGarage);
    }
    //alim seche serviettes
    targetObj.electricite.ttlPrixElectricite += 17.05 * validFloat(targetObj.electricite.pieces.nombreSde);
    //alim connecteur fils pilote
    targetObj.electricite.ttlPrixElectricite += 15.25 * ( validFloat(targetObj.electricite.pieces.nombreChambres) * ajoutRadElec )
  
    // -- VMC --
    //alim vmc
    targetObj.electricite.ttlPrixElectricite += 29.81;
    //kit vmc
    targetObj.electricite.ttlPrixElectricite += 211.81;
    nombreAppareillages += 3;
    //bouche cellier sup
    targetObj.electricite.ttlPrixElectricite += 26.51 * validFloat(targetObj.electricite.pieces.nombreCellier);
    nombreAppareillages += validFloat(targetObj.electricite.pieces.nombreCellier);
    //bouche wc sup
    if (targetObj.electricite.pieces.nombreWc) {
      targetObj.electricite.ttlPrixElectricite += 38.28 * ( validFloat(targetObj.electricite.pieces.nombreWc) - 1 );
      nombreAppareillages += validFloat(targetObj.electricite.pieces.nombreWc) - 1;
    }
    //bouche sdb sup
    if (targetObj.electricite.pieces.nombreSde) {
      targetObj.electricite.ttlPrixElectricite += 26.51 * ( validFloat(targetObj.electricite.pieces.nombreSde) - 1 );
      nombreAppareillages += validFloat(targetObj.electricite.pieces.nombreSde) - 1;
    }
    //gaine vmc d80
    targetObj.electricite.ttlPrixElectricite += 1.13 * 10 * ( validFloat(targetObj.electricite.pieces.nombreCellier) + validFloat(targetObj.electricite.pieces.nombreWc) + validFloat(targetObj.electricite.pieces.nombreSde) );
    //gaine vmc d125
    targetObj.electricite.ttlPrixElectricite += 1.43 * 10;

    // -- DIVERS --
    //combles amenagees
    if (typeCombles === SKeys.TypesCombles.AMENAGEABLES) {
      targetObj.electricite.ttlPrixElectricite += 42.12;
    }

    // -- APPAREILLAGE / FILERIE --
    //pack netatmo
    targetObj.electricite.ttlPrixElectricite += 87.82;
    nombreAppareillages++;
    //interrupteur VR
    let nombreVR = 0;
    fenetres.forEach(fenetre => {
      switch (fenetre.type) {
        case SKeys.TypesFenetre.FENETRE_OUVRANTE:
        case SKeys.TypesFenetre.FENETRE_COULISSANTE:
        case SKeys.TypesFenetre.PORTE_FENETRE:
        case SKeys.TypesFenetre.BAIE_COULISSANTE:
          nombreVR += fenetre.nombre;
          break;
      
        default:
          break;
      }
    });
    targetObj.electricite.ttlPrixElectricite += nombreVR * 60.82;
    nombreAppareillages += nombreVR;
    //point lumineux simple allumage plexo
    targetObj.electricite.ttlPrixElectricite += 32.75 * validFloat(targetObj.electricite.pieces.nombreGarage);
    nombreAppareillages += validFloat(targetObj.electricite.pieces.nombreGarage);
    //prise courant plexo
    targetObj.electricite.ttlPrixElectricite += 18.21 * ( validFloat(targetObj.electricite.pieces.nombreGarage) + 1 );
    nombreAppareillages += validFloat(targetObj.electricite.pieces.nombreGarage) + 1;
    //point lumineux simple allumage
    targetObj.electricite.ttlPrixElectricite += 20.59 * ( 2 * validFloat(targetObj.electricite.pieces.nombrePdv) + validFloat(targetObj.electricite.pieces.nombreCellier) + validFloat(targetObj.electricite.pieces.nombreWc) + validFloat(targetObj.electricite.pieces.nombreSde) + validFloat(targetObj.electricite.pieces.nombreChambres) + 2 );
    nombreAppareillages +=  2 * validFloat(targetObj.electricite.pieces.nombrePdv) + validFloat(targetObj.electricite.pieces.nombreCellier) + validFloat(targetObj.electricite.pieces.nombreWc) + validFloat(targetObj.electricite.pieces.nombreSde) + validFloat(targetObj.electricite.pieces.nombreChambres) + 2;
    //point lumineux va-et-vient
    targetObj.electricite.ttlPrixElectricite += 32.64 * ( validFloat(targetObj.electricite.pieces.nombreDgt) + 1 );
    nombreAppareillages += validFloat(targetObj.electricite.pieces.nombreDgt) + 1;
    //sortie fils
    targetObj.electricite.ttlPrixElectricite += 10.37 * ( validFloat(targetObj.electricite.pieces.nombreSde) + 1 );
    nombreAppareillages += validFloat(targetObj.electricite.pieces.nombreSde) + 1;
    //prise courant 2p + t
    targetObj.electricite.ttlPrixElectricite += 13.44 * ( 10 * validFloat(targetObj.electricite.pieces.nombrePdv) + 6 + validFloat(targetObj.electricite.pieces.nombreCellier) + validFloat(targetObj.electricite.pieces.nombreDgt) + validFloat(targetObj.electricite.pieces.nombreSde) + 3 * validFloat(targetObj.electricite.pieces.nombreChambres) );
    nombreAppareillages += 10 * validFloat(targetObj.electricite.pieces.nombrePdv) + 6 + validFloat(targetObj.electricite.pieces.nombreCellier) + validFloat(targetObj.electricite.pieces.nombreDgt) + validFloat(targetObj.electricite.pieces.nombreSde) + 3 * validFloat(targetObj.electricite.pieces.nombreChambres);
    //prise courant 20A
    targetObj.electricite.ttlPrixElectricite += 25.04 * ( 2 + validFloat(targetObj.electricite.pieces.nombreCellier) );
    nombreAppareillages += 2 + validFloat(targetObj.electricite.pieces.nombreCellier);
    //sortie cable 32A
    targetObj.electricite.ttlPrixElectricite += 56.43;
    nombreAppareillages++;
    //prise RJ45 stp cat6 + cable grade 2
    targetObj.electricite.ttlPrixElectricite += 22.02 * ( 2 + 2 * validFloat(targetObj.electricite.pieces.nombrePdv) );
    nombreAppareillages += 2 + 2 * validFloat(targetObj.electricite.pieces.nombrePdv);
    //plus value RJ45 stp cat6 + cable grade 3 tv
    targetObj.electricite.ttlPrixElectricite += 6.44 * ( 2 + 2 * validFloat(targetObj.electricite.pieces.nombrePdv) );
    //liaison equipotentielle
    targetObj.electricite.ttlPrixElectricite += 8.74 * validFloat(targetObj.electricite.pieces.nombreSde);
    //alim volet roulant
    targetObj.electricite.ttlPrixElectricite += 14 * validFloat(nombreVR);
    //plus value interrupteur voyant temoin
    targetObj.electricite.ttlPrixElectricite += 4.84 * 2;
    nombreAppareillages += 2;
    //kit tv
    targetObj.electricite.ttlPrixElectricite += 16.9 * validFloat(targetObj.electricite.pieces.nombrePdv);

    // -- APPAREIL --
    //radiateur atlantic solius digital 750W
    if (typeChauffage === SKeys.TypesChauffage.POELE_RADIATEUR_ELEC || typeChauffage === SKeys.TypesChauffage.CLIM_RADIATEUR_ELEC) {
      targetObj.electricite.ttlPrixElectricite += 115.07 * validFloat(targetObj.electricite.pieces.nombreChambres);
      nombreSecheServiettes += validFloat(targetObj.electricite.pieces.nombreChambres);
    }
    //seche serviette atlantic dcb18 500W
    targetObj.electricite.ttlPrixElectricite += 113.62 * validFloat(targetObj.electricite.pieces.nombreSde);
    nombreSecheServiettes += validFloat(targetObj.electricite.pieces.nombreSde);

    // -- MICRO PW --
    //kit de reinjection
    //cable rfvf
    //cable de terre
    if (typeChauffage === SKeys.TypesChauffage.GAZ_PLANCHER_CHAUFFANT || typeChauffage === SKeys.TypesChauffage.GAZ_RADIATEUR_EAU) {
      targetObj.electricite.ttlPrixElectricite += 78.39;
      targetObj.electricite.ttlPrixElectricite += 4.89 * 10;
      targetObj.electricite.ttlPrixElectricite += 2 * 10;
    }
  }
  else if (typeElec === SKeys.TypesElectricite.SQUARE_HOMECO) {
    // -- GTL --
    //tableau abonne
    if (targetObj.videSanitaire.surfaceHabitable >= 100) {
      targetObj.electricite.ttlPrixElectricite += 281.47;
    }
    else {
      targetObj.electricite.ttlPrixElectricite += 256.59;
    }
    //reseau terre
    targetObj.electricite.ttlPrixElectricite += 23.18;
    //pc modulaire
    targetObj.electricite.ttlPrixElectricite += 59.74;
    
    // -- ALIM DIVERSES --
    let ajoutRadElec = 0;
    switch (typeChauffage) {
      case SKeys.TypesChauffage.PAC_PLANCHER_CHAUFFANT:
      case SKeys.TypesChauffage.PAC_RADIATEUR_EAU:
        //alim pac duo
        targetObj.electricite.ttlPrixElectricite += 126.80;
        nombreAppareillages++;
        break;

      case SKeys.TypesChauffage.CLIM_RADIATEUR_ELEC:
        ajoutRadElec = 1;
        //alim pac mono
        targetObj.electricite.ttlPrixElectricite += 99.35;
        //alim chauffe eau
        targetObj.electricite.ttlPrixElectricite += 61.43;
        nombreAppareillages += 2;
        break;
      
      case SKeys.TypesChauffage.POELE_RADIATEUR_ELEC:
        ajoutRadElec = 1;
        break;

      case SKeys.TypesChauffage.GAZ_RADIATEUR_EAU:
      case SKeys.TypesChauffage.GAZ_PLANCHER_CHAUFFANT:
        //alim chaudiere lignes thermostat sonde ext
        targetObj.electricite.ttlPrixElectricite += 55.27;
        nombreAppareillages++;
        nombreMicroPv++;
        break;
  
      default:
        break;
    }
    //alim porte garage
    if (typePorteGarage === SKeys.TypesPorteGarage.MOTORISEE) {
      targetObj.electricite.ttlPrixElectricite += 27.45 * validFloat(nombrePorteGarage);
      nombreAppareillages += validFloat(nombrePorteGarage);
    }
    //alim seche serviettes
    targetObj.electricite.ttlPrixElectricite += 16.61 * validFloat(targetObj.electricite.pieces.nombreSde);
    //alim connecteur fils pilote
    targetObj.electricite.ttlPrixElectricite += 15.25 * ( validFloat(targetObj.electricite.pieces.nombreChambres) * ajoutRadElec )
  
    // -- VMC --
    //alim vmc
    targetObj.electricite.ttlPrixElectricite += 17.12;
    //kit vmc
    targetObj.electricite.ttlPrixElectricite += 206.37;
    nombreAppareillages += 3;
    //bouche cellier sup
    targetObj.electricite.ttlPrixElectricite += 25.83 * validFloat(targetObj.electricite.pieces.nombreCellier);
    nombreAppareillages += validFloat(targetObj.electricite.pieces.nombreCellier);
    //bouche wc sup
    if (targetObj.electricite.pieces.nombreWc) {
      targetObj.electricite.ttlPrixElectricite += 37.30 * ( validFloat(targetObj.electricite.pieces.nombreWc) - 1 );
      nombreAppareillages += validFloat(targetObj.electricite.pieces.nombreWc) - 1;
    }
    //bouche sdb sup
    if (targetObj.electricite.pieces.nombreSde) {
      targetObj.electricite.ttlPrixElectricite += 25.83 * ( validFloat(targetObj.electricite.pieces.nombreSde) - 1 );
      nombreAppareillages += validFloat(targetObj.electricite.pieces.nombreSde) - 1;
    }
    //gaine vmc d80
    targetObj.electricite.ttlPrixElectricite += 1.11 * 10 * ( validFloat(targetObj.electricite.pieces.nombreCellier) + validFloat(targetObj.electricite.pieces.nombreWc) + validFloat(targetObj.electricite.pieces.nombreSde) );
    //gaine vmc d125
    targetObj.electricite.ttlPrixElectricite += 1.39 * 10;

    // -- DIVERS --
    //combles amenagees
    if (typeCombles === SKeys.TypesCombles.AMENAGEABLES) {
      targetObj.electricite.ttlPrixElectricite += 41.04;
    }

    // -- APPAREILLAGE / FILERIE --
    //pack netatmo
    targetObj.electricite.ttlPrixElectricite += 87.82;
    nombreAppareillages++;
    //interrupteur VR
    let nombreVR = 0;
    fenetres.forEach(fenetre => {
      switch (fenetre.type) {
        case SKeys.TypesFenetre.FENETRE_OUVRANTE:
        case SKeys.TypesFenetre.FENETRE_COULISSANTE:
        case SKeys.TypesFenetre.PORTE_FENETRE:
        case SKeys.TypesFenetre.BAIE_COULISSANTE:
          nombreVR += fenetre.nombre;
          break;
      
        default:
          break;
      }
    });
    targetObj.electricite.ttlPrixElectricite += nombreVR * 60.82;
    nombreAppareillages += nombreVR;
    //point lumineux simple allumage plexo
    targetObj.electricite.ttlPrixElectricite += 31.91 * validFloat(targetObj.electricite.pieces.nombreGarage);
    nombreAppareillages += validFloat(targetObj.electricite.pieces.nombreGarage);
    //prise courant plexo
    targetObj.electricite.ttlPrixElectricite += 17.74 * ( validFloat(targetObj.electricite.pieces.nombreGarage) + 1 );
    nombreAppareillages += validFloat(targetObj.electricite.pieces.nombreGarage) + 1;
    //point lumineux simple allumage
    targetObj.electricite.ttlPrixElectricite += 19.22 * ( 2 * validFloat(targetObj.electricite.pieces.nombrePdv) + validFloat(targetObj.electricite.pieces.nombreCellier) + validFloat(targetObj.electricite.pieces.nombreWc) + validFloat(targetObj.electricite.pieces.nombreSde) + validFloat(targetObj.electricite.pieces.nombreChambres) + 2 );
    nombreAppareillages +=  2 * validFloat(targetObj.electricite.pieces.nombrePdv) + validFloat(targetObj.electricite.pieces.nombreCellier) + validFloat(targetObj.electricite.pieces.nombreWc) + validFloat(targetObj.electricite.pieces.nombreSde) + validFloat(targetObj.electricite.pieces.nombreChambres) + 2;
    //point lumineux va-et-vient
    targetObj.electricite.ttlPrixElectricite += 30.08 * ( validFloat(targetObj.electricite.pieces.nombreDgt) + 1 );
    nombreAppareillages += validFloat(targetObj.electricite.pieces.nombreDgt) + 1;
    //sortie fils
    targetObj.electricite.ttlPrixElectricite += 10.10 * ( validFloat(targetObj.electricite.pieces.nombreSde) + 1 );
    nombreAppareillages += validFloat(targetObj.electricite.pieces.nombreSde) + 1;
    //prise courant 2p + t
    targetObj.electricite.ttlPrixElectricite += 12.32 * ( 10 * validFloat(targetObj.electricite.pieces.nombrePdv) + 6 + validFloat(targetObj.electricite.pieces.nombreCellier) + validFloat(targetObj.electricite.pieces.nombreDgt) + validFloat(targetObj.electricite.pieces.nombreSde) + 3 * validFloat(targetObj.electricite.pieces.nombreChambres) );
    nombreAppareillages += 10 * validFloat(targetObj.electricite.pieces.nombrePdv) + 6 + validFloat(targetObj.electricite.pieces.nombreCellier) + validFloat(targetObj.electricite.pieces.nombreDgt) + validFloat(targetObj.electricite.pieces.nombreSde) + 3 * validFloat(targetObj.electricite.pieces.nombreChambres);
    
    //prise courant 20A
    targetObj.electricite.ttlPrixElectricite += 20.42 * ( 2 + validFloat(targetObj.electricite.pieces.nombreCellier) );
    nombreAppareillages += 2 + validFloat(targetObj.electricite.pieces.nombreCellier);
    //sortie cable 32A
    targetObj.electricite.ttlPrixElectricite += 50.63;
    nombreAppareillages++;
    //prise RJ45 stp cat6 + cable grade 2
    targetObj.electricite.ttlPrixElectricite += 18.95 * ( 2 + 2 * validFloat(targetObj.electricite.pieces.nombrePdv) );
    nombreAppareillages += 2 + 2 * validFloat(targetObj.electricite.pieces.nombrePdv);
    //plus value RJ45 stp cat6 + cable grade 3 tv
    targetObj.electricite.ttlPrixElectricite += 5.47 * ( 2 + 2 * validFloat(targetObj.electricite.pieces.nombrePdv) );
    //liaison equipotentielle
    targetObj.electricite.ttlPrixElectricite += 8.52 * validFloat(targetObj.electricite.pieces.nombreSde);
    //alim volet roulant
    targetObj.electricite.ttlPrixElectricite += 13.65 * validFloat(nombreVR);
    //plus value interrupteur voyant temoin
    targetObj.electricite.ttlPrixElectricite += 4.84 * 2;
    nombreAppareillages += 2;
    //kit tv
    targetObj.electricite.ttlPrixElectricite += 16.9 * validFloat(targetObj.electricite.pieces.nombrePdv);

    // -- APPAREIL --
    //radiateur atlantic solius digital 750W
    if (typeChauffage === SKeys.TypesChauffage.POELE_RADIATEUR_ELEC || typeChauffage === SKeys.TypesChauffage.CLIM_RADIATEUR_ELEC) {
      targetObj.electricite.ttlPrixElectricite += 113.10 * validFloat(targetObj.electricite.pieces.nombreChambres);
      nombreSecheServiettes += validFloat(targetObj.electricite.pieces.nombreChambres);
    }
    //seche serviette atlantic dcb18 500W
    targetObj.electricite.ttlPrixElectricite += 111.68 * validFloat(targetObj.electricite.pieces.nombreSde);
    nombreSecheServiettes += validFloat(targetObj.electricite.pieces.nombreSde);

    // -- MICRO PW --
    //kit de reinjection
    //cable rfvf
    //cable de terre
    if (typeChauffage === SKeys.TypesChauffage.GAZ_PLANCHER_CHAUFFANT || typeChauffage === SKeys.TypesChauffage.GAZ_RADIATEUR_EAU) {
      targetObj.electricite.ttlPrixElectricite += 78.39;
      targetObj.electricite.ttlPrixElectricite += 4.89 * 10;
      targetObj.electricite.ttlPrixElectricite += 2 * 10;
    }
  }

  targetObj.electricite.ttlPrixElectricite = validFloat(targetObj.electricite.ttlPrixElectricite) * 1.03 + 150
  //FORMULES : Appareillages
  targetObj.electricite.ttlPrixElectricite += validFloat(ElectriciteParams.APPAREILLAGES) * nombreAppareillages;
  //Seche-serviettes
  targetObj.electricite.ttlPrixElectricite += validFloat(ElectriciteParams.SECHE_SERVIETTES) * nombreSecheServiettes;
  //Seche-serviettes
  targetObj.electricite.ttlPrixElectricite += validFloat(ElectriciteParams.MICRO_PV) * nombreMicroPv;
  //Consuel
  targetObj.electricite.ttlPrixElectricite += ElectriciteParams.CONSUEL;
}








/*
export async function addPdv(targetObj, typeChauffage) {
  let sumPrix = validFloat(ElectriciteParams.PRIX_PDV_1) + validFloat(ElectriciteParams.PRIX_PDV_2) + validFloat(ElectriciteParams.PRIX_PDV_3);
  if (typeChauffage === SKeys.TypesChauffage.GAZ_RADIATEUR_EAU || typeChauffage === SKeys.TypesChauffage.GAZ_PLANCHER_CHAUFFANT) {
    sumPrix += validFloat(ElectriciteParams.PRIX_PDV_GAZ);
  }
  if (validFloat(targetObj.electricite.pieces.surfacePdv) || validFloat(targetObj.electricite.pieces.surfaceCuisine) || validFloat(targetObj.electricite.pieces.surfaceCellier)) {
    targetObj.electricite.ttlPrixElectricite += validFloat(sumPrix);
  }
}

export async function addChambres(targetObj, typeChauffage) {
  let sumPrix = validFloat(ElectriciteParams.PRIX_CHAMBRE_1) + validFloat(ElectriciteParams.PRIX_CHAMBRE_2);
  if (typeChauffage === SKeys.TypesChauffage.CLIM_RADIATEUR_ELEC || typeChauffage === SKeys.TypesChauffage.POELE_RADIATEUR_ELEC) {
    sumPrix += validFloat(ElectriciteParams.PRIX_CHAMBRE_RAD_ELEC);
  }
  targetObj.electricite.ttlPrixElectricite += validFloat(sumPrix) * validFloat(targetObj.electricite.pieces.nombreChambres);
}

export async function addSde(targetObj) {
  targetObj.electricite.ttlPrixElectricite += (validFloat(ElectriciteParams.PRIX_SDE_1) + validFloat(ElectriciteParams.PRIX_SDE_2)) * validFloat(targetObj.electricite.pieces.nombreSde);
}

export async function addWc(targetObj) {
  targetObj.electricite.ttlPrixElectricite += (validFloat(ElectriciteParams.PRIX_WC_1) + validFloat(ElectriciteParams.PRIX_WC_2)) * validFloat(targetObj.electricite.pieces.nombreWc);
}

export async function addGarage(targetObj) {
  targetObj.electricite.ttlPrixElectricite += (validFloat(ElectriciteParams.PRIX_GARAGE_1) + validFloat(ElectriciteParams.PRIX_GARAGE_2)) * validFloat(targetObj.electricite.pieces.nombreGarage);
}
*/