<template>
  <div class="textes single-view flex-cnt-row justify-center">
    <div class="main-text flex-cnt-col align-center justify-between">
      <div class="view-header flex-cnt-row flex-center">
        <h2>{{("Textes").toUpperCase()}}</h2>
      </div>
      <div class="unit-text flx1 flex-cnt-col justify-start align-center">
        <TextTable
          v-bind:initTexts="initTexts"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TextTable from "../components/forms/TextTable.vue"
import ListeTextesBase from "../pdf/texts"

export default {
  components: {
    TextTable
  },
  data: function () {
    return {
      initTexts: ListeTextesBase,
    }
  },
}
</script>

<style scoped>
.textes {
  min-width: 800px;
  overflow: auto;
  box-sizing: border-box;
}

.single-view {
  padding: 0;
}

.main-text {
  box-sizing: border-box;
  padding: 20px 40px;
  min-width: 700px;
}

.unit-text{
  justify-content: start;
  box-sizing: border-box;
  width: fit-content;
}

.unit-table{
  width: fit-content;
}

.addel-btn {
  width: fit-content;
  padding: 0px 10px;
}

.cancel-btn {
  background: rgb(245, 197, 197);
  margin-right: 2px;
}

.cancel-btn:hover {
  background: rgb(230, 180, 180);
}

.add-btn {
  background: rgb(177, 226, 184);
}
.add-btn:hover {
  background: rgb(158, 207, 165);
}
.add-btn:disabled {
  opacity: 0.4;
  background: #dee3e6;
  cursor: normal;
  transition: 0.25s;
}

.add-section {
  margin-top: 20px;
  border: 1px solid #c6d2d9;
  background: rgb(228, 234, 238);
  padding: 5px 10px;
  box-sizing: border-box;
}

.add-cell {
  padding: 0px 5px;
  min-width: 230px;
  box-sizing: border-box;
}

input.invalid, select.invalid {
  border-color: red;
  border-width: 2px;
  border-radius: 3px;
  outline: 0;
}
</style>
