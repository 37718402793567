<template>
  <div class="detail-table flex-cnt-col">
    <h4 class="section-title" v-if="title">
      {{title}}
    </h4>
    <div v-if="sForm">
      <table>
        <tr>
          <th>
            Paramètre
          </th>
          <th>
            Valeur
          </th>
        </tr>
        <tr 
          v-for="input in inputs"
          :key="input.index"
        >
          <td class="cell-key">
            {{input.label}}
          </td>
          <td class="cell-val">
            {{displayValue(sForm[input.queryKey], input.inputUnit)}}
          </td>
        </tr>
      </table>
    </div>
    <div v-else-if="sForms">
      <table 
        v-for="form in sForms"
        :key="form.index"
      >
        <tr>
          <th>
            Paramètre
          </th>
          <th>
            Valeur
          </th>
        </tr>
        <tr 
          v-for="input in inputs"
          :key="input.index"
        >
          <td class="cell-key">
            {{input.label}}
          </td>
          <td class="cell-val">
            {{displayValue(form[input.queryKey], input.inputUnit)}}
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
    }
  },
  props: {
    title: {
      type: String,
      required: false,
      default: null
    },
    sForm: {
      type: Object,
      required: false,
      default: null
    },
    sForms: {
      type: Array,
      required: false,
      default: null
    },
    inputs: {
      type: Array,
      required: false,
      default: null
    }
  },
  methods: {
    displayValue(val, unit) {
      if (val === null || val === undefined) {
        return '-';
      }
      else if (unit) {
        return `${val} ${unit}`;
      }
      else {
        return val;
      }
    }
  }
}
</script>

<style scoped>
.detail-table {
  margin-top: 20px;
  margin-bottom: 30px;
  min-width: 600px;
  box-sizing: border-box;
}

.section-title {
  margin-bottom: 20px;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #c6d2d9;
  background: #fff;
  text-align: center;
  text-transform: uppercase;
}

table {
  border-collapse: collapse;
  border: 1px solid #c6d2d9;
  margin-bottom: 10px;
}

thead {
  min-height: 30px;
}

tr:nth-child(2n+1) {
  background: #fff;
}
tr:nth-child(2n) {
  background: transparent;
}

th {
  min-width: 150px;
  padding: 5px 10px;
  box-sizing: border-box;
  border: 1px solid #c6d2d9;
  background: rgb(228, 234, 238);
  font-size: 14px;
  font-family: "OpenSans400";
  text-transform: uppercase;
}

td {
  padding: 10px 10px;
  font-size: 13px;
  font-family: "OpenSans400";
  border-left: 1px solid #c6d2d9;
  box-sizing: border-box;
}

.cell-key {
  width: 250px;
  overflow: hidden;
  text-align: right;
}

.cell-val {
  width: 350px;
  overflow: auto;
  text-align: left;
}
</style>