import { SKeys } from "../param/selectKeys";

import { validFloat } from "./helpers"

export const PlomberieParams = Object.freeze({
  PRIX_BAIGNOIRE:          270,
  PRIX_DOUCHE_ITAL:        254,
  PRIX_BAC_A_DOUCHE:       259,
  PRIX_DOUCHE_EXTRA_PLATE: 359,
  PRIX_WC_SIMPLE:          167,
  PRIX_WC_SUSPENDU:        293,
  PRIX_LAVABO:             109,
  PRIX_LAVE_MAINS:         96,
  PRIX_ELEMENT:            150,
  PRIX_RACCORD:            50,
  PRIX_COLONNE:            150
});

export async function computeParamsPlomberie(targetObj, sanitaires) {
  sanitaires.forEach(element => {
    if (element.fournisseur === SKeys.Fournisseurs.CLIENT) {
      targetObj.plomberie.ttlSanitaires.nombreClient += element.nombre;
    }
    switch (element.type) {
      case SKeys.TypesSanitaires.BAIGNOIRE:
        if (element.fournisseur === SKeys.Fournisseurs.MAV) {
          targetObj.plomberie.baignoire.nombre += element.nombre;
          targetObj.plomberie.ttlSanitaires.nombre += element.nombre;
        }
        else if (element.fournisseur === SKeys.Fournisseurs.OPTION) {
          targetObj.plomberie.baignoire.nombreOption += element.nombre;
          targetObj.plomberie.ttlSanitaires.nombreOption += element.nombre;
        }
        else if (element.fournisseur === SKeys.Fournisseurs.CLIENT) {
          targetObj.plomberie.baignoire.nombreClient += element.nombre;
        }
        break;

      case SKeys.TypesSanitaires.DOUCHE_ITAL:
        if (element.fournisseur === SKeys.Fournisseurs.MAV) {
          targetObj.plomberie.doucheItal.nombre += element.nombre;
          targetObj.plomberie.ttlSanitaires.nombre += element.nombre;
        }
        else if (element.fournisseur === SKeys.Fournisseurs.OPTION) {
          targetObj.plomberie.doucheItal.nombreOption += element.nombre;
          targetObj.plomberie.ttlSanitaires.nombreOption += element.nombre;
        }
        else if (element.fournisseur === SKeys.Fournisseurs.CLIENT) {
          targetObj.plomberie.doucheItal.nombreClient += element.nombre;
        }
        break;
        
      case SKeys.TypesSanitaires.BAC_A_DOUCHE:
        if (element.fournisseur === SKeys.Fournisseurs.MAV) {
          targetObj.plomberie.bacADouche.nombre += element.nombre;
          targetObj.plomberie.ttlSanitaires.nombre += element.nombre;
        }
        else if (element.fournisseur === SKeys.Fournisseurs.OPTION) {
          targetObj.plomberie.bacADouche.nombreOption += element.nombre;
          targetObj.plomberie.ttlSanitaires.nombreOption += element.nombre;
        }
        else if (element.fournisseur === SKeys.Fournisseurs.CLIENT) {
          targetObj.plomberie.bacADouche.nombreClient += element.nombre;
        }
        break;
        
      case SKeys.TypesSanitaires.DOUCHE_EXTRA_PLATE:
        if (element.fournisseur === SKeys.Fournisseurs.MAV) {
          targetObj.plomberie.doucheExtraPlate.nombre += element.nombre;
          targetObj.plomberie.ttlSanitaires.nombre += element.nombre;
        }
        else if (element.fournisseur === SKeys.Fournisseurs.OPTION) {
          targetObj.plomberie.doucheExtraPlate.nombreOption += element.nombre;
          targetObj.plomberie.ttlSanitaires.nombreOption += element.nombre;
        }
        else if (element.fournisseur === SKeys.Fournisseurs.CLIENT) {
          targetObj.plomberie.doucheExtraPlate.nombreClient += element.nombre;
        }
        break;
        
      case SKeys.TypesSanitaires.WC_SIMPLE:
        if (element.fournisseur === SKeys.Fournisseurs.MAV) {
          targetObj.plomberie.wcSimple.nombre += element.nombre;
          targetObj.plomberie.ttlSanitaires.nombre += element.nombre;
        }
        else if (element.fournisseur === SKeys.Fournisseurs.OPTION) {
          targetObj.plomberie.wcSimple.nombreOption += element.nombre;
          targetObj.plomberie.ttlSanitaires.nombreOption += element.nombre;
        }
        else if (element.fournisseur === SKeys.Fournisseurs.CLIENT) {
          targetObj.plomberie.wcSimple.nombreClient += element.nombre;
        }
        break;
        
      case SKeys.TypesSanitaires.WC_SUSPENDU:
        if (element.fournisseur === SKeys.Fournisseurs.MAV) {
          targetObj.plomberie.wcSuspendu.nombre += element.nombre;
          targetObj.plomberie.ttlSanitaires.nombre += element.nombre;
        }
        else if (element.fournisseur === SKeys.Fournisseurs.OPTION) {
          targetObj.plomberie.wcSuspendu.nombreOption += element.nombre;
          targetObj.plomberie.ttlSanitaires.nombreOption += element.nombre;
        }
        else if (element.fournisseur === SKeys.Fournisseurs.CLIENT) {
          targetObj.plomberie.wcSuspendu.nombreClient += element.nombre;
        }
        break;
        
      case SKeys.TypesSanitaires.LAVABO:
        if (element.fournisseur === SKeys.Fournisseurs.MAV) {
          targetObj.plomberie.lavabo.nombre += element.nombre;
          targetObj.plomberie.ttlSanitaires.nombre += element.nombre;
        }
        else if (element.fournisseur === SKeys.Fournisseurs.OPTION) {
          targetObj.plomberie.lavabo.nombreOption += element.nombre;
          targetObj.plomberie.ttlSanitaires.nombreOption += element.nombre;
        }
        else if (element.fournisseur === SKeys.Fournisseurs.CLIENT) {
          targetObj.plomberie.lavabo.nombreClient += element.nombre;
        }
        break;
        
      case SKeys.TypesSanitaires.LAVE_MAINS:
        if (element.fournisseur === SKeys.Fournisseurs.MAV) {
          targetObj.plomberie.laveMains.nombre += element.nombre;
          targetObj.plomberie.ttlSanitaires.nombre += element.nombre;
        }
        else if (element.fournisseur === SKeys.Fournisseurs.OPTION) {
          targetObj.plomberie.laveMains.nombreOption += element.nombre;
          targetObj.plomberie.ttlSanitaires.nombreOption += element.nombre;
        }
        else if (element.fournisseur === SKeys.Fournisseurs.CLIENT) {
          targetObj.plomberie.laveMains.nombreClient += element.nombre;
        }
        break;
    
      default:
        break;
    }
  });
}

export function addSanitaires(targetObj) {
  //BAIGNOIRE
  targetObj.plomberie.ttlPrixPlomberie += validFloat(PlomberieParams.PRIX_BAIGNOIRE) * validFloat(targetObj.plomberie.baignoire.nombre);
  targetObj.plomberie.ttlPrixPlomberieOption += validFloat(PlomberieParams.PRIX_BAIGNOIRE) * validFloat(targetObj.plomberie.baignoire.nombreOption);
  //DOUCHE ITAL
  targetObj.plomberie.ttlPrixPlomberie += validFloat(PlomberieParams.PRIX_DOUCHE_ITAL) * validFloat(targetObj.plomberie.doucheItal.nombre);
  targetObj.plomberie.ttlPrixPlomberieOption += validFloat(PlomberieParams.PRIX_DOUCHE_ITAL) * validFloat(targetObj.plomberie.doucheItal.nombreOption) + validFloat(targetObj.revetementInt.ttlPrixDoucheItalOption);
  //BAC A DOUCHE
  targetObj.plomberie.ttlPrixPlomberie += validFloat(PlomberieParams.PRIX_BAC_A_DOUCHE) * validFloat(targetObj.plomberie.bacADouche.nombre);
  targetObj.plomberie.ttlPrixPlomberieOption += validFloat(PlomberieParams.PRIX_BAC_A_DOUCHE) * validFloat(targetObj.plomberie.bacADouche.nombreOption);
  //DOUCHE EXTRA PLATE
  targetObj.plomberie.ttlPrixPlomberie += validFloat(PlomberieParams.PRIX_DOUCHE_EXTRA_PLATE) * validFloat(targetObj.plomberie.doucheExtraPlate.nombre);
  targetObj.plomberie.ttlPrixPlomberieOption += validFloat(PlomberieParams.PRIX_DOUCHE_EXTRA_PLATE) * validFloat(targetObj.plomberie.doucheExtraPlate.nombreOption);
  //WC SIMPLE
  targetObj.plomberie.ttlPrixPlomberie += validFloat(PlomberieParams.PRIX_WC_SIMPLE) * validFloat(targetObj.plomberie.wcSimple.nombre);
  targetObj.plomberie.ttlPrixPlomberieOption += validFloat(PlomberieParams.PRIX_WC_SIMPLE) * validFloat(targetObj.plomberie.wcSimple.nombreOption);
  //WC SUSPENDU
  targetObj.plomberie.ttlPrixPlomberie += validFloat(PlomberieParams.PRIX_WC_SUSPENDU) * validFloat(targetObj.plomberie.wcSuspendu.nombre);
  targetObj.plomberie.ttlPrixPlomberieOption += validFloat(PlomberieParams.PRIX_WC_SUSPENDU) * validFloat(targetObj.plomberie.wcSuspendu.nombreOption);
  //LAVABO
  targetObj.plomberie.ttlPrixPlomberie += validFloat(PlomberieParams.PRIX_LAVABO) * validFloat(targetObj.plomberie.lavabo.nombre);
  targetObj.plomberie.ttlPrixPlomberieOption += validFloat(PlomberieParams.PRIX_LAVABO) * validFloat(targetObj.plomberie.lavabo.nombreOption);
  //LAVE MAINS
  targetObj.plomberie.ttlPrixPlomberie += validFloat(PlomberieParams.PRIX_LAVE_MAINS) * validFloat(targetObj.plomberie.laveMains.nombre);
  targetObj.plomberie.ttlPrixPlomberieOption += validFloat(PlomberieParams.PRIX_LAVE_MAINS) * validFloat(targetObj.plomberie.laveMains.nombreOption);
  //ELEMENTS
  targetObj.plomberie.ttlPrixPlomberie += validFloat(PlomberieParams.PRIX_ELEMENT) * (4 + validFloat(targetObj.plomberie.ttlSanitaires.nombre) + validFloat(targetObj.plomberie.ttlSanitaires.nombreOption) + validFloat(targetObj.plomberie.ttlSanitaires.nombreClient));
  //RACCORDS
  targetObj.plomberie.ttlPrixPlomberie += validFloat(PlomberieParams.PRIX_RACCORD);
  //COLONNE
  if (validFloat(targetObj.toitureRdc.comblesAmenageables)) {
    targetObj.plomberie.ttlPrixPlomberie += validFloat(PlomberieParams.PRIX_COLONNE);
  }
}