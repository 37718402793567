<template>
  <div class="recap-table flex-cnt-row">
    <div class="recap-sections flx1 flex-cnt-col align-center">
      <div>
        <h4 class="section-title">
          TOTAUX
        </h4>
        <table class="table-recap">
          <tr>
            <td class="cell-key">
              TERRASSEMENT
            </td>
            <td class="cell-val">
              {{cData.terrassement.ttlPrixTerrassement ? cData.terrassement.ttlPrixTerrassement.toLocaleString('it-IT', {minimumFractionDigits: 2, maximumFractionDigits: 2}).replace('.', ' ') : '-'}} €
            </td>
          </tr>
          <tr>
            <td class="cell-key">
              VS/SOUS-SOL
            </td>
            <td class="cell-val">
              {{cData.videSanitaire.ttlPrixVideSanitaire ? cData.videSanitaire.ttlPrixVideSanitaire.toLocaleString('it-IT', {minimumFractionDigits: 2, maximumFractionDigits: 2}).replace('.', ' ') : '-'}} €
            </td>
          </tr>
          <tr>
            <td class="cell-key">
              RDC
            </td>
            <td class="cell-val">
              {{cData.rdc.ttlPrixRdc ? cData.rdc.ttlPrixRdc.toLocaleString('it-IT', {minimumFractionDigits: 2, maximumFractionDigits: 2}).replace('.', ' ') : '-'}} €
            </td>
          </tr>
          <tr>
            <td class="cell-key">
              ETAGE
            </td>
            <td class="cell-val">
              {{cData.combles.ttlPrixCombles ? cData.combles.ttlPrixCombles.toLocaleString('it-IT', {minimumFractionDigits: 2, maximumFractionDigits: 2}).replace('.', ' ') : '-'}} €
            </td>
          </tr>
          <tr>
            <td class="cell-key">
              PIGNON RDC
            </td>
            <td class="cell-val">
              {{cData.pignonRdc.ttlPrixPignonRdc ? cData.pignonRdc.ttlPrixPignonRdc.toLocaleString('it-IT', {minimumFractionDigits: 2, maximumFractionDigits: 2}).replace('.', ' ') : '-'}} €
            </td>
          </tr>
          <tr>
            <td class="cell-key">
              TOITURE RDC
            </td>
            <td class="cell-val">
              {{cData.toitureRdc.ttlPrixToitureRdc ? cData.toitureRdc.ttlPrixToitureRdc.toLocaleString('it-IT', {minimumFractionDigits: 2, maximumFractionDigits: 2}).replace('.', ' ') : '-'}} €
            </td>
          </tr>
          <tr>
            <td class="cell-key">
              MENUISERIE
            </td>
            <td class="cell-val">
              {{cData.ouverture.ttlPrixOuverture ? cData.ouverture.ttlPrixOuverture.toLocaleString('it-IT', {minimumFractionDigits: 2, maximumFractionDigits: 2}).replace('.', ' ') : '-'}} €
            </td>
          </tr>
          <tr>
            <td class="cell-key">
              PLACO
            </td>
            <td class="cell-val">
              {{cData.placo.ttlPrixPlaco ? cData.placo.ttlPrixPlaco.toLocaleString('it-IT', {minimumFractionDigits: 2, maximumFractionDigits: 2}).replace('.', ' ') : '-'}} €
            </td>
          </tr>
          <tr>
            <td class="cell-key">
              REVETEMENT INTERIEUR
            </td>
            <td class="cell-val">
              {{cData.revetementInt.ttlPrixRevetementInt ? cData.revetementInt.ttlPrixRevetementInt.toLocaleString('it-IT', {minimumFractionDigits: 2, maximumFractionDigits: 2}).replace('.', ' ') : '-'}} €
            </td>
          </tr>
          <tr>
            <td class="cell-key">
              ELECTRICITE
            </td>
            <td class="cell-val">
              {{cData.electricite.ttlPrixElectricite ? cData.electricite.ttlPrixElectricite.toLocaleString('it-IT', {minimumFractionDigits: 2, maximumFractionDigits: 2}).replace('.', ' ') : '-'}} €
            </td>
          </tr>
          <tr>
            <td class="cell-key">
              REVETEMENT EXTERIEUR
            </td>
            <td class="cell-val">
              {{cData.revetementExt.ttlPrixRevetementExt ? cData.revetementExt.ttlPrixRevetementExt.toLocaleString('it-IT', {minimumFractionDigits: 2, maximumFractionDigits: 2}).replace('.', ' ') : '-'}} €
            </td>
          </tr>
          <tr>
            <td class="cell-key">
              PLOMBERIE
            </td>
            <td class="cell-val">
              {{cData.plomberie.ttlPrixPlomberie ? cData.plomberie.ttlPrixPlomberie.toLocaleString('it-IT', {minimumFractionDigits: 2, maximumFractionDigits: 2}).replace('.', ' ') : '-'}} €
            </td>
          </tr>
          <tr>
            <td class="cell-key">
              CHAUFFAGE
            </td>
            <td class="cell-val">
              {{cData.chauffage.ttlPrixChauffage ? cData.chauffage.ttlPrixChauffage.toLocaleString('it-IT', {minimumFractionDigits: 2, maximumFractionDigits: 2}).replace('.', ' ') : '-'}} €
            </td>
          </tr>
          <tr>
            <td class="cell-key">
              PEINTURE
            </td>
            <td class="cell-val">
              {{cData.peinture.ttlPrixPeinture ? cData.peinture.ttlPrixPeinture.toLocaleString('it-IT', {minimumFractionDigits: 2, maximumFractionDigits: 2}).replace('.', ' ') : '-'}} €
            </td>
          </tr>
          <tr>
            <td class="cell-key">
              DIAGNOSTIQUE BBC + FRAIS DIVERS
            </td>
            <td class="cell-val">
              {{cData.diagnostiqueBBCFraisDivers.ttlPrixDiagnostiqueBBCFraisDivers ? cData.diagnostiqueBBCFraisDivers.ttlPrixDiagnostiqueBBCFraisDivers.toLocaleString('it-IT', {minimumFractionDigits: 2, maximumFractionDigits: 2}).replace('.', ' ') : '-'}} €
            </td>
          </tr>
          <tr>
            <td class="cell-key">
              
            </td>
            <td class="cell-val">

            </td>
          </tr>
          <tr>
            <td class="cell-key">
              {{gForm.constructions && gForm.constructions.origineProspect ? gForm.constructions.origineProspect.toUpperCase() : '-'}}
            </td>
            <td class="cell-val">
              {{cData.origineProspect ? cData.origineProspect.ttlPrixOrigineProspect.toLocaleString('it-IT', {minimumFractionDigits: 2, maximumFractionDigits: 2}).replace('.', ' ') : '-'}} €
            </td>
          </tr>
          <tr>
            <td class="cell-key">
              AJUSTEMENT PRIX TTC
            </td>
            <td class="cell-val">
              {{gForm.ajustements && parseFloat(gForm.ajustements.montant) ? gForm.ajustements.montant.toLocaleString('it-IT', {minimumFractionDigits: 2, maximumFractionDigits: 2}).replace('.', ' ') : '-'}} €
            </td>
          </tr>
          <tr>
            <td class="cell-key">
              FRAIS ARCHITECTE
            </td>
            <td class="cell-val">
              {{cData.architecte.ttlPrixArchitecte ? cData.architecte.ttlPrixArchitecte.toLocaleString('it-IT', {minimumFractionDigits: 2, maximumFractionDigits: 2}).replace('.', ' ') : '-'}} €
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="compute-section flx1 flex-cnt-col align-center">
      <div>
        <h4 class="section-title">
          {{'Résultats'.toUpperCase()}}
        </h4>
        <table class="table-recap">
          <tr>
            <td class="cell-key">
              HAUSSE DE PRIX
            </td>
            <td class="cell-val">
              {{((paramHausseDePrix - 1).toFixed(2) * 100).toFixed(0)}} %
            </td>
          </tr>
        </table>
        <table class="table-recap">
          <tr>
            <td class="cell-key">
              TOTAL HT REVIENT
            </td>
            <td class="cell-val">
              {{cData.recap ? cData.recap.ttlHtRevient.toLocaleString('it-IT', {minimumFractionDigits: 2, maximumFractionDigits: 2}).replace('.', ' ') : '-'}} €
            </td>
          </tr>
          <tr>
            <td class="cell-key">
              TOTAL HT GAIN
            </td>
            <td class="cell-val">
              {{cData.recap ? cData.recap.ttlHtGain.toLocaleString('it-IT', {minimumFractionDigits: 2, maximumFractionDigits: 2}).replace('.', ' ') : '-'}} €
            </td>
          </tr>
          <tr>
            <td class="cell-key">
              % DU GAIN
            </td>
            <td class="cell-val">
              {{cData.recap ? cData.recap.pourcentageGain.toLocaleString('it-IT', {minimumFractionDigits: 0, maximumFractionDigits: 0}).replace('.', ' ') : '-'}} %
            </td>
          </tr>
          <tr>
            <td class="cell-key">
              TOTAL HT INTERNE
            </td>
            <td class="cell-val">
              {{cData.recap ? cData.recap.ttlHtInterne.toLocaleString('it-IT', {minimumFractionDigits: 2, maximumFractionDigits: 2}).replace('.', ' ') : '-'}} €
            </td>
          </tr>
          <tr>
            <td class="cell-key">
              TVA 20%
            </td>
            <td class="cell-val">
              {{cData.recap ? cData.recap.tvaInterne.toLocaleString('it-IT', {minimumFractionDigits: 2, maximumFractionDigits: 2}).replace('.', ' ') : '-'}} €
            </td>
          </tr>
          <tr>
            <td class="cell-key">
              TOTAL TTC + GL
            </td>
            <td class="cell-val">
              {{cData.recap ? cData.recap.ttlTtcGlInterne.toLocaleString('it-IT', {minimumFractionDigits: 2, maximumFractionDigits: 2}).replace('.', ' ') : '-'}} €
            </td>
          </tr>
          <tr>
            <td class="cell-key">
              TTC AVEC RCP
            </td>
            <td class="cell-val">
              {{cData.recap ? cData.recap.ttcRcpInterne.toLocaleString('it-IT', {minimumFractionDigits: 2, maximumFractionDigits: 2}).replace('.', ' ') : '-'}} €
            </td>
          </tr>
          <tr>
            <td class="cell-key">
              DOMMAGE OUVRABLE
            </td>
            <td class="cell-val">
              {{cData.recap ? cData.recap.dommageOuvrableInterne.toLocaleString('it-IT', {minimumFractionDigits: 2, maximumFractionDigits: 2}).replace('.', ' ') : '-'}} €
            </td>
          </tr>
          <tr>
            <td class="cell-key">
            COUT TOTAL PROJET
            </td>
            <td class="cell-val">
              {{cData.recap ? cData.recap.ttlCoutProjetInterne.toLocaleString('it-IT', {minimumFractionDigits: 2, maximumFractionDigits: 2}).replace('.', ' ') : '-'}} €
            </td>
          </tr>
        </table>
        <table class="table-recap">
          <tr>
            <td class="cell-key">
              {{labelDepartement}}
            </td>
            <td class="cell-val">
              {{paramDepartement}}
            </td>
          </tr>
        </table>
        <table class="table-recap">
          <tr>
            <td class="cell-key">
              TOTAL HT AGCO
            </td>
            <td class="cell-val">
              {{cData.recap.ttlHtAgco.toLocaleString('it-IT', {minimumFractionDigits: 2, maximumFractionDigits: 2}).replace('.', ' ')}} €
            </td>
          </tr>
          <tr>
            <td class="cell-key">
              TVA 20%
            </td>
            <td class="cell-val">
              {{cData.recap.tvaAgco.toLocaleString('it-IT', {minimumFractionDigits: 2, maximumFractionDigits: 2}).replace('.', ' ')}} €
            </td>
          </tr>
          <tr>
            <td class="cell-key">
              TOTAL TTC + GL
            </td>
            <td class="cell-val">
              {{cData.recap.ttlTtcGlAgco.toLocaleString('it-IT', {minimumFractionDigits: 2, maximumFractionDigits: 2}).replace('.', ' ')}} €
            </td>
          </tr>
          <tr class="tr-hled-1">
            <td class="cell-key">
              TTC AVEC RCP
            </td>
            <td class="cell-val">
              {{cData.recap.ttcRcpAgco.toLocaleString('it-IT', {minimumFractionDigits: 2, maximumFractionDigits: 2}).replace('.', ' ')}} €
            </td>
          </tr>
          <tr class="tr-hled-2">
            <td class="cell-key">
              DOMMAGE OUVRABLE
            </td>
            <td class="cell-val">
              {{cData.recap.dommageOuvrableAgco.toLocaleString('it-IT', {minimumFractionDigits: 2, maximumFractionDigits: 2}).replace('.', ' ')}} €
            </td>
          </tr>
          <tr class="tr-hled-3">
            <td class="cell-key">
            COUT TOTAL PROJET
            </td>
            <td class="cell-val">
              {{cData.recap.ttlCoutProjetAgco.toLocaleString('it-IT', {minimumFractionDigits: 2, maximumFractionDigits: 2}).replace('.', ' ')}} €
            </td>
          </tr>
        </table>
        <table class="table-recap">
          <tr>
            <td class="cell-key">
              COMMISSION COMMERCIALE
            </td>
            <td class="cell-val">
              {{cData.recap.commissionCommerciale.toLocaleString('it-IT', {minimumFractionDigits: 2, maximumFractionDigits: 2}).replace('.', ' ')}} €
            </td>
          </tr>
          <tr>
            <td class="cell-key">
              SUPPLEMENT REMY
            </td>
            <td class="cell-val">
              {{cData.recap.supplementRemy.toLocaleString('it-IT', {minimumFractionDigits: 2, maximumFractionDigits: 2}).replace('.', ' ')}} €
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { SKeys } from '../../param/selectKeys';

export default {
  props: {
    cData: {
      type: Object,
      required: false,
      default: null
    },
    gForm: {
      type: Object,
      required: false, 
      default: null
    },
    paramHausseDePrix: {
      type: String,
      required: true, 
      default: null
    },
    paramDepartement: {
      type: String,
      required: true, 
      default: null
    },
  },
  computed: {
    labelDepartement: function() {
      let res = '-';
      if (this.gForm.constructions) {
      if (this.gForm.constructions.departement === SKeys.Departements.LOIR_ET_CHER_41) {
          res = "LOIR ET CHER 41"
        }
        else if (this.gForm.constructions.departement === SKeys.Departements.LOIRET_45) {
          res = "LOIRET 45"
        }
        else if (this.gForm.constructions.departement === SKeys.Departements.INDRE_ET_LOIRE_37) {
          res = "INDRE ET LOIRE 37"
        }
      }
      return res;
    }
  }
}
</script>

<style>
.recap-table {
  width: 100%;
}

.recap-sections {
  border-right: 1px solid #c6d2d9;
}

.section-title {
  margin-top: 10px;
  margin-bottom: 20px;
  width: 400px;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #c6d2d9;
  background: #fff;
  text-align: center;
  text-transform: uppercase;
}

.compute-section table {
  margin-bottom: 20px;
}

table {
  border-collapse: collapse;
  border: 1px solid #c6d2d9;
}

tr:nth-child(2n+1) {
  background: #fff;
}
tr:nth-child(2n) {
  background: transparent;
}

td {
  padding: 10px 10px;
  font-size: 13px;
  font-family: "OpenSans400";
  border-left: 1px solid #c6d2d9;
  box-sizing: border-box;
  height: 38px;
}

.cell-key {
  width: 250px;
  overflow: hidden;
  text-align: right;
}

.cell-val {
  width: 150px;
  overflow: auto;
  text-align: right;
}

.tr-hled-1 td {
  background-color: rgba(255, 182, 72, 0.226);
  /* border: 1px solid red; */
}
.tr-hled-2 td {
  background-color: rgba(255, 182, 72, 0.082);
  /* border: 1px solid red; */
}
.tr-hled-3 td {
  background-color: rgba(255, 182, 72, 0.425);
  /* border: 1px solid red; */
}
</style>