import { 
  BlocPorteTypes,
  ComblesTypes,
  MenuiserieMateriaux,
  MenuiserieMateriauxAluOb,
  MenuiserieMateriauxPvcAlu,
  MenuiserieMateriauxAlu,
  PorteEntreeMateriaux,
  PorteServiceMateriaux,
  PorteGarageTailles
} from "./parametersTypes";

import { SKeys } from "./selectKeys";

export const OriginesProspect = Object.freeze([
  {index: 0, key: SKeys.OriginesProspect.AUCUN     , title: 'AUCUN'     , listTypes: null},
  {index: 1, key: SKeys.OriginesProspect.PARRAINAGE, title: 'PARRAINAGE', listTypes: null},
  // {index: 2, key: SKeys.OriginesProspect.ANNONCES  , title: 'ANNONCES'  , listTypes: null},
  {index: 2, key: SKeys.OriginesProspect.APPORTEURS, title: 'APPORTEURS', listTypes: null}
]);

export const Departements = Object.freeze([
  {index: 0, key: SKeys.Departements.INDRE_ET_LOIRE_37, title: 'INDRE ET LOIRE 37', listTypes: null},
  {index: 1, key: SKeys.Departements.LOIR_ET_CHER_41  , title: 'LOIR ET CHER 41'  , listTypes: null},
  {index: 2, key: SKeys.Departements.LOIRET_45        , title: 'LOIRET 45'        , listTypes: null}
]);

export const BranchementLongueurs = Object.freeze([
  {index: 0, key: '7' , title: '7' , listTypes: null},
  // {index: 1, key: '10', title: '10', listTypes: null},
  // {index: 2, key: '15', title: '15', listTypes: null},
  // {index: 3, key: '20', title: '20', listTypes: null},
  // {index: 4, key: '30', title: '30', listTypes: null},
  // {index: 5, key: '40', title: '40', listTypes: null},
  // {index: 6, key: '50', title: '50', listTypes: null},
  // {index: 7, key: '75', title: '75', listTypes: null}
]);

// export const NiveauM1Types = Object.freeze([
//   {index: 0, key: SKeys.RangsVideSanitaire.SOUS_SOL     , title: 'SOUS-SOL', listTypes: null},
//   {index: 1, key: SKeys.RangsVideSanitaire.TROIS_RANGS  , title: '3 RANGS' , listTypes: null},
//   {index: 2, key: SKeys.RangsVideSanitaire.QUATRE_RANGS , title: '4 RANGS' , listTypes: null},
//   {index: 3, key: SKeys.RangsVideSanitaire.CINQ_RANGS   , title: '5 RANGS' , listTypes: null}
// ]);

// export const NiveauP1Types = Object.freeze([
//   {index: 0, key: SKeys.TypesCombles.PERDUS       , title: 'Combles perdus'       , listTypes: null},
//   {index: 1, key: SKeys.TypesCombles.AMENAGEABLES , title: 'Combles aménageables' , listTypes: null}
// ]);

export const ToitTypes = Object.freeze([
  {index: 0, key: SKeys.TypesToit.RECTANGLE  , title: 'RECTANGLE'  , listTypes: null},
  {index: 1, key: SKeys.TypesToit.L          , title: 'L'          , listTypes: null},
  {index: 2, key: SKeys.TypesToit.V          , title: 'V'          , listTypes: null},
  {index: 3, key: SKeys.TypesToit.U          , title: 'U'          , listTypes: null},
  {index: 4, key: SKeys.TypesToit.L_EVOLUTION, title: 'L EVOLUTION', listTypes: null},
]);

export const ToitInclinaisons = Object.freeze([
  {index: 0, key: SKeys.InclinaisonsToit.DEGRES_35, title: '35°'      , listTypes: null},
  {index: 1, key: SKeys.InclinaisonsToit.DEGRES_40, title: '40°'      , listTypes: null},
  {index: 2, key: SKeys.InclinaisonsToit.DEGRES_45, title: '45°'      , listTypes: null},  
  {index: 3, key: SKeys.SpeciauxToit.BITUME       , title: 'BITUME'   , listTypes: null},
  {index: 4, key: SKeys.SpeciauxToit.BAC_ACIER    , title: 'BAC ACIER', listTypes: null},  
]);

export const CouvertureTypes = Object.freeze([
  {index: 0 , key: SKeys.TypesCouverture.DOUBLE_ROMANE_ROUGE         , title: 'DOUBLE ROMANE ROUGE 10 u/m2'          , listTypes: null},
  {index: 1 , key: SKeys.TypesCouverture.DOUBLE_ROMANE_ARDOISEE      , title: 'DOUBLE ROMANE ARDOISEE 10 u/m2'       , listTypes: null},
  {index: 2 , key: SKeys.TypesCouverture.PLATE_NOBILEE_ROUGE_ASPECT  , title: 'PLATE NOBILEE ROUGE ASPECT 20 u/m2'   , listTypes: null},
  {index: 3 , key: SKeys.TypesCouverture.PLATE_NOBILEE_ARDOISE_ASPECT, title: 'PLATE NOBILEE ARDOISEE ASPECT 20 u/m2', listTypes: null},
  {index: 4 , key: SKeys.TypesCouverture.REGENCE_ROUGE               , title: 'REGENCE ROUGE 20 u/m2'                , listTypes: null},
  {index: 5 , key: SKeys.TypesCouverture.REGENCE_ARDOISEE            , title: 'REGENCE ARDOISEE 20 u/m2'             , listTypes: null},
  {index: 6 , key: SKeys.TypesCouverture.FONTENNELLE_ROUGE           , title: 'FONTENNELLE ROUGE 10 u/m2'            , listTypes: null},
  {index: 7 , key: SKeys.TypesCouverture.FONTENNELLE_ARDOISEE        , title: 'FONTENNELLE ARDOISEE 20 u/m2'         , listTypes: null},
  {index: 8 , key: SKeys.TypesCouverture.SIGNY_ROUGE                 , title: 'SIGNY ROUGE 10 u/m2'                  , listTypes: null},
  {index: 9 , key: SKeys.TypesCouverture.SIGNY_ARDOISEE              , title: 'SIGNY ARDOISEE 10 u/m2'               , listTypes: null},
  {index: 10, key: SKeys.TypesCouverture.PLATE_DE_PAYS               , title: 'PLATE DE PAYS 62 u/m2'                , listTypes: null},
  {index: 11, key: SKeys.TypesCouverture.NATURELLE_DESPAGNE          , title: 'NATURELLE D\'ESPAGNE 32x22'           , listTypes: null},
  {index: 12, key: SKeys.TypesCouverture.ARTIFICIELLE_DROIT          , title: 'ARTIFICIELLE DROIT 40x24'             , listTypes: null},
  {index: 13, key: SKeys.TypesCouverture.ARTIFICIELLE_EPAUFRE_40_24  , title: 'ARTIFICIELLE EPEAUFRE 40x24'          , listTypes: null},
  {index: 14, key: SKeys.TypesCouverture.ARTIFICIELLE_EPAUFRE_33_23  , title: 'ARTIFICIELLE EPEAUFRE 33x23'          , listTypes: null}
]);

export const CouvertureComblesTypes = Object.freeze([
  {index: 0 , key: SKeys.TypesCouverture.DOUBLE_ROMANE_ROUGE         , title: 'DOUBLE ROMANE ROUGE 10 u/m2'          , listTypes: ComblesTypes},
  {index: 1 , key: SKeys.TypesCouverture.DOUBLE_ROMANE_ARDOISEE      , title: 'DOUBLE ROMANE ARDOISEE 10 u/m2'       , listTypes: ComblesTypes},
  {index: 2 , key: SKeys.TypesCouverture.PLATE_NOBILEE_ROUGE_ASPECT  , title: 'PLATE NOBILEE ROUGE ASPECT 20 u/m2'   , listTypes: ComblesTypes},
  {index: 3 , key: SKeys.TypesCouverture.PLATE_NOBILEE_ARDOISE_ASPECT, title: 'PLATE NOBILEE ARDOISEE ASPECT 20 u/m2', listTypes: ComblesTypes},
  {index: 4 , key: SKeys.TypesCouverture.REGENCE_ROUGE               , title: 'REGENCE ROUGE 20 u/m2'                , listTypes: ComblesTypes},
  {index: 5 , key: SKeys.TypesCouverture.REGENCE_ARDOISEE            , title: 'REGENCE ARDOISEE 20 u/m2'             , listTypes: ComblesTypes},
  {index: 6 , key: SKeys.TypesCouverture.FONTENNELLE_ROUGE           , title: 'FONTENNELLE ROUGE 10 u/m2'            , listTypes: ComblesTypes},
  {index: 7 , key: SKeys.TypesCouverture.FONTENNELLE_ARDOISEE        , title: 'FONTENNELLE ARDOISEE 20 u/m2'         , listTypes: ComblesTypes},
  {index: 8 , key: SKeys.TypesCouverture.SIGNY_ROUGE                 , title: 'SIGNY ROUGE 10 u/m2'                  , listTypes: ComblesTypes},
  {index: 9 , key: SKeys.TypesCouverture.SIGNY_ARDOISEE              , title: 'SIGNY ARDOISEE 10 u/m2'               , listTypes: ComblesTypes},
  {index: 10, key: SKeys.TypesCouverture.PLATE_DE_PAYS               , title: 'PLATE DE PAYS 62 u/m2'                , listTypes: ComblesTypes},
  {index: 11, key: SKeys.TypesCouverture.NATURELLE_DESPAGNE          , title: 'NATURELLE D\'ESPAGNE 32x22'           , listTypes: ComblesTypes},
  {index: 12, key: SKeys.TypesCouverture.ARTIFICIELLE_DROIT          , title: 'ARTIFICIELLE DROIT 40x24'             , listTypes: ComblesTypes},
  {index: 13, key: SKeys.TypesCouverture.ARTIFICIELLE_EPAUFRE_40_24  , title: 'ARTIFICIELLE EPEAUFRE 40x24'          , listTypes: ComblesTypes},
  {index: 14, key: SKeys.TypesCouverture.ARTIFICIELLE_EPAUFRE_33_23  , title: 'ARTIFICIELLE EPEAUFRE 33x23'          , listTypes: ComblesTypes}
]);

export const ToitSpeciauxTypes = Object.freeze([
  {index: 0, key: SKeys.SpeciauxToit.BITUME   , title: 'BITUME'   , listTypes: null},
  {index: 1, key: SKeys.SpeciauxToit.BAC_ACIER, title: 'BAC ACIER', listTypes: null},
]);

export const MenuiserieCouleurs = Object.freeze([
  {index: 0, key: SKeys.CouleursMenuiserie.BLANC          , title: 'BLANC'          , listTypes: null},
  {index: 1, key: SKeys.CouleursMenuiserie.GRIS_ANTHARCITE, title: 'GRIS ANTHRACITE', listTypes: null},
  {index: 2, key: SKeys.CouleursMenuiserie.ABF            , title: 'ABF'            , listTypes: null}
]);

export const PlacoPlafondCloisonEpaisseurs = Object.freeze([
  {index: 0, key: '50', title: '50', listTypes: null},
  {index: 1, key: '72', title: '72', listTypes: null}
]);

export const BlocPorteGammes = Object.freeze([
  // {index: 0, key: SKeys.GammesBlocPorte.RIGHINI   , title: 'Righini'   , listTypes: BlocPorteTypes},
  {index: 0, key: SKeys.GammesBlocPorte.MF_GAMME_1, title: 'MF Gamme 1', listTypes: BlocPorteTypes},
  // {index: 2, key: SKeys.GammesBlocPorte.MF_GAMME_2, title: 'MF Gamme 2', listTypes: BlocPorteTypes}
]);

export const RangsStructureRdc = Object.freeze([
  {index: 0, key: SKeys.HauteursVideSousPlafond._2_5, title: '2,50', listTypes: null},
  {index: 1, key: SKeys.HauteursVideSousPlafond._2_7, title: '2,70', listTypes: null},
]);

export const CarrelageGammes = Object.freeze([
  {index: 0, key: SKeys.GammesCarrelage.INITIALE, title: 'INITIALE', listTypes: null},
  {index: 1, key: SKeys.GammesCarrelage.PRESTIGE, title: 'PRESTIGE', listTypes: null}
]);

export const ChauffageTypes = Object.freeze([
  {index: 0 , key: SKeys.TypesChauffagePrix.CLIM_MAX_35       , title: 'CLIM < 35 m²'         , listTypes: null},
  {index: 1 , key: SKeys.TypesChauffagePrix.CLIM_MAX_45       , title: 'CLIM < 45 m²'         , listTypes: null},
  {index: 2 , key: SKeys.TypesChauffagePrix.CLIM_MAX_65       , title: 'CLIM < 60 m²'         , listTypes: null},
  {index: 3 , key: SKeys.TypesChauffagePrix.POELE             , title: 'POELE'                , listTypes: null},
  {index: 4 , key: SKeys.TypesChauffagePrix.GAZ_1Z_1SDB       , title: 'GAZ 1Z 1SdB'          , listTypes: null},
  {index: 5 , key: SKeys.TypesChauffagePrix.GAZ_1Z_2SDB       , title: 'GAZ 1Z 2SdB'          , listTypes: null},
  {index: 6 , key: SKeys.TypesChauffagePrix.GAZ_1Z_XSDB       , title: 'GAZ 1Z XSdB'          , listTypes: null},
  {index: 7 , key: SKeys.TypesChauffagePrix.GAZ_2Z_1SDB       , title: 'GAZ 2Z 1SdB'          , listTypes: null},
  {index: 8 , key: SKeys.TypesChauffagePrix.GAZ_2Z_2SDB       , title: 'GAZ 2Z 2SdB'          , listTypes: null},
  {index: 9 , key: SKeys.TypesChauffagePrix.GAZ_2Z_XSDB       , title: 'GAZ 2Z XSdB'          , listTypes: null},
  {index: 10, key: SKeys.TypesChauffagePrix.PAC_1Z_100        , title: 'PAC 1Z < 100 m²'      , listTypes: null},
  {index: 12, key: SKeys.TypesChauffagePrix.PAC_2Z_120        , title: 'PAC 2Z < 120 m²'      , listTypes: null},
  {index: 13, key: SKeys.TypesChauffagePrix.PAC_2Z_140        , title: 'PAC 2Z < 140 m²'      , listTypes: null},
  {index: 14, key: SKeys.TypesChauffagePrix.PAC_2Z_170        , title: 'PAC 2Z < 170 m²'      , listTypes: null},
  {index: 15, key: SKeys.TypesChauffagePrix.PAC_2Z_190        , title: 'PAC 2Z < 190 m²'      , listTypes: null},

  {index: 16, key: SKeys.TypesChauffagePrix.GAINABLE_7_1_100  , title: 'GAINABLE 7,1 < 100 m²', listTypes: null},
  {index: 17, key: SKeys.TypesChauffagePrix.GAINABLE_10_130   , title: 'GAINABLE 10 < 130 m²' , listTypes: null},
  {index: 18, key: SKeys.TypesChauffagePrix.GAINABLE_12_5_190 , title: 'GAINABLE 12,5 130+ m²', listTypes: null},
]);

export const PetiteFenetreDimensions = Object.freeze([
  {index: 0, key: '40X75', title: '40 x 75', listTypes: MenuiserieMateriaux},
  {index: 1, key: '50X75', title: '50 x 75', listTypes: MenuiserieMateriaux},
  {index: 2, key: '60X75', title: '60 x 75', listTypes: MenuiserieMateriaux},
  {index: 3, key: '80X75', title: '80 x 75', listTypes: MenuiserieMateriaux},
  {index: 4, key: '60X95', title: '60 x 95', listTypes: MenuiserieMateriaux}
]);

export const AbattantDimensions = Object.freeze([
  {index: 0, key: '100X75', title: '100 x 75', listTypes: MenuiserieMateriaux},
  {index: 1, key: '120X75', title: '120 x 75', listTypes: MenuiserieMateriaux},
  {index: 2, key: '140X75', title: '140 x 75', listTypes: MenuiserieMateriaux}
]);

export const OeilDeBoeufDimensions = Object.freeze([
  {index: 0, key: '60X60', title: '60 x 60', listTypes: MenuiserieMateriaux},
  {index: 1, key: '80X80', title: '80 x 80', listTypes: MenuiserieMateriaux}
]);

export const FenetreOuvranteDimensions = Object.freeze([
  {index: 0 , key: '80X85'  , title: '80 x 85'  , listTypes: MenuiserieMateriaux},
  {index: 1 , key: '100X85' , title: '100 x 85' , listTypes: MenuiserieMateriaux},
  {index: 2 , key: '120X85' , title: '120 x 85' , listTypes: MenuiserieMateriaux},
  {index: 3 , key: '80X105' , title: '80 x 105', listTypes: MenuiserieMateriaux},
  {index: 4 , key: '90X105' , title: '90 x 105', listTypes: MenuiserieMateriaux},
  {index: 5 , key: '100X105', title: '100 x 105', listTypes: MenuiserieMateriaux},
  {index: 6 , key: '120X105', title: '120 x 105', listTypes: MenuiserieMateriaux},
  {index: 7 , key: '140X105', title: '140 x 105', listTypes: MenuiserieMateriaux},
  {index: 8 , key: '100X115', title: '100 x 115', listTypes: MenuiserieMateriaux},
  {index: 9 , key: '120X115', title: '120 x 115', listTypes: MenuiserieMateriaux},
  {index: 10, key: '140X115', title: '140 x 115', listTypes: MenuiserieMateriaux},
  {index: 11, key: '100X125', title: '100 x 125', listTypes: MenuiserieMateriaux},
  {index: 12, key: '120X125', title: '120 x 125', listTypes: MenuiserieMateriaux},
  {index: 13, key: '140X125', title: '140 x 125', listTypes: MenuiserieMateriaux},
  {index: 14, key: '100X135', title: '100 x 135', listTypes: MenuiserieMateriaux},
  {index: 15, key: '120X135', title: '120 x 135', listTypes: MenuiserieMateriaux},
  {index: 16, key: '140X135', title: '140 x 135', listTypes: MenuiserieMateriaux},
  {index: 17, key: '100X155', title: '100 x 155', listTypes: MenuiserieMateriaux},
  {index: 18, key: '120X155', title: '120 x 155', listTypes: MenuiserieMateriaux},
  {index: 19, key: '140X155', title: '140 x 155', listTypes: MenuiserieMateriaux}
]);

export const VRFenetreOuvranteDimensions = Object.freeze([
  {index: 0 , key: '80X85'  , title: '80 x 85'  , listTypes: MenuiserieMateriauxPvcAlu},
  {index: 1 , key: '100X85' , title: '100 x 85' , listTypes: MenuiserieMateriauxPvcAlu},
  {index: 2 , key: '120X85' , title: '120 x 85' , listTypes: MenuiserieMateriauxPvcAlu},
  {index: 3 , key: '80X105' , title: '80 x 105', listTypes: MenuiserieMateriauxPvcAlu},
  {index: 4 , key: '90X105' , title: '90 x 105', listTypes: MenuiserieMateriauxPvcAlu},
  {index: 5 , key: '100X105', title: '100 x 105', listTypes: MenuiserieMateriauxPvcAlu},
  {index: 6 , key: '120X105', title: '120 x 105', listTypes: MenuiserieMateriauxPvcAlu},
  {index: 7 , key: '140X105', title: '140 x 105', listTypes: MenuiserieMateriauxPvcAlu},
  {index: 8 , key: '100X115', title: '100 x 115', listTypes: MenuiserieMateriauxPvcAlu},
  {index: 9 , key: '120X115', title: '120 x 115', listTypes: MenuiserieMateriauxPvcAlu},
  {index: 10, key: '140X115', title: '140 x 115', listTypes: MenuiserieMateriauxPvcAlu},
  {index: 11, key: '100X125', title: '100 x 125', listTypes: MenuiserieMateriauxPvcAlu},
  {index: 12, key: '120X125', title: '120 x 125', listTypes: MenuiserieMateriauxPvcAlu},
  {index: 13, key: '140X125', title: '140 x 125', listTypes: MenuiserieMateriauxPvcAlu},
  {index: 14, key: '100X135', title: '100 x 135', listTypes: MenuiserieMateriauxPvcAlu},
  {index: 15, key: '120X135', title: '120 x 135', listTypes: MenuiserieMateriauxPvcAlu},
  {index: 16, key: '140X135', title: '140 x 135', listTypes: MenuiserieMateriauxPvcAlu},
  {index: 17, key: '100X155', title: '100 x 155', listTypes: MenuiserieMateriauxPvcAlu},
  {index: 18, key: '120X155', title: '120 x 155', listTypes: MenuiserieMateriauxPvcAlu},
  {index: 19, key: '140X155', title: '140 x 155', listTypes: MenuiserieMateriauxPvcAlu}
]);

export const FenetreCoulissanteDimensions = Object.freeze([
  {index: 0 , key: '140X85' , title: '140 x 85' , listTypes: MenuiserieMateriauxAluOb},
  {index: 1 , key: '160X85' , title: '160 x 85' , listTypes: MenuiserieMateriauxAluOb},
  {index: 2 , key: '180X85' , title: '180 x 85' , listTypes: MenuiserieMateriauxAluOb},
  {index: 3 , key: '200X85' , title: '200 x 85' , listTypes: MenuiserieMateriauxAluOb},
  {index: 4 , key: '220X85' , title: '220 x 85' , listTypes: MenuiserieMateriauxAluOb},
  {index: 5 , key: '240X85' , title: '240 x 85' , listTypes: MenuiserieMateriauxAluOb},
  {index: 6 , key: '260X85' , title: '260 x 85' , listTypes: MenuiserieMateriauxAluOb},
  {index: 7 , key: '280X85' , title: '280 x 85' , listTypes: MenuiserieMateriauxAluOb},
  {index: 8 , key: '300X85' , title: '300 x 85' , listTypes: MenuiserieMateriauxAluOb},
  {index: 9 , key: '140X105', title: '140 x 105', listTypes: MenuiserieMateriauxAluOb},
  {index: 10, key: '160X105', title: '160 x 105', listTypes: MenuiserieMateriauxAluOb},
  {index: 11, key: '180X105', title: '180 x 105', listTypes: MenuiserieMateriauxAluOb},
  {index: 12, key: '200X105', title: '200 x 105', listTypes: MenuiserieMateriauxAluOb},
  {index: 13, key: '220X105', title: '220 x 105', listTypes: MenuiserieMateriauxAluOb},
  {index: 14, key: '240X105', title: '240 x 105', listTypes: MenuiserieMateriauxAluOb},
  {index: 15, key: '300X105', title: '300 x 105', listTypes: MenuiserieMateriauxAluOb},
  {index: 16, key: '140X115', title: '140 x 115', listTypes: MenuiserieMateriauxAluOb},
  {index: 17, key: '140X125', title: '140 x 125', listTypes: MenuiserieMateriauxAluOb},
  {index: 18, key: '140X135', title: '140 x 135', listTypes: MenuiserieMateriauxAluOb},
  {index: 19, key: '140X155', title: '140 x 155', listTypes: MenuiserieMateriauxAluOb},
  {index: 20, key: '140X175', title: '140 x 175', listTypes: MenuiserieMateriauxAluOb}
]);

export const VRFenetreCoulissanteDimensions = Object.freeze([
  {index: 0 , key: '140X85' , title: '140 x 85' , listTypes: MenuiserieMateriauxAlu},
  {index: 1 , key: '160X85' , title: '160 x 85' , listTypes: MenuiserieMateriauxAlu},
  {index: 2 , key: '180X85' , title: '180 x 85' , listTypes: MenuiserieMateriauxAlu},
  {index: 3 , key: '200X85' , title: '200 x 85' , listTypes: MenuiserieMateriauxAlu},
  {index: 4 , key: '220X85' , title: '220 x 85' , listTypes: MenuiserieMateriauxAlu},
  {index: 5 , key: '240X85' , title: '240 x 85' , listTypes: MenuiserieMateriauxAlu},
  {index: 6 , key: '260X85' , title: '260 x 85' , listTypes: MenuiserieMateriauxAlu},
  {index: 7 , key: '280X85' , title: '280 x 85' , listTypes: MenuiserieMateriauxAlu},
  {index: 8 , key: '300X85' , title: '300 x 85' , listTypes: MenuiserieMateriauxAlu},
  {index: 9 , key: '140X105', title: '140 x 105', listTypes: MenuiserieMateriauxAlu},
  {index: 10, key: '160X105', title: '160 x 105', listTypes: MenuiserieMateriauxAlu},
  {index: 11, key: '180X105', title: '180 x 105', listTypes: MenuiserieMateriauxAlu},
  {index: 12, key: '200X105', title: '200 x 105', listTypes: MenuiserieMateriauxAlu},
  {index: 13, key: '220X105', title: '220 x 105', listTypes: MenuiserieMateriauxAlu},
  {index: 14, key: '240X105', title: '240 x 105', listTypes: MenuiserieMateriauxAlu},
  {index: 15, key: '300X105', title: '300 x 105', listTypes: MenuiserieMateriauxAlu},
  {index: 16, key: '140X115', title: '140 x 115', listTypes: MenuiserieMateriauxAlu},
  {index: 17, key: '140X125', title: '140 x 125', listTypes: MenuiserieMateriauxAlu},
  {index: 18, key: '140X135', title: '140 x 135', listTypes: MenuiserieMateriauxAlu},
  {index: 19, key: '140X155', title: '140 x 155', listTypes: MenuiserieMateriauxAlu},
  {index: 20, key: '140X175', title: '140 x 175', listTypes: MenuiserieMateriauxAlu}
]);

export const FenetreFixeDimensions = Object.freeze([
  {index: 0, key: '50X395', title: '50 x 395', listTypes: MenuiserieMateriaux},
  {index: 1, key: '50X215', title: '50 x 215', listTypes: MenuiserieMateriaux},
  {index: 2, key: '60X215', title: '60 x 215', listTypes: MenuiserieMateriaux}
]);

export const PorteFenetreDimensions = Object.freeze([
  {index: 0, key: '90X215' , title: '90 x 215' , listTypes: MenuiserieMateriaux},
  {index: 1, key: '100X215', title: '100 x 215', listTypes: MenuiserieMateriaux},
  {index: 2, key: '120X215', title: '120 x 215', listTypes: MenuiserieMateriaux},
  {index: 3, key: '130X215', title: '130 x 215', listTypes: MenuiserieMateriaux},
  {index: 4, key: '140X215', title: '140 x 215', listTypes: MenuiserieMateriaux},
  {index: 5, key: '150X215', title: '150 x 215', listTypes: MenuiserieMateriaux}
]);

export const VRPorteFenetreDimensions = Object.freeze([
  {index: 0, key: '90X215' , title: '90 x 215' , listTypes: MenuiserieMateriauxPvcAlu},
  {index: 1, key: '100X215', title: '100 x 215', listTypes: MenuiserieMateriauxPvcAlu},
  {index: 2, key: '120X215', title: '120 x 215', listTypes: MenuiserieMateriauxPvcAlu},
  {index: 3, key: '130X215', title: '130 x 215', listTypes: MenuiserieMateriauxPvcAlu},
  {index: 4, key: '140X215', title: '140 x 215', listTypes: MenuiserieMateriauxPvcAlu},
  {index: 5, key: '150X215', title: '150 x 215', listTypes: MenuiserieMateriauxPvcAlu}
]);

export const BaieVitreeDimensions = Object.freeze([
  {index: 0, key: '160X215', title: '160 x 215', listTypes: MenuiserieMateriauxAluOb},
  {index: 1, key: '180X215', title: '180 x 215', listTypes: MenuiserieMateriauxAluOb},
  {index: 2, key: '200X215', title: '200 x 215', listTypes: MenuiserieMateriauxAluOb},
  {index: 3, key: '220X215', title: '220 x 215', listTypes: MenuiserieMateriauxAluOb},
  {index: 4, key: '240X215', title: '240 x 215', listTypes: MenuiserieMateriauxAluOb},
  {index: 5, key: '260X215', title: '260 x 215', listTypes: MenuiserieMateriauxAluOb},
  {index: 6, key: '280X215', title: '280 x 215', listTypes: MenuiserieMateriauxAluOb},
  {index: 7, key: '300X215', title: '300 x 215', listTypes: MenuiserieMateriauxAluOb}
]);

export const VRBaieVitreeDimensions = Object.freeze([
  {index: 0, key: '160X215', title: '160 x 215', listTypes: MenuiserieMateriauxAlu},
  {index: 1, key: '180X215', title: '180 x 215', listTypes: MenuiserieMateriauxAlu},
  {index: 2, key: '200X215', title: '200 x 215', listTypes: MenuiserieMateriauxAlu},
  {index: 3, key: '220X215', title: '220 x 215', listTypes: MenuiserieMateriauxAlu},
  {index: 4, key: '240X215', title: '240 x 215', listTypes: MenuiserieMateriauxAlu},
  {index: 5, key: '260X215', title: '260 x 215', listTypes: MenuiserieMateriauxAlu},
  {index: 6, key: '280X215', title: '280 x 215', listTypes: MenuiserieMateriauxAlu},
  {index: 7, key: '300X215', title: '300 x 215', listTypes: MenuiserieMateriauxAlu}
]);

export const PorteEntreeGammes = Object.freeze([
  {index: 0, key: SKeys.ModelesPorteEntree.GAMME_1, title: 'GAMME 1', listTypes: PorteEntreeMateriaux},
  // {index: 1, key: SKeys.ModelesPorteEntree.GAMME_2, title: 'GAMME 2', listTypes: PorteEntreeMateriaux},
  // {index: 2, key: SKeys.ModelesPorteEntree.GAMME_3, title: 'GAMME 3', listTypes: PorteEntreeMateriaux}
]);

export const PorteServiceGammes = Object.freeze([
  {index: 0, key: SKeys.ModelesPorteService.GAMME_1_PVC, title: 'GAMME 1 PVC', listTypes: PorteServiceMateriaux},
  // {index: 1, key: SKeys.ModelesPorteService.GAMME_2    , title: 'GAMME 2'    , listTypes: MenuiserieMateriauxAluOb}
]);

export const PorteGarageDimensions = Object.freeze([
  {index: 0, key: SKeys.TaillesPorteGarage._240, title: '240 x 200', listTypes: null},
  {index: 1, key: SKeys.TaillesPorteGarage._300, title: '300 x 200', listTypes: null}
]);

export const PorteGarageModeles = Object.freeze([
  {index: 0, key: SKeys.ModelesPorteGarage._902,        title: '902',         listTypes: PorteGarageTailles},
  {index: 1, key: SKeys.ModelesPorteGarage.SECTIONELLE, title: 'SECTIONELLE', listTypes: PorteGarageTailles}
]);

export const SurfaceVitreeCouleurs = Object.freeze([
  {index: 0, key: SKeys.CouleursSurfaceVitreeNord.VERT  , title: `VERT`, listTypes: null},
  {index: 0, key: SKeys.CouleursSurfaceVitreeNord.ORANGE, title: `ORANGE`, listTypes: null},
  {index: 0, key: SKeys.CouleursSurfaceVitreeNord.ROUGE , title: `ROUGE`, listTypes: null},
  {index: 0, key: SKeys.CouleursSurfaceVitreeNord.NOIR  , title: `NOIR`, listTypes: null},
]);