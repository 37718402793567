import { queryOneParamValue } from "../api/queries";
import { SKeys } from "../param/selectKeys";
import { PKeys } from "../param/parametersKeys";

import { validFloat } from "./helpers"

export const ToitureRdcParams = Object.freeze({
  PRIX_COMBLES_PERDUS: 12,
  PRIX_COMBLES_PERDUS_NBRE: 32,
  PRIX_COMBLES_AMENAGEABLES: 23,
  PRIX_COMBLES_AMENAGEABLES_NBRE: 35,
  PRIX_TAILLAGE_LAMELLE_COLLEE: 875,
  PRIX_CHARPENTE_TRADI: 29,
  PRIX_CHARPENTE_TRADI_NBRE: 65,
  PRIX_GOUTTIERE: 26.5,
  PRIX_DESCENTE: 49.5,
  PRIX_TRANSPORT_1: 760,
  PRIX_TRANSPORT_2: 50,
  PRIX_PENETRATION: 2100,
  PRIX_ACCROTERE: 32,
  //OLD PARAMS
  CoeffPenetration: {
    RECTANGLE: 0,
    L: 1,
    V: 2,
    U: 2,
    L_EVOLUTION: 1
  },
  CosInclinaison: {
    DEGRES_35: 0.83,
    DEGRES_40: 0.78,
    DEGRES_45: 0.71
  }
});

export async function computeParamsToitureRdc(targetObj, typeToit, inclinaisonToit, facade, pignon, facadeL, pignonL, facadeCoyaux, pignonCoyaux, facadeSpe, ratioComblesAmenageables, typeCouverture, nombrePentes) {
  //TOITURE RDC
  let coeffCosInclinaison = 0;
  if (inclinaisonToit === SKeys.InclinaisonsToit.DEGRES_35) {
    coeffCosInclinaison = ToitureRdcParams.CosInclinaison.DEGRES_35;
  }
  else if (inclinaisonToit === SKeys.InclinaisonsToit.DEGRES_40) {
    coeffCosInclinaison = ToitureRdcParams.CosInclinaison.DEGRES_40;
  }
  else if (inclinaisonToit === SKeys.InclinaisonsToit.DEGRES_45) {
    coeffCosInclinaison = ToitureRdcParams.CosInclinaison.DEGRES_45;
  }

  if (coeffCosInclinaison) {
    if (typeToit === SKeys.TypesToit.RECTANGLE) {
      targetObj.toitureRdc.surfaceToitureRdc += validFloat(facade) * (validFloat(pignon) + 0.5) / validFloat(coeffCosInclinaison);
    }
    else if (typeToit === SKeys.TypesToit.L || typeToit === SKeys.TypesToit.U || typeToit === SKeys.TypesToit.V) {
      targetObj.toitureRdc.surfaceToitureRdc += ( (validFloat(facade) * (validFloat(pignon) + 0.5)) + (validFloat(facadeL) * (validFloat(pignonL) + 0.5)) ) / validFloat(coeffCosInclinaison);
    }
    targetObj.toitureRdc.surfaceToitureRdc += validFloat(facadeCoyaux) * (validFloat(pignonCoyaux) + 0.25) / validFloat(coeffCosInclinaison);
  }
  //COMBLES AMENAGEABLES
  targetObj.toitureRdc.comblesAmenageables = validFloat(targetObj.toitureRdc.surfaceToitureRdc) * validFloat(ratioComblesAmenageables);
  //NBRE AMENAGEABLES
  const ratioSelonComblesKey = PKeys.COUVERTURE_SELON_COMBLES_COEFF;
  let coeffComblesAmenageables;
  if (typeCouverture && ratioSelonComblesKey) {
    coeffComblesAmenageables = await queryOneParamValue(ratioSelonComblesKey, typeCouverture, SKeys.TypesCombles.AMENAGEABLES);
  }
  if (targetObj.toitureRdc.comblesAmenageables && validFloat(coeffComblesAmenageables)) {
    targetObj.toitureRdc.nombreAmenageables = validFloat(facade) * validFloat(ratioComblesAmenageables) / validFloat(coeffComblesAmenageables);
  }
  //CHARPENTE TRADI
  if (nombrePentes === SKeys.PentesToit.QUATRE_PENTES) {
    targetObj.toitureRdc.charpenteTradi = validFloat(targetObj.toitureRdc.surfaceToitureRdc);
  }
  //COMBLES PERDUS
  targetObj.toitureRdc.comblesPerdus = validFloat(targetObj.toitureRdc.surfaceToitureRdc) - validFloat(targetObj.toitureRdc.comblesAmenageables) - validFloat(targetObj.toitureRdc.charpenteTradi);
  //NBRE PERDUS ???
  if (validFloat(targetObj.toitureRdc.comblesPerdus) && validFloat(coeffComblesAmenageables)) {
    targetObj.toitureRdc.nombrePerdus = ( ((validFloat(facade) * (1 - validFloat(ratioComblesAmenageables))) + validFloat(facadeL) + validFloat(facadeSpe)) / validFloat(coeffComblesAmenageables) ) + 1;
  }
}

export async function addComblesPerdus(targetObj) {
  targetObj.toitureRdc.ttlPrixToitureRdc += validFloat(ToitureRdcParams.PRIX_COMBLES_PERDUS) * validFloat(targetObj.toitureRdc.comblesPerdus);
}

export async function addNombreComblesPerdus(targetObj) {
  targetObj.toitureRdc.ttlPrixToitureRdc += validFloat(ToitureRdcParams.PRIX_COMBLES_PERDUS_NBRE) * validFloat(targetObj.toitureRdc.nombrePerdus);
}

export async function addComblesAmenageables(targetObj) {
  targetObj.toitureRdc.ttlPrixToitureRdc += validFloat(ToitureRdcParams.PRIX_COMBLES_AMENAGEABLES) * validFloat(targetObj.toitureRdc.comblesAmenageables);
}

export async function addNombreComblesAmenageables(targetObj) {
  targetObj.toitureRdc.ttlPrixToitureRdc += validFloat(ToitureRdcParams.PRIX_COMBLES_AMENAGEABLES_NBRE) * validFloat(targetObj.toitureRdc.nombreAmenageables);
}

export async function addTaillageLamelleCollee(targetObj, facade) {
  if (validFloat(targetObj.toitureRdc.comblesAmenageables)) {
    targetObj.toitureRdc.ttlPrixToitureRdc += validFloat(ToitureRdcParams.PRIX_TAILLAGE_LAMELLE_COLLEE) * validFloat(facade) * (0.14 * 0.36 + 2.5 * 0.14 * 0.36 / 4);
  }
}

export async function addCharpenteTradi(targetObj) {
  targetObj.toitureRdc.ttlPrixToitureRdc += validFloat(ToitureRdcParams.PRIX_CHARPENTE_TRADI) * validFloat(targetObj.toitureRdc.charpenteTradi);
}

export async function addNombreCharpenteTradi(targetObj, nombrePentes, facade, facadeL) {
  if (nombrePentes === SKeys.PentesToit.QUATRE_PENTES) {
    targetObj.toitureRdc.ttlPrixToitureRdc += 3 * validFloat(ToitureRdcParams.PRIX_CHARPENTE_TRADI_NBRE) * (validFloat(facade) + validFloat(facadeL));
  }
}

export async function addCouverture(targetObj, typeCouverture) {
  const prixCouvertureKey = PKeys.COUVERTURE_PRIX;
  if (typeCouverture && prixCouvertureKey) {
    let prixCouverture = await queryOneParamValue(prixCouvertureKey, typeCouverture);
    targetObj.toitureRdc.ttlPrixToitureRdc += validFloat(prixCouverture) * (validFloat(targetObj.toitureRdc.comblesPerdus) + validFloat(targetObj.toitureRdc.comblesAmenageables));
  }
}

// export async function addToitSpecial(targetObj, typeToitSpe, facadeSpe, pignonSpe) {
//   const prixToitSpeKey = PKeys.TOIT_SPECIAL_PRIX;
//   if (typeToitSpe && prixToitSpeKey) {
//     let prixToitSpe = await queryOneParamValue(prixToitSpeKey, typeToitSpe);
//     targetObj.toitureRdc.ttlPrixToitureRdc += validFloat(prixToitSpe) * validFloat(facadeSpe) * validFloat(pignonSpe);
//   }
// }

export async function addGouttiere(targetObj, facade, facadeL, pignon, pignonL) {
  let facadeSpe = 0;
  targetObj.toitureRdc.ttlPrixToitureRdc += validFloat(ToitureRdcParams.PRIX_GOUTTIERE) * (((validFloat(facade) + validFloat(facadeL)) * 2) + validFloat(facadeSpe));
  let tmpPignon = validFloat(facade) ? pignon + 0.5 : 0;
  let tmpPignonL = validFloat(facadeL) ? pignonL + 0.5 : 0;
  if (validFloat(targetObj.toitureRdc.charpenteTradi)) {
    targetObj.toitureRdc.ttlPrixToitureRdc += validFloat(ToitureRdcParams.PRIX_GOUTTIERE) * 2 * (validFloat(tmpPignon) + validFloat(tmpPignonL));
  }
}

export async function addDescente(targetObj, typeToit, facade, facadeCoyaux, facadeL, pignonL) {
  if (validFloat(facade)) {
    let nbrDescente = 0;
    if (validFloat(facade) >= 12) {
      nbrDescente += 4;
    }
    else {
      nbrDescente += 2;
      if (validFloat(facadeCoyaux)) {
        nbrDescente += 1;
      }
    }
    if (typeToit === SKeys.TypesToit.L || typeToit === SKeys.TypesToit.U || typeToit === SKeys.TypesToit.V) {
      nbrDescente += 1;
      let tmpPignonL = validFloat(facadeL) ? pignonL : 0;
      if ( (validFloat(facade) + validFloat(facadeL) - validFloat(tmpPignonL)) >= 12) {
        nbrDescente += 1;
      }
    }
    targetObj.toitureRdc.ttlPrixToitureRdc += validFloat(ToitureRdcParams.PRIX_DESCENTE) * validFloat(nbrDescente);
  }
}

export async function addTransport(targetObj) {
  if (validFloat(targetObj.toitureRdc.comblesPerdus) || validFloat(targetObj.toitureRdc.nombrePerdus) || validFloat(targetObj.toitureRdc.comblesAmenageables)) {
    targetObj.toitureRdc.ttlPrixToitureRdc += validFloat(ToitureRdcParams.PRIX_TRANSPORT_1) + validFloat(ToitureRdcParams.PRIX_TRANSPORT_2);
  }
}

export async function addPenetration(targetObj, typeToit) {
  let coeffPenetration = 0;
  if (typeToit === SKeys.TypesToit.RECTANGLE) {
    coeffPenetration = ToitureRdcParams.CoeffPenetration.RECTANGLE;
  }
  else if (typeToit === SKeys.TypesToit.L) {
    coeffPenetration = ToitureRdcParams.CoeffPenetration.L;
  }
  else if (typeToit === SKeys.TypesToit.V) {
    coeffPenetration = ToitureRdcParams.CoeffPenetration.V;
  }
  else if (typeToit === SKeys.TypesToit.U) {
    coeffPenetration = ToitureRdcParams.CoeffPenetration.U;
  }
  else if (typeToit === SKeys.TypesToit.L_EVOLUTION) {
    coeffPenetration = ToitureRdcParams.CoeffPenetration.L_EVOLUTION;
  }
  targetObj.toitureRdc.ttlPrixToitureRdc += validFloat(ToitureRdcParams.PRIX_PENETRATION) * validFloat(coeffPenetration);
}

export async function addAccrotere(targetObj, facade, pignon) {
  if (validFloat(targetObj.pignonRdc.pignonToitPlat)) {
    let tmpPignon = validFloat(facade) ? pignon + 0.5 : 0;
    targetObj.toitureRdc.ttlPrixToitureRdc += validFloat(ToitureRdcParams.PRIX_ACCROTERE) * 2 * (validFloat(facade) + validFloat(tmpPignon));
  }
}