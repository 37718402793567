<template>
  <div class="users-table flx1 flex-cnt-col align-center justify-start">
    <table>
      <thead>
        <tr>
          <th>
            Identifiant
          </th>
          <th>
            Mot de passe
          </th>
          <th>
            Rôle
          </th>
          <th class="input-btn">
            
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="user in listUsers" :key="user.index">
          <td>
            <div class="user-cell flex-cnt-row align-center">
              <input type="checkbox" v-model="user.editModeUsername">
              <UserInput
                v-bind:isEditMode="true"
                v-bind:queryTable="'users'"
                v-bind:queryId="user.queryId"
                v-bind:initialValue="user.username"
                v-bind:queryKey="'username'"
                v-bind:validType="validTypes.USERNAME"
                v-bind:placeholder="'Identifiant'"
                v-bind:disabledInput="!user.editModeUsername"
                v-bind:showResetIconIfSelect="false"
                v-on:updateValue="queryUsers"
              />
            </div>
          </td>
          <td>
            <div class="user-cell flex-cnt-row align-center">
              <input type="checkbox" v-model="user.editModePassword">
              <UserInput
                v-bind:isEditMode="true"
                v-bind:queryTable="'users'"
                v-bind:queryId="user.queryId"
                v-bind:initialValue="user.password"
                v-bind:queryKey="'password'"
                v-bind:validType="validTypes.PASSWORD"
                v-bind:placeholder="'Mot de passe'"
                v-bind:disabledInput="!user.editModePassword"
                v-bind:showResetIconIfSelect="false"
                v-on:updateValue="queryUsers"
              />
            </div>
          </td>
          <td>
            <div class="user-cell flex-cnt-row align-center">
              <input type="checkbox" v-model="user.editModeRole">
              <UserInput
                v-bind:isEditMode="true"
                v-bind:queryTable="'users'"
                v-bind:queryId="user.queryId"
                v-bind:initialValue="user.role"
                v-bind:queryKey="'role'"
                v-bind:validType="validTypes.ROLE"
                v-bind:inputTag="'select'"
                v-bind:options="optionsRole"
                v-bind:placeholder="'Rôle'"
                v-bind:disabledInput="!user.editModeRole"
                v-bind:showResetIconIfSelect="false"
                v-on:updateValue="queryUsers"
              />
            </div>
          </td>
          <td>
            <button class="delete-btn" v-on:click="deleteUser(user.queryId)">
              ×
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import UserInput from "../forms/FormInput.vue";

import { queryDelete, queryGet } from '../../api/queries';

import { InputTypes  } from '../../api/validation'

import { UserRoles } from "../../param/selectOptions";


export default {
  components: {
    UserInput
  },
  data: function () {
    return {
      listUsers: [],
      validTypes: InputTypes,
      optionsRole: UserRoles
    }
  },
  props: {
    reset: Boolean,
  },
  watch: {
    reset: function () {
      this.queryUsers();
    }
  },
  methods: {
    async deleteUser(userId) {
      if (confirm('Voulez-vous supprimer définitivement cet utilisateur? Toutes les données liées seront effacées...')) {
        let result = await queryDelete('users', userId);
        if (result) {
          this.queryUsers();
        }
        else {
          alert("Une erreur est survenur lors de la suppression de l'utilisateur. Veuillez réessayer...")
        }
      }
    },
    formatlistUsers(userList) {
      let indexCnt = 0;
      userList.forEach(user => {
        this.listUsers.push({
          index: indexCnt++, 
          queryId: user.id,
          username: user.username, 
          password: user.password,
          role: user.role,
          editModeUsername: false,
          editModePassword: false,
          editModeRole: false
        });
      });
    },
    async queryUsers() {
      this.listUsers = [];
      let qResult = await queryGet('users');
      if (qResult && qResult.length) {
        this.formatlistUsers(qResult);
      }
    }
  },
  mounted() {
    this.queryUsers();
  }
}
</script>

<style scoped>
.users-table {
  margin-top: 20px;
  width: 100%;
  box-sizing: border-box;
}

.param-key {
  margin-bottom: 20px;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #c6d2d9;
  background: #fff;
  text-align: center;
  text-transform: uppercase;
}

table {
  border-collapse: collapse;
  border: 1px solid #c6d2d9;
}

thead {
  min-height: 30px;
}

th {
  min-width: 172px;
  padding: 5px 10px;
  box-sizing: border-box;
  border: 1px solid #c6d2d9;
  background: rgb(228, 234, 238);
  font-size: 14px;
  font-family: "OpenSans400";
  text-transform: uppercase;
}

th.input-btn {
  min-width: 15px;
  padding: 5px 10px;
  box-sizing: border-box;
  border: 1px solid #c6d2d9;
  background: rgb(228, 234, 238);
  font-size: 14px;
  font-family: "OpenSans400";
  text-transform: uppercase;
}

tr:nth-child(2n+1) {
  background: #fff;
}
tr:nth-child(2n) {
  background: transparent;
}

td {
  padding: 7px 7px;
  font-size: 13px;
  font-family: "OpenSans400";
  border-left: 1px solid #c6d2d9;
  vertical-align: middle;;
}

.user-cell input[type='checkbox'] {
  margin-right: 10px;
}

.delete-btn {
  margin: 0
}
</style>