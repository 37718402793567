<template>
  <div class="document-folder flex-cnt-row flex-center">
    <div class="doc-row flx1 flex-cnt-row align-center justify-start" v-on:click="selectUser">
      <div class="icon-cell flex-cnt-row flex-center">
        <img class="icon-folder" src="@/assets/icons/folder-48.png" alt="">
      </div>
      <div class="client-cell text-left" v-if="stateDoc === 'client'">
        {{clientName}}
      </div>
      <div class="client-cell text-left" v-else-if="stateDoc === 'user'">
        {{userName}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    stateDoc: {
      type: String,
      required: true
    },
    userName: {
      type: String,
      required: true
    },
    userId: {
      type: Number,
      required: true
    },
    clientName: {
      type: String,
      required: false,
      default: null
    }
  },
  methods: {
    selectUser() {
      if (this.stateDoc === 'client') {
        this.$emit('selectClient', this.clientName);
      }
      else if (this.stateDoc === 'user') {
        this.$emit('selectUser', this.userId, this.userName);
      }
    }
  }
}
</script>

<style scoped>
.document-folder {
  min-height: 50px;
  box-sizing: border-box;
  background: transparent;
  transition: 0.15s;
}
.document-folder:hover {
  background: #eceff1;
  transition: 0.15s;
}

.doc-row {
  height: 50px;
  margin: 0px 30px;
  padding: 0px 20px;
  box-sizing: border-box;
  border-bottom: 1px solid #e7edf0;
}

.user-cell {
  font-size: 15px;
}

.icon-cell {
  margin-right: 10px;
}

.icon-folder {
  height: 20px;
}
</style>