import { queryOneParamValue } from "../api/queries";
import { SKeys } from "../param/selectKeys";
import { PKeys } from "../param/parametersKeys";

import { validFloat } from "./helpers"

export const AutreParams = Object.freeze({
  COEFF_TVA: 0.2,
  COEFF_TTC_GL: 1.006,
  COEFF_RCP_INTERNE: 1.01,
  COEFF_RCP_AGCO: 1.0084,
  COEFF_DO: 0.0126,
});

// **********
export async function addHaussesPrix(targetObj) {
  const hausseTerrassementKey      = PKeys.RECAP_TERRASSEMENT_HAUSSE;
  const hausseVsSousSolKey        = PKeys.RECAP_VS_SOUSSOL_HAUSSE;
  const hausseRdcKey              = PKeys.RECAP_RDC_HAUSSE;
  const hausseEtageKey            = PKeys.RECAP_ETAGE_HAUSSE;
  const haussePignonRdcKey        = PKeys.RECAP_PIGNON_RDC_HAUSSE;
  const hausseToitureRdcKey       = PKeys.RECAP_TOITURE_RDC_HAUSSE;
  const hausseMenuiserieKey       = PKeys.RECAP_MENUISERIE_HAUSSE;
  const haussePlacoKey            = PKeys.RECAP_PLACO_HAUSSE;
  const hausseRevetIntKey         = PKeys.RECAP_REVET_INT_HAUSSE;
  const hausseElecKey             = PKeys.RECAP_ELEC_HAUSSE;
  const hausseRevetExtKey         = PKeys.RECAP_REVET_EXT_HAUSSE;
  const haussePlomberieKey        = PKeys.RECAP_PLOMBERIE_HAUSSE;
  const hausseChauffageKey        = PKeys.RECAP_CHAUFFAGE_HAUSSE;
  const haussePeintureKey         = PKeys.RECAP_PEINTURE_HAUSSE;
  const hausseDiagBbcKey          = PKeys.RECAP_DIAG_BBC_HAUSSE;
  const hausseArchitecteKey       = PKeys.RECAP_ARCHITECTE_HAUSSE;

  let hausseTerrassementCoeff,
      hausseVsSousSolCoeff,     
      hausseRdcCoeff,       
      hausseEtageCoeff,            
      haussePignonRdcCoeff,       
      hausseToitureRdcCoeff,
      hausseMenuiserieCoeff,     
      haussePlacoCoeff,         
      hausseRevetIntCoeff,  
      hausseElecCoeff, 
      hausseRevetExtCoeff,
      haussePlomberieCoeff,
      hausseChauffageCoeff,
      haussePeintureCoeff,
      hausseDiagBbcCoeff,
      hausseArchitecteCoeff;
  
  if (hausseTerrassementKey) {
    hausseTerrassementCoeff = await queryOneParamValue(hausseTerrassementKey);
  }
  if (hausseVsSousSolKey) {
    hausseVsSousSolCoeff = await queryOneParamValue(hausseVsSousSolKey);
  }
  if (hausseRdcKey) {
    hausseRdcCoeff = await queryOneParamValue(hausseRdcKey);
  }
  if (hausseEtageKey) {
    hausseEtageCoeff = await queryOneParamValue(hausseEtageKey);
  }
  if (haussePignonRdcKey) {
    haussePignonRdcCoeff = await queryOneParamValue(haussePignonRdcKey);
  }
  if (hausseToitureRdcKey) {
    hausseToitureRdcCoeff = await queryOneParamValue(hausseToitureRdcKey);
  }
  if (hausseMenuiserieKey) {
    hausseMenuiserieCoeff = await queryOneParamValue(hausseMenuiserieKey);
  }
  if (haussePlacoKey) {
    haussePlacoCoeff = await queryOneParamValue(haussePlacoKey);
  }
  if (hausseRevetIntKey) {
    hausseRevetIntCoeff = await queryOneParamValue(hausseRevetIntKey);
  }
  if (hausseElecKey) {
    hausseElecCoeff = await queryOneParamValue(hausseElecKey);
  }
  if (hausseRevetExtKey) {
    hausseRevetExtCoeff = await queryOneParamValue(hausseRevetExtKey);
  }
  if (haussePlomberieKey) {
    haussePlomberieCoeff = await queryOneParamValue(haussePlomberieKey);
  }
  if (hausseChauffageKey) {
    hausseChauffageCoeff = await queryOneParamValue(hausseChauffageKey);
  }
  if (haussePeintureKey) {
    haussePeintureCoeff = await queryOneParamValue(haussePeintureKey);
  }
  if (hausseDiagBbcKey) {
    hausseDiagBbcCoeff = await queryOneParamValue(hausseDiagBbcKey);
  }
  if (hausseArchitecteKey) {
    hausseArchitecteCoeff = await queryOneParamValue(hausseArchitecteKey);
  }

  targetObj.terrassement.ttlPrixTerrassement = validFloat(targetObj.terrassement.ttlPrixTerrassement) * validFloat(hausseTerrassementCoeff);
  targetObj.videSanitaire.ttlPrixVideSanitaire = validFloat(targetObj.videSanitaire.ttlPrixVideSanitaire) * validFloat(hausseVsSousSolCoeff);
  targetObj.rdc.ttlPrixRdc = validFloat(targetObj.rdc.ttlPrixRdc) * validFloat(hausseRdcCoeff);
  targetObj.combles.ttlPrixCombles = validFloat(targetObj.combles.ttlPrixCombles) * validFloat(hausseEtageCoeff);
  targetObj.pignonRdc.ttlPrixPignonRdc = validFloat(targetObj.pignonRdc.ttlPrixPignonRdc) * validFloat(haussePignonRdcCoeff);
  targetObj.toitureRdc.ttlPrixToitureRdc = validFloat(targetObj.toitureRdc.ttlPrixToitureRdc) * validFloat(hausseToitureRdcCoeff);
  targetObj.ouverture.ttlPrixOuverture = validFloat(targetObj.ouverture.ttlPrixOuverture) * validFloat(hausseMenuiserieCoeff);
  targetObj.placo.ttlPrixPlaco = validFloat(targetObj.placo.ttlPrixPlaco) * validFloat(haussePlacoCoeff);
  targetObj.revetementInt.ttlPrixRevetementInt = validFloat(targetObj.revetementInt.ttlPrixRevetementInt) * validFloat(hausseRevetIntCoeff);
  targetObj.electricite.ttlPrixElectricite = validFloat(targetObj.electricite.ttlPrixElectricite) * validFloat(hausseElecCoeff);
  targetObj.revetementExt.ttlPrixRevetementExt = validFloat(targetObj.revetementExt.ttlPrixRevetementExt) * validFloat(hausseRevetExtCoeff);
  targetObj.plomberie.ttlPrixPlomberie = validFloat(targetObj.plomberie.ttlPrixPlomberie) * validFloat(haussePlomberieCoeff);
  targetObj.chauffage.ttlPrixChauffage = validFloat(targetObj.chauffage.ttlPrixChauffage) * validFloat(hausseChauffageCoeff);
  targetObj.peinture.ttlPrixPeinture = validFloat(targetObj.peinture.ttlPrixPeinture) * validFloat(haussePeintureCoeff);
  targetObj.diagnostiqueBBCFraisDivers.ttlPrixDiagnostiqueBBCFraisDivers = validFloat(targetObj.diagnostiqueBBCFraisDivers.ttlPrixDiagnostiqueBBCFraisDivers) * validFloat(hausseDiagBbcCoeff);
  targetObj.architecte.ttlPrixArchitecte = validFloat(targetObj.architecte.ttlPrixArchitecte) * validFloat(hausseArchitecteCoeff);
}

// **********
export async function addRecap(targetObj, departementChoix, origineProspect) {
  const hausseDePrixKey = PKeys.HAUSSE_DE_PRIX_COEFF;
  let hausseDePrixCoeff;
  if (hausseDePrixKey) {
    hausseDePrixCoeff = await queryOneParamValue(hausseDePrixKey);
  }

  const hausseOrigineProspectKey  = PKeys.RECAP_ORIGINE_PROSPECT_HAUSSE;
  const hausseAjustementKey       = PKeys.RECAP_AJUSTEMENT_HAUSSE;
  const surfaceVitreeNordKey = PKeys.SURFACE_VITREE_NORD_COEFF;
  const dommageOuvrableKey = PKeys.DOMMAGE_OUVRABLE_PRIX;
  
  let hausseOrigineProspectCoeff,
      hausseAjustementCoeff,
      surfaceVitreeNordCoeff,
      dommageOuvrablePrix;

  if (hausseOrigineProspectKey) {
    hausseOrigineProspectCoeff = await queryOneParamValue(hausseOrigineProspectKey);
  }
  if (hausseAjustementKey) {
    hausseAjustementCoeff = await queryOneParamValue(hausseAjustementKey);
  }
  if (dommageOuvrableKey) {
    dommageOuvrablePrix = await queryOneParamValue(dommageOuvrableKey);
  }
  else {
    dommageOuvrablePrix = 1500;
  }
  
  //
  if (targetObj.ouverture.surfaceVitreeColor && targetObj.ouverture.surfaceVitreeColor != SKeys.CouleursSurfaceVitreeNord.BLOQUE && surfaceVitreeNordKey) {
    targetObj.recap.isSurfaceVitreeValid = true;
    surfaceVitreeNordCoeff = await queryOneParamValue(surfaceVitreeNordKey, targetObj.ouverture.surfaceVitreeColor);
  }
  else {
    targetObj.recap.isSurfaceVitreeValid = false;
    alert("Les menuiseries ne sont pas optimisées, veuillez les modifier.");
    surfaceVitreeNordCoeff = 0;
  }


  //TOTAL HT REVIENT
  targetObj.recap.ttlHtRevient = 
  ( validFloat(targetObj.terrassement.ttlPrixTerrassement)
  + validFloat(targetObj.videSanitaire.ttlPrixVideSanitaire)
  + validFloat(targetObj.rdc.ttlPrixRdc)
  + validFloat(targetObj.combles.ttlPrixCombles)
  + validFloat(targetObj.pignonRdc.ttlPrixPignonRdc)
  + validFloat(targetObj.toitureRdc.ttlPrixToitureRdc)
  + validFloat(targetObj.ouverture.ttlPrixOuverture)
  + validFloat(targetObj.placo.ttlPrixPlaco)
  + validFloat(targetObj.revetementInt.ttlPrixRevetementInt)
  + validFloat(targetObj.electricite.ttlPrixElectricite)
  + validFloat(targetObj.revetementExt.ttlPrixRevetementExt)
  + validFloat(targetObj.plomberie.ttlPrixPlomberie)
  + validFloat(targetObj.chauffage.ttlPrixChauffage)
  + validFloat(targetObj.peinture.ttlPrixPeinture)
  + validFloat(targetObj.diagnostiqueBBCFraisDivers.ttlPrixDiagnostiqueBBCFraisDivers)
  + validFloat(targetObj.architecte.ttlPrixArchitecte)
  ) * validFloat(hausseDePrixCoeff);
  

  //TOTAL HT INTERNE
  const totalHtInterneKey = PKeys.TOTAL_HT_INTERNE_COEFF;
  const departementKey = PKeys.DEPARTEMENT_COEFF;
  let departementCoeff, totalHtInterneCoeff;
  if (departementChoix && departementKey) {
    departementCoeff = await queryOneParamValue(departementKey, departementChoix);
    if (totalHtInterneKey) {
      totalHtInterneCoeff = await queryOneParamValue(totalHtInterneKey);
      targetObj.recap.ttlHtInterne = validFloat(targetObj.recap.ttlHtRevient) * validFloat(departementCoeff) * totalHtInterneCoeff * validFloat(surfaceVitreeNordCoeff);
      if (origineProspect === SKeys.OriginesProspect.AUCUN) {
        targetObj.recap.ttlHtInterne += 1000;
      }
    }  
  }
  

  //TOAL HT GAIN
  targetObj.recap.ttlHtGain =  validFloat(targetObj.recap.ttlHtInterne) - validFloat(targetObj.recap.ttlHtRevient);
  

  //% GAIN
  targetObj.recap.pourcentageGain = validFloat(targetObj.recap.ttlHtGain) / validFloat(targetObj.recap.ttlHtInterne);
  targetObj.recap.pourcentageGain = parseFloat(targetObj.recap.pourcentageGain.toFixed(3) * 100);
  

  //TVA INTERNE
  targetObj.recap.tvaInterne = validFloat(targetObj.recap.ttlHtInterne) * validFloat(AutreParams.COEFF_TVA);
  

  //TOTAL TTC + GL INTERNE
  targetObj.recap.ttlTtcGlInterne = (validFloat(targetObj.recap.tvaInterne) + validFloat(targetObj.recap.ttlHtInterne)) * validFloat(AutreParams.COEFF_TTC_GL);
  

  //TOTAL AVEC RCP INTERNE
  let tmpTtlRcpFoisInterne = validFloat(validFloat(targetObj.recap.ttlTtcGlInterne) * validFloat(AutreParams.COEFF_RCP_INTERNE));
  let tmpTtlRcpPlusInterne = validFloat(validFloat(targetObj.recap.ttlTtcGlInterne) + 1000);
  targetObj.recap.ttcRcpInterne = tmpTtlRcpFoisInterne < tmpTtlRcpPlusInterne ? tmpTtlRcpPlusInterne : tmpTtlRcpFoisInterne;
  

  //DOMMAGE OUVRABLE INTERNE
  let tmpDOInterne = validFloat(validFloat(targetObj.recap.ttcRcpInterne) * validFloat(AutreParams.COEFF_DO));
  targetObj.recap.dommageOuvrableInterne = tmpDOInterne < dommageOuvrablePrix ? dommageOuvrablePrix : tmpDOInterne;
  

  //COUT TOTAL PROJET INTERNE
  targetObj.recap.ttlCoutProjetInterne = validFloat(targetObj.recap.ttcRcpInterne) + validFloat(targetObj.recap.dommageOuvrableInterne);
  
  //
  //

  //TOTAL HT AGCO
  //
  const totalHtAgcoKey = PKeys.TOTAL_HT_AGCO_COEFF;
  let totalHtAgcoCoeff;
  if (totalHtAgcoKey) {
    totalHtAgcoCoeff = await queryOneParamValue(totalHtAgcoKey);
  }
  let coeffHTAgco = departementChoix === SKeys.Departements.LOIRET_45 ? 1.01 : 1;
  targetObj.recap.ttlHtAgco = (validFloat(targetObj.recap.ttlHtInterne) * validFloat(totalHtAgcoCoeff) * validFloat(departementCoeff) * validFloat(coeffHTAgco)) + ( validFloat(targetObj.origineProspect.ttlPrixOrigineProspect) * validFloat(hausseOrigineProspectCoeff) );

  //TVA AGCO
  targetObj.recap.tvaAgco = validFloat(targetObj.recap.ttlHtAgco) * validFloat(AutreParams.COEFF_TVA);
  

  //TOTAL TTC + GL AGCO
  targetObj.recap.ttlTtcGlAgco = (validFloat(targetObj.recap.tvaAgco) + validFloat(targetObj.recap.ttlHtAgco) + (validFloat(targetObj.ajustement.ttlPrixAjustement) * validFloat(hausseAjustementCoeff))) * validFloat(AutreParams.COEFF_TTC_GL);
  

  //TOTAL AVEC RCP AGCO
  let tmpTtlRcpFoisAgco = validFloat(validFloat(targetObj.recap.ttlTtcGlAgco) * validFloat(AutreParams.COEFF_RCP_AGCO));
  let tmpTtlRcpPlusAgco = validFloat(validFloat(targetObj.recap.ttlTtcGlAgco) + 1000);
  targetObj.recap.ttcRcpAgco = tmpTtlRcpFoisAgco < tmpTtlRcpPlusAgco ? tmpTtlRcpPlusAgco : tmpTtlRcpFoisAgco;
  

  //DOMMAGE OUVRABLE AGCO
  let tmpDOAgco = validFloat(validFloat(targetObj.recap.ttcRcpAgco) * validFloat(AutreParams.COEFF_DO));
  targetObj.recap.dommageOuvrableAgco = tmpDOAgco < dommageOuvrablePrix ? dommageOuvrablePrix : tmpDOAgco;
  

  //COUT TOTAL PROJET AGCO
  targetObj.recap.ttlCoutProjetAgco = validFloat(targetObj.recap.ttcRcpAgco) + validFloat(targetObj.recap.dommageOuvrableAgco);
  

  //COMISSION COMMERCIALE
  targetObj.recap.commissionCommerciale = validFloat(targetObj.recap.ttlHtAgco) - validFloat(targetObj.recap.ttlHtInterne) - ( validFloat(targetObj.origineProspect.ttlPrixOrigineProspect) * validFloat(hausseOrigineProspectCoeff) );
  

  //SUPPLEMENT REMY
  const supRemyKey = PKeys.SUPPLEMENT_REMY_PRIX;
  if (origineProspect && supRemyKey) {
    let supRemyPrix = await queryOneParamValue(supRemyKey, origineProspect);
    targetObj.recap.supplementRemy = validFloat(supRemyPrix);
  }
}

// **********
export async function addOptions(targetObj, departementChoix, origineProspect) {
  const departementKey = PKeys.DEPARTEMENT_COEFF;
  const hausseDePrixKey = PKeys.HAUSSE_DE_PRIX_COEFF;
  const totalHtInterneKey = PKeys.TOTAL_HT_INTERNE_COEFF;
  const totalHtAgcoKey = PKeys.TOTAL_HT_AGCO_COEFF;
  const hausseOrigineProspectKey  = PKeys.RECAP_ORIGINE_PROSPECT_HAUSSE;
  const hausseAjustementKey  = PKeys.RECAP_AJUSTEMENT_HAUSSE;
  const surfaceVitreeNordKey = PKeys.SURFACE_VITREE_NORD_COEFF;
  let departementCoeff, hausseDePrixCoeff, totalHtInterneCoeff, totalHtAgcoCoeff, hausseOrigineProspectCoeff, hausseAjustementCoeff, surfaceVitreeNordCoeff;
  if (departementChoix && departementKey) {
    departementCoeff = await queryOneParamValue(departementKey, departementChoix);
  }
  if (hausseDePrixKey) {
    hausseDePrixCoeff = await queryOneParamValue(hausseDePrixKey);
  }
  if (totalHtInterneKey) {
    totalHtInterneCoeff = await queryOneParamValue(totalHtInterneKey);
  }
  if (totalHtAgcoKey) {
    totalHtAgcoCoeff = await queryOneParamValue(totalHtAgcoKey);
  }
  if (hausseOrigineProspectKey) {
    hausseOrigineProspectCoeff = await queryOneParamValue(hausseOrigineProspectKey);
  }
  if (hausseAjustementKey) {
    hausseAjustementCoeff = await queryOneParamValue(hausseAjustementKey);
  }

  if (targetObj.ouverture.surfaceVitreeColor && targetObj.ouverture.surfaceVitreeColor != SKeys.CouleursSurfaceVitreeNord.BLOQUE && surfaceVitreeNordKey) {
    targetObj.recap.isSurfaceVitreeValid = true;
    surfaceVitreeNordCoeff = await queryOneParamValue(surfaceVitreeNordKey, targetObj.ouverture.surfaceVitreeColor);
  }
  else {
    targetObj.recap.isSurfaceVitreeValid = false;
    surfaceVitreeNordCoeff = 0;
  }

  let coeffHTAgco = departementChoix === SKeys.Departements.LOIRET_45 ? 1.01 : 1;
  let prixAucunOrigineProspect = origineProspect === SKeys.OriginesProspect.AUCUN ? 1000 : 0;
  //CARRELAGE
  if (validFloat(targetObj.revetementInt.ttlPrixCarrelageOption)) {
    //
    let tmpCarrelage = 
    ((((( validFloat(targetObj.revetementInt.ttlPrixCarrelageOption) * validFloat(hausseDePrixCoeff) ) + validFloat(targetObj.recap.ttlHtRevient)) 
    * validFloat(totalHtInterneCoeff) 
    * validFloat(departementCoeff)
    * validFloat(surfaceVitreeNordCoeff))
    + validFloat(prixAucunOrigineProspect))
    * validFloat(totalHtAgcoCoeff) 
    * validFloat(departementCoeff) 
    * validFloat(coeffHTAgco)) 
    + ( validFloat(targetObj.origineProspect.ttlPrixOrigineProspect) * validFloat(hausseOrigineProspectCoeff) );
    //
    let tvaCarrelage = validFloat(tmpCarrelage) * validFloat(AutreParams.COEFF_TVA);
    tmpCarrelage = (validFloat(tmpCarrelage) + validFloat(tvaCarrelage) + (validFloat(targetObj.ajustement.ttlPrixAjustement) * validFloat(hausseAjustementCoeff))) * validFloat(AutreParams.COEFF_TTC_GL);
    //
    let foisCarrelage = validFloat(validFloat(tmpCarrelage) * validFloat(AutreParams.COEFF_RCP_AGCO));
    let plusCarrelage = validFloat(validFloat(tmpCarrelage) + 1000);
    tmpCarrelage = foisCarrelage < plusCarrelage ? validFloat(plusCarrelage) : validFloat(foisCarrelage);
    //
    let doCarrelage = validFloat(validFloat(tmpCarrelage) * validFloat(AutreParams.COEFF_DO));
    tmpCarrelage += doCarrelage < 1500 ? 1500 : validFloat(doCarrelage);
    //
    targetObj.recap.options.ttlCarrelage = validFloat(tmpCarrelage);

    targetObj.recap.options.carrelage = (validFloat(tmpCarrelage) - validFloat(targetObj.recap.ttlCoutProjetAgco));
  }
  //FAIENCE
  if (validFloat(targetObj.revetementInt.ttlPrixFaienceOption)) {
    //
    let tmpFaience = 
    ((((( validFloat(targetObj.revetementInt.ttlPrixFaienceOption) * validFloat(hausseDePrixCoeff) ) + validFloat(targetObj.recap.ttlHtRevient)) 
    * validFloat(totalHtInterneCoeff) 
    * validFloat(departementCoeff)
    * validFloat(surfaceVitreeNordCoeff))
    + validFloat(prixAucunOrigineProspect))
    * validFloat(totalHtAgcoCoeff) 
    * validFloat(departementCoeff) 
    * validFloat(coeffHTAgco)) 
    + ( validFloat(targetObj.origineProspect.ttlPrixOrigineProspect) * validFloat(hausseOrigineProspectCoeff) );
    //
    let tvaFaience = validFloat(tmpFaience) * validFloat(AutreParams.COEFF_TVA);
    tmpFaience = (validFloat(tmpFaience) + validFloat(tvaFaience) + (validFloat(targetObj.ajustement.ttlPrixAjustement) * validFloat(hausseAjustementCoeff))) * validFloat(AutreParams.COEFF_TTC_GL);
    //
    let foisFaience = validFloat(validFloat(tmpFaience) * validFloat(AutreParams.COEFF_RCP_AGCO));
    let plusFaience = validFloat(validFloat(tmpFaience) + 1000);
    tmpFaience = foisFaience < plusFaience ? validFloat(plusFaience) : validFloat(foisFaience);
    //
    let doFaience = validFloat(validFloat(tmpFaience) * validFloat(AutreParams.COEFF_DO));
    tmpFaience += doFaience < 1500 ? 1500 : validFloat(doFaience);
    //
    targetObj.recap.options.ttlFaience = validFloat(tmpFaience);

    targetObj.recap.options.faience = (validFloat(tmpFaience) - validFloat(targetObj.recap.ttlCoutProjetAgco));
  }
  //PARQUET
  if (validFloat(targetObj.revetementInt.ttlPrixParquetOption)) {
    //
    let tmpParquet = 
    ((((( validFloat(targetObj.revetementInt.ttlPrixParquetOption) * validFloat(hausseDePrixCoeff) ) + validFloat(targetObj.recap.ttlHtRevient)) 
    * validFloat(totalHtInterneCoeff) 
    * validFloat(departementCoeff)
    * validFloat(surfaceVitreeNordCoeff))
    + validFloat(prixAucunOrigineProspect))
    * validFloat(totalHtAgcoCoeff) 
    * validFloat(departementCoeff) 
    * validFloat(coeffHTAgco)) 
    + ( validFloat(targetObj.origineProspect.ttlPrixOrigineProspect) * validFloat(hausseOrigineProspectCoeff) );
    //
    let tvaParquet = validFloat(tmpParquet) * validFloat(AutreParams.COEFF_TVA);
    tmpParquet = (validFloat(tmpParquet) + validFloat(tvaParquet) + (validFloat(targetObj.ajustement.ttlPrixAjustement) * validFloat(hausseAjustementCoeff))) * validFloat(AutreParams.COEFF_TTC_GL);
    //
    let foisParquet = validFloat(validFloat(tmpParquet) * validFloat(AutreParams.COEFF_RCP_AGCO));
    let plusParquet = validFloat(validFloat(tmpParquet) + 1000);
    tmpParquet = foisParquet < plusParquet ? validFloat(plusParquet) : validFloat(foisParquet);
    //
    let doParquet = validFloat(validFloat(tmpParquet) * validFloat(AutreParams.COEFF_DO));
    tmpParquet += doParquet < 1500 ? 1500 : validFloat(doParquet);
    //
    targetObj.recap.options.ttlParquet = validFloat(tmpParquet);

    targetObj.recap.options.parquet = (validFloat(tmpParquet) - validFloat(targetObj.recap.ttlCoutProjetAgco));
  }
  //SANITAIRE
  if (validFloat(targetObj.plomberie.ttlPrixPlomberieOption)) {
    //

    let tmpSanitaires = 
    ((((( validFloat(targetObj.plomberie.ttlPrixPlomberieOption) * validFloat(hausseDePrixCoeff) ) + validFloat(targetObj.recap.ttlHtRevient)) 
    * validFloat(totalHtInterneCoeff) 
    * validFloat(departementCoeff)
    * validFloat(surfaceVitreeNordCoeff))
    + validFloat(prixAucunOrigineProspect))
    * validFloat(totalHtAgcoCoeff) 
    * validFloat(departementCoeff) 
    * validFloat(coeffHTAgco)) 
    + ( validFloat(targetObj.origineProspect.ttlPrixOrigineProspect) * validFloat(hausseOrigineProspectCoeff) );
    //
    let tvaSanitaires = validFloat(tmpSanitaires) * validFloat(AutreParams.COEFF_TVA);
    tmpSanitaires = (validFloat(tmpSanitaires) + validFloat(tvaSanitaires) + (validFloat(targetObj.ajustement.ttlPrixAjustement) * validFloat(hausseAjustementCoeff))) * validFloat(AutreParams.COEFF_TTC_GL);
    //
    let foisSanitaires = validFloat(validFloat(tmpSanitaires) * validFloat(AutreParams.COEFF_RCP_AGCO));
    let plusSanitaires = validFloat(validFloat(tmpSanitaires) + 1000);
    tmpSanitaires = foisSanitaires < plusSanitaires ? validFloat(plusSanitaires) : validFloat(foisSanitaires);
    //
    let doSanitaires = validFloat(validFloat(tmpSanitaires) * validFloat(AutreParams.COEFF_DO));
    tmpSanitaires += doSanitaires < 1500 ? 1500 : validFloat(doSanitaires);
    //
    targetObj.recap.options.ttlSanitaires = validFloat(tmpSanitaires);

    targetObj.recap.options.sanitaires = (validFloat(tmpSanitaires) - validFloat(targetObj.recap.ttlCoutProjetAgco)) ;
  }
  //PEINTURE
  if (validFloat(targetObj.peinture.ttlPrixPeintureOption)) {
    //
    let tmpPeinture = 
    ((((( validFloat(targetObj.peinture.ttlPrixPeintureOption)  * validFloat(hausseDePrixCoeff) ) + validFloat(targetObj.recap.ttlHtRevient)) 
    * validFloat(totalHtInterneCoeff) 
    * validFloat(departementCoeff)
    * validFloat(surfaceVitreeNordCoeff))
    + validFloat(prixAucunOrigineProspect))
    * validFloat(totalHtAgcoCoeff) 
    * validFloat(departementCoeff) 
    * validFloat(coeffHTAgco)) 
    + ( validFloat(targetObj.origineProspect.ttlPrixOrigineProspect) * validFloat(hausseOrigineProspectCoeff) );
    //
    let tvaPeinture = validFloat(tmpPeinture) * validFloat(AutreParams.COEFF_TVA);
    tmpPeinture = (validFloat(tmpPeinture) + validFloat(tvaPeinture) + (validFloat(targetObj.ajustement.ttlPrixAjustement) * validFloat(hausseAjustementCoeff))) * validFloat(AutreParams.COEFF_TTC_GL);
    //
    let foisPeinture = validFloat(validFloat(tmpPeinture) * validFloat(AutreParams.COEFF_RCP_AGCO));
    let plusPeinture = validFloat(validFloat(tmpPeinture) + 1000);
    tmpPeinture = foisPeinture < plusPeinture ? validFloat(plusPeinture) : validFloat(foisPeinture);
    //
    let doPeinture = validFloat(validFloat(tmpPeinture) * validFloat(AutreParams.COEFF_DO));
    tmpPeinture += doPeinture < 1500 ? 1500 : validFloat(doPeinture);
    //
    targetObj.recap.options.ttlPeinture = validFloat(tmpPeinture);

    targetObj.recap.options.peinture = (validFloat(tmpPeinture) - validFloat(targetObj.recap.ttlCoutProjetAgco));
  }
  //PARPAINGS SUPP VS
  if (validFloat(targetObj.videSanitaire.ligneParpaingsSupp)) {
    //
    let tmpParpaings = 
    ((((( validFloat(targetObj.videSanitaire.ligneParpaingsSupp) * validFloat(hausseDePrixCoeff) ) + validFloat(targetObj.recap.ttlHtRevient)) 
    * validFloat(totalHtInterneCoeff) 
    * validFloat(departementCoeff)
    * validFloat(surfaceVitreeNordCoeff))
    + validFloat(prixAucunOrigineProspect))
    * validFloat(totalHtAgcoCoeff) 
    * validFloat(departementCoeff) 
    * validFloat(coeffHTAgco)) 
    + ( validFloat(targetObj.origineProspect.ttlPrixOrigineProspect) * validFloat(hausseOrigineProspectCoeff) );
    //
    let tvaParpaings = validFloat(tmpParpaings) * validFloat(AutreParams.COEFF_TVA);
    tmpParpaings = (validFloat(tmpParpaings) + validFloat(tvaParpaings) + (validFloat(targetObj.ajustement.ttlPrixAjustement) * validFloat(hausseAjustementCoeff))) * validFloat(AutreParams.COEFF_TTC_GL);
    //
    let foisParpaings = validFloat(validFloat(tmpParpaings) * validFloat(AutreParams.COEFF_RCP_AGCO));
    let plusParpaings = validFloat(validFloat(tmpParpaings) + 1000);
    tmpParpaings = foisParpaings < plusParpaings ? validFloat(plusParpaings) : validFloat(foisParpaings);
    //
    let doParpaings = validFloat(validFloat(tmpParpaings) * validFloat(AutreParams.COEFF_DO));
    tmpParpaings += doParpaings < 1500 ? 1500 : validFloat(doParpaings);
    //
    targetObj.recap.options.ttlParpaings = validFloat(tmpParpaings);

    targetObj.recap.options.parpaings = (validFloat(tmpParpaings) - validFloat(targetObj.recap.ttlCoutProjetAgco));
  }

  
  // //HYBRIS
  // if (validFloat(targetObj.placo.ttlPrixHybrisOption)) {
  //   //
  //   let tmpHybris = 
  //   ((((validFloat(targetObj.placo.ttlPrixHybrisOption) + validFloat(targetObj.recap.ttlHtRevient)) 
  //   * validFloat(totalHtInterneCoeff) 
  //   * validFloat(departementCoeff))
  //   * validFloat(surfaceVitreeNordCoeff)
  //   + validFloat(prixAucunOrigineProspect))
  //   * validFloat(totalHtAgcoCoeff) 
  //   * validFloat(departementCoeff) 
  //   * validFloat(coeffHTAgco)) 
  //   + ( validFloat(targetObj.origineProspect.ttlPrixOrigineProspect) * validFloat(hausseOrigineProspectCoeff) );
  //   //
  //   let tvaHybris = validFloat(tmpHybris) * validFloat(AutreParams.COEFF_TVA);
  //   tmpHybris = (validFloat(tmpHybris) + validFloat(tvaHybris) + (validFloat(targetObj.ajustement.ttlPrixAjustement) * validFloat(hausseAjustementCoeff))) * validFloat(AutreParams.COEFF_TTC_GL);
  //   //
  //   let foisHybris = validFloat(validFloat(tmpHybris) * validFloat(AutreParams.COEFF_RCP_AGCO));
  //   let plusHybris = validFloat(validFloat(tmpHybris) + 1000);
  //   tmpHybris = foisHybris < plusHybris ? validFloat(plusHybris) : validFloat(foisHybris);
  //   //
  //   let doHybris = validFloat(validFloat(tmpHybris) * validFloat(AutreParams.COEFF_DO));
  //   tmpHybris += doHybris < 1500 ? 1500 : validFloat(doHybris);
  //   //
  //   targetObj.recap.options.ttlHybris = validFloat(tmpHybris);

  //   targetObj.recap.options.hybris = (validFloat(tmpHybris) - validFloat(targetObj.recap.ttlCoutProjetAgco)) * validFloat(hausseDePrixCoeff);
  // }
}