import { queryOneParamValue } from "../api/queries";
import { SKeys } from "../param/selectKeys";
import { PKeys } from "../param/parametersKeys";

import { validFloat } from "./helpers"

export const TerrassementParams = Object.freeze({
  COEFF_DECAISSMENT_1: 7,
  COEFF_DECAISSMENT_2: 0.75,
  COEFF_FONDATION:   2.5,
  EXTRA_PRICE_:      300
});

export function addDecaissement(targetObj, empriseSol, nombreRangs) {
  const coeffDecaissement1 = TerrassementParams.COEFF_DECAISSMENT_1;
  let coeffRangStructure = 0;
  switch (nombreRangs) {
    case SKeys.RangsVideSanitaire.TROIS_RANGS:
      coeffRangStructure = 0.75;
      break;
      
    case SKeys.RangsVideSanitaire.QUATRE_RANGS:
      coeffRangStructure = 1;
      break;
    
    case SKeys.RangsVideSanitaire.CINQ_RANGS:
      coeffRangStructure = 1.25;
      break;
    
    case SKeys.RangsVideSanitaire.SOUS_SOL:
      coeffRangStructure = 2.6;
      break;
  
    default:
      break;
  }
  if (validFloat(empriseSol) && validFloat(coeffDecaissement1)) {
    targetObj.terrassement.ttlPrixTerrassement += validFloat(coeffDecaissement1) * validFloat(coeffRangStructure) * validFloat(empriseSol);
  }
}

export async function addFondation(targetObj, facadeVS, pignonVS, refendVS, decrocheVS) {
  const coeffFondation = TerrassementParams.COEFF_FONDATION;
  let fondation = 2 * ( validFloat(facadeVS) + validFloat(pignonVS) + validFloat(decrocheVS) ) + validFloat(refendVS);
  // targetObj.terrassement.fondation = parseFloat(fondation.toFixed(2));
  targetObj.terrassement.fondation = fondation;
  if (validFloat(coeffFondation)) {
    targetObj.terrassement.ttlPrixTerrassement += validFloat(fondation) * validFloat(coeffFondation);
  }
}

export function addExtra(targetObj) {
  const coeffExtra = TerrassementParams.EXTRA_PRICE_;
  if (validFloat(coeffExtra)) {
    targetObj.terrassement.ttlPrixTerrassement += validFloat(coeffExtra);
  }
}

export async function addBranchement(targetObj) {
  let type = SKeys.Fournisseurs.CLIENT;
  let longueur = '7';
  //
  if (type === SKeys.Fournisseurs.MAV) {
    const prixKey = PKeys.BRANCHEMENT_PRIX;
    if (prixKey) {
      let prixUnit = await queryOneParamValue(prixKey, longueur);
      if (validFloat(prixUnit)) {
        targetObj.terrassement.ttlPrixTerrassement += validFloat(prixUnit);
      }
    }
  }
}