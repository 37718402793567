<template>
  <div class="nav-bar flex-cnt-col align-center">
    <div class="nav-section flex-cnt-col align-start justify-center"
      v-for="element in listElements"
      :key="element.index"
    >
      <div class="nav-section-title flex-cnt-row align-center justify-between" 
        v-bind:class="{'nav-section-active': activeSection && activeSection === element.section}"
        v-if="element.isSectionFirst"
        v-on:click="activateSection(element.section)"
      >
        <div class="nav-title">
          {{element.section.toUpperCase()}}
        </div>
        <div class="dropdown-cnt">
          <img class="dropdown-icon" 
            v-bind:class="{'icon-open': sectionIsOpen[element.section], 'icon-not-open': !sectionIsOpen[element.section]}" 
            src="@/assets/icons/list-arrow.png" 
            alt="Dropdown Icon"
          >
        </div>
      </div>
      <div class="nav-link flex-cnt-row align-center"
        v-bind:class="{'nav-link-active': activeIndex === element.index}"
        v-if="!sectionIsOpen || sectionIsOpen[element.section]"
        v-on:click="activateElement(element.index, element.section)"
      >
        <div class="nav-name">
          {{element.title.toUpperCase()}}
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  props: {
    activeIndex: {
      type: Number,
      required: true
    },
    activeSection: {
      type: String,
      required: false,
      default: null
    },
    sectionIsOpen: {
      type: Object,
      required: false,
      default: null
    },
    listElements: {
      type: Array,
      required: true
    }
  },
  methods: {
    activateElement(index, section) {
      this.$emit('activateElement', index, section);
    },
    activateSection(section) {
      this.$emit('activateSection', section);
    }
  }
}
</script>

<style scoped>
.nav-bar {
  padding: 20px;
  width: 240px;
  box-sizing: border-box;
  background: #fff;
  overflow: auto;
  border-right: 1px solid #c6d2d9;
}

.nav-param.nav-bar {
  width: 350px;
  padding-top: 10px;
}

.nav-section {
  width: 100%;
}

.nav-section-title {
  border-top: 0px solid #fff;
  border-left: 0px solid #fff;
  border-right: 0px solid #fff;
  border-bottom: 1px solid #c6d2d9;
  margin: 15px 0px 8px 0px;
  padding: 0px 5px;
  box-sizing: border-box;
  width: 100%;
  transition: 0.2s;
}
.nav-section-title:hover {
  cursor: pointer;
  background: rgb(210, 216, 221);
  transition: 0.2s;
}
.nav-section-title:hover .nav-title {
  padding-left: 5px;
  transition: 0.2s;
}
.nav-section-active {
  background: rgb(228, 234, 238);
  border: 1px solid #c6d2d9;
  transition: 0.2s;
}

.nav-title {
  box-sizing: border-box;
  font-family: 'OpenSans400';
  font-size: 12px;
  color: #6b7377;
  transition: 0.2s;
}

.dropdown-icon {
  height: 10px;
}

.dropdown-icon.icon-open {
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(9deg);
  -o-transform: rotate(9deg);
}
.dropdown-icon.icon-not-open {
  transform: rotate(0deg);
  -webkit-transform:rotate(0deg);
  -moz-transform:rotate(0deg);
  -o-transform:rotate(0deg);
}

.nav-link {
  width: 100%;
  margin-bottom: 5px;
  border: 1px solid #a4aeb4;
  border-radius: 2px;
  padding: 1px 10px;
  box-sizing: border-box;
  background-color: rgb(253, 253, 253);
  transition: 0.25s;
}
.nav-param .nav-link:hover {
  background-color: rgb(228, 234, 238);
  cursor: pointer;
}

.nav-link-active {
  display: flex;
  padding: 20px 10px;
  background-color: rgb(228, 234, 238);
  transition: 0.25s;
  padding-left: 30px;
}

.nav-param .nav-name {
  font-size: 14px;
}

.nav-name {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 15px;
}
</style>