<template>
  <div class="document-single flex-cnt-row flex-center">
    <div class="doc-row flx1 flex-cnt-row align-center justify-between">
      <div class="client-cell text-left">
        {{`${doc.nomDocument} V${doc.version}`}}
      </div>
      <div class="tel-cell flx1 text-center">
        {{formatTel(doc.tel, '## ## ## ## ##')}}
      </div>
      <div class="date-cell flx1 text-center">
        {{new Date(doc.creationDate).toLocaleDateString('fr')}}
      </div>
      <div class="btn-cell flx1 flex-cnt-row align-center justify-end text-right">
        <button class="doc-btn consult-btn" v-on:click="consultDocument(doc.id)">
          <span class="font-consult">≣</span> VOIR
        </button>
        <button class="doc-btn edit-btn" v-on:click="editDocument(doc.id)">
          ✎ MODIFIER
        </button>
        <button class="doc-btn generate-btn" v-on:click="generateDevis(doc.id)" :disabled="!authSVN || !authVSV">
          ⬇︎ DEVIS 
        </button>
        <button class="delete-btn" v-if="isAdmin" v-on:click="deleteDocument(doc.id)">
          ×
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { queryDelete } from "../../api/queries"
import { SKeys } from '../../param/selectKeys';

export default {
  props: {
    doc: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters(['getAuthData']),
    isAdmin: function () {
      return this.getAuthData.isAdmin === 1 || this.getAuthData.isAdmin === 2;
    },
    authSVN() {
      return ( Number.parseInt(this.doc.surfaceVitreeNord) && Number.parseInt(this.doc.surfaceVitreeNord) < SKeys.AutorisationSurfaceVitree.BLOQUE ) ? true : false;
    },
    authVSV() {
      return parseFloat(this.doc.validSurfaceVitree) >= 0 ? true : false;
    }
  },
  methods: {
    consultDocument(clientId) {
      this.$router.push({name: 'Recapitulatif', params: {clientId: clientId}})
    },
    editDocument(clientId) {
      this.$router.push({name: 'Descriptif', params: {clientId: clientId, isEditMode: true, nomDocument: this.doc.nomDocument}})
    },
    generateDevis(clientId) {
      if (this.authSVN && this.authVSV) {
        this.$router.push({name: 'Generation', params: {clientId: clientId}});
      }
    },
    async deleteDocument(clientId) {
      if (Number.parseInt(clientId) > 0 && confirm("Voulez-vous supprimer ce document ?")) {
        let response = await queryDelete('clients', clientId);
        if (Number.parseInt(response) === clientId) {
          this.$emit('refreshDocuments');
        }
      }
    },
    formatTel(value, pattern) {
      if (value && value.length === 10) {
        let i = 0;
        let phone = value.toString();
        let result = pattern.split('');
        for (let index = 0; index < pattern.length; index++) {
          if (pattern[index] === '#') {
            result[index] = phone[i++];
          }
        }
        return result.join('');
      }
      else if (value) {
        return value;
      }
      else {
        return '-';
      }
    }
  }
}
</script>

<style scoped>
.document-single {
  min-height: 50px;
  box-sizing: border-box;
  background: transparent;
  transition: 0.25s;
}
.document-single:hover {
  background: #fafafa;
  transition: 0.15s;
}

.doc-row {
  height: 50px;
  margin: 0px 30px;
  padding: 0px 20px;
  box-sizing: border-box;
  border-bottom: 1px solid #e7edf0;
  overflow: hidden;
}

button {
  margin-left: 5px;
  cursor: pointer;
}

.doc-btn {
  position: relative;
  height: 25px;
  padding: 0px 10px;
  background: #fafafa;
  color: #424749;
  font-size: 13px;
  line-height: 15px;
  font-weight: 500;
  font-family: "OpenSans400";
  text-transform: uppercase;
  border: 1px solid #5c6164;
  border-radius: 2px;
  transition: 0.25s;
  white-space: nowrap;
  overflow: visible;
}
.doc-btn:hover {
  background: #e3e7e9;
  transition: 0.25s;
}
.doc-btn:disabled {
  opacity: 1;
  background: rgba(250, 250, 250, 0.33);
  color: rgba(65, 70, 72, 0.33);
  cursor: pointer;
  border-color: rgba(92, 97, 100, 0.33);
  cursor: not-allowed;
}
.doc-btn:disabled:hover:before {
  content: "Les menuiseries ne sont pas optimisées, veuillez les modifier.";
  position: absolute;
  top: -4px;
  right: calc(100% + 5px);
  height: 24px;
  padding: 3px 20px;
  z-index: 99999;
  opacity: 1;
  
  background: rgba(228, 234, 238, 1);
  border: 1px solid #c6d2d9;
  border-radius: 3px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.0025);
  text-align: center;
  color: red;
  font-family: OpenSans400;
  font-weight: 700;
  line-height: 24px;
  text-transform: none;
}

.delete-btn {
  margin-right: 0;
  margin-bottom: 0;
}

.font-consult {
  font-size: 19px;
  line-height: 0px;
}

.client-cell {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 15px;
  flex: 2;
  min-width: 115px;
}

.date-cell {
  font-size: 14px;
  min-width: 115px;
}

.tel-cell {
  font-size: 14px;
  min-width: 115px;
}

.btn-cell {
  min-width: fit-content;
}
</style>