import { SKeys } from "../param/selectKeys";

import { validFloat } from "./helpers"

export const VideSanitaireParams = Object.freeze({
  VALID_PLANCHER_TERRE_PLEIN: 2.6,
  PRIX_BETON_FONDATION:       70,
  PRIX_MAC:                   40.4,
  PRIX_PLANCHER_TERRE_PLEIN:  17,
  PRIX_PLANCHER_ISO_1:        44.64,
  PRIX_PLANCHER_ISO_2:        14,
  PRIX_PLANCHER_NON_ISO_1:    26.94,
  PRIX_PLANCHER_NON_ISO_2:    14,
  PRIX_PLANCHER_CHAUFFANT:    19.5,
  PRIX_ENDUIT_VS:             4,
  PRIX_BETON_PLANCHER:        73,
  PRIX_PARPAINGS_SUPP:        40.4,
  //OLD PARAMS
  //NiveauM1Coeff
  NiveauM1Coeff: {
    COEFF_NIVEAUM1_SOUS_SOL: 2.6,
    COEFF_NIVEAUM1_3_RANGS: 0.75,
    COEFF_NIVEAUM1_4_RANGS: 1,
    COEFF_NIVEAUM1_5_RANGS: 1.25
  }

});

export async function computeParamsVS(targetObj, empriseSol, pieces, niveauM1Type) {
  let ttlSurfaceGarage = 0;
  let tmpBetonPlancher = 0;
  if (validFloat(empriseSol)) {
    //SHON RDC
    if (pieces.length) {
      pieces.forEach(piece => {
        if (piece.type === SKeys.TypesPieces.GARAGE) {
          ttlSurfaceGarage += validFloat(piece.surface);
        }
      });
    }
    targetObj.videSanitaire.shonRdc = validFloat(empriseSol) - validFloat(ttlSurfaceGarage);
    //BETON PLANCHER
    let coeffM1Value = 0;
    if (niveauM1Type) {
      if (niveauM1Type === SKeys.RangsVideSanitaire.SOUS_SOL) {
        coeffM1Value = VideSanitaireParams.NiveauM1Coeff.COEFF_NIVEAUM1_SOUS_SOL;
      }
      else if (niveauM1Type === SKeys.RangsVideSanitaire.TROIS_RANGS) {
        coeffM1Value = VideSanitaireParams.NiveauM1Coeff.COEFF_NIVEAUM1_3_RANGS;
      }
      else if (niveauM1Type === SKeys.RangsVideSanitaire.QUATRE_RANGS) {
        coeffM1Value = VideSanitaireParams.NiveauM1Coeff.COEFF_NIVEAUM1_4_RANGS;
      }
      else if (niveauM1Type === SKeys.RangsVideSanitaire.CINQ_RANGS) {
        coeffM1Value = VideSanitaireParams.NiveauM1Coeff.COEFF_NIVEAUM1_5_RANGS;
      }
    }
    if (parseFloat(coeffM1Value) === VideSanitaireParams.VALID_PLANCHER_TERRE_PLEIN) {
      tmpBetonPlancher += validFloat(empriseSol);
    }
    tmpBetonPlancher += validFloat(targetObj.videSanitaire.shonRdc);
    tmpBetonPlancher += validFloat(empriseSol) - validFloat(targetObj.videSanitaire.shonRdc);
    targetObj.videSanitaire.plancherBeton = 0.1 * tmpBetonPlancher;
  }
  //SURFACE HABITABLE
  let surfaceHabitable = 0;
  pieces.forEach(piece => {
    if (piece.type && piece.type != SKeys.TypesPieces.GARAGE && piece.type != SKeys.TypesPieces.TERASSE_BETON) {
      surfaceHabitable += piece.surface;
    }
  });
  targetObj.videSanitaire.surfaceHabitable = validFloat(surfaceHabitable);
}

export function addFondationBeton(targetObj, fondation, plancherBeton) {
  let tmpVal = (0.3 * validFloat(VideSanitaireParams.PRIX_BETON_FONDATION) * validFloat(fondation)) + (13 * validFloat(fondation)) + (150 * (0.3 * validFloat(fondation)) / parseFloat(5)) + (150 * ( (0.3 * validFloat(fondation) / parseFloat(5) ) - 2 )) + (150 * validFloat(plancherBeton) / parseFloat(5));
  targetObj.videSanitaire.ttlPrixVideSanitaire += tmpVal;
}

export async function addMac(targetObj, fondation, niveauM1Type) {
  const prixMac = VideSanitaireParams.PRIX_MAC;
  let coeffM1Value = 0;
  if (niveauM1Type) {
    if (niveauM1Type === SKeys.RangsVideSanitaire.SOUS_SOL) {
      coeffM1Value = VideSanitaireParams.NiveauM1Coeff.COEFF_NIVEAUM1_SOUS_SOL;
    }
    else if (niveauM1Type === SKeys.RangsVideSanitaire.TROIS_RANGS) {
      coeffM1Value = VideSanitaireParams.NiveauM1Coeff.COEFF_NIVEAUM1_3_RANGS;
    }
    else if (niveauM1Type === SKeys.RangsVideSanitaire.QUATRE_RANGS) {
      coeffM1Value = VideSanitaireParams.NiveauM1Coeff.COEFF_NIVEAUM1_4_RANGS;
    }
    else if (niveauM1Type === SKeys.RangsVideSanitaire.CINQ_RANGS) {
      coeffM1Value = VideSanitaireParams.NiveauM1Coeff.COEFF_NIVEAUM1_5_RANGS;
    }
  }
  targetObj.videSanitaire.ttlPrixVideSanitaire += (validFloat(coeffM1Value) * validFloat(fondation) * validFloat(prixMac)) + 250 + 100;
}

export async function addPlancherTerrePlein(targetObj, empriseSol, niveauM1Type) {
  let coeffM1Value = 0;
  if (niveauM1Type) {
    if (niveauM1Type === SKeys.RangsVideSanitaire.SOUS_SOL) {
      coeffM1Value = VideSanitaireParams.NiveauM1Coeff.COEFF_NIVEAUM1_SOUS_SOL;
    }
    else if (niveauM1Type === SKeys.RangsVideSanitaire.TROIS_RANGS) {
      coeffM1Value = VideSanitaireParams.NiveauM1Coeff.COEFF_NIVEAUM1_3_RANGS;
    }
    else if (niveauM1Type === SKeys.RangsVideSanitaire.QUATRE_RANGS) {
      coeffM1Value = VideSanitaireParams.NiveauM1Coeff.COEFF_NIVEAUM1_4_RANGS;
    }
    else if (niveauM1Type === SKeys.RangsVideSanitaire.CINQ_RANGS) {
      coeffM1Value = VideSanitaireParams.NiveauM1Coeff.COEFF_NIVEAUM1_5_RANGS;
    }
  }
  const prixPlancherTP = VideSanitaireParams.PRIX_PLANCHER_TERRE_PLEIN;
  if (parseFloat(coeffM1Value) === VideSanitaireParams.VALID_PLANCHER_TERRE_PLEIN) {
    targetObj.videSanitaire.ttlPrixVideSanitaire += validFloat(empriseSol) * validFloat(prixPlancherTP);
  }
}

export function addPlancherIso(targetObj, shonRdc) {
  const prix1 = VideSanitaireParams.PRIX_PLANCHER_ISO_1;
  const prix2 = VideSanitaireParams.PRIX_PLANCHER_ISO_2;
  targetObj.videSanitaire.ttlPrixVideSanitaire += (validFloat(prix1) + validFloat(prix2)) * validFloat(shonRdc);
}

export function addPlancherNonIso(targetObj, empriseSol, shonRdc) {
  const prix1 = VideSanitaireParams.PRIX_PLANCHER_NON_ISO_1;
  const prix2 = VideSanitaireParams.PRIX_PLANCHER_NON_ISO_2;
  targetObj.videSanitaire.ttlPrixVideSanitaire += (validFloat(prix1) + validFloat(prix2)) * (validFloat(empriseSol) - validFloat(shonRdc));
}

export function addPlancherChauffant(targetObj, chauffageType, surfaceHabitable) {
  if (chauffageType === SKeys.TypesChauffage.GAZ_PLANCHER_CHAUFFANT || chauffageType === SKeys.TypesChauffage.PAC_PLANCHER_CHAUFFANT) {
    const prixPC = VideSanitaireParams.PRIX_PLANCHER_CHAUFFANT;
    targetObj.videSanitaire.ttlPrixVideSanitaire += validFloat(prixPC) * validFloat(surfaceHabitable);
  }
}

export async function addEnduitVS(targetObj, niveauM1Type, facadeVS, pignonVS, decrocheVS) {
  let coeffM1Value = 0;
  if (niveauM1Type) {
    if (niveauM1Type === SKeys.RangsVideSanitaire.SOUS_SOL) {
      coeffM1Value = VideSanitaireParams.NiveauM1Coeff.COEFF_NIVEAUM1_SOUS_SOL;
    }
    else if (niveauM1Type === SKeys.RangsVideSanitaire.TROIS_RANGS) {
      coeffM1Value = VideSanitaireParams.NiveauM1Coeff.COEFF_NIVEAUM1_3_RANGS;
    }
    else if (niveauM1Type === SKeys.RangsVideSanitaire.QUATRE_RANGS) {
      coeffM1Value = VideSanitaireParams.NiveauM1Coeff.COEFF_NIVEAUM1_4_RANGS;
    }
    else if (niveauM1Type === SKeys.RangsVideSanitaire.CINQ_RANGS) {
      coeffM1Value = VideSanitaireParams.NiveauM1Coeff.COEFF_NIVEAUM1_5_RANGS;
    }
  }
  const prixEnduit = VideSanitaireParams.PRIX_ENDUIT_VS;
  targetObj.videSanitaire.ttlPrixVideSanitaire += validFloat(prixEnduit) * validFloat(coeffM1Value) * 2 * (validFloat(facadeVS) + validFloat(pignonVS) + validFloat(decrocheVS));
}

export function addBetonPlancher(targetObj, plancherBeton) {
  targetObj.videSanitaire.ttlPrixVideSanitaire += validFloat(VideSanitaireParams.PRIX_BETON_PLANCHER) * validFloat(plancherBeton);
}

export function computeParpaingsSupp(targetObj, fondation, empriseSol) {
  targetObj.videSanitaire.ligneParpaingsSupp = (validFloat(VideSanitaireParams.PRIX_PARPAINGS_SUPP) * 0.25 * validFloat(fondation)) + (1.75 * validFloat(empriseSol));
}