import Vue from 'vue'
import Vuex from 'vuex'

import VueJwtDecode from "vue-jwt-decode"
import { queryLogin } from '../api/queries'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    authData: {
      token: "",
      refreshToken: "",
      tokenExp: "",
      userId: "",
      userName: "",
      isAdmin: "",
    },
    logged: ""
  },
  mutations: {
    saveTokenData(state, data) {
   
      localStorage.setItem("access_token", data.token);
      localStorage.setItem("refresh_token", data.refresh);
   
      const jwtDecodedValue = VueJwtDecode.decode(data.token);
      const newTokenData = {
        token: data.token,
        refreshToken: data.refresh,
        tokenExp: jwtDecodedValue.exp,
        userId: jwtDecodedValue.id,
        userName: jwtDecodedValue.username,
        isAdmin: jwtDecodedValue.role,
      };
      state.authData = newTokenData; 
    },
    setLoginStatus(state, value){
       state.logged = value;
    }
  },
  getters: {
    getLoginStatus(state){
      return state.logged;
    },
    getAuthData(state){
      return state.authData;
    },
    isTokenActive(state) {
      if (!state.authData.tokenExp) {
        return false;
      }
      if (Date.now() >= state.authData.tokenExp * 1000) {
        return false;
      }
      return true;
    }
  },
  actions: {
    async login({commit}, payload) {

      let response = await queryLogin(payload);
      
      //change backend for only response.data = response.data.token
      if (response && response.data) {
        commit("saveTokenData", response.data);
        commit("setLoginStatus", true);
      } else {
        commit("setLoginStatus", false);
      }
    }
  },
  modules: {
  }
})
