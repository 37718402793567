<template>
  <div class="recapitulatif single-view">
    <div class="view-header flex-cnt-row align-center">
      <div class="flx1 flex-cnt-row align-center justify-start">
        <button class="title-btn" v-on:click="editDocument(clientId)">
          &lt; Modifier
        </button>
      </div>
      <div class="flx1 flex-cnt-row flex-center">
        <h2>DESCRPTIF MAISON</h2>
      </div>
      <div class="flx1 flex-cnt-row align-center justify-end">
        <button class="title-btn" v-on:click="generateDevis" :disabled="!authGeneration">
          Générer le devis >
        </button>
      </div>
    </div>
    <div class="view-content flex-cnt-col align-center">
      <DetailTable 
        v-bind:title="'Client'"
        v-bind:sForm="gForm.clients"
        v-bind:inputs="inputsClient"
      />
      <DetailTable 
        v-bind:title="'Construction'"
        v-bind:sForm="gForm.constructions"
        v-bind:inputs="inputsConstruction"
      />
      <DetailTable 
        v-bind:title="'Pieces'"
        v-bind:sForms="gForm.pieces"
        v-bind:inputs="inputsPiece"
      />
      <DetailTable 
        v-bind:title="'Vide sanitaire'"
        v-bind:sForm="gForm.videSanitaires"
        v-bind:inputs="inputsVideSanitaire"
      />
      <DetailTable 
        v-bind:title="'RDC'"
        v-bind:sForm="gForm.detailRdcs"
        v-bind:inputs="inputsDetailRdc"
      />
      <DetailTable 
        v-bind:title="'Combles'"
        v-bind:sForm="gForm.combles"
        v-bind:inputs="inputsCombles"
      />
      <DetailTable 
        v-bind:title="'Toit'"
        v-bind:sForm="gForm.toitRdcs"
        v-bind:inputs="inputsToitRdc"
      />
      <DetailTable 
        v-bind:title="'Orientation'"
        v-bind:sForm="gForm.orientations"
        v-bind:inputs="inputsOrientation"
      />
      <DetailTable 
        v-bind:title="'Menuiserie'"
        v-bind:sForm="gForm.menuiseries"
        v-bind:inputs="inputsMenuiserie"
      />
      <DetailTable 
        v-bind:title="'Portes'"
        v-bind:sForm="gForm.portes"
        v-bind:inputs="inputsPortes"
      />
      <DetailTable 
        v-bind:title="'Ouvertures'"
        v-bind:sForms="gForm.fenetres"
        v-bind:inputs="inputsFenetre"
      />
      <DetailTable 
        v-bind:title="'Ouvertures spéciales'"
        v-bind:sForms="gForm.fenetreSpeciales"
        v-bind:inputs="inputsFenetreSpeciale"
      />
      <DetailTable 
        v-bind:title="'Placo'"
        v-bind:sForm="gForm.placos"
        v-bind:inputs="inputsPlaco"
      />
      <DetailTable 
        v-bind:title="'Electricité'"
        v-bind:sForm="gForm.electricites"
        v-bind:inputs="inputsElectricite"
      />
      <DetailTable 
        v-bind:title="'Carrelage'"
        v-bind:sForm="gForm.revetementsSols"
        v-bind:inputs="inputsCarrelage"
      />
      <DetailTable 
        v-bind:title="'Chape'"
        v-bind:sForm="gForm.chapes"
        v-bind:inputs="inputsChape"
      />
      <DetailTable 
        v-bind:title="'Parquet'"
        v-bind:sForm="gForm.parquets"
        v-bind:inputs="inputsParquet"
      />
      <DetailTable 
        v-bind:title="'Sanitaires'"
        v-bind:sForms="gForm.sanitaires"
        v-bind:inputs="inputsSanitaires"
      />
      <DetailTable 
        v-bind:title="'Chauffage'"
        v-bind:sForm="gForm.chauffages"
        v-bind:inputs="inputsChauffage"
      />
      <DetailTable 
        v-bind:title="'Peinture'"
        v-bind:sForm="gForm.peintures"
        v-bind:inputs="inputsPeinture"
      />
      <DetailTable 
        v-bind:title="'Revêtement façade'"
        v-bind:sForm="gForm.revetementFacades"
        v-bind:inputs="inputsRevetementFacade"
      />
      <DetailTable 
        v-bind:title="'Ajustement'"
        v-bind:sForm="gForm.ajustements"
        v-bind:inputs="inputsAjustement"
      />
    </div>
  </div>
</template>

<script>
import { queryGet } from "../api/queries"
import DetailTable from '../components/forms/DetailTable.vue'
import { 
  InputsClient, 
  InputsConstruction, 
  InputsVideSanitaire, 
  InputsDetailRdc, 
  InputsCombles, 
  InputsToitRdc, 
  InputsOrientation, 
  InputsMenuiserie,
  InputsPortes,
  InputsFenetre,
  InputsFenetreSpeciale,
  InputsPlaco,
  InputsElectricite,
  InputsCarrelage,
  InputsChape,
  InputsParquet,
  InputsChauffage,
  InputsPeinture,
  InputsRevetementFacade,
  InputsPiece,
  InputsSanitaires,
  InputsAjustement
} from "../param/inputList";

import { SKeys } from '../param/selectKeys';

export default {
  components: {
    DetailTable
  },
  data: function () {
    return {
      clientId: null,
      gForm: {},
      //
      inputsClient: InputsClient ,
      inputsConstruction: InputsConstruction,
      inputsPiece: InputsPiece,
      inputsVideSanitaire: InputsVideSanitaire ,
      inputsDetailRdc: InputsDetailRdc,
      inputsCombles: InputsCombles,
      inputsToitRdc: InputsToitRdc ,
      inputsOrientation: InputsOrientation,
      inputsMenuiserie: InputsMenuiserie,
      inputsPortes: InputsPortes,
      inputsFenetre: InputsFenetre,
      inputsFenetreSpeciale: InputsFenetreSpeciale,
      inputsPlaco: InputsPlaco,
      inputsElectricite: InputsElectricite,
      inputsCarrelage: InputsCarrelage ,
      inputsChape: InputsChape,
      inputsParquet: InputsParquet,
      inputsChauffage: InputsChauffage ,
      inputsPeinture: InputsPeinture,
      inputsRevetementFacade: InputsRevetementFacade,
      inputsSanitaires: InputsSanitaires,
      inputsAjustement: InputsAjustement
    }
  },
  watch: {
    clientId: async function (val) {
      this.gForm = {};
      if (Number.parseInt(val) > 0) {
        let response = await queryGet('descriptif', this.clientId);
        if (response) {
          this.gForm = response;
        }
      }
    }
  },
  computed: {
    authGeneration() {
      if (this.gForm.clients) {
        return ( Number.parseInt(this.gForm.clients.surfaceVitreeNord) && Number.parseInt(this.gForm.clients.surfaceVitreeNord) < SKeys.AutorisationSurfaceVitree.BLOQUE ) ? true : false;
      }
      return false;
    }
  },
  methods: {
    generateDevis() {
      if (this.authGeneration) {
        this.$router.push({name: 'Generation', params: {clientId: this.clientId}});
      }
    },
    editDocument(clientId) {
      this.$router.push({name: 'Descriptif', params: {clientId: clientId, isEditMode: true}});
    }  
  },
  mounted() {
    let clientId = this.$route.params.clientId;
    if (Number.parseInt(clientId) && Number.parseInt(clientId) > 0) {
      this.clientId = clientId;
    }
    else {
      this.clientId = null;
      this.$router.push({name: 'Documents'});
    }
  }
}
</script>

<style scoped>
.recapitulatif {
  padding: 20px;
  overflow: auto;
}
</style>