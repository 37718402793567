import { SKeys } from "../param/selectKeys";

import { validFloat } from "./helpers"

export const RevetementExtParams = Object.freeze({
  PRIX_ENDUIT:    19.5,
  PRIX_ENTOILAGE: 0,
  PRIX_BARDAGE:   40.1
});

export async function computeParamsRevetementExt(targetObj, enduit) {
  //ENDUIT
  if (enduit === SKeys.RevetementsFacade.ENDUIT) {
    targetObj.revetementExt.enduit = validFloat(targetObj.rdc.structure) + validFloat(targetObj.combles.encuvement) + validFloat(targetObj.pignonRdc.structure) + validFloat(targetObj.pignonRdc.structureSpe);
    // targetObj.revetementExt.enduit = parseFloat(targetObj.revetementExt.enduit.toFixed(2));
  }
}

export function addEnduit(targetObj) {
  targetObj.revetementExt.ttlPrixRevetementExt += validFloat(RevetementExtParams.PRIX_ENDUIT) * validFloat(targetObj.revetementExt.enduit);
}

export function addEntoilage(targetObj) {
  if (targetObj.revetementExt.enduit) {
    targetObj.revetementExt.ttlPrixRevetementExt += validFloat(RevetementExtParams.PRIX_ENTOILAGE);
  }
}

export function addBardage(targetObj, enduit) {
  if (enduit === SKeys.RevetementsFacade.BARDAGE) {
    targetObj.revetementExt.ttlPrixRevetementExt += validFloat(RevetementExtParams.PRIX_BARDAGE) * validFloat(targetObj.rdc.structure);
  }
}