<template>
  <div class="image-table flx1 flex-cnt-col align-center justify-start">
    <table>
      <thead>
        <tr>
          <th>
            Label
          </th>
          <!--
          <th>
            Page
          </th>
          -->
          <th>
            Image
          </th>
          <th>
            Upload
          </th>
        </tr>
      </thead>
      <tbody>
        <ImageRow 
          v-for="image in listImages" 
          :key="image.index"
          v-bind:index="image.index"
          v-bind:url="image.key"
          v-bind:label="image.label"
          v-bind:page="image.page"
        />
      </tbody>
    </table>
  </div>
</template>

<script>
import ImageRow from "./ImageRow.vue"

export default {
  components: {
    ImageRow
  },
  data: function () {
    return {
      listImages: null
    }
  },
  props: {
    initImages: {
      type: Array,
      required: false,
      default: null
    }
  },
  watch: {
    initImages: function () {
      this.formatlistImages();
    }
  },
  methods: {
    formatlistImages() {
      this.listImages = [];
      let indexCnt = 0;
      this.initImages.forEach(image => {
        this.listImages.push({
          index: indexCnt++, 
          key: image.key, 
          label: image.label,
          page: image.page,
          data: null
        });
      });
    }
  },
  mounted() {
    this.formatlistImages();
  }
}
</script>

<style scoped>
.image-table {
  margin-top: 20px;
}

.text-key {
  margin-bottom: 20px;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #c6d2d9;
  background: #fff;
  text-align: center;
  text-transform: uppercase;
}

table {
  border-collapse: collapse;
  border: 1px solid #c6d2d9;
  margin-bottom: 40px;
}

thead {
  min-height: 30px;
}

th {
  min-width: 150px;
  padding: 5px 10px;
  box-sizing: border-box;
  border: 1px solid #c6d2d9;
  background: rgb(228, 234, 238);
  font-size: 14px;
  font-family: "OpenSans400";
  text-transform: uppercase;
}

tr:nth-child(2n+1) {
  background: #fff;
}
tr:nth-child(2n) {
  background: transparent;
}

td {
  padding: 10px 10px;
  font-size: 13px;
  font-family: "OpenSans400";
  border-left: 1px solid #c6d2d9;
}
</style>