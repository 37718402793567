<template>
  <div class="form-multiple flex-cnt-col align-center">
    <div class="title-cnt">
      <h4>
        {{formIndex}}. {{formTitle.toUpperCase()}}
      </h4>
    </div>
    <div class="indic-section flex-cnt-row flex-center" v-if="queryTable === 'pieces'">
      <div class="indic-label">
        SURFACE HABITABLE : 
      </div>
      <div class="indic-value">
        {{indicData ? indicData : '0'}} m²
      </div>
    </div>
    <div class="indic-section flex-cnt-row flex-center" v-if="queryTable === 'fenetres' || queryTable === 'fenetreSpeciales'">
      <div class="indic-label">
        Surface vitrée : 
      </div>
      <div class="indic-value validate-color" v-bind:class="{'valid': parseFloat(indicData) >= 0}">
        {{indicData ? indicData : '0'}}
      </div>
    </div>
    <div class="input-cnt flex-cnt-col justify-start">
      <div
        v-for="form in qListForm"
        :key="form.index"
        class="input-row flex-cnt-row align-center"
      >
        <div class="flex-cnt-row align-center justify-end">
          <button 
            class="input-btn delete-btn"
            v-on:click="deleteForm(form.index)" 
          >
            ×
          </button>
        </div>
        <div class="flex-cnt-col">
          <FormInput 
            v-for="input in listeInputs"
            :key="input.index"
            
            v-bind:isEditMode="isEditMode"
            v-bind:queryTable="queryTable"
            v-bind:queryId="getQueryId(form.index)"
            v-bind:initialValue="form[input.queryKey]"
            v-bind:inputTag="input.inputTag"
            v-bind:label="input.label" 
            v-bind:queryKey="input.queryKey"
            v-bind:queryIndex="form.index"
            v-bind:inputType="input.inputType"
            v-bind:validType="input.validType"
            v-bind:placeholder="input.placeholder"
            v-bind:inputUnit="input.inputUnit"
            v-bind:showLabel="input.showLabel"
            v-bind:spaceBefore="input.spaceBefore"
            v-bind:options="getOptions(form.index, input.hasPreOptionKey, input.options)"
            v-bind:hasPreOptionKey="input.hasPreOptionKey"
            v-bind:hasPreOptionValue="getPreOptionValue(form.index, input.hasPreOptionKey)"
            v-bind:isPreOptionTable="input.isPreOptionTable"
            v-bind:isPreOptionKeys="input.isPreOptionKeys"
            v-bind:reset="getPreOptionReset(input.hasPreOptionKey, input.queryKey, form.index)"
            v-bind:defaultValue="input.defaultValue"

            v-on:updateValue="updateValue"
            v-on:updatePreOption="updatePreOption"
          />
        </div>
        <div></div>
      </div>
    </div>
    <div class="add-btn">
      <button
      class="addel-btn"
        v-on:click="addForm"
      >
        Ajouter un élément
      </button>
    </div>
  </div>
</template>

<script>
//components
import FormInput from './FormInput.vue'
//functions
import { queryGetByClientId, queryPost, queryDelete } from '../../api/queries'
//parameters & constants
import { InputTypes } from "../../api/validation";

export default {
  components: {
    FormInput
  },
  data: function () {
    return {
      listQueryId: null,
      qListForm: [],
      ValidTypes: InputTypes
    }
  },
  props: {
    formIndex: {
      type: Number,
      required: true
    },
    activeIndex: {
      type: Number,
      required: true
    },
    isEditMode: {
      type: Boolean, 
      required: false, 
      default: false
    },
    formTitle: {
      type: String,
      required: false
    },
    clientId: {
      type: Number,
      required: true
    },
    queryTable: {
      type: String,
      required: true
    },
    listeInputs: {
      type: Array,
      required: true
    },
    hasPreOptionForm: {
      type: Array,
      required: false,
      default: null
    },
    resetFlag: {
      type: Number,
      required: false,
      default: 0
    },
    resetKeys: {
      type: Array,
      required: false,
      default: null
    },
    resetIndex: {
      type: Number,
      required: false,
      default: null
    },
    //
    indicData: [String, Number]
  },
  watch: {
    clientId: async function () {
      if (this.isEditMode) {
        this.qListForm = [];
        this.listQueryId = [];
        let response = await queryGetByClientId(this.queryTable, this.clientId);
        if (response && response.length) {
          this.qListForm = response;
          this.qListForm.forEach(form => {
            this.listQueryId.push(form.id);
          });
          this.$emit('updateForm', this.queryTable, this.qListForm);
        }
      }
    },
    activeIndex: function () {
      if (this.formIndex === this.activeIndex) {
        this.enableNext();
      }
    },
    indicData: function () {
      if (this.formIndex === this.activeIndex) {
        this.enableNext();
      }
    }
  },
  methods: {
    async addForm() {
      if (this.isEditMode) {
        let response = await queryPost(this.queryTable, {index: this.qListForm.length, clientId: this.clientId});
        if (Number.parseInt(response)) {
          this.listQueryId.push(response);
          this.qListForm.push({index: this.qListForm.length});
          this.$emit('updateForm', this.queryTable, this.qListForm);
        }
      }
      else {
        this.qListForm.push({index: this.qListForm.length});
        this.$emit('updateForm', this.queryTable, this.qListForm);
      }
    },
    async deleteForm(index) {
      if (confirm("Voulez-vous supprimer cet élément ?")) {
        if (this.isEditMode) {
          let response = await queryDelete(this.queryTable, this.listQueryId[index]);
          if (Number.parseInt(response)) {
            this.listQueryId.splice(index, 1);
            this.qListForm.splice(index, 1);
            this.qListForm.forEach(form => {
              if (form.index > index) {
                form.index--;
              }
            });
            this.$emit('updateForm', this.queryTable, this.qListForm);
          }
        }
        else {
          this.qListForm.splice(index, 1);
          this.qListForm.forEach(form => {
            if (form.index > index) {
              form.index--;
            }
          });
          this.$emit('updateForm', this.queryTable, this.qListForm);
        }
      }
    },
    getQueryId(index) {
      return this.listQueryId ? this.listQueryId[index] : null;
    },
    updateValue(key, value, index) {
      if (value === '') {
        value = null;
      }
      this.$set(this.qListForm[index], key, value);
      this.$emit('updateForm', this.queryTable, this.qListForm);
    },
    updatePreOption(table, keys, index) {
      this.$emit('updatePreOptionMultiple', table, keys, index);
    },
    getPreOptionValue(formIndex, key) {
      if (this.hasPreOptionForm) {
        return this.hasPreOptionForm[formIndex] ? this.hasPreOptionForm[formIndex][key] : null;
      }
      else {
        return null;
      }
    },
    getOptions(formIndex, key, allOptions) {
      if (key) {
        return allOptions[this.getPreOptionValue(formIndex, key)];
      }
      else {
        return allOptions;
      }
    },
    getPreOptionReset(hasPreOption, inputKey, formIndex) {
      return hasPreOption && this.resetKeys && this.resetKeys.includes(inputKey) && formIndex === this.resetIndex ? this.resetFlag : null;
    },
    enableNext () {
      if (this.formIndex === this.activeIndex) {
        if (this.queryTable === 'fenetres') {
          if (parseFloat(this.indicData) >= 0) {
            this.$emit('enableNext', true);
          }
          else {
            this.$emit('enableNext', false);
          }
        }
        else {
          this.$emit('enableNext', true);
        }
      }
    }
  }
}
</script>

<style scoped>
.form-multiple {
  flex: 1;
  width: 100%;
  min-height: fit-content;
}

.title-cnt {
  margin-bottom: 10px;
}

h4 {
  text-align: center;
}

.indic-section {
  font-size: 13px;
  text-align: center;
  background: rgb(228, 234, 238);
  padding: 5px 10px;
}
.indic-value.validate-color {
  border: 1px solid #64696b;
  background: rgb(245, 197, 197);
}
.indic-value.validate-color.valid {
  border: 1px solid #64696b;
  background: rgb(177, 226, 184);
}

.indic-value {
  margin-left: 5px;
  padding: 3px 10px;
  border: 1px solid #c6d2d9;
  background: #ffffff;
}

.input-cnt {
  flex: 1;
  width: 100%;
  border-bottom: 1px solid #c6d2d9;
  border-top: 1px solid #c6d2d9;
  padding-top: 20px;
  overflow: auto;
  margin-top: 10px;
}

.input-row {
  width: 100%;
  margin-bottom: 20px;
}

.input-row > div {
  flex: 1;
}

.add-btn {
  margin-top: 20px;
  padding: 0px 50px;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
}
</style>