<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import axios from 'axios';


export default {
  mounted() {
    axios.interceptors.response.use(undefined, function (err) {
      return new Promise(function () {
        if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
          localStorage.removeItem("user");
          localStorage.removeItem("token");
          localStorage.removeItem("isAdmin");
          delete axios.defaults.headers.common['Authorization']
          this.console('logged out : token expired');
        }
        throw err;
      });
    });
  },
  created() {
    document.title = "Maisons à Vivre | Calculateur";
  }
}
</script>


<style>
@import './assets/styles/global-stylesheet.css';

@font-face {
  font-family: "OpenSans400";
  src: local("OpenSans400"),
   url(./assets/fonts/Open_Sans/OpenSans-Regular.ttf) format("truetype");
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
