<template>
  <div id="the-nav-menu" class="flex-cnt-row align-center justify-between">
    <!-- <div class="route-cnt flex-cnt-row justify-center">
        <router-link to="/">Accueil</router-link>
    </div> -->
    <div class="route-cnt flex-cnt-row justify-center">
      <router-link to="/">Espace Perso</router-link>
    </div>
    <div class="route-cnt flex-cnt-row justify-center" v-if="isAdmin">
      <router-link to="/parameters">Paramètres</router-link>
    </div>
    <div class="route-cnt flex-cnt-row justify-center" v-if="isAdmin">
      <router-link to="/textes">Textes</router-link>
    </div>
    <div class="route-cnt flex-cnt-row justify-center" v-if="isAdmin">
      <router-link to="/images">Images</router-link>
    </div>
    <div class="route-cnt flex-cnt-row justify-center" v-if="isAdmin">
      <router-link to="/utilisateurs">Utilisateurs</router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
    }
  },
  computed: {
    isAdmin: function () {
      return this.getAuthData.isAdmin === 1 || this.getAuthData.isAdmin === 2;
    },
    ...mapGetters(['getAuthData'])
  }
}
</script>

<style scoped>
#the-nav-menu {
  /* COLORS */
  --link-color-dark: #5c6164;
}

.route-cnt {
  width: 160px;
}

a {
  text-align: center;
  margin: 0px 50px;
  position: relative;
  display: inline-block;
  color: var(--link-color-dark);
  font-family: "OpenSans400";
  font-size: 16px;
  text-decoration: none;
  white-space: nowrap;
}

a::before {
  content: '';
  position: absolute;
  height: 0.5px;
  width: 0;
  bottom: 0;
  left: 0;
  background-color: var(--link-color-dark);
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}

a:hover::before {
  width: 100%;
  visibility: visible;
  transition: all 0.35s ease-in-out;
}

.router-link-exact-active::before {
  content: '';
  position: absolute;
  height: 1px;
  width: 100%;
  bottom: 0;
  left: 0;
  visibility: visible;
  background-color: var(--link-color-dark);
}

@media only screen and (max-width: 1000px) {
  .route-cnt {
    width: 150px;
  }
}

@media only screen and (max-width: 768px) {
  .route-cnt {
    width: 120px;
  }
}

@media only screen and (max-width: 640px) {
  a {
    font-size: 14px;
  }
}

@media only screen and (max-width: 520px) {
  .route-cnt {
    width: 90px;
  }
}
</style>