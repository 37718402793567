// export const urls = Object.freeze({
//   baseApi:     "http://127.0.0.1:3000/api",
//   baseUsers:   "http://127.0.0.1:3000/auth",
//   baseUploads: "http://127.0.0.1:3000/upload",
// });

export const urls = Object.freeze({
  baseApi:     "https://api.mav-calculateur.fr/api",
  baseUsers:   "https://api.mav-calculateur.fr/auth",
  baseUploads: "https://api.mav-calculateur.fr/upload",
});