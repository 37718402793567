<template>
  <tr class="image-row">
    <td>
      {{label}}
    </td>
    <!--
    <td>
      {{page}}
    </td>
    -->
    <td class="image-cell">
      <img :src="imgUrl" v-if="imgUrl">
    </td>
    <td>
      <input type="file" v-on:change="uploadImage" accept=".jpg,.jpeg,.png">
    </td>
  </tr>
</template>

<script>
import { queryUploadImage } from '../../api/queries';
import { urls } from '../../api/serverInfo';

export default {
  data: function () {
    return {
      imgUrl: `${urls.baseUploads}/image/${this.url}.jpg`
    }
  },
  props: {
    index: {
      type: Number,
      required: false,
      default: null
    },
    url: {
      type: String,
      required: false,
      default: null
    },
    label: {
      type: String,
      required: false,
      default: null
    },
    page: {
      type: Number,
      required: false,
      default: null
    },
  },
  watch: {
    url: function (val) {
      if (val) {
        this.imgUrl = `${urls.baseUploads}/image/${val}.jpg`
      }
    }
  },
  methods: {
    async uploadImage(e) {
      const file = e.target.files[0];      
      const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
      if (!allowedTypes.includes(file.type)) {
        alert("Seulement les images sont acceptées !");
      }
      if (file.size > 500000) {
        alert("Le fichier est trop volumineux, la taille maximum acceptée est 500kB");
      }
      const formData = new FormData();
      formData.append('file', file, `${this.url}.jpg`);
      let response = await queryUploadImage(formData);
      if (response && response.file && response.file.filename) {
        if (response.file.filename === `${this.url}.jpg`) {
          alert("L'image a été sauvegardée dans la base de données.");
          this.$router.go();
        }
      }
    },
  }
}
</script>

<style scoped>
.image-cell {
  text-align: center;
}

img {
  max-width: 200px;
  max-height: 150px;
}
</style>