import { SKeys } from "./selectKeys"

export const BlocPorteTypes = Object.freeze([
  {index: 0, key: SKeys.ModelesBlocPorte._73       , title: '73'        },
  {index: 1, key: SKeys.ModelesBlocPorte._83       , title: '83'        },
  {index: 2, key: SKeys.ModelesBlocPorte._73_ISO   , title: '73 ISO'    },
  {index: 3, key: SKeys.ModelesBlocPorte._83_ISO   , title: '83 ISO'    },
  {index: 4, key: SKeys.ModelesBlocPorte.COULISSANT, title: 'COULISSANT'},
]);

export const ComblesTypes = Object.freeze([
  {index: 0, key: SKeys.TypesCombles.PERDUS      , title: 'Combles perdus'      },
  {index: 1, key: SKeys.TypesCombles.AMENAGEABLES, title: 'Combles aménageables'}
]);

export const MenuiserieMateriaux = Object.freeze([
  {index: 0, key: SKeys.MateriauxMenuiserie.PVC  , title: 'PVC'  },
  {index: 1, key: SKeys.MateriauxMenuiserie.ALU  , title: 'ALU'  },
  {index: 2, key: SKeys.MateriauxMenuiserie.PLAXE, title: 'PLAXE'},
]);

export const MenuiserieMateriauxAluOb = Object.freeze([
  {index: 0, key: SKeys.MateriauxMenuiserie.ALU  , title: 'ALU'  },
]);

export const MenuiserieMateriauxPvcAlu = Object.freeze([
  {index: 0, key: SKeys.MateriauxMenuiserie.PVC  , title: 'PVC'  },
  {index: 1, key: SKeys.MateriauxMenuiserie.ALU  , title: 'ALU'  },
]);

export const MenuiserieMateriauxAlu = Object.freeze([
  {index: 0, key: SKeys.MateriauxMenuiserie.ALU  , title: 'ALU'  }
]);

export const PorteEntreeMateriaux = Object.freeze([
  {index: 0, key: SKeys.MateriauxMenuiserie.ALU  , title: 'ALU'  }
]);

export const PorteServiceMateriaux = Object.freeze([
  {index: 0, key: SKeys.MateriauxMenuiserie.ALU  , title: 'ALU'  },
  {index: 1, key: SKeys.MateriauxMenuiserie.PLAXE, title: 'PLAXE'},
]);

export const PorteGarageTailles = Object.freeze([
  {index: 0, key: SKeys.TaillesPorteGarage._240, title: '240 x 200'  },
  {index: 1, key: SKeys.TaillesPorteGarage._300, title: '300 x 200'},
]);
