export default Object.freeze([
  //SERVICES
  {
    key: "ASSURANCES",
    label: "ASSURANCES",
    initValue: "Garantie de remboursement d’acompte, décennale, responsabilité professionnelle, DOMMAGE-OUVRAGE, non-révision de prix convenu, livraison à délais convenus, conforme à la loi du 19 Décembre 1990.",  
  },
  {
    key: "TERRAIN",
    label: "TERRAIN",
    initValue: "Enquête de terrain afin de déterminer la nature du sol et la nature des fondations à mettre en œuvre pour le projet.",  
  },
  {
    key: "ASSISTANCE",
    label: "ASSISTANCE",
    initValue: "Assistance lors des rendez-vous chez le notaire.",
  },
  {
    key: "FINANCEMENT",
    label: "FINANCEMENT",
    initValue: "Recherche et montage du dossier de financement auprès de nos partenaires bancaires.",
  },
  {
    key: "ETUDE_THERMIQUE",
    label: "ETUDE THERMIQUE",
    initValue: "Etude de faisabilité RT2012, détermination des consommations et optimisation du projet.",
  },
  {
    key: "PC",
    label: "PC",
    initValue: "Elaboration du dossier de Permis de Construire, dépôt en mairie et pose d’un panneau d’information sur le terrain.",
  },
  {
    key: "CONTROLE_QUALITE",
    label: "CONTROLE QUALITE",
    initValue: "Contrôle de l’étanchéité à l’air et de la conformité des installations par un contrôleur indépendant.",
  },
  {
    key: "VISITE_CHANTIER",
    label: "VISITE CHANTIER",
    initValue: "Rendez-vous de compte-rendu d’avancement des travaux à chaque stade de la construction.",
  },
  {
    key: "SAV",
    label: "SAV",
    initValue: "Garantie structurelle dommage-ouvrage de 10 ans, garantie de bon fonctionnement de l’installation de 2 ans pièces et main-d’œuvre.",
  },
  //GROS OEUVRES
  {
    key: "TERRASSEMENT",
    label: "TERRASSEMENT",
    initValue: "Décaissement de l’emprise du projet et terrassement des fondations en HORS GEL (80 cm de profondeur au sol naturel), dimensions 50 cm de large x 40 cm de profondeur, ferraillage ELS 15x35 de Ø 8mm ou ELS 20x40 Ø 10mm (suivant étude). Béton prêt à l'emploi avec dosage de ciment à 350 kg/m3 NF EN 206-1 Classe C25/30.",
  },
  //
  {
    key: "VIDE_SANITAIRE_3_RANGS",
    label: "VS 3 RANGS",
    initValue: "3 rangs de parpaings de 25 cm de hauteur, 20 cm de large, 50 cm de longueur, hourdé au mortier ciment, soit 75 cm de hauteur de vide sanitaire.",
  },
  {
    key: "VIDE_SANITAIRE_4_RANGS",
    label: "VS 4 RANGS",
    initValue: "4 rangs de parpaings de 25 cm de hauteur, 20 cm de large, 50 cm de longueur, hourdé au mortier ciment, soit 100 cm de hauteur de vide sanitaire.",
  },
  {
    key: "VIDE_SANITAIRE_5_RANGS",
    label: "VS 5 RANGS",
    initValue: "5 rangs de parpaings de 25 cm de hauteur, 20 cm de large, 50 cm de longueur, hourdé au mortier ciment, soit 125 cm de hauteur de vide sanitaire.",
  },
  //
  {
    key: "SOUS_SOL",
    label: "VS SOUS-SOL",
    initValue: "Parpaings de 25 cm de haut, 20 cm de large, 50 cm de long, hauteur brut du mur de 2.50 m, hourdé au mortier ciment, coefficient de résistance R = 0.20, enduit d’étanchéité en ciment hydrofuge, et application d’une couche de goudron pâteux.",
  },
  {
    key: "PLANCHER_SOUS_SOL",
    label: "PLANCHER SOUS-SOL",
    initValue: "Lit de sable de niveau, passage des fourreaux techniques, film polyane anti-humidité, béton prêt à l’emploi, finition talochée. Béton prêt à l'emploi avec dosage de ciment à 350 kg/m3 NF EN 206-1 Classe C25/30.",
  },
  //
  {
    key: "PLANCHER_RDC_SANS_GARAGE_TITLE",
    label: "PLANCHER RDC - SANS_GARAGE / titre",
    initValue: "PLANCHER RDC :",
  },
  {
    key: "PLANCHER_RDC_SANS_GARAGE_TEXT",
    label: "PLANCHER RDC - SANS GARAGE / texte",
    initValue: "Poutrelles béton, hourdis polystyrènes, rupteurs de ponts thermiques périphériques et mur de refend, isolation selon étude thermique. Béton prêt à l'emploi avec dosage de ciment à 350 kg/m3 NF EN 206-1 Classe C25/30.",
  },
  {
    key: "PLANCHER_RDC_AVEC_GARAGE_TITLE",
    label: "PLANCHER RDC - AVEC GARAGE / titre",
    initValue: "PLANCHER RDC + GARAGE :",
  },
  {
    key: "PLANCHER_RDC_AVEC_GARAGE_TEXT",
    label: "PLANCHER RDC - AVEC GARAGE / texte",
    initValue: "Poutrelles béton, hourdis polystyrènes, rupteurs de ponts thermiques périphériques et mur de refend, isolation selon étude thermique pour le RDC et poutrelles béton, hourdis non isolant pour le garage. Béton prêt à l'emploi avec dosage de ciment à 350 kg/m3 NF EN 206-1 Classe C25/30.",
  },
  //
  {
    key: "ELEVATION",
    label: "ELEVATION",
    initValue: "Parpaings de 25 cm de hauteur, 20 cm de large, 50 cm de longueur, hauteur brut du mur de 2.65 m, hourdé au mortier ciment, coefficient de résistance R = 0.20.",
  },
  {
    key: "PLANCHER_BETON_ETAGE",
    label: "PLANCHER BETON ETAGE",
    initValue: "Poutrelles béton, hourdis bi-matière Polystyrènes/Bois, rupteurs de ponts thermiques périphériques et mur de refend, isolation selon étude thermique. Béton prêt à l'emploi avec dosage de ciment à 350 kg/m3 NF EN 206-1 Classe C25/30.",
  },
  {
    key: "PLANCHER_BOIS_COMBLES",
    label: "PLANCHER BOIS COMBLES",
    initValue: "Solivage en bois, section selon portée hors pose des panneaux de plancher.",
  },
  {
    key: "PLANCHER_BOIS_ETAGE",
    label: "PLANCHER BOIS ETAGE",
    initValue: "Solivage en bois, section selon portée, contre-lattage en bois à entraxe de 50 cm, bande résiliente en fibre de bois de 1 cm d’épaisseur, pose de panneaux d’OSB 3 HYDROFUGE d’épaisseur 18 mm, vissés au contre-lattage.",
  },
  {
    key: "CHAINAGE_RENFORTS",
    label: "CHAINAGE RENFORTS",
    initValue: "Chaînages périphériques et poteaux raidisseurs en béton armé.",
  },
  {
    key: "SEUILS_APPUIS_BETON",
    label: "SEUILS APPUIS BETON",
    initValue: "En béton moulé couleur TON PIERRE.",
  },
  {
    key: "SEUILS_APPUIS_ALU",
    label: "SEUILS APPUIS ALU",
    initValue: "En ALUMINIUM, intégré au précadre de la menuiserie, coloris selon la menuiserie.",
  },
  {
    key: "SEUILS_APPUIS_BETON_GRIS",
    label: "SEUILS APPUIS BETON GRIS",
    initValue: "Seuil en béton couleur GRIS adapté PMR et Appuis en béton moulé couleur TON PIERRE.",
  },
  {
    key: "LINTEAUX",
    label: "LINTEAUX",
    initValue: "Droits",
  },
  {
    key: "ENDUIT_EXT_MONO",
    label: "ENDUIT EXT MONO",
    initValue: "Monocouche taloché de chez PAREX-LANKO, couleur au choix sur nuancier.",
  },
  {
    key: "ENDUIT_EXT_BI",
    label: "ENDUIT EXT BI",
    initValue: "Bicouche taloché de chez PAREX-LANKO, couleur au choix sur nuancier.",
  },
  {
    key: "TERASSE_BETON_PIN",
    label: "TERASSE BETON PIN",
    initValue: "Sur une structure en PIN classe 4 posée sur lit de calcaire, revêtement final en DOUGLAS rainuré 1 face de section 145 mm de large x 28 mm d’épaisseur, vissé sur la structure avec des vis inox A2.",
  },
  {
    key: "TERASSE_BETON_FONDATIONS",
    label: "TERASSE BETON FONDATIONS",
    initValue: "Sur fondations de même section et hauteur de vide sanitaire que la maison, plancher béton non isolé. (hourdis en BOIS HYDROFUGE) Béton prêt à l'emploi avec dosage de ciment à 350 kg/m3 NF EN 206-1 Classe C25/30. ",
  },
  //TOITURE
  {
    key: "CHARPENTE_PERDUS_RECTANGLE",
    label: "CHARPENTE PERDUS : RECTANGLE",
    initValue: "Fermette industrielle combles perdus.",
  },
  {
    key: "CHARPENTE_PERDUS_L_U",
    label: "CHARPENTE PERDUS : L - U",
    initValue: "Fermette industrielle en L combles perdus.",
  },
  {
    key: "CHARPENTE_PERDUS_V",
    label: "CHARPENTE PERDUS : V",
    initValue: "Fermette industrielle en V combles perdus.",
  },
  {
    key: "CHARPENTE_AMENAGEABLES_RECTANGLE",
    label: "CHARPENTE AMENAGEABLES : RECTANGLE",
    initValue: "Fermette industrielle combles aménageables. Solivage en bois, section selon portée hors pose des panneaux de plancher.",
  },
  {
    key: "CHARPENTE_AMENAGEABLES_L_U",
    label: "CHARPENTE AMENAGEABLES : L - U",
    initValue: "Fermette industrielle en L combles aménageables. Solivage en bois, section selon portée hors pose des panneaux de plancher.",
  },
  {
    key: "CHARPENTE_AMENAGEABLES_V",
    label: "CHARPENTE AMENAGEABLES : V",
    initValue: "Fermette industrielle en V combles aménageables. Solivage en bois, section selon portée hors pose des panneaux de plancher.",
  },
  {
    key: "TOIT_PENTES_2_PENTES",
    label: "TOIT - 2 PENTES",
    initValue: "Toit deux pentes",
  },
  {
    key: "TOIT_INCLINAISON_35",
    label: "INCLINAISON 35°",
    initValue: "à 35°",
  },
  {
    key: "TOIT_INCLINAISON_40",
    label: "INCLINAISON 40°",
    initValue: "à 40°",
  },
  {
    key: "TOIT_INCLINAISON_45",
    label: "INCLINAISON 45°",
    initValue: "à 45°",
  },
  {
    key: "TOIT_AUTRE_ENCUVEMENT",
    label: "TOIT AUTRE - ENCUVEMENT",
    initValue: "ENCUVEMENT EN ml",
  },
  {
    key: "TOIT_AUTRE_COMBLES_AMENAGEABLES",
    label: "TOIT AUTRE - COMBLES AMENAGEABLES",
    initValue: "COMBLES AMENAGEABLES SUR",
  },
  {
    key: "COUVERTURE_DOUBLE_ROMANE_ROUGE",
    label: "DOUBLE ROMANE ROUGE",
    initValue: "TUILE MONIER DOUBLE ROMANE Badiane, aspect 10u/m², Rives et faîtages assorties.",
  },
  {
    key: "COUVERTURE_DOUBLE_ROMANE_ARDOISEE",
    label: "DOUBLE ROMANE ARDOISEE",
    initValue: "TUILE MONIER DOUBLE ROMANE Ardoise, aspect 10u/m², Rives et faîtages assorties.",
  },
  {
    key: "COUVERTURE_PLATE_NOBILEE_ROUGE_ASPECT",
    label: "PLATE NOBILEE ROUGE ASPECT",
    initValue: "TUILE MONIER PLATE NOBILEE Palissandre, aspect 20u/m², Rives et faîtages assorties.",
  },
  {
    key: "COUVERTURE_PLATE_NOBILEE_ARDOISEE_ASPECT",
    label: "PLATE NOBILEE ARDOISEE ASPECT",
    initValue: "TUILE MONIER PLATE NOBILEE Graphite, aspect 20u/m², Rives et faîtages assorties.",
  },
  {
    key: "COUVERTURE_REGENCE_ROUGE",
    label: "REGENCE ROUGE",
    initValue: "TUILE MONIER PLATE TERRE CUITE REGENCE Brun Vieilli, 22u/m², Rives et faîtages assorties.",
  },
  {
    key: "COUVERTURE_REGENCE_ARDOISEE",
    label: "REGENCE ARDOISEE",
    initValue: "TUILE MONIER PLATE TERRE CUITE REGENCE Noir, 22u/m², Rives et faîtages assorties.",
  },
  {
    key: "COUVERTURE_FONTENNELLE_ROUGE",
    label: "FONTENNELLE ROUGE",
    initValue: "TUILE MONIER PLATE TERRE CUITE FONTENELLE Rouge Vielli, 20u/m², Rives et faîtages assorties.",
  },
  {
    key: "COUVERTURE_FONTENNELLE_ARDOISEE",
    label: "FONTENNELLE ARDOISEE",
    initValue: "TUILE MONIER PLATE TERRE CUITE FONTENELLE Anthracite, 20u/m², Rives et faîtages assorties.",
  },
  {
    key: "COUVERTURE_SIGNY_ROUGE",
    label: "SIGNY ROUGE",
    initValue: "TUILE MONIER PLATE TERRE CUITE SIGNY Rouge Vielli, 10u/m², Rives et faîtages assorties.",
  },
  {
    key: "COUVERTURE_SIGNY_ARDOISEE",
    label: "SIGNY ARDOISEE",
    initValue: "TUILE MONIER PLATE TERRE CUITE SIGNY Anthracite Mat, 10u/m², Rives et faîtages assorties.",
  },
  {
    key: "COUVERTURE_PLATE_DE_PAYS",
    label: "PLATE DE PAYS",
    initValue: "TUILE MONIER PLATE TERRE CUITE PLATE DE PAYS Rouge Vielli, 62u/m², Rives et faîtages assorties.",
  },
  {
    key: "COUVERTURE_NATURELLE_DESPAGNE",
    label: "NATURELLE DESPAGNE",
    initValue: "ARDOISE NATURELLE D'ESPAGNE 32x22, Rives et faîtages en ZINC.",
  },
  {
    key: "COUVERTURE_ARTIFICIELLE_DROIT",
    label: "ARTIFICIELLE DROIT",
    initValue: "ARDOISE ARTIFICIELLE Bords droit 40x24, Rives et faîtages en ZINC.",
  },
  {
    key: "COUVERTURE_ARTIFICIELLE_EPAUFRE_40_24",
    label: "ARTIFICIELLE EPAUFRE 40 24",
    initValue: "ARDOISE ARTIFICIELLE Bords épaufré 40x24, Rives et faîtages en ZINC.",
  },
  {
    key: "COUVERTURE_ARTIFICIELLE_EPAUFRE_33_23",
    label: "ARTIFICIELLE EPAUFRE 33 23",
    initValue: "ARDOISE ARTIFICIELLE Bords épaufré 33x23, Rives et faîtages en ZINC.",
  },
  {
    key: "GOUTTIERE_ALU",
    label: "GOUTTIERE - ALU",
    initValue: "ALU couleur au choix",
  },
  {
    key: "GOUTTIERE_ZINC",
    label: "GOUTTIERE - ZINC",
    initValue: "ZINC",
  },
  {
    key: "DEBORD_FACADE",
    label: "DEBORD FACADE",
    initValue: "PVC couleur BLANC",
  },
  {
    key: "VENTILATIONS",
    label: "VENTILATIONS",
    initValue: "1 lanterne de diamètre 160 mm pour la VMC. Chatières de ventilation de la toiture, quantité selon surface et type de couverture.",
  },
  {
    key: "CONDUIT_FUMEE",
    label: "CONDUIT FUMEE",
    initValue: "1 conduit de fumée poly-combustible isolé, sortie de toit crépie et plaque de plâtre anti-feu.",
  },
  //MENUISERIES
  {
    key: "PORTE_ENTREE",
    label: "PORTE ENTREE",
    initValue: "En ACIER ou ALU coloris au choix, sécurité 5 points, épaisseur du panneau de porte de 72 mm, aux normes RT2012, marque FALCO ALLURE, poignée à rosace, choix sur gamme constructeur, dimensions selon plans.",
  },
  {
    key: "PORTE_ENTREE_GAMME_1_PRIX",
    label: "PORTES ENTREE - GAMME 1 PRIX",
    initValue: "INCLUS",
  },
  {
    key: "PORTE_ENTREE_GAMME_2_PRIX",
    label: "PORTES ENTREE - GAMME 2",
    initValue: "OPTION : +330 € / PORTE",
  },
  {
    key: "PORTE_ENTREE_GAMME_2_LISTE",
    label: "PORTES ENTREE - GAMME 2 LISTE",
    initValue: "Modèle BLOOMY, DECO, PEPS, GROOVE, FUJI, NANDA, OURAL, VOSGES, SANDY",
  },
  {
    key: "PORTE_ENTREE_GAMME_3_PRIX",
    label: "PORTES ENTREE - GAMME 3",
    initValue: "OPTION : +1430 € / PORTE",
  },
  {
    key: "PORTE_ENTREE_GAMME_3_LISTE",
    label: "PORTES ENTREE - GAMME 3 LISTE",
    initValue: "Modèle INSPI, ULTIME, ATELIUM, INDIUM, LLUMINIUM, QUADRATUM, YTRIUM, VERRIUM, ACTINIUM, BARYUM, CESIUM, CURIUM, ERBIUM, GALLIUM, HASSIUM, HELIUM, IRIDIUM, KALIUM, LITHIUM, NATRIUM, NOBELIUM, OSNIUM, SELENIUM, THALLIUM, THORIUM",
  },
  {
    key: "PORTE_SERVICE",
    label: "PORTE SERVICE",
    initValue: "En ACIER coloris au choix, sécurité 5 points, épaisseur du panneau de porte de 72 mm, aux normes RT2012, marque FALCO ALLURE, poignée à rosace, choix sur gamme constructeur, quantité et dimensions selon plans.",
  },
  {
    key: "PORTE_SERVICE_GAMME_1_PRIX",
    label: "PORTES SERVICE - GAMME 1",
    initValue: "INCLUS",
  },
  {
    key: "PORTE_SERVICE_GAMME_2_PRIX",
    label: "PORTES SERVICE - GAMME 2",
    initValue: "OPTION : +980 € / PORTE",
  },
  {
    key: "PORTE_SERVICE_GAMME_2_LISTE",
    label: "PORTES SERVICE - GAMME 2 LISTE",
    initValue: "Modèle OPTIMUM, TOTEM",
  },
  {
    key: "BAIES_COULISSANTES_BLANC_PVC_ALU",
    label: "BAIES COULISSANTES - BLANC PVC / ALU",
    initValue: "En ALU BLANC, vitrage 4/18Wena/4, normes RT2012, gamme FALCO CONFORT +, quantité et dimensions selon plans.",
  },
  {
    key: "BAIES_COULISSANTES_GRIS_ALU",
    label: "BAIES COULISSANTES - GRIS ALU",
    initValue: "En ALU GRIS ANTHARCITE, vitrage 4/18Wena/4, normes RT2012, gamme FALCO CONFORT +, quantité et dimensions selon plans.",
  },
  {
    key: "BAIES_COULISSANTES_ABF_ALU",
    label: "BAIES COULISSANTES  - ABF ALU",
    initValue: "En ALU COULEUR COMPATIBLE ABF , vitrage 4/18Wena/4, normes RT2012, gamme FALCO CONFORT +, quantité et dimensions selon plans.",
  },
  {
    key: "BAIES_COULISSANTES_GRIS_PLAXE",
    label: "BAIES COULISSANTES  - GRIS PLAXE",
    initValue: "En PLAXE GRIS ANTHARCITE, vitrage 4/18Wena/4, normes RT2012, gamme FALCO CONFORT +, quantité et dimensions selon plans.",
  },
  {
    key: "BAIES_COULISSANTES_ABF_PLAXE",
    label: "BAIES COULISSANTES - ABF PLAXE",
    initValue: "En PLAXE COMPATIBLE ABF, vitrage 4/18Wena/4, normes RT2012, gamme FALCO CONFORT +, quantité et dimensions selon plans.",
  },
  {
    key: "PORTE_FENETRE_BLANC_PVC",
    label: "PORTE FENETRE - BLANC PVC",
    initValue: "En PVC BLANC, vitrage 4/20Wena/4, normes RT2012, gamme FALCO CONFORT +, quantité et dimensions selon plans.",
  },
  {
    key: "PORTE_FENETRE_GRIS_PLAXE",
    label: "PORTE FENETRE - GRIS PLAXE",
    initValue: "En PVC BLANC intérieur et ALU GRIS ANTHARCITE extérieur, vitrage 4/20Wena/4, normes RT2012, gamme FALCO CONFORT +, quantité et dimensions selon plans.",
  },
  {
    key: "PORTE_FENETRE_ABF_PLAXE",
    label: "PORTE FENETRE - ABF PLAXE",
    initValue: "En PVC BLANC intérieur et ALU COMPATIBLE ABF extérieur, vitrage 4/20Wena/4, normes RT2012, gamme FALCO CONFORT +, quantité et dimensions selon plans.",
  },
  {
    key: "PORTE_FENETRE_BLANC_ALU",
    label: "PORTE FENETRE - BLANC ALU",
    initValue: "En ALU BLANC, vitrage 4/20Wena/4, normes RT2012, gamme FALCO CONFORT +, quantité et dimensions selon plans.",
  },
  {
    key: "PORTE_FENETRE_GRIS_ALU",
    label: "PORTE FENETRE - GRIS ALU",
    initValue: "En ALU GRIS ANTHARCITE, vitrage 4/20Wena/4, normes RT2012, gamme FALCO CONFORT +, quantité et dimensions selon plans.",
  },
  {
    key: "PORTE_FENETRE_ABF_ALU",
    label: "PORTE FENETRE - ABF ALU",
    initValue: "En ALU COMPATIBLE ABF, vitrage 4/20Wena/4, normes RT2012, gamme FALCO CONFORT +, quantité et dimensions selon plans.",
  },
  {
    key: "VOLETS",
    label: "VOLETS",
    initValue: "Volets roulants électriques intégrés dans le mur. Couleur et matière identique à l'extérieur de la menuiserie.",
  },
  {
    key: "RENFORTS_VITRAGE",
    label: "RENFORTS VITRAGE",
    initValue: "Vitrage renforcé 44.2  ou barreaux de sécurité pour les fenêtres de dimensions ≤ 60x75 cm.",
  },
  {
    key: "PORTE_GARAGE_BLANC_902",
    label: "PORTE GARAGE - BLANC 902",
    initValue: "En ACIER LAQUE BLANC, basculante et débordante modèle 902 HORMANN, quantité et dimensions selon plans.",
  },
  {
    key: "PORTE_GARAGE_GRIS_902",
    label: "PORTE GARAGE - GRIS 902",
    initValue: "En ACIER LAQUE GRIS ANTHRACITE, basculante et débordante modèle 902 HORMANN, quantité et dimensions selon plans.",
  },
  {
    key: "PORTE_GARAGE_ABF_902",
    label: "PORTE GARAGE - ABF 902",
    initValue: "En ACIER LAQUE COMPATIBLE ABF, basculante et débordante modèle 902 HORMANN, quantité et dimensions selon plans.",
  },
  {
    key: "PORTE_GARAGE_BLANC_902_MOTORISEE",
    label: "PORTE GARAGE - BLANC 902 MOTORISEE",
    initValue: "En ACIER LAQUE BLANC, basculante et débordante modèle 902 HORMANN avec motorisation et 2 télécommandes, quantité et dimensions selon plans.",
  },
  {
    key: "PORTE_GARAGE_GRIS_902_MOTORISEE",
    label: "PORTE GARAGE - GRIS 902 MOTORISEE",
    initValue: "En ACIER LAQUE GRIS ANTHRACITE, basculante et débordante modèle 902 HORMANN avec motorisation et 2 télécommandes, quantité et dimensions selon plans.",
  },
  {
    key: "PORTE_GARAGE_ABF_902_MOTORISEE",
    label: "PORTE GARAGE - ABF 902 MOTORISEE",
    initValue: "En ACIER LAQUE COMPATIBLE ABF, basculante et débordante modèle 902 HORMANN avec motorisation et 2 télécommandes, quantité et dimensions selon plans.",
  },
  {
    key: "PORTE_GARAGE_BLANC_SECT",
    label: "PORTE GARAGE - BLANC SECT",
    initValue: "En ACIER LAQUE BLANC, ISOLANTE et sectionnelle modèle HORMANN RAINURES M, quantité et dimensions selon plans.",
  },
  {
    key: "PORTE_GARAGE_GRIS_SECT",
    label: "PORTE GARAGE - GRIS SECT",
    initValue: "En ACIER LAQUE GRIS ANTHRACITE, ISOLANTE et sectionnelle modèle HORMANN RAINURES M, quantité et dimensions selon plans.",
  },
  {
    key: "PORTE_GARAGE_ABF_SECT",
    label: "PORTE GARAGE - ABF SECT",
    initValue: "En ACIER LAQUE COMPATIBLE ABF, ISOLANTE et sectionnelle modèle HORMANN RAINURES M, quantité et dimensions selon plans.",
  },
  {
    key: "PORTE_GARAGE_BLANC_SECT_MOTORISEE",
    label: "PORTE GARAGE - BLANC SECT MOTORISEE",
    initValue: "En ACIER LAQUE BLANC, ISOLANTE et sectionnelle modèle HORMANN RAINURES M avec motorisation et 2 télécommandes, quantité et dimensions selon plans.",
  },
  {
    key: "PORTE_GARAGE_GRIS_SECT_MOTORISEE",
    label: "PORTE GARAGE - GRIS SECT MOTORISEE",
    initValue: "En ACIER LAQUE GRIS ANTHRACITE, ISOLANTE et sectionnelle modèle HORMANN RAINURES M avec motorisation et 2 télécommandes, quantité et dimensions selon plans.",
  },
  {
    key: "PORTE_GARAGE_ABF_SECT_MOTORISEE",
    label: "PORTE GARAGE - ABF SECT MOTORISEE",
    initValue: "En ACIER LAQUE COMPATIBLE ABF, ISOLANTE et sectionnelle modèle HORMANN RAINURES M avec motorisation et 2 télécommandes, quantité et dimensions selon plans.",
  },
  {
    key: "ISOLATION_PLAFOND_SOUFFLE",
    label: "ISOLATION PLAFOND SOUFFLE",
    initValue: "Laine de verre soufflée KNAUF, épaisseur 400 mm, R=8.5 (ou préconisation de l'étude thermique), système rail métallique et plaques de plâtre en recouvrement avec bandes, hauteur sous plafond à 2,50 m ± 2 cm. \nHors Garage / Hors Sous-Sol",
  },
  {
    key: "ISOLATION_PLAFOND_POSE",
    label: "ISOLATION PLAFOND POSE",
    initValue: "Panneaux isolants ACTIS HYBRIS posée en 2 couches croisées, coefficient de résistance moyen R=8,70 (ou préconisation de l'étude thermique), système rail métallique et plaques de plâtre en recouvrement avec bandes, hauteur sous plafond à 2,50 m ± 2 cm. \nHors Garage / Hors Sous-Sol",
  },
  {
    key: "ISOLATION_MURS",
    label: "ISOLATION MURS",
    initValue: "Panneaux ACTIS HYBRIS, système rail/montant métallique et plaques de plâtre en recouvrement avec bandes. \nEpaisseur selon plan (ou préconisation de l'étude thermique). \nHors Garage / Hors Sous-Sol",
  },
  {
    key: "CLOISON_50",
    label: "CLOISON 50",
    initValue: "Cloisons de distribution en plaque de plâtre de 50 mm d’épaisseur avec bandes et cloisons de distribution en plaque de plâtre hydrofuge pour les salles d'eau.",
  },
  {
    key: "CLOISON_72",
    label: "CLOISON 72",
    initValue: "Cloisons de distribution en plaque de plâtre de 72 mm d’épaisseur, système rail et montants métalliques, isolation ACTIS QUALI'CLOISON de 40 mm avec bandes et plaque de plâtre hydrofuge pour les salles d'eau.",
  },
  {
    key: "CLOISON_NON_ISO_GARAGE",
    label: "CLOISON NON ISO - GARAGE",
    initValue: "Cloisons de distribution en plaque de plâtre entre pieces non-isolées et habitation (selon plan), système rail et montants métalliques, isolation THERMIQUE et PHONIQUE en panneaux ACTIS HYBRIS avec bandes. \nEpaisseur et résistance thermique selon étude thermique.",
  },
  {
    key: "CLOISON_NON_ISO_SOUS_SOL",
    label: "CLOISON NON ISO - SOUS_SOL",
    initValue: "Cloisons de distribution en plaque de plâtre entre sous-sol et habitation, système rail et montants métalliques, isolation THERMIQUE et PHONIQUE en panneaux rigides ISOVER GR32 avec bandes. \nEpaisseur et Résistance selon Etude Thermique.",
  },
  {
    key: "PORTES_INTERIEURES",
    label: "PORTES INTERIEURES",
    initValue: "Blocs portes à gravures MODERNES, LES MENUISERIES FRANCAISES gamme \"ALINEA / PULSION GRAPHICA\". Quantité et dimensions selon plan.",
  },
  {
    key: "PORTES_INTERIEURES_GAMME_1_PRIX",
    label: "PORTES INT - GAMME 1",
    initValue: "INCLUS",
  },
  {
    key: "PORTES_INTERIEURES_GAMME_2_PRIX",
    label: "PORTES INT - GAMME 2",
    initValue: "OPTION : +50 € / PORTE",
  },
  {
    key: "PORTES_INTERIEURES_GAMME_2_LISTE",
    label: "PORTES INT - GAMME 2 LISTE",
    initValue: "Modèle SNOW, RIGI, DENA, GALLA, OSAKA, FREEZE",
  },
  //ELECTRICITE
  {
    key: "DOMOTIQUE_DOOXIE",
    label: "DOMOTIQUE DOOXIE",
    initValue: "Module LEGRAND DOOXIE NETATMO \nGestion des volets intélligent",
  },
  {
    key: "VMC",
    label: "VMC",
    initValue: "Simple flux HYGRO B, basse consommation RT2012",
  },
  {
    key: "QUANTITATIFS_1",
    label: "QUANTITATIFS 1",
    initValue: "INSTALLATION ET QUANTITÉ CONFORME A \"NF C 15-100\" PRISES RJ45 DE GRADE 3 (TV INCLUS)",
  },
  {
    key: "QUANTITATIFS_2",
    label: "QUANTITATIFS 2",
    initValue: "x2 : Prises RJ45 Grade 3 (TV inclus) dans la Piece de vie \nx1 : Prise RJ 45 Grade 3 (TV inclus) par Chambre / Bureau",
  },
  {
    key: "ELEC_CUISINE",
    label: "ELEC CUISINE",
    initValue: "x1 : Point lumineux \nx4 : Prises au-dessus du plan de travail \nx1 : Prise hotte \nx1 : Prise réfrigirateur \nx1 : Prise four \nx1 : Prise lave-vaisselle \nx1 : Prise cuisson",
  },
  {
    key: "ELEC_CELLIER",
    label: "ELEC CELLIER",
    initValue: "x1 : Point lumineux \nx1 : Alimentation du chauffage et/ou chauffe-eau \nx1 : Prise lave-linge\nx1 : Prise",
  },
  {
    key: "ELEC_PDV",
    label: "ELEC PDV",
    initValue: "2 Points lumineux\n >6 Prises",
  },
  {
    key: "ELEC_EXTERIEUR",
    label: "ELEC EXTERIEUR",
    initValue: "x2 : Points lumineux \nx1 : Prise",
  },
  {
    key: "ELEC_WC_DRESSING",
    label: "ELEC WC / DRESSING",
    initValue: "x1 : Point lumineux",
  },
  {
    key: "ELEC_BAINS_SDE",
    label: "ELEC SDE",
    initValue: "x1 : Point lumineux \nx1 : Prise",
  },
  {
    key: "ELEC_CHAMBRE_BUREAUX",
    label: "ELEC CHAMBRE / BUREAUX",
    initValue: "x1 : Point lumineux\nx3 : Prises \nx1 : Prise télécommunication",
  },
  {
    key: "ELEC_DGT_PALLIER",
    label: "ELEC DGT / PALLIER",
    initValue: "x1 : Point lumineux",
  },
  {
    key: "ELEC_GARAGE",
    label: "ELEC GARAGE",
    initValue: "x1 : Point lumineux \nx1 : Prise",
  },
  {
    key: "ELEC_ESCALIER",
    label: "ELEC ESCALIER",
    initValue: "x1 : Point lumineux",
  },
  {
    key: "ELEC_SOUS_SOL",
    label: "ELEC SOUS-SOL",
    initValue: "x2 : Points lumineux \nx2 : Prises",
  },
  //CHAUFFAGE
  {
    key: "CHAUFFAGE_1_CLIM_RAD_ELEC",
    label: "CHAUFFAGE 1 - CLIM RAD ELEC",
    initValue: "Pompe à chaleur AIR-AIR HITACHI MOKAI.",
  },
  {
    key: "CHAUFFAGE_1_PAC_AIR_EAU",
    label: "CHAUFFAGE 1 - PAC AIR EAU",
    initValue: "Pompe à chaleur AIR-EAU HITACHI YUTAKI S COMBI.",
  },
  {
    key: "CHAUFFAGE_1_GAZ",
    label: "CHAUFFAGE 1 - GAZ",
    initValue: "Chaudière GAZ condensation CHAFFOTEAUX MIRA C GREEN.",
  },
  {
    key: "CHAUFFAGE_1_POELE",
    label: "CHAUFFAGE 1 - POELE",
    initValue: "Poêle à granulé MCZ, modèle / coloris choix constructeur. \nAvec sortie de toit.",
  },
  {
    key: "CHAUFFAGE_1_GAINABLE",
    label: "CHAUFFAGE 1 - GAINABLE",
    initValue: "Pompe à chaleur AIR-AIR GAINABLE HITACHI.",
  },
  {
    key: "CHAUFFAGE_2_CLIM_RAD_ELEC",
    label: "CHAUFFAGE 2 - CLIM RAD ELEC",
    initValue: "Ballon Thermodynamique AUER EDEL 200L.",
  },
  {
    key: "CHAUFFAGE_2_GAZ",
    label: "CHAUFFAGE 2 - GAZ",
    initValue: "Panneau photovoltaïque intégré à la couverture, utilisation en auto-consomation. \nPuissance selon Etude Thermique.",
  },
  {
    key: "CHAUFFAGE_2_POELE",
    label: "CHAUFFAGE 2 - POELE",
    initValue: "Ballon thermodynamique AUER EDEL 200L.",
  },
  {
    key: "CHAUFFAGE_2_GAINABLE",
    label: "CHAUFFAGE 2 - GAINABLE",
    initValue: "Ballon thermodynamique AUER EDEL 200L.",
  },
  {
    key: "DIFFUSION_CLIM_RAD_ELEC",
    label: "DIFFUSION - CLIM RAD ELEC",
    initValue: "Radiateurs rayonnants CHAMBRES et sèche-serviette électrique BAINS.",
  },
  {
    key: "DIFFUSION_POELE",
    label: "DIFFUSION - POELE",
    initValue: "Chaleur du poêle et sèche-serviette BAINS.",
  },
  {
    key: "DIFFUSION_RAD_EAU",
    label: "DIFFUSION - RAD EAU",
    initValue: "Radiateurs à eau avec vannes thermostatiques, sèche-serviette électrique BAINS.",
  },
  {
    key: "DIFFUSION_SANS_RAD_EAU",
    label: "DIFFUSION - SANS RAD EAU",
    initValue: "Plancher chauffant et sèche-serviette électrique BAINS.",
  },
  {
    key: "DIFFUSION_GAINABLE",
    label: "DIFFUSION - GAINABLE",
    initValue: "Plénum de Souflage dans la Pièce de vie et les Chambres, radiateur électrique dans le local technique et sèche-serviette électrique dans salle de BAINS.",
  },
  //PLOMBERIE
  {
    key: "RESEAUX_EAU",
    label: "RESEAUX EAU",
    initValue: "Réseaux d'arrivée et d'évacuation d'eau pour tout appareil ménager et sanitaires. \nRobinet extérieur inclus",
  },
  {
    key: "WC_SIMPLE",
    label: "WC SIMPLE",
    initValue: "WC BLANC avec abattant et double chasse. (quantité selon plans)"         ,
  },
  {
    key: "WC_SIMPLE_OPTION",
    label: "WC SIMPLE OPTION",
    initValue: "WC BLANC avec abattant et double chasse en OPTION."                      ,
  },
  {
    key: "WC_SIMPLE_CLIENT",
    label: "WC SIMPLE CLIENT",
    initValue: "WC BLANC avec abattant et double chasse CHARGE CLIENT."                  ,
  },
  {
    key: "WC_SIMPLE_NON_LIEU",
    label: "WC SIMPLE NON LIEU",
    initValue: "WC BLANC avec abattant et double chasse NON LIEU."                       ,
  },
  {
    key: "WC_SUS",
    label: "WC SUSPENDU",
    initValue: "WC BLANC SUSPENDU avec abattant et double chasse. (quantité selon plans)",
  },
  {
    key: "WC_SUS_OPTION",
    label: "WC SUSPENDU OPTION",
    initValue: "WC BLANC SUSPENDU avec abattant et double chasse en OPTION."             ,
  },
  {
    key: "WC_SUS_CLIENT",
    label: "WC SUSPENDU CLIENT",
    initValue: "WC BLANC SUSPENDU avec abattant et double chasse CHARGE CLIENT"          ,
  },
  {
    key: "WC_SUS_NON_LIEU",
    label: "WC SUSPENDU NON LIEU",
    initValue: "WC BLANC SUSPENDU avec abattant et double chasse NON LIEU",
  },
  {
    key: "LAVE_MAIN_SIMPLE",
    label: "LAVE MAIN SIMPLE",
    initValue: "Lave-main BLANC de 33 cm avec robinetterie. (quantité selon plans)"         ,
  },
  {
    key: "LAVE_MAIN_SIMPLE_OPTION",
    label: "LAVE MAIN SIMPLE_OPTION",
    initValue: "Lave-main BLANC de 33 cm avec robinetterie en OPTION."                      ,
  },
  {
    key: "LAVE_MAIN_SIMPLE_CLIENT",
    label: "LAVE MAIN SIMPLE CLIENT",
    initValue: "Lave-main BLANC de 33 cm avec robinetterie CHARGE CLIENT."                  ,
  },
  {
    key: "LAVABO_SIMPLE",
    label: "LAVABO SIMPLE",
    initValue: "Lavabo sur colonne BLANC de 55 cm avec robinetterie. (quantité selon plans)"         ,
  },
  {
    key: "LAVABO_SIMPLE_OPTION",
    label: "LAVABO SIMPLE OPTION",
    initValue: "Lavabo sur colonne BLANC de 55 cm avec robinetterie en OPTION."                      ,
  },
  {
    key: "LAVABO_SIMPLE_CLIENT",
    label: "LAVABO SIMPLE CLIENT",
    initValue: "Lavabo sur colonne BLANC de 55 cm avec robinetterie CHARGE CLIENT."                  ,
  },
  {
    key: "BAIGNOIRE_SIMPLE",
    label: "BAIGNOIRE SIMPLE",
    initValue: "Baignoire acrylique avec robinetterie. (quantité selon plans)"         ,
  },
  {
    key: "BAIGNOIRE_SIMPLE_OPTION",
    label: "BAIGNOIRE SIMPLE OPTION",
    initValue: "Baignoire acrylique avec robinetterie en OPTION."                      ,
  },
  {
    key: "BAIGNOIRE_SIMPLE_CLIENT",
    label: "BAIGNOIRE SIMPLE CLIENT",
    initValue: "Baignoire acrylique avec robinetterie CHARGE CLIENT."                  ,
  },
  {
    key: "DOUCHE_ITAL",
    label: "DOUCHE ITAL",
    initValue: "Douche à l'italienne avec robinetterie. (quantité et dimension selon plans)"         ,
  },
  {
    key: "DOUCHE_ITAL_OPTION",
    label: "DOUCHE ITAL OPTION",
    initValue: "Douche à l'italienne avec robinetterie en OPTION."                      ,
  },
  {
    key: "DOUCHE_ITAL_CLIENT",
    label: "DOUCHE ITAL CLIENT",
    initValue: "Douche à l'italienne avec robinetterie CHARGE CLIENT."                  ,
  },
  {
    key: "DOUCHE_CERAM",
    label: "DOUCHE CERAM",
    initValue: "Douche en céramique avec robinetterie. (quantité et dimension selon plans)"         ,
  },
  {
    key: "DOUCHE_CERAM_OPTION",
    label: "DOUCHE CERAM OPTION",
    initValue: "Douche en céramique avec robinetterie en OPTION."                      ,
  },
  {
    key: "DOUCHE_CERAM_CLIENT",
    label: "DOUCHE CERAM CLIENT",
    initValue: "Douche en céramique avec robinetterie CHARGE CLIENT."                  ,
  },
  {
    key: "DOUCHE_PLATE",
    label: "DOUCHE PLATE",
    initValue: "Douche extra-plat avec robinetterie. (quantité et dimension selon plans)"         ,
  },
  {
    key: "DOUCHE_PLATE_OPTION",
    label: "DOUCHE PLATE OPTION",
    initValue: "Douche extra-plat avec robinetterie en OPTION."                      ,
  },
  {
    key: "DOUCHE_PLATE_CLIENT",
    label: "DOUCHE PLATE CLIENT",
    initValue: "Douche extra-plat avec robinetterie CHARGE CLIENT."                  ,
  },
  //CARRELAGE
  {
    key: "CARRELAGE_SAUF_CHAMBRES",
    label: "CARRELAGE SAUF CHAMBRES",
    initValue: "Choix gamme constructeur, dans toutes les pièces, sauf les chambres / dressing.",
  },
  {
    key: "CARRELAGE_SAUF_CHAMBRES_GARAGE",
    label: "CARRELAGE SAUF CHAMBRES / GARAGE",
    initValue: "Choix gamme constructeur, dans toutes les pièces, sauf chambres / dressing et garage.",
  },
  {
    key: "CARRELAGE_TOUT",
    label: "CARRELAGE TOUT",
    initValue: "Choix gamme constructeur, dans toutes les pièces.",
  },
  {
    key: "CARRELAGE_SAUF_GARAGE",
    label: "CARRELAGE SAUF GARAGE",
    initValue: "Choix gamme constructeur, dans toutes les pièces sauf garage.",
  },
  {
    key: "CARRELAGE_SAUF_CHAMBRES_PLAN",
    label: "CARRELAGE SAUF CHAMBRES PLAN",
    initValue: "Choix gamme constructeur, dans toutes les pièces, sauf chambres / dressing indiqué au plan.",
  },
  {
    key: "CARRELAGE_SAUF_CHAMBRES_PLAN_GARAGE",
    label: "CARRELAGE SAUF CHAMBRES PLAN GARAGE",
    initValue: "Choix gamme constructeur, dans toutes les pièces, sauf chambres / dressing indiqué au plan et garage.",
  },
  {
    key: "CARRELAGE_GAMME_INITIALE",
    label: "CARRELAGE : GAMME INITIALE",
    initValue: "gamme INITIALE",
  },
  {
    key: "CARRELAGE_GAMME_PRESTIGE",
    label: "CARRELAGE : GAMME_PRESTIGE",
    initValue: "gamme PRESTIGE",
  },
  {
    key: "CHAPE_FINITION_NORMAL",
    label: "CHAPE FINITION_NORMAL",
    initValue: "Chape de finition aux CHAMBRES hors GARAGE / Sous-Sol.",
  },
  {
    key: "CHAPE_FINITION_NON_CARRELE",
    label: "CHAPE FINITION NON CARRELE",
    initValue: "Chape de finition aux chambres non carrelé hors GARAGE / Sous-sol (voir plan).",
  },
  {
    key: "CHAPE_FINITION_RAGREAGE",
    label: "CHAPE FINITION RAGREAGE",
    initValue: "Ragréage à prévoir CHARGE CLIENT.",
  },
  {
    key: "FAIENCES_TOUT",
    label: "FAIENCES TOUT",
    initValue: "Choix gamme constructeur, autour des Douches et/ou Baignoires sur une hauteur de 2 mètres.",
  },
  {
    key: "FAIENCES_SAUF_CLIENT",
    label: "FAIENCES SAUF CLIENT",
    initValue: "Choix gamme constructeur, autour des Douches et/ou Baignoires sur une hauteur de 2 mètres, sauf autour des Douches et/ou Baignoires à la charge du client.",
  },
  {
    key: "PARQUET_CHOIX",
    label: "PARQUET CHOIX",
    initValue: "Choix gamme LOCFLOOR / UNIVERSAL / CLASSIC 8-32 dans CHAMBRES et BUREAUX.",
  },
  //PEINTURE
  {
    key: "PREPARATIONS",
    label: "PREPARATIONS",
    initValue: "Ponçage, finition des bandes et application d’une couche de primaire aux murs et aux plafonds.",
  },
  {
    key: "PEINTURE_TOUT",
    label: "PEINTURE TOUT",
    initValue: "Applications de 2 couches de finition en BLANC MAT aux murs et aux plafonds de la maison.",
  },
  {
    key: "PEINTURE_SAUF_GARAGE",
    label: "PEINTURE SAUF GARAGE",
    initValue: "Applications de 2 couches de finition en BLANC MAT aux murs et aux plafonds de la maison hors garage.",
  },
  //RACCORDEMENTS
  {
    key: "RACCORDEMENTS",
    label: "RACCORDEMENTS",
    initValue: "Pose de fourreaux techniques pour alimentations EDF, PORTAIL, TELEPHONE, GAZ; Alimentation en eau, évacuation des eaux usées au réseau public; Traitements des eaux usées; Evacuation des terres; Etalement des terres sur la parcelle; Mise en œuvre d’un chemin d’accès en calcaire pour les livraisons du chantier et, après travaux, du chemin final avec finitions.",
  },
  //AUTRES
  {
    key: "CHARGE_CLIENT",
    label: "CHARGE CLIENT",
    initValue: "CHARGE CLIENT",  
  },
  {
    key: "OPTION",
    label: "OPTION",
    initValue: "OPTION",
  },
  {
    key: "NON_LIEU",
    label: "NON LIEU",
    initValue: "NON LIEU",
  },
  //OPTIONS
  {
    key: "ETUDE_SOL_PLUS_150_TEXT",
    label: "ETUDE SOL +150 / texte",
    initValue: "Etude de sol de type G2AVP (argile moyen/fort) maison ≥ 150 m²",
  },
  {
    key: "ETUDE_SOL_PLUS_150_PRIX",
    label: "ETUDE SOL +150 / prix",
    initValue: "2 200 €",
  },
  {
    key: "ETUDE_SOL_MOINS_150_TEXT",
    label: "ETUDE SOL -150 / texte",
    initValue: "Etude de sol de type G2AVP (argile moyen/fort) maison < 150 m²",
  },
  {
    key: "ETUDE_SOL_MOINS_150_PRIX",
    label: "ETUDE SOL -150 / prix",
    initValue: "1 500 €"
  },
]);