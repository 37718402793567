<template>
  <div class="images single-view flex-cnt-row justify-center">
    <div class="main-text flex-cnt-col align-center justify-between">
      <div class="view-header flex-cnt-row flex-center">
        <h2>{{("Images").toUpperCase()}}</h2>
      </div>
      <div class="unit-text flx1 flex-cnt-col justify-start align-center">
        <ImageTable
          v-bind:initImages="initImages"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ImageTable from '../components/forms/ImageTable.vue';
import { ListeImagesDevis } from '../pdf/base64images';

export default {
  components: {
    ImageTable
  },
  data: function () {
    return {
      initImages: ListeImagesDevis,
    }
  },
  methods:{
  }
}
</script>

<style scoped>
.images {
  min-width: 800px;
  overflow: auto;
  box-sizing: border-box;
}

.single-view {
  padding: 0;
}

.main-text {
  box-sizing: border-box;
  padding: 20px 40px;
  min-width: 700px;
}

.unit-text{
  justify-content: start;
  box-sizing: border-box;
  width: fit-content;
}

pre {
  max-width: 1000px;
}
</style>
