import PDFDocument from './pdfkit'
import blobStream  from 'blob-stream';

import { SKeys } from "../param/selectKeys";
import { urls } from '../api/serverInfo';

 

const axios = require('axios');

async function fetchImage(src) {
  const image = await axios
      .get(src, {
        responseType: 'arraybuffer'
      })
  return image.data;
}

export async function generateDevisPdf(iframe, gForm, cData, images, texts) {
  //FETCHING IMAGES
  const imgBaseUrl = urls.baseUploads + '/image/';

  const imgMedailleOr         = await fetchImage(imgBaseUrl + "medailleOr.jpg"              );
  const imgHeadRever          = await fetchImage(imgBaseUrl + "headRever.jpg"               );
  const imgHeadRaser          = await fetchImage(imgBaseUrl + "headRaser.jpg"               );
  const imgVS1                = await fetchImage(imgBaseUrl + "videSanitaire1.jpg"          );
  const imgVS2                = await fetchImage(imgBaseUrl + "videSanitaire2.jpg"          );
  const imgVS3                = await fetchImage(imgBaseUrl + "videSanitaire3.jpg"          );
  const imgPortesEntree       = await fetchImage(imgBaseUrl + "portesEntree.jpg"            );
  const imgPortesService      = await fetchImage(imgBaseUrl + "portesService.jpg"           );
  const imgIso1               = await fetchImage(imgBaseUrl + "isolation1.jpg"              );
  const imgIso2               = await fetchImage(imgBaseUrl + "isolation2.jpg"              );
  const imgIso3               = await fetchImage(imgBaseUrl + "isolation3.jpg"              );
  const imgIsoPortes          = await fetchImage(imgBaseUrl + "isolationPortes.jpg"         );
  const imgElecDooxie         = await fetchImage(imgBaseUrl + "electriciteDooxie.jpg"       );
  const imgElecSquareHomeco   = await fetchImage(imgBaseUrl + "electriciteSquareHomeco.jpg" );
  const imgElecGPlay          = await fetchImage(imgBaseUrl + "electriciteGPlay.jpg"        );
  const imgElecAppStore       = await fetchImage(imgBaseUrl + "electriciteAppStore.jpg"     );
  const imgElecPrises         = await fetchImage(imgBaseUrl + "electriciteElec.jpg"         );
  const imgElecAlexa          = await fetchImage(imgBaseUrl + "electriciteAlexa.jpg"        );
  const imgElecGAssist        = await fetchImage(imgBaseUrl + "electriciteGAssistant.jpg"   );
  const imgChauffageBallon    = await fetchImage(imgBaseUrl + "chauffageBallon.jpg"         );
  const imgChauffageChaudiere = await fetchImage(imgBaseUrl + "chauffageChaudiere.jpg"      );
  const imgChauffageMokai     = await fetchImage(imgBaseUrl + "chauffageMokai.jpg"          );
  const imgChauffagePhotoV    = await fetchImage(imgBaseUrl + "chauffagePhotoV.jpg"         );
  const imgChauffagePoele     = await fetchImage(imgBaseUrl + "chauffagePoele.jpg"          );
  const imgChauffageYutaki    = await fetchImage(imgBaseUrl + "chauffageYutaki.jpg"         );
  const imgChauffageGainable  = await fetchImage(imgBaseUrl + "chauffageGainable.jpg"       );
  const imgHeadBudget         = await fetchImage(imgBaseUrl + "headBudget.jpg"              );

  var doc = new PDFDocument({size: 'A4', autoFirstPage: false});
  var stream = doc.pipe(blobStream());

  //DATA
  let dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  const localeDate = new Date().toLocaleDateString('fr-FR', dateOptions);

  //INIT
  doc.info['Title'] = `devis-${gForm.clients.nomDocument}-v${gForm.clients.version}`;
  
  let cntPages = 1;

  doc.on('pageAdded', () => {
    doc.image(images.mavBackground, 26, 160, {width: 558});
    doc.image(images.mavHeader, 555, 20, {width: 35});
    doc
      .fillColor('black')
      .fontSize(8)
      .font('Helvetica')
      .text(`${gForm.clients.nomDocument && gForm.clients.version ? `${gForm.clients.nomDocument} V${gForm.clients.version}` : ''}`, 25, 770, {
        width: 562,
        align: 'left',
        baseline: 'middle'
      })
      .text('Paraphes :', 25, 770, {
        width: 562,
        align: 'center',
        baseline: 'middle'
      })
      .text(`Page ${cntPages++} de 8`, 25, 770, {
        width: 562,
        align: 'right',
        baseline: 'middle'
      });
  });

  //*************************************************************************************************
  //******************************               PAGE 1              ********************************
  //*************************************************************************************************
  doc.addPage({margin: 0});
  
  //HEADER
  doc.image(imgHeadRaser, 155, 30, {fit: [300, 120], align: 'center', valign: 'center'});
  doc.image(imgMedailleOr, 452, 40, {fit: [100, 124], align: 'center', valign: 'center'});
  
  //INFORMATIONS CLIENT
  doc
    .fillColor('brown')
    .fontSize(18)
    .text('PROJET DE CONSTRUCTION', 35, 180, {
      align: 'center',
      underline: true
    });

  doc
    .fillColor('black')
    .fontSize(10)
    .font('Helvetica-Bold')
    .text(`${gForm.clients.civiliteA ? gForm.clients.civiliteA : 'M'}. :`, 35, 220, {
      width: 125,
      align: 'right'
    })
    .text(`${gForm.clients.civiliteB ? gForm.clients.civiliteB : 'Mme'}. :`, 35, 234, {
      width: 125,
      align: 'right'
    })
    .text('ADRESSE :', 35, 248, {
      width: 125,
      align: 'right'
    })
    .text('CP + VILLE :', 35, 262, {
      width: 125,
      align: 'right'
    })
    .text('TEL :', 35, 276, {
      width: 125,
      align: 'right'
    })
    .text('DATE :', 35, 300, {
      width: 125,
      align: 'right'
    })
    .text('MODELE :', 35, 330, {
      width: 125,
      align: 'right'
    });

  doc
    .fillColor('black')
    .fontSize(10)
    .font('Helvetica')
    .text(`${gForm.clients.nomA ? gForm.clients.nomA : ''}`, 170, 220, {
      align: 'left'
    })
    .text(`${gForm.clients.nomB ? gForm.clients.nomB : ''}`, 170, 234, {
      align: 'left'
    })
    .text(`${gForm.clients.adresse ? gForm.clients.adresse : ''}`, 170, 248, {
      align: 'left'
    })
    .text(`${gForm.clients.codePostal ? gForm.clients.codePostal : ''} ${gForm.clients.ville ? gForm.clients.ville : ''}`, 170, 262, {
      align: 'left'
    })
    .text(`${gForm.clients.tel ? gForm.clients.tel : ''}`, 170, 276, {
      align: 'left'
    })
    .text(localeDate, 170, 300, {
      align: 'left'
    })
    .fontSize(14)
    .fillColor('blue')
    .text(`${gForm.constructions.modele ? gForm.constructions.modele.toUpperCase() : ''}`, 170, 328, {
      align: 'left',
      underline: true
    });

  //LISTE SERVICES
  doc
    .fillColor('blue')
    .fontSize(16)
    .font('Helvetica-Bold')
    .text('SERVICES', 
      35, 360, {
      width: 500,
      align: 'left',
      underline: true
    });

  doc
    .fillColor('black')
    .fontSize(10)
    .font('Helvetica-Bold')
    .text('ASSURANCES :', 
      35, 409, {
      width: 125,
      height: 45,
      align: 'center',
      baseline: 'middle'
    }).lineWidth(0.5).rect(35, 385, 125, 45).stroke()
    .text('TERRAIN :', 
      35, 456, {
      width: 125,
      height: 30,
      align: 'center',
      baseline: 'middle'
    }).lineWidth(0.5).rect(35, 440, 125, 30).stroke()
    .text('ASSISTANCE :', 
      35, 491, {
      width: 125,
      height: 20,
      align: 'center',
      baseline: 'middle'
    }).lineWidth(0.5).rect(35, 480, 125, 20).stroke()
    .text('FINANCEMENT :', 
      35, 526, {
      width: 125,
      height: 30,
      align: 'center',
      baseline: 'middle'
    }).lineWidth(0.5).rect(35, 510, 125, 30).stroke()
    .text('ÉTUDE THERMIQUE :', 
      35, 566, {
      width: 125,
      height: 30,
      align: 'center',
      baseline: 'middle'
    }).lineWidth(0.5).rect(35, 550, 125, 30).stroke()
    .text('PC :', 
      35, 606, {
      width: 125,
      height: 30,
      align: 'center',
      baseline: 'middle'
    }).lineWidth(0.5).rect(35, 590, 125, 30).stroke()
    .text('CONTRÔLE QUALITÉ :', 
      35, 646, {
      width: 125,
      height: 30,
      align: 'center',
      baseline: 'middle'
    }).lineWidth(0.5).rect(35, 630, 125, 30).stroke()
    .text('VISITE CHANTIER :', 
      35, 686, {
      width: 125,
      height: 30,
      align: 'center',
      baseline: 'middle'
    }).lineWidth(0.5).rect(35, 670, 125, 30).stroke()
    .text('SAV :', 
      35, 726, {
      width: 125,
      height: 30,
      align: 'center',
      baseline: 'middle'
    }).lineWidth(0.5).rect(35, 710, 125, 30).stroke();

  doc
    .fillColor('black')
    .fontSize(10)
    .font('Helvetica')
    .text(texts.ASSURANCES,
      167, 392, {
      width: 403,
      height: 45,
      align: 'justify'
    }).lineWidth(0.5).rect(160, 385, 417, 45).stroke()
    .text(texts.TERRAIN, 
      167, 445, {
      width: 403,
      height: 30,
      align: 'justify'
    }).lineWidth(0.5).rect(160, 440, 417, 30).stroke()
    .text(texts.ASSISTANCE,
      167, 486, {
      width: 403,
      height: 20,
      align: 'justify'
    }).lineWidth(0.5).rect(160, 480, 417, 20).stroke()
    .text(texts.FINANCEMENT,
      167, 515, {
      width: 403,
      height: 30,
      align: 'justify'
    }).lineWidth(0.5).rect(160, 510, 417, 30).stroke()
    .text(texts.ETUDE_THERMIQUE,
      167, 555, {
      width: 403,
      height: 30,
      align: 'justify'
    }).lineWidth(0.5).rect(160, 550, 417, 30).stroke()
    .text(texts.PC,
      167, 595, {
      width: 403,
      height: 30,
      align: 'justify'
    }).lineWidth(0.5).rect(160, 590, 417, 30).stroke()
    .text(texts.CONTROLE_QUALITE,
      167, 635, {
      width: 403,
      height: 30,
      align: 'justify'
    }).lineWidth(0.5).rect(160, 630, 417, 30).stroke()
    .text(texts.VISITE_CHANTIER,
      167, 675, {
      width: 403,
      height: 30,
      align: 'justify'
    }).lineWidth(0.5).rect(160, 670, 417, 30).stroke()
    .text(texts.SAV,
      167, 715, {
      width: 403,
      height: 30,
      align: 'justify'
    }).lineWidth(0.5).rect(160, 710, 417, 30).stroke();

  //*************************************************************************************************
  //******************************               PAGE 2              ********************************
  //*************************************************************************************************
  let textVS;
  let titrePlancherRdc = '';
  let textPlancherRdc = '';
  let offsetPlancherRdc;
  if (gForm.videSanitaires.nombreRangs === SKeys.RangsVideSanitaire.TROIS_RANGS) {
    textVS = texts.VIDE_SANITAIRE_3_RANGS;
    if (Number.parseInt(cData.electricite.pieces.nombreGarage)) {
      titrePlancherRdc = texts.PLANCHER_RDC_AVEC_GARAGE_TITLE;
      textPlancherRdc = texts.PLANCHER_RDC_AVEC_GARAGE_TEXT;
      offsetPlancherRdc = 25;
    }
    else {
      titrePlancherRdc = texts.PLANCHER_RDC_SANS_GARAGE_TITLE;
      textPlancherRdc = texts.PLANCHER_RDC_SANS_GARAGE_TEXT;
      offsetPlancherRdc = 31;
    }
  }
  else if (gForm.videSanitaires.nombreRangs === SKeys.RangsVideSanitaire.QUATRE_RANGS) {
    textVS = texts.VIDE_SANITAIRE_4_RANGS;
    if (Number.parseInt(cData.electricite.pieces.nombreGarage)) {
      titrePlancherRdc = texts.PLANCHER_RDC_AVEC_GARAGE_TITLE;
      textPlancherRdc = texts.PLANCHER_RDC_AVEC_GARAGE_TEXT;
      offsetPlancherRdc = 25;
    }
    else {
      titrePlancherRdc = texts.PLANCHER_RDC_SANS_GARAGE_TITLE;
      textPlancherRdc = texts.PLANCHER_RDC_SANS_GARAGE_TEXT;
      offsetPlancherRdc = 31;
    }
  }
  else if (gForm.videSanitaires.nombreRangs === SKeys.RangsVideSanitaire.CINQ_RANGS) {
    textVS = texts.VIDE_SANITAIRE_5_RANGS;
    if (Number.parseInt(cData.electricite.pieces.nombreGarage)) {
      titrePlancherRdc = texts.PLANCHER_RDC_AVEC_GARAGE_TITLE;
      textPlancherRdc = texts.PLANCHER_RDC_AVEC_GARAGE_TEXT;
      offsetPlancherRdc = 25;
    }
    else {
      titrePlancherRdc = texts.PLANCHER_RDC_SANS_GARAGE_TITLE;
      textPlancherRdc = texts.PLANCHER_RDC_SANS_GARAGE_TEXT;
      offsetPlancherRdc = 31;
    }
  }

  doc.addPage({margin: 0});
  
  //HEADER
  doc.image(imgHeadRever, 155, 30, {fit: [300, 104], align: 'center', valign: 'center'});

  //IMAGES GROS OEUVRE
  doc.image(imgVS1, 34,  304, {fit: [150, 100], align: 'center', valign: 'center'});
  doc.image(imgVS2, 231, 304, {fit: [150, 100], align: 'center', valign: 'center'});
  doc.image(imgVS3, 428, 304, {fit: [150, 100], align: 'center', valign: 'center'});


  //LISTE GROS OEUVRE
  doc
    .fillColor('blue')
    .fontSize(16)
    .font('Helvetica-Bold')
    .text('GROS OEUVRE', 
      35, 170, {
      width: 500,
      align: 'left',
      underline: true
    });

  doc
    .fillColor('black')
    .fontSize(10)
    .font('Helvetica-Bold')
    .text('TERRASSEMENT :', 
      35, 226, {
      width: 125,
      height: 58,
      align: 'center',
      baseline: 'middle'
    }).lineWidth(0.5).rect(35, 195, 125, 58).stroke()
    .text('VIDE SANITAIRE :', 
      35, 279, {
      width: 125,
      height: 30,
      align: 'center',
      baseline: 'middle'
    }).lineWidth(0.5).rect(35, 263, 125, 30).stroke()

    .text(titrePlancherRdc, 
      35, 415 + offsetPlancherRdc, {
      width: 125,
      height: 58,
      align: 'center',
      baseline: 'middle'
    }).lineWidth(0.5).rect(35, 415, 125, 58).stroke()
    .text('ÉLÉVATION :', 
      35, 499, {
      width: 125,
      height: 30,
      align: 'center',
      baseline: 'middle'
    }).lineWidth(0.5).rect(35, 483, 125, 30).stroke()
    .text('CHAINAGE / RENFORTS :', 
      35, 539, {
      width: 125,
      height: 30,
      align: 'center',
      baseline: 'middle'
    }).lineWidth(0.5).rect(35, 523, 125, 30).stroke()
    .text('SEUILS / APPUIS :', 
      35, 579, {
      width: 125,
      height: 30,
      align: 'center',
      baseline: 'middle'
    }).lineWidth(0.5).rect(35, 563, 125, 30).stroke()
    .text('LINTEAUX :', 
      35, 614, {
      width: 125,
      height: 20,
      align: 'center',
      baseline: 'middle'
    }).lineWidth(0.5).rect(35, 603, 125, 20).stroke()
    .text('ENDUIT EXTÉRIEUR :', 
      35, 649, {
      width: 125,
      height: 30,
      align: 'center',
      baseline: 'middle'
    }).lineWidth(0.5).rect(35, 633, 125, 30).stroke()

  doc
    .fillColor('black')
    .fontSize(10)
    .font('Helvetica')
    .text(texts.TERRASSEMENT,
      167, 202, {
      width: 403,
      height: 58,
      align: 'justify'
    }).lineWidth(0.5).rect(160, 195, 417, 58).stroke()
    .text(textVS, 
      167, 268, {
      width: 403,
      height: 30,
      align: 'justify'
    }).lineWidth(0.5).rect(160, 263, 417, 30).stroke()

    .text(textPlancherRdc,
      167, 422, {
      width: 403,
      height: 58,
      align: 'justify'
    }).lineWidth(0.5).rect(160, 415, 417, 58).stroke()
    .text(texts.ELEVATION,
      167, 488, {
      width: 403,
      height: 30,
      align: 'justify'
    }).lineWidth(0.5).rect(160, 483, 417, 30).stroke()
    .text(texts.CHAINAGE_RENFORTS,
      167, 528, {
      width: 403,
      height: 30,
      align: 'justify'
    }).lineWidth(0.5).rect(160, 523, 417, 30).stroke()
    .text(texts.SEUILS_APPUIS_BETON,
      167, 568, {
      width: 403,
      height: 30,
      align: 'justify'
    }).lineWidth(0.5).rect(160, 563, 417, 30).stroke()
    .text(texts.LINTEAUX,
      167, 609, {
      width: 403,
      height: 20,
      align: 'justify'
    }).lineWidth(0.5).rect(160, 603, 417, 20).stroke()
    .text(texts.ENDUIT_EXT_MONO,
      167, 638, {
      width: 403,
      height: 30,
      align: 'justify'
    }).lineWidth(0.5).rect(160, 633, 417, 30).stroke()

  //*************************************************************************************************
  //******************************               PAGE 3              ********************************
  //*************************************************************************************************
  let textCharpente = '';
  if (gForm.combles.type === SKeys.TypesCombles.PERDUS) {
    if (gForm.toitRdcs.type === SKeys.TypesToit.RECTANGLE) {
      textCharpente = texts.CHARPENTE_PERDUS_RECTANGLE;
    }
    else if (gForm.toitRdcs.type === SKeys.TypesToit.L || gForm.toitRdcs.type === SKeys.TypesToit.U) {
      textCharpente = texts.CHARPENTE_PERDUS_L_U;
    }
    else if (gForm.toitRdcs.type === SKeys.TypesToit.V) {
      textCharpente = texts.CHARPENTE_PERDUS_V;
    }
  }
  else if (gForm.combles.type === SKeys.TypesCombles.AMENAGEABLES) {
    if (gForm.toitRdcs.type === SKeys.TypesToit.RECTANGLE) {
      textCharpente = texts.CHARPENTE_AMENAGEABLES_RECTANGLE;
    }
    else if (gForm.toitRdcs.type === SKeys.TypesToit.L || gForm.toitRdcs.type === SKeys.TypesToit.U) {
      textCharpente = texts.CHARPENTE_AMENAGEABLES_L_U;
    }
    else if (gForm.toitRdcs.type === SKeys.TypesToit.V) {
      textCharpente = texts.CHARPENTE_AMENAGEABLES_V;
    }
  }
  let textEncuvement = '';
  if (parseFloat(gForm.combles.encuvement) > 0) {
    textEncuvement = `${texts.TOIT_AUTRE_ENCUVEMENT} ${gForm.combles.encuvement.toFixed(2)}`;
  }
  //
  let textPentes = '';
  if (gForm.toitRdcs.nombrePentes === SKeys.PentesToit.DEUX_PENTES) {
    textPentes = texts.TOIT_PENTES_2_PENTES;
  }
  //
  let textInclinaison = '';
  if (gForm.toitRdcs.inclinaison === SKeys.InclinaisonsToit.DEGRES_35) {
    textInclinaison = texts.TOIT_INCLINAISON_35;
  }
  else if (gForm.toitRdcs.inclinaison === SKeys.InclinaisonsToit.DEGRES_40) {
    textInclinaison = texts.TOIT_INCLINAISON_40;
  }
  else if (gForm.toitRdcs.inclinaison === SKeys.InclinaisonsToit.DEGRES_45) {
    textInclinaison = texts.TOIT_INCLINAISON_45;
  }
  //
  let textComblesAmenageables = '';
  if (gForm.combles.type === SKeys.TypesCombles.AMENAGEABLES) {
    textComblesAmenageables = `${texts.TOIT_AUTRE_COMBLES_AMENAGEABLES} ${(parseFloat(gForm.combles.ratioComblesAmenageables) * 100).toFixed(0)} %`
  }
  //
  let textCouverture = '';
  switch (gForm.toitRdcs.typeCouverture) {
    case SKeys.TypesCouverture.DOUBLE_ROMANE_ROUGE:
      textCouverture = texts.COUVERTURE_DOUBLE_ROMANE_ROUGE;
      break;

    case SKeys.TypesCouverture.DOUBLE_ROMANE_ARDOISEE:
      textCouverture = texts.COUVERTURE_DOUBLE_ROMANE_ARDOISEE;
      break;
      
    case SKeys.TypesCouverture.PLATE_NOBILEE_ROUGE_ASPECT:
      textCouverture = texts.COUVERTURE_PLATE_NOBILEE_ROUGE_ASPECT;
      break;
      
    case SKeys.TypesCouverture.PLATE_NOBILEE_ARDOISE_ASPECT:
      textCouverture = texts.COUVERTURE_PLATE_NOBILEE_ARDOISEE_ASPECT;
      break;
      
    case SKeys.TypesCouverture.REGENCE_ROUGE:
      textCouverture = texts.COUVERTURE_REGENCE_ROUGE;
      break;
      
    case SKeys.TypesCouverture.REGENCE_ARDOISEE:
      textCouverture = texts.COUVERTURE_REGENCE_ARDOISEE;
      break;
      
    case SKeys.TypesCouverture.FONTENNELLE_ROUGE:
      textCouverture = texts.COUVERTURE_FONTENNELLE_ROUGE;
      break;
      
    case SKeys.TypesCouverture.FONTENNELLE_ARDOISEE:
      textCouverture = texts.COUVERTURE_FONTENNELLE_ARDOISEE;
      break;
      
    case SKeys.TypesCouverture.SIGNY_ROUGE:
      textCouverture = texts.COUVERTURE_SIGNY_ROUGE;
      break;
      
    case SKeys.TypesCouverture.SIGNY_ARDOISEE:
      textCouverture = texts.COUVERTURE_SIGNY_ARDOISEE;
      break;
      
    case SKeys.TypesCouverture.PLATE_DE_PAYS:
      textCouverture = texts.COUVERTURE_PLATE_DE_PAYS;
      break;
      
    case SKeys.TypesCouverture.NATURELLE_DESPAGNE:
      textCouverture = texts.COUVERTURE_NATURELLE_DESPAGNE;
      break;
      
    case SKeys.TypesCouverture.ARTIFICIELLE_DROIT:
      textCouverture = texts.COUVERTURE_ARTIFICIELLE_DROIT;
      break;
      
    case SKeys.TypesCouverture.ARTIFICIELLE_EPAUFRE_40_24:
      textCouverture = texts.COUVERTURE_ARTIFICIELLE_EPAUFRE_40_24;
      break;
      
    case SKeys.TypesCouverture.ARTIFICIELLE_EPAUFRE_33_23 :
      textCouverture = texts.COUVERTURE_ARTIFICIELLE_EPAUFRE_33_23;
      break;
  
    default:
      break;
  }
  let textGouttiere = '';
  if (gForm.toitRdcs.gouttiere === SKeys.TypesGouttiere.ALU) {
    textGouttiere = texts.GOUTTIERE_ALU;
  }
  else if (gForm.toitRdcs.gouttiere === SKeys.TypesGouttiere.ZINC) {
    textGouttiere = texts.GOUTTIERE_ZINC;
  }

  let textConduit = texts.NON_LIEU;
  if (gForm.toitRdcs.conduit === SKeys.TypesConduit.POLYCOMBUSTIBLE) {
    textConduit = texts.CONDUIT_FUMEE;
  }

  doc.addPage({margin: 0});

  //LISTE TOITURE
  doc
    .fillColor('blue')
    .fontSize(16)
    .font('Helvetica-Bold')
    .text('TOITURE', 
      35, 60, {
      width: 500,
      align: 'left',
      underline: true
    });
    
  doc
    .fillColor('black')
    .fontSize(10)
    .font('Helvetica-Bold')
    .text('CHARPENTE :', 
      35, 111, {
      width: 125,
      height: 50,
      align: 'center',
      baseline: 'middle'
    }).lineWidth(0.5).rect(35, 85, 125, 50).stroke()
    .text('COUVERTURE :', 
      35, 161, {
      width: 125,
      height: 30,
      align: 'center',
      baseline: 'middle'
    }).lineWidth(0.5).rect(35, 145, 125, 30).stroke()
    .text('GOUTTIÈRE', 
      35, 196, {
      width: 125,
      height: 20,
      align: 'center',
      baseline: 'middle'
    }).lineWidth(0.5).rect(35, 185, 125, 20).stroke()
    .text('DÉBORD DE FAÇADE :', 
      35, 226, {
      width: 125,
      height: 20,
      align: 'center',
      baseline: 'middle'
    }).lineWidth(0.5).rect(35, 215, 125, 20).stroke()
    .text('VENTILATIONS :', 
      35, 261, {
      width: 125,
      height: 30,
      align: 'center',
      baseline: 'middle'
    }).lineWidth(0.5).rect(35, 245, 125, 30).stroke()
    .text('CONDUIT DE FUMÉE :', 
      35, 301, {
      width: 125,
      height: 30,
      align: 'center',
      baseline: 'middle'
    }).lineWidth(0.5).rect(35, 285, 125, 30).stroke();
  
  doc
    .fillColor('black')
    .fontSize(10)
    .font('Helvetica')
    .text(`${textCharpente} ${textEncuvement}`,
      167, 90, {
      width: 403,
      height: 30,
      align: 'justify'
    }).lineWidth(0.5).rect(160, 85, 417, 30).stroke()
    .text(`${textPentes} ${textInclinaison}. ${textComblesAmenageables}`,
      167, 121, {
      width: 403,
      height: 20,
      align: 'justify'
    }).lineWidth(0.5).rect(160, 115, 417, 20).stroke()
    .text(textCouverture, 
      167, 150, {
      width: 403,
      height: 30,
      align: 'justify'
    }).lineWidth(0.5).rect(160, 145, 417, 30).stroke()
    .text(textGouttiere,
      167, 191, {
      width: 403,
      height: 20,
      align: 'justify'
    }).lineWidth(0.5).rect(160, 185, 417, 20).stroke()
    .text(texts.DEBORD_FACADE,
      167, 221, {
      width: 403,
      height: 20,
      align: 'justify'
    }).lineWidth(0.5).rect(160, 215, 417, 20).stroke()
    .text(texts.VENTILATIONS,
      167, 250, {
      width: 403,
      height: 30,
      align: 'justify'
    }).lineWidth(0.5).rect(160, 245, 417, 30).stroke()
    .text(textConduit,
      167, 291, {
      width: 403,
      height: 30,
      align: 'justify'
    }).lineWidth(0.5).rect(160, 285, 417, 30).stroke();

  //LISTE MENUISERIES
  let textBaiesCoulissants = texts.NON_LIEU;
  if (Number.parseInt(cData.ouverture.nombreBaiesCoulissants)) {
    if (gForm.menuiseries.couleur === SKeys.CouleursMenuiserie.BLANC) {
      if (gForm.menuiseries.materiau === SKeys.MateriauxMenuiserie.ALU || gForm.menuiseries.materiau === SKeys.MateriauxMenuiserie.PVC) {
        textBaiesCoulissants = texts.BAIES_COULISSANTES_BLANC_PVC_ALU;
      }
    }
    else if (gForm.menuiseries.couleur === SKeys.CouleursMenuiserie.GRIS_ANTHARCITE) {
      if (gForm.menuiseries.materiau === SKeys.MateriauxMenuiserie.ALU) {
        textBaiesCoulissants = texts.BAIES_COULISSANTES_GRIS_ALU;
      }
      else if (gForm.menuiseries.materiau === SKeys.MateriauxMenuiserie.PLAXE) {
        textBaiesCoulissants = texts.BAIES_COULISSANTES_GRIS_PLAXE;
      }
    }
    else if (gForm.menuiseries.couleur === SKeys.CouleursMenuiserie.ABF) {
      if (gForm.menuiseries.materiau === SKeys.MateriauxMenuiserie.ALU) {
        textBaiesCoulissants = texts.BAIES_COULISSANTES_ABF_ALU;
      }
      else if (gForm.menuiseries.materiau === SKeys.MateriauxMenuiserie.PLAXE) {
        textBaiesCoulissants = texts.BAIES_COULISSANTES_ABF_PLAXE;
      }
    }
  }

  let textPorteEntree = texts.NON_LIEU;
  if (gForm.portes.nombreEntree) {
    textPorteEntree = texts.PORTE_ENTREE
  }

  let textPorteFenetre = '';
  if (gForm.menuiseries.couleur === SKeys.CouleursMenuiserie.BLANC) {
    if (gForm.menuiseries.materiau === SKeys.MateriauxMenuiserie.ALU) {
      textPorteFenetre = texts.PORTE_FENETRE_BLANC_ALU;
    }
    else if (gForm.menuiseries.materiau === SKeys.MateriauxMenuiserie.PVC) {
      textPorteFenetre = texts.PORTE_FENETRE_BLANC_PVC;
    }
  }
  else if (gForm.menuiseries.couleur === SKeys.CouleursMenuiserie.GRIS_ANTHARCITE) {
    if (gForm.menuiseries.materiau === SKeys.MateriauxMenuiserie.ALU) {
      textPorteFenetre = texts.PORTE_FENETRE_GRIS_ALU;
    }
    else if (gForm.menuiseries.materiau === SKeys.MateriauxMenuiserie.PLAXE) {
      textPorteFenetre = texts.PORTE_FENETRE_GRIS_PLAXE;
    }
  }
  else if (gForm.menuiseries.couleur === SKeys.CouleursMenuiserie.ABF) {
    if (gForm.menuiseries.materiau === SKeys.MateriauxMenuiserie.ALU) {
      textPorteFenetre = texts.PORTE_FENETRE_ABF_ALU;
    }
    else if (gForm.menuiseries.materiau === SKeys.MateriauxMenuiserie.PLAXE) {
      textPorteFenetre = texts.PORTE_FENETRE_ABF_PLAXE;
    }
  }

  let textPorteGarage = '';
  if (Number.parseInt(gForm.portes.nombreGarage)) {
    if (gForm.portes.modeleGarage === SKeys.ModelesPorteGarage._902) {
      if (gForm.portes.typeGarage === SKeys.TypesPorteGarage.NON_MOTORISEE) {
        if (gForm.portes.couleurGarage === SKeys.CouleursMenuiserie.BLANC) {
          textPorteGarage = texts.PORTE_GARAGE_BLANC_902;
        }
        else if (gForm.portes.couleurGarage === SKeys.CouleursMenuiserie.GRIS_ANTHARCITE) {
          textPorteGarage = texts.PORTE_GARAGE_GRIS_902;
        }
        else if (gForm.portes.couleurGarage === SKeys.CouleursMenuiserie.ABF) {
          textPorteGarage = texts.PORTE_GARAGE_ABF_902;
        }
      }
      else if (gForm.portes.typeGarage === SKeys.TypesPorteGarage.MOTORISEE) {
        if (gForm.portes.couleurGarage === SKeys.CouleursMenuiserie.BLANC) {
          textPorteGarage = texts.PORTE_GARAGE_BLANC_902_MOTORISEE;
        }
        else if (gForm.portes.couleurGarage === SKeys.CouleursMenuiserie.GRIS_ANTHARCITE) {
          textPorteGarage = texts.PORTE_GARAGE_GRIS_902_MOTORISEE;
        }
        else if (gForm.portes.couleurGarage === SKeys.CouleursMenuiserie.ABF) {
          textPorteGarage = texts.PORTE_GARAGE_ABF_902_MOTORISEE;
        }
      }
    }
    else if (gForm.portes.modeleGarage === SKeys.ModelesPorteGarage.SECTIONELLE) {
      if (gForm.portes.typeGarage === SKeys.TypesPorteGarage.NON_MOTORISEE) {
        if (gForm.portes.couleurGarage === SKeys.CouleursMenuiserie.BLANC) {
          textPorteGarage = texts.PORTE_GARAGE_BLANC_SECT;
        }
        else if (gForm.portes.couleurGarage === SKeys.CouleursMenuiserie.GRIS_ANTHARCITE) {
          textPorteGarage = texts.PORTE_GARAGE_GRIS_SECT;
        }
        else if (gForm.portes.couleurGarage === SKeys.CouleursMenuiserie.ABF) {
          textPorteGarage = texts.PORTE_GARAGE_ABF_SECT;
        }
      }
      else if (gForm.portes.typeGarage === SKeys.TypesPorteGarage.MOTORISEE) {
        if (gForm.portes.couleurGarage === SKeys.CouleursMenuiserie.BLANC) {
          textPorteGarage = texts.PORTE_GARAGE_BLANC_SECT_MOTORISEE;
        }
        else if (gForm.portes.couleurGarage === SKeys.CouleursMenuiserie.GRIS_ANTHARCITE) {
          textPorteGarage = texts.PORTE_GARAGE_GRIS_SECT_MOTORISEE;
        }
        else if (gForm.portes.couleurGarage === SKeys.CouleursMenuiserie.ABF) {
          textPorteGarage = texts.PORTE_GARAGE_ABF_SECT_MOTORISEE;
        }
      }
    }
  }
  else {
    textPorteGarage = texts.NON_LIEU;
  }

  doc
    .fillColor('blue')
    .fontSize(16)
    .font('Helvetica-Bold')
    .text('MENUISERIES', 
      35, 330, {
      width: 500,
      align: 'left',
      underline: true
    });

  doc
    .fillColor('black')
    .fontSize(10)
    .font('Helvetica-Bold')
    .text('PORTE D\'ENTRÉE :', 
      35, 384, {
      width: 125,
      height: 58,
      align: 'center',
      baseline: 'middle'
    }).lineWidth(0.5).rect(35, 355, 125, 58).stroke();

  doc
    .fillColor('black')
    .fontSize(10)
    .font('Helvetica')
    .text(textPorteEntree,
      167, 362, {
      width: 403,
      height: 58,
      align: 'justify'
    }).lineWidth(0.5).rect(160, 355, 417, 58).stroke();

  doc.image(imgPortesEntree, 40, 430, {fit:[533, 223], align: 'center', valign: 'center'});

  doc
    .fillColor('black')
    .fontSize(10)
    .font('Helvetica-Bold')
    .text('GAMME 1 :', 
      35, 418, {
      width: 269,
      height: 20,
      align: 'right',
      underline: true
    })
    .fillColor('red')
    .fontSize(10)
    .font('Helvetica-Bold')
    .text(texts.PORTE_ENTREE_GAMME_1_PRIX, 
      309, 418, {
      width: 268,
      height: 20,
      align: 'left',
      underline: true
    }).lineWidth(0.5).rect(35, 413, 542, 242).stroke();

  doc
    .fillColor('black')
    .fontSize(10)
    .font('Helvetica-Bold')
    .text('GAMME 2 :', 
      35, 660, {
      width: 269,
      height: 20,
      align: 'right',
      underline: true
    })
    .fillColor('red')
    .fontSize(10)
    .font('Helvetica-Bold')
    .text(texts.PORTE_ENTREE_GAMME_2_PRIX, 
      309, 660, {
      width: 268,
      height: 20,
      align: 'left',
      underline: true
    })
    .fillColor('#3D73CB')
    .fontSize(10)
    .font('Helvetica-Bold')
    .text(texts.PORTE_ENTREE_GAMME_2_LISTE, 
      40, 673, {
      width: 532,
      height: 20,
      align: 'left',
    }).lineWidth(0.5).rect(35, 655, 542, 30).stroke();

    doc
    .fillColor('black')
    .fontSize(10)
    .font('Helvetica-Bold')
    .text('GAMME 3 :', 
      35, 691, {
      width: 269,
      height: 20,
      align: 'right',
      underline: true
    })
    .fillColor('red')
    .fontSize(10)
    .font('Helvetica-Bold')
    .text(texts.PORTE_ENTREE_GAMME_3_PRIX, 
      309, 691, {
      width: 268,
      height: 20,
      align: 'left',
      underline: true
    })
    .fillColor('#3D73CB')
    .fontSize(10)
    .font('Helvetica-Bold')
    .text(texts.PORTE_ENTREE_GAMME_3_LISTE, 
      40, 706, {
      width: 532,
      height: 45,
      align: 'justify',
    }).lineWidth(0.5).rect(35, 685, 542, 58).stroke();

  
  //*************************************************************************************************
  //******************************               PAGE 4              ********************************
  //*************************************************************************************************

  let textPorteService = "NON LIEU";
  let textPorteServiceGamme1Prix = "NON INCLUS";
  let textPorteServiceGamme2Prix = "NON INCLUS";
  let textPorteServiceGamme2Liste = "";
  if (Number(gForm.portes.nombreService)) {
    textPorteService = texts.PORTE_SERVICE;
    textPorteServiceGamme1Prix = texts.PORTE_SERVICE_GAMME_1_PRIX;
    textPorteServiceGamme2Prix = texts.PORTE_SERVICE_GAMME_2_PRIX;
    textPorteServiceGamme2Liste = texts.PORTE_SERVICE_GAMME_2_LISTE;
  }

  doc.addPage({margin: 0});

  doc
    .fillColor('black')
    .fontSize(10)
    .font('Helvetica-Bold')
    .text('PORTE DE SERVICE :', 
      35, 111, {
      width: 125,
      height: 58,
      align: 'center',
    }).lineWidth(0.5).rect(35, 85, 542, 58).stroke();

  doc
    .fillColor('black')
    .fontSize(10)
    .font('Helvetica')
    .text(textPorteService,
      167, 92, {
      width: 403,
      height: 58,
      align: 'justify'
    }).lineWidth(0.5).rect(160, 85, 417, 58).stroke();

    doc.image(imgPortesService, 40, 160, {fit:[533, 151], align: 'center', valign: 'center'});

    doc
      .fillColor('black')
      .fontSize(10)
      .font('Helvetica-Bold')
      .text('GAMME 1 :', 
        35, 148, {
        width: 269,
        height: 20,
        align: 'right',
        underline: true
      })
      .fillColor('red')
      .fontSize(10)
      .font('Helvetica-Bold')
      .text(textPorteServiceGamme1Prix, 
        309, 148, {
        width: 268,
        height: 20,
        align: 'left',
        underline: true
      }).lineWidth(0.5).rect(35, 143, 542, 170).stroke();

      doc
      .fillColor('black')
      .fontSize(10)
      .font('Helvetica-Bold')
      .text('GAMME 2 :', 
        35, 318, {
        width: 269,
        height: 20,
        align: 'right',
        underline: true
      })
      .fillColor('red')
      .fontSize(10)
      .font('Helvetica-Bold')
      .text(textPorteServiceGamme2Prix, 
        309, 318, {
        width: 268,
        height: 20,
        align: 'left',
        underline: true
      })
      .fillColor('#3D73CB')
      .fontSize(10)
      .font('Helvetica-Bold')
      .text(textPorteServiceGamme2Liste, 
        40, 331, {
        width: 532,
        height: 20,
        align: 'left',
      }).lineWidth(0.5).rect(35, 313, 542, 30).stroke();

  doc
    .fillColor('black')
    .fontSize(10)
    .font('Helvetica-Bold')
    .text('BAIES ET COULISSANTS :', 
      35, 363, {
      width: 125,
      height: 30,
      align: 'center',
      baseline: 'middle'
    }).lineWidth(0.5).rect(35, 353, 125, 30).stroke()
    .text('FENÊTRES\nPORTES-FENÊTRES :', 
      35, 403, {
      width: 125,
      height: 30,
      align: 'center',
      baseline: 'middle'
    }).lineWidth(0.5).rect(35, 393, 125, 30).stroke()
    .text('VOLETS :', 
      35, 449, {
      width: 125,
      height: 30,
      align: 'center',
      baseline: 'middle'
    }).lineWidth(0.5).rect(35, 433, 125, 30).stroke()
    .text('RENFORTS VITRAGE :', 
      35, 489, {
      width: 125,
      height: 30,
      align: 'center',
      baseline: 'middle'
    }).lineWidth(0.5).rect(35, 473, 125, 30).stroke()
    .text('PORTE DE GARAGE :', 
      35, 529, {
      width: 125,
      height: 30,
      align: 'center',
      baseline: 'middle'
    }).lineWidth(0.5).rect(35, 513, 125, 30).stroke();

    doc
    .fillColor('black')
    .fontSize(10)
    .font('Helvetica')
    .text(textBaiesCoulissants,
      167, 358, {
      width: 403,
      height: 30,
      align: 'justify'
    }).lineWidth(0.5).rect(160, 353, 417, 30).stroke()
    .text(textPorteFenetre, 
      167, 398, {
      width: 403,
      height: 30,
      align: 'justify'
    }).lineWidth(0.5).rect(160, 393, 417, 30).stroke()
    .text(texts.VOLETS,
      167, 438, {
      width: 403,
      height: 30,
      align: 'justify'
    }).lineWidth(0.5).rect(160, 433, 417, 30).stroke()
    .text(texts.RENFORTS_VITRAGE,
      167, 478, {
      width: 403,
      height: 30,
      align: 'justify'
    }).lineWidth(0.5).rect(160, 473, 417, 30).stroke()
    .text(textPorteGarage,
      167, 518, {
      width: 403,
      height: 30,
      align: 'justify'
    }).lineWidth(0.5).rect(160, 513, 417, 30).stroke();


  //*************************************************************************************************
  //******************************               PAGE 5              ********************************
  //*************************************************************************************************
  let textIsoPlafond = '';
  if (gForm.placos.plafond === SKeys.TypesPlafondPlaco.SOUFFLE) {
    textIsoPlafond = texts.ISOLATION_PLAFOND_SOUFFLE;
  }
  else if (gForm.placos.plafond === SKeys.TypesPlafondPlaco.POSE) {
    textIsoPlafond = texts.ISOLATION_PLAFOND_POSE;
  }

  let textCloisons = '';
  if (gForm.placos.cloison === Number.parseInt(SKeys.EpaisseursPlaco._50)) {
    textCloisons = texts.CLOISON_50;
  }
  else if (gForm.placos.cloison === Number.parseInt(SKeys.EpaisseursPlaco._72)) {
    textCloisons = texts.CLOISON_72;
  }

  let textCloisonsNonIso = '';
  if (Number.parseInt(cData.electricite.pieces.nombreGarage) || parseFloat(cData.electricite.pieces.surfaceGarage)) {
    textCloisonsNonIso = texts.CLOISON_NON_ISO_GARAGE;
  }
  else {
    textCloisonsNonIso = texts.NON_LIEU;
  }


  doc.addPage({margin: 0});

  //LISTE ISOLATION-DISTRIBUTION
  doc
    .fillColor('blue')
    .fontSize(16)
    .font('Helvetica-Bold')
    .text('ISOLATION-DISTRIBUTION', 
      35, 60, {
      width: 500,
      align: 'left',
      underline: true
    });

  //IMAGES ISOLATION
  doc.image(imgIso1, 29,  79, {fit: [243, 89], align: 'center', valign: 'center'});
  doc.image(imgIso2, 291, 74, {fit: [133, 97], align: 'center', valign: 'center'});
  doc.image(imgIso3, 431, 77, {fit: [145, 95], align: 'center', valign: 'center'});

  doc
    .fillColor('black')
    .fontSize(10)
    .font('Helvetica-Bold')
    .text('ISOLATION DU PLAFOND :', 
      35, 195, {
      width: 125,
      height: 58,
      align: 'center',
      baseline: 'middle'
    }).lineWidth(0.5).rect(35, 172, 125, 58).stroke()
    .text('ISOLATION\n DES MURS :', 
      35, 263, {
      width: 125,
      height: 58,
      align: 'center',
      baseline: 'middle'
    }).lineWidth(0.5).rect(35, 240, 125, 58).stroke()
    .text('CLOISONS :', 
      35, 332, {
      width: 125,
      height: 45,
      align: 'center',
      baseline: 'middle'
    }).lineWidth(0.5).rect(35, 308, 125, 45).stroke()
    .text('CLOISONS ISOLANTES ENTRE PIÈCE NON ISOLÉE ET HABITATION :', 
      35, 381, {
      width: 125,
      height: 58,
      align: 'center',
      baseline: 'middle'
    }).lineWidth(0.5).rect(35, 363, 125, 58).stroke()
    .text('PORTES INTÉRIEURES :', 
      35, 455, {
      width: 125,
      height: 45,
      align: 'center',
      baseline: 'middle'
    }).lineWidth(0.5).rect(35, 431, 125, 45).stroke();

  doc
    .fillColor('black')
    .fontSize(10)
    .font('Helvetica')
    .text(textIsoPlafond,
      167, 179, {
      width: 403,
      height: 58,
      align: 'justify'
    }).lineWidth(0.5).rect(160, 172, 417, 58).stroke()
    .text(texts.ISOLATION_MURS,
      167, 247, {
      width: 403,
      height: 58,
      align: 'justify'
    }).lineWidth(0.5).rect(160, 240, 417, 58).stroke()
    .text(textCloisons, 
      167, 315, {
      width: 403,
      height: 45,
      align: 'justify'
    }).lineWidth(0.5).rect(160, 308, 417, 45).stroke()
    .text(textCloisonsNonIso,
      167, 370, {
      width: 403,
      height: 58,
      align: 'justify'
    }).lineWidth(0.5).rect(160, 363, 417, 58).stroke()
    .text(texts.PORTES_INTERIEURES,
      167, 438, {
      width: 403,
      height: 45,
      align: 'justify'
    }).lineWidth(0.5).rect(160, 431, 417, 45).stroke();

    doc.image(imgIsoPortes, 40, 493, {fit:[533, 170], align: 'center', valign: 'center'});

    doc
      .fillColor('black')
      .fontSize(10)
      .font('Helvetica-Bold')
      .text('GAMME 1 :', 
        35, 481, {
        width: 269,
        height: 20,
        align: 'right',
        underline: true
      })
      .fillColor('red')
      .fontSize(10)
      .font('Helvetica-Bold')
      .text(texts.PORTES_INTERIEURES_GAMME_1_PRIX, 
        309, 481, {
        width: 268,
        height: 20,
        align: 'left',
        underline: true
      }).lineWidth(0.5).rect(35, 476, 542, 189).stroke();

      doc
      .fillColor('black')
      .fontSize(10)
      .font('Helvetica-Bold')
      .text('GAMME 2 :', 
        35, 670, {
        width: 269,
        height: 20,
        align: 'right',
        underline: true
      })
      .fillColor('red')
      .fontSize(10)
      .font('Helvetica-Bold')
      .text(texts.PORTES_INTERIEURES_GAMME_2_PRIX, 
        309, 670, {
        width: 268,
        height: 20,
        align: 'left',
        underline: true
      })
      .fillColor('#3D73CB')
      .fontSize(10)
      .font('Helvetica-Bold')
      .text(texts.PORTES_INTERIEURES_GAMME_2_LISTE, 
        40, 682, {
        width: 532,
        height: 20,
        align: 'left',
      }).lineWidth(0.5).rect(35, 665, 542, 30).stroke();


  //*************************************************************************************************
  //******************************               PAGE 6              ********************************
  //*************************************************************************************************
  let textAppareillage = '';
  let imageDomotique = imgElecDooxie;
  if (gForm.electricites.type === SKeys.TypesElectricite.DOOXIE) {
    textAppareillage = "LEGRAND DOOXIE";
  }
  else if (gForm.electricites.type === SKeys.TypesElectricite.SQUARE_HOMECO) {
    textAppareillage = "EUR'OHM SQUARE";
    imageDomotique = imgElecSquareHomeco;
  }

  let textElecWcDressing = texts.NON_LIEU;
  if (Number.parseInt(cData.electricite.pieces.nombreWc)) {
    textElecWcDressing = texts.ELEC_WC_DRESSING;
  }

  let textElecGarage = texts.NON_LIEU;
  if (Number.parseInt(cData.electricite.pieces.nombreGarage)) {
    textElecGarage = texts.ELEC_GARAGE;
  }

  doc.addPage({margin: 0});

  doc.image(imageDomotique , 41 , 111, {fit: [109, 60], align: 'center', valign: 'center'});

  doc.image(imgElecGPlay   , 210, 88 , {fit: [160, 24], align: 'center', valign: 'center'});
  doc.image(imgElecAppStore, 210, 115, {fit: [160, 24], align: 'center', valign: 'center'});
  doc.image(imgElecPrises  , 360, 88 , {fit: [180, 52], align: 'center', valign: 'center'});

  doc.image(imgElecAlexa   , 432 , 145, {fit: [27, 26], align: 'center', valign: 'center'});
  doc.image(imgElecGAssist , 462 , 145, {fit: [27, 26], align: 'center', valign: 'center'});

  //LISTE ELECTRICITE
  doc
  .fillColor('blue')
  .fontSize(16)
  .font('Helvetica-Bold')
  .text('ÉLECTRICITÉ', 
    35, 60, {
    width: 500,
    align: 'left',
    underline: true
  });

  doc
    .fillColor('black')
    .fontSize(10)
    .font('Helvetica-Bold')
    .text('APPAREILLAGE + DOMOTIQUE RE2020 :', 
      35, 95, {
      width: 125,
      height: 88,
      align: 'center',
      baseline: 'middle'
    }).lineWidth(0.5).rect(35, 85, 125, 88).stroke()
    .text('VMC :', 
      35, 194, {
      width: 125,
      height: 30,
      align: 'center',
      baseline: 'middle'
    }).lineWidth(0.5).rect(35, 183, 125, 20).stroke()
    .text('APPAREILLAGE :', 
      35, 224, {
      width: 125,
      height: 30,
      align: 'center',
      baseline: 'middle'
    }).lineWidth(0.5).rect(35, 213, 125, 20).stroke()
    .text('QUANTITATIFS :', 
      35, 259, {
      width: 125,
      height: 60,
      align: 'center',
      baseline: 'middle'
    }).lineWidth(0.5).rect(35, 233, 125, 60).stroke();

  doc
    .fillColor('black')
    .fontSize(10)
    .font('Helvetica-Bold')
    .text('Cuisine :', 
      160, 341, {
      width: 125,
      height: 94,
      align: 'center',
      baseline: 'middle'
    }).lineWidth(0.5).rect(160, 293, 125, 94).stroke()
    .text('Local technique :', 
      160, 418, {
      width: 125,
      height: 58,
      align: 'center',
      baseline: 'middle'
    }).lineWidth(0.5).rect(160, 387, 125, 58).stroke()
    .text('Pièce de vie :', 
      160, 461, {
      width: 125,
      height: 30,
      align: 'center',
      baseline: 'middle'
    }).lineWidth(0.5).rect(160, 445, 125, 30).stroke()
    .text('Extérieur :', 
      160, 491, {
      width: 125,
      height: 30,
      align: 'center',
      baseline: 'middle'
    }).lineWidth(0.5).rect(160, 475, 125, 30).stroke()
    .text('WC / Dressing :', 
      160, 516, {
      width: 125,
      height: 20,
      align: 'center',
      baseline: 'middle'
    }).lineWidth(0.5).rect(160, 505, 125, 20).stroke()
    .text('Bains / SDE :', 
      160, 541, {
      width: 125,
      height: 30,
      align: 'center',
      baseline: 'middle'
    }).lineWidth(0.5).rect(160, 525, 125, 30).stroke()
    .text('Chambre / Bureaux :', 
      160, 579, {
      width: 125,
      height: 45,
      align: 'center',
      baseline: 'middle'
    }).lineWidth(0.5).rect(160, 555, 125, 45).stroke()
    .text('DGT / Palier :', 
      160, 611, {
      width: 125,
      height: 20,
      align: 'center',
      baseline: 'middle'
    }).lineWidth(0.5).rect(160, 600, 125, 20).stroke()
    .text('Garage :', 
      160, 636, {
      width: 125,
      height: 30,
      align: 'center',
      baseline: 'middle'
    }).lineWidth(0.5).rect(160, 620, 125, 30).stroke();

  doc
    .fillColor('black')
    .fontSize(10)
    .font('Helvetica')
    .text('',
      167, 85, {
      width: 403,
      height: 58,
      align: 'justify'
    }).lineWidth(0.5).rect(160, 85, 417, 58).stroke()
    .text(texts.DOMOTIQUE_DOOXIE,
      167, 148, {
      width: 403,
      height: 58,
      align: 'justify'
    }).lineWidth(0.5).rect(160, 143, 417, 30).stroke()
    .text(texts.VMC,
      167, 189, {
      width: 403,
      height: 20,
      align: 'justify'
    }).lineWidth(0.5).rect(160, 183, 417, 20).stroke()
    .text(textAppareillage,
      167, 219, {
      width: 403,
      height: 20,
      align: 'justify'
    }).lineWidth(0.5).rect(160, 213, 417, 20).stroke()
    .text(texts.QUANTITATIFS_1,
      167, 238, {
      width: 403,
      height: 30,
      align: 'justify'
    }).lineWidth(0.5).rect(160, 233, 417, 30).stroke()
    .text(texts.QUANTITATIFS_2,
      167, 268, {
      width: 403,
      height: 30,
      align: 'justify'
    }).lineWidth(0.5).rect(160, 263, 417, 30).stroke();

  doc
    .fillColor('black')
    .fontSize(10)
    .font('Helvetica')
    .text(texts.ELEC_CUISINE,
      292, 300, {
      width: 278,
      height: 94,
      align: 'justify'
    }).lineWidth(0.5).rect(285, 293, 292, 94).stroke()
    .text(texts.ELEC_CELLIER,
      292, 394, {
      width: 278,
      height: 58,
      align: 'justify'
    }).lineWidth(0.5).rect(285, 387, 292, 58).stroke()
    .text(texts.ELEC_PDV,
      292, 450, {
      width: 278,
      height: 30,
      align: 'justify'
    }).lineWidth(0.5).rect(285, 445, 292, 30).stroke()
    .text(texts.ELEC_EXTERIEUR,
      292, 480, {
      width: 278,
      height: 30,
      align: 'justify'
    }).lineWidth(0.5).rect(285, 475, 292, 30).stroke()
    .text(textElecWcDressing,
      292, 510, {
      width: 278,
      height: 20,
      align: 'justify'
    }).lineWidth(0.5).rect(285, 505, 292, 20).stroke()
    .text(texts.ELEC_BAINS_SDE,
      292, 530, {
      width: 278,
      height: 30,
      align: 'justify'
    }).lineWidth(0.5).rect(285, 525, 292, 30).stroke()
    .text(texts.ELEC_CHAMBRE_BUREAUX,
      292, 562, {
      width: 278,
      height: 45,
      align: 'justify'
    }).lineWidth(0.5).rect(285, 555, 292, 45).stroke()
    .text(texts.ELEC_DGT_PALLIER,
      292, 605, {
      width: 278,
      height: 20,
      align: 'justify'
    }).lineWidth(0.5).rect(285, 600, 292, 20).stroke()
    .text(textElecGarage,
      292, 625, {
      width: 278,
      height: 30,
      align: 'justify'
    }).lineWidth(0.5).rect(285, 620, 292, 30).stroke();

  

  //*************************************************************************************************
  //******************************               PAGE 7              ********************************
  //*************************************************************************************************
  let titleChauffage = 'CHAUFFAGE / \nEAU CHAUDE';
  let textChauffage1 = '';
  let textChauffage2 = '';
  let srcImgChauffage1 = null;
  let srcImgChauffage2 = null;
  let textDiffusion = '';
  let offsetChauffageT = 0;
  let offsetChauffage1 = 0;
  let offsetChauffage2 = 0;


  if (gForm.chauffages.chauffage === SKeys.TypesChauffage.CLIM_RADIATEUR_ELEC) {
    textChauffage1 = texts.CHAUFFAGE_1_CLIM_RAD_ELEC;
    textChauffage2 = texts.CHAUFFAGE_2_CLIM_RAD_ELEC;
    textDiffusion = texts.DIFFUSION_CLIM_RAD_ELEC;
    srcImgChauffage1 = imgChauffageMokai;
    srcImgChauffage2 = imgChauffageBallon;
    offsetChauffageT = 12;
    offsetChauffage1 = 8;
    offsetChauffage2 = 11;
  }
  else if (gForm.chauffages.chauffage === SKeys.TypesChauffage.POELE_RADIATEUR_ELEC) {
    textChauffage1 = texts.CHAUFFAGE_1_POELE;
    textChauffage2 = texts.CHAUFFAGE_2_POELE;
    textDiffusion = texts.DIFFUSION_POELE;
    srcImgChauffage1 = imgChauffagePoele;
    srcImgChauffage2 = imgChauffageBallon;
    offsetChauffageT = 12;
    offsetChauffage2 = 11;
  }
  else if (gForm.chauffages.chauffage === SKeys.TypesChauffage.PAC_PLANCHER_CHAUFFANT) {
    textChauffage1 = texts.CHAUFFAGE_1_PAC_AIR_EAU;
    textDiffusion = texts.DIFFUSION_SANS_RAD_EAU;
    srcImgChauffage1 = imgChauffageYutaki;
    offsetChauffageT = 12;
    offsetChauffage1 = 8;
  }
  else if (gForm.chauffages.chauffage === SKeys.TypesChauffage.GAZ_PLANCHER_CHAUFFANT) {
    titleChauffage += ' / \nÉNERGIE RENOUVELABLE';
    textChauffage1 = texts.CHAUFFAGE_1_GAZ;
    textChauffage2 = texts.CHAUFFAGE_2_GAZ;
    textDiffusion = texts.DIFFUSION_SANS_RAD_EAU;
    srcImgChauffage1 = imgChauffageChaudiere;
    srcImgChauffage2 = imgChauffagePhotoV;
    offsetChauffage1 = 8;
  }
  else if (gForm.chauffages.chauffage === SKeys.TypesChauffage.PAC_RADIATEUR_EAU) {
    textChauffage1 = texts.CHAUFFAGE_1_PAC_AIR_EAU;
    textDiffusion = texts.DIFFUSION_RAD_EAU;
    srcImgChauffage1 = imgChauffageYutaki;
    offsetChauffageT = 12;
    offsetChauffage1 = 8;
  }
  else if (gForm.chauffages.chauffage === SKeys.TypesChauffage.GAZ_RADIATEUR_EAU) {
    titleChauffage += ' / \nÉNERGIE RENOUVELABLE';
    textChauffage1 = texts.CHAUFFAGE_1_GAZ;
    textChauffage2 = texts.CHAUFFAGE_2_GAZ;
    textDiffusion = texts.DIFFUSION_RAD_EAU;
    srcImgChauffage1 = imgChauffageChaudiere;
    srcImgChauffage2 = imgChauffagePhotoV;
    offsetChauffage1 = 8;
  }
  else if (gForm.chauffages.chauffage === SKeys.TypesChauffage.PAC_GAINABLE) {
    textChauffage1 = texts.CHAUFFAGE_1_GAINABLE;
    textChauffage2 = texts.CHAUFFAGE_2_GAINABLE;
    textDiffusion = texts.DIFFUSION_GAINABLE;
    srcImgChauffage1 = imgChauffageGainable;
    srcImgChauffage2 = imgChauffageBallon;
    offsetChauffage1 = 8;
    offsetChauffageT = 12;
  }
  

  doc.addPage({margin: 0});

  //LISTE CHAUFFAGE
  doc
  .fillColor('blue')
  .fontSize(16)
  .font('Helvetica-Bold')
  .text('CHAUFFAGE / EAU CHAUDE', 
    35, 60, {
    width: 500,
    align: 'left',
    underline: true
  });

  if (srcImgChauffage1) {
    doc.image(srcImgChauffage1, 490, 82, {fit: [80, 54], align: 'center', valign: 'center'});
  }
  if (srcImgChauffage2) {
    doc.image(srcImgChauffage2, 490, 141, {fit: [80, 54], align: 'center', valign: 'center'});
  }

  doc
    .fillColor('black')
    .fontSize(10)
    .font('Helvetica-Bold')
    .text(`${titleChauffage} :`, 
      35, 122 + offsetChauffageT, {
      width: 125,
      height: 116,
      align: 'center',
      baseline: 'middle'
    }).lineWidth(0.5).rect(35, 80, 125, 116).stroke()
    .text('DIFFUSION :', 
      35, 213, {
      width: 125,
      height: 30,
      align: 'center',
      baseline: 'middle'
    }).lineWidth(0.5).rect(35, 196, 125, 30).stroke();

  doc
    .fillColor('black')
    .fontSize(10)
    .font('Helvetica')
    .text(textChauffage1,
      167, 103 + offsetChauffage1, {
      width: 318,
      height: 58,
      align: 'justify',
      baseline: 'middle'
    }).lineWidth(0.5).rect(160, 80, 417, 116).stroke()
    .text(textChauffage2,
      167, 157 + offsetChauffage2, {
      width: 318,
      height: 58,
      align: 'justify',
      baseline: 'middle'
    }).lineWidth(0.5).rect(160, 138, 417, 58).stroke()
    .text(textDiffusion,
      167, 202, {
      width: 403,
      height: 30,
      align: 'justify'
    }).lineWidth(0.5).rect(160, 196, 417, 30).stroke();

  //LISTE PLOMBERIE
  let textWcSimple = texts.WC_SIMPLE_NON_LIEU;
  if (Number.parseInt(cData.plomberie.wcSimple.nombre)) {
    textWcSimple = texts.WC_SIMPLE;
  }
  else if (Number.parseInt(cData.plomberie.wcSimple.nombreOption)) {
    textWcSimple = texts.WC_SIMPLE_OPTION;
  }
  else if (Number.parseInt(cData.plomberie.wcSimple.nombreClient)) {
    textWcSimple = texts.WC_SIMPLE_CLIENT;
  }

  let textWcSus = texts.WC_SUS_NON_LIEU;
  if (Number.parseInt(cData.plomberie.wcSuspendu.nombre)) {
    textWcSus = texts.WC_SUS;
  }
  else if (Number.parseInt(cData.plomberie.wcSuspendu.nombreOption)) {
    textWcSus = texts.WC_SUS_OPTION;
  }
  else if (Number.parseInt(cData.plomberie.wcSuspendu.nombreClient)) {
    textWcSus = texts.WC_SUS_CLIENT;
  }

  let textLaveMain = texts.NON_LIEU;
  if (Number.parseInt(cData.plomberie.laveMains.nombre)) {
    textLaveMain = texts.LAVE_MAIN_SIMPLE;
  }
  else if (Number.parseInt(cData.plomberie.laveMains.nombreOption)) {
    textLaveMain = texts.LAVE_MAIN_SIMPLE_OPTION;
  }
  else if (Number.parseInt(cData.plomberie.laveMains.nombreClient)) {
    textLaveMain = texts.LAVE_MAIN_SIMPLE_CLIENT;
  }

  let textLavabo = '';
  if (Number.parseInt(cData.plomberie.lavabo.nombre)) {
    textLavabo = texts.LAVABO_SIMPLE;
  }
  else if (Number.parseInt(cData.plomberie.lavabo.nombreOption)) {
    textLavabo = texts.LAVABO_SIMPLE_OPTION;
  }
  else {
    textLavabo = texts.LAVABO_SIMPLE_CLIENT;
  }

  let textBaignoire = texts.NON_LIEU;
  if (Number.parseInt(cData.plomberie.baignoire.nombre)) {
    textBaignoire = texts.BAIGNOIRE_SIMPLE;
  }
  else if (Number.parseInt(cData.plomberie.baignoire.nombreOption)) {
    textBaignoire = texts.BAIGNOIRE_SIMPLE_OPTION;
  }
  else if (Number.parseInt(cData.plomberie.baignoire.nombreClient)) {
    textBaignoire = texts.BAIGNOIRE_SIMPLE_CLIENT;
  }

  let textDouche = texts.NON_LIEU;
  if (Number.parseInt(cData.plomberie.doucheItal.nombre)) {
    textDouche = texts.DOUCHE_ITAL;
  }
  else if (Number.parseInt(cData.plomberie.doucheItal.nombreOption)) {
    textDouche = texts.DOUCHE_ITAL_OPTION;
  }
  else if (Number.parseInt(cData.plomberie.doucheItal.nombreClient)) {
    textDouche = texts.DOUCHE_ITAL_CLIENT;
  }
  else if (Number.parseInt(cData.plomberie.doucheExtraPlate.nombre)) {
    textDouche = texts.DOUCHE_PLATE;
  }
  else if (Number.parseInt(cData.plomberie.doucheExtraPlate.nombreOption)) {
    textDouche = texts.DOUCHE_PLATE_OPTION;
  }
  else if (Number.parseInt(cData.plomberie.doucheExtraPlate.nombreClient)) {
    textDouche = texts.DOUCHE_PLATE_CLIENT;
  }
  else if (Number.parseInt(cData.plomberie.bacADouche.nombre)) {
    textDouche = texts.DOUCHE_CERAM;
  }
  else if (Number.parseInt(cData.plomberie.bacADouche.nombreOption)) {
    textDouche = texts.DOUCHE_CERAM_OPTION;
  }
  else if (Number.parseInt(cData.plomberie.bacADouche.nombreClient)) {
    textDouche = texts.DOUCHE_CERAM_CLIENT;
  }

  doc
    .fillColor('blue')
    .fontSize(16)
    .font('Helvetica-Bold')
    .text('PLOMBERIE / SANITAIRES', 
      35, 236, {
      width: 500,
      align: 'left',
      underline: true
    });

  doc
    .fillColor('black')
    .fontSize(9)
    .font('Helvetica-Bold')
    .text('RÉSEAUX D\'ARRIVÉE ET D\'ÉVACUATION D\'EAU :', 
      35, 266, {
      width: 125,
      height: 30,
      align: 'center',
      baseline: 'middle'
    }).lineWidth(0.5).rect(35, 256, 125, 30).stroke()
    .text('WC :', 
      35, 302, {
      width: 125,
      height: 30,
      align: 'center',
      baseline: 'middle'
    }).lineWidth(0.5).rect(35, 286, 125, 30).stroke()
    .text('LAVE-MAIN :', 
      35, 327, {
      width: 125,
      height: 20,
      align: 'center',
      baseline: 'middle'
    }).lineWidth(0.5).rect(35, 316, 125, 20).stroke()
    .text('LAVABO :', 
      35, 347, {
      width: 125,
      height: 20,
      align: 'center',
      baseline: 'middle'
    }).lineWidth(0.5).rect(35, 336, 125, 20).stroke()
    .text('BAIGNOIRE :', 
      35, 367, {
      width: 125,
      height: 20,
      align: 'center',
      baseline: 'middle'
    }).lineWidth(0.5).rect(35, 356, 125, 20).stroke()
    .text('DOUCHE :', 
      35, 387, {
      width: 125,
      height: 20,
      align: 'center',
      baseline: 'middle'
    }).lineWidth(0.5).rect(35, 376, 125, 20).stroke()
    .text('ÉVIER/MEUBLE-VASQUE', 
      35, 407, {
      width: 125,
      height: 20,
      align: 'center',
      baseline: 'middle'
    }).lineWidth(0.5).rect(35, 396, 125, 20).stroke();

  doc
    .fillColor('black')
    .fontSize(10)
    .font('Helvetica')
    .text(texts.RESEAUX_EAU,
      167, 261, {
      width: 403,
      height: 30,
      align: 'justify'
    }).lineWidth(0.5).rect(160, 256, 417, 30).stroke()
    .text(textWcSimple,
      167, 290, {
      width: 403,
      height: 15,
      align: 'justify'
    })
    .text(textWcSus,
      167, 304, {
      width: 403,
      height: 15,
      align: 'justify'
    }).lineWidth(0.5).rect(160, 286, 417, 30).stroke()
    .text(textLaveMain,
      167, 322, {
      width: 403,
      height: 20,
      align: 'justify'
    }).lineWidth(0.5).rect(160, 316, 417, 20).stroke()
    .text(textLavabo,
      167, 342, {
      width: 403,
      height: 20,
      align: 'justify'
    }).lineWidth(0.5).rect(160, 336, 417, 20).stroke()
    .text(textBaignoire,
      167, 362, {
      width: 403,
      height: 20,
      align: 'justify'
    }).lineWidth(0.5).rect(160, 356, 417, 20).stroke()
    .text(textDouche,
      167, 382, {
      width: 403,
      height: 20,
      align: 'justify'
    }).lineWidth(0.5).rect(160, 376, 417, 20).stroke()
    .text(texts.CHARGE_CLIENT,
      167, 402, {
      width: 403,
      height: 20,
      align: 'justify'
    }).lineWidth(0.5).rect(160, 396, 417, 20).stroke();

  //LISTE CARRELAGE
  let textChapeFinition1 = texts.NON_LIEU;
  //383
  let textCarrelage = texts.NON_LIEU;
  if (gForm.revetementsSols.optionPrincipales && gForm.revetementsSols.optionPrincipales === SKeys.OuiNon.OUI) {
    //385
    textCarrelage = texts.OPTION;
    if ( !( (parseFloat(gForm.parquets.surface) === parseFloat(cData.electricite.pieces.surfaceChambres)) && (parseFloat(gForm.parquets.surface) && !(gForm.parquets.option === SKeys.OuiNon.OUI)) ) ) {
      textChapeFinition1 = texts.CHAPE_FINITION_NORMAL;
    }
  }
  else if (parseFloat(cData.electricite.pieces.surfacePdv) > 0) {
    // /!\/!\/!\/!\/!\/!\/!\ //
    //  DEMANDER A SALIH !!  //
    // /!\/!\/!\/!\/!\/!\/!\ //
    if (gForm.revetementsSols.optionAutre && (gForm.revetementsSols.optionAutre === SKeys.OuiNon.OUI || !gForm.revetementsSols.surfaceAutre)) {
      if (parseFloat(cData.electricite.pieces.surfaceGarage) > 0) {
        //373
        textCarrelage = texts.CARRELAGE_SAUF_CHAMBRES_GARAGE;
        if ( !( (parseFloat(gForm.parquets.surface) === parseFloat(cData.electricite.pieces.surfaceChambres)) && (parseFloat(gForm.parquets.surface) && !(gForm.parquets.option === SKeys.OuiNon.OUI)) ) ) {
          textChapeFinition1 = texts.CHAPE_FINITION_NORMAL;
        }
      }
      else {
        //371
        textCarrelage = texts.CARRELAGE_SAUF_CHAMBRES;
        if ( !( (parseFloat(gForm.parquets.surface) === parseFloat(cData.electricite.pieces.surfaceChambres)) && (parseFloat(gForm.parquets.surface) && !(gForm.parquets.option === SKeys.OuiNon.OUI)) ) ) {
          textChapeFinition1 = texts.CHAPE_FINITION_NORMAL;
        }
      }
    }
    else {
      if (parseFloat(gForm.revetementsSols.surfaceAutre) > 0) {
        if (parseFloat(gForm.chapes.surfaceChambre) > 0) {
          if (parseFloat(cData.electricite.pieces.surfaceGarage) > 0) {
            //381
            textCarrelage = texts.CARRELAGE_SAUF_CHAMBRES_PLAN_GARAGE;
            textChapeFinition1 = texts.CHAPE_FINITION_NON_CARRELE;
          }
          else {
            //379
            textCarrelage = texts.CARRELAGE_SAUF_CHAMBRES_PLAN;
            textChapeFinition1 = texts.CHAPE_FINITION_NON_CARRELE;
          }
        }
        else {
          if (parseFloat(cData.electricite.pieces.surfaceGarage) > 0) {
            //377
            textCarrelage = texts.CARRELAGE_SAUF_GARAGE;
          }
          else {
            //375
            textCarrelage = texts.CARRELAGE_TOUT;
          }
        }
      }
    }
  }
  if (textCarrelage === texts.NON_LIEU) {
    if ( !( (parseFloat(gForm.parquets.surface) === parseFloat(cData.electricite.pieces.surfaceChambres)) && (parseFloat(gForm.parquets.surface) && !(gForm.parquets.option === SKeys.OuiNon.OUI)) ) ) {
      textChapeFinition1 = texts.CHAPE_FINITION_NORMAL;
    }
  }

  let textGammeCarrelage = '';
  if (gForm.revetementsSols.gammePrincipales === SKeys.GammesCarrelage.INITIALE) {
    textGammeCarrelage = texts.CARRELAGE_GAMME_INITIALE;
  }
  else if (gForm.revetementsSols.gammePrincipales === SKeys.GammesCarrelage.PRESTIGE) {
    textGammeCarrelage = texts.CARRELAGE_GAMME_PRESTIGE;
  }

  let textChapeFinition2 = '';
  if (textChapeFinition1 === texts.CHAPE_FINITION_NORMAL || textChapeFinition1 === texts.CHAPE_FINITION_NON_CARRELE) {
    if ( !( parseFloat(gForm.parquets.surface) && !(gForm.parquets.option === SKeys.OuiNon.OUI)) ) {
      textChapeFinition2 = texts.CHAPE_FINITION_RAGREAGE;
    }
  }

  let textFaiences = texts.CHARGE_CLIENT;
  let ttlFaiencesMav = 0;
  let ttlFaiencesClient = 0;
  let ttlFaiencesOption = 0;
  gForm.sanitaires.forEach(element => {
    switch (element.type) {
      case SKeys.TypesSanitaires.BAIGNOIRE:
      case SKeys.TypesSanitaires.DOUCHE_ITAL:        
      case SKeys.TypesSanitaires.BAC_A_DOUCHE:
      case SKeys.TypesSanitaires.DOUCHE_EXTRA_PLATE:
        if (element.faience === SKeys.OuiOptionNon.OUI) {
          ttlFaiencesMav += element.nombre;
        }
        else if (element.faience === SKeys.OuiOptionNon.NON) {
          ttlFaiencesClient += element.nombre;
        }
        else if (element.faience === SKeys.OuiOptionNon.OPTION) {
          ttlFaiencesOption += element.nombre;
          
        }
        break;
    
      default:
        break;
    }
  });
  if (ttlFaiencesMav) {
    if (ttlFaiencesClient || ttlFaiencesOption) {
      textFaiences = texts.FAIENCES_SAUF_CLIENT;
    }
    else {
      textFaiences = texts.FAIENCES_TOUT;
    }
  }

  let textParquet = texts.PARQUET_CHOIX;
  if (parseFloat(gForm.parquets.surface)) {
    if (gForm.parquets.option === SKeys.OuiNon.OUI) {
      textParquet = texts.OPTION;
    }
  }
  else {
    textParquet = texts.CHARGE_CLIENT;
  }

  doc
    .fillColor('blue')
    .fontSize(16)
    .font('Helvetica-Bold')
    .text('CARRELAGE / FAIENCE / PARQUET', 
      35, 426, {
      width: 500,
      align: 'left',
      underline: true
    });

  doc
    .fillColor('black')
    .fontSize(9)
    .font('Helvetica-Bold')
    .text('CARRELAGE :', 
      35, 462, {
      width: 125,
      height: 30,
      align: 'center',
      baseline: 'middle'
    }).lineWidth(0.5).rect(35, 446, 125, 30).stroke()
    .text('CHAPE DE FINITION :', 
      35, 492, {
      width: 125,
      height: 30,
      align: 'center',
      baseline: 'middle'
    }).lineWidth(0.5).rect(35, 476, 125, 30).stroke()
    .text('FAIENCES :', 
      35, 521, {
      width: 125,
      height: 30,
      align: 'center',
      baseline: 'middle'
    }).lineWidth(0.5).rect(35, 506, 125, 30).stroke()
    .text('PARQUET :', 
      35, 547, {
      width: 125,
      height: 20,
      align: 'center',
      baseline: 'middle'
    }).lineWidth(0.5).rect(35, 536, 125, 20).stroke();

  doc
    .fillColor('black')
    .fontSize(10)
    .font('Helvetica')
    .text(textCarrelage,
      167, 451, {
      width: 325,
      height: 30,
      align: 'justify',
    }).lineWidth(0.5).rect(160, 446, 339, 30).stroke()
    .text(textGammeCarrelage,
      506, 456, {
      width: 64,
      height: 30,
      align: 'center',
      baseline: 'middle'
    }).lineWidth(0.5).rect(499, 446, 78, 30).stroke()
    .text(textChapeFinition1,
      167, 480, {
      width: 403,
      height: 15,
      align: 'justify'
    })
    .text(textChapeFinition2,
      167, 494, {
      width: 403,
      height: 15,
      align: 'justify'
    }).lineWidth(0.5).rect(160, 476, 417, 30).stroke()
    .text(textFaiences,
      167, 511, {
      width: 403,
      height: 30,
      align: 'justify'
    }).lineWidth(0.5).rect(160, 506, 417, 30).stroke()
    .text(textParquet,
      167, 542, {
      width: 403,
      height: 20,
      align: 'justify'
    }).lineWidth(0.5).rect(160, 536, 417, 20).stroke();

  //LISTE PEINTURE
    let textPreparations = texts.PREPARATIONS;
    let textPeinture = '';
    if (gForm.peintures.peinture === SKeys.OuiNon.NON) {
      textPreparations = texts.CHARGE_CLIENT;
      textPeinture = texts.CHARGE_CLIENT;
    }
    else if (Number.parseInt(cData.electricite.pieces.nombreGarage)) {
      textPeinture = texts.PEINTURE_SAUF_GARAGE;
    }
    else {
      textPeinture = texts.PEINTURE_TOUT;
    }


  doc
    .fillColor('blue')
    .fontSize(16)
    .font('Helvetica-Bold')
    .text('PEINTURE / PAPIERS PEINTS', 
      35, 566, {
      width: 500,
      align: 'left',
      underline: true
    });

  doc
    .fillColor('black')
    .fontSize(9)
    .font('Helvetica-Bold')
    .text('PRÉPARATIONS :', 
      35, 602, {
      width: 125,
      height: 30,
      align: 'center',
      baseline: 'middle'
    }).lineWidth(0.5).rect(35, 586, 125, 30).stroke()
    .text('PEINTURE :', 
      35, 631, {
      width: 125,
      height: 30,
      align: 'center',
      baseline: 'middle'
    }).lineWidth(0.5).rect(35, 616, 125, 30).stroke();

  doc
    .fillColor('black')
    .fontSize(10)
    .font('Helvetica')
    .text(textPreparations,
      167, 591, {
      width: 403,
      height: 30,
      align: 'justify'
    }).lineWidth(0.5).rect(160, 586, 417, 30).stroke()
    .text(textPeinture,
      167, 621, {
      width: 403,
      height: 30,
      align: 'justify'
    }).lineWidth(0.5).rect(160, 616, 417, 30).stroke()

  //RACCORDEMENTS
  doc
    .fillColor('blue')
    .fontSize(16)
    .font('Helvetica-Bold')
    .text('RACCORDEMENTS / CHEMIN D\'ACCÈS => NON INCLUS', 
      35, 656, {
      width: 500,
      align: 'left',
      underline: true
    });

  doc
    .fillColor('black')
    .fontSize(10)
    .font('Helvetica')
    .text(texts.RACCORDEMENTS,
      42, 683, {
      width: 450,
      height: 58,
      align: 'justify',
    }).lineWidth(0.5).rect(35, 676, 464, 58).stroke()
  doc
    .fillColor('black')
    .fontSize(10)
    .font('Helvetica')
    .text(texts.CHARGE_CLIENT,
      506, 700, {
      width: 64,
      height: 58,
      align: 'center',
      baseline: 'middle'
    }).lineWidth(0.5).rect(499, 676, 78, 58).stroke();

  //*************************************************************************************************
  //******************************               PAGE 8              ********************************
  //*************************************************************************************************
  let dateISO = new Date().toISOString().split('T')[0].split('-');
  dateISO = `${dateISO[2]}/${dateISO[1]}/${dateISO[0]}`;
  
  doc.addPage({margin: 0});
  
  //HEADER
  doc.image(imgHeadBudget, 176, 30, {fit: [260, 109], align: 'center', valign: 'center'});
  
  //INFORMATIONS CLIENT
  doc
    .fillColor('black')
    .fontSize(10)
    .font('Helvetica-Bold')
    .text(`${gForm.clients.civiliteA ? gForm.clients.civiliteA : 'M'}. :`, 35, 152, {
      width: 125,
      align: 'right'
    })
    .text(`${gForm.clients.civiliteB ? gForm.clients.civiliteB : 'Mme'}. :`, 35, 166, {
      width: 125,
      align: 'right'
    })
    .text('ADRESSE :', 35, 180, {
      width: 125,
      align: 'right'
    })
    .text('CP + VILLE :', 35, 194, {
      width: 125,
      align: 'right'
    })
    .text('TEL :', 35, 208, {
      width: 125,
      align: 'right'
    });

  doc
    .fillColor('black')
    .fontSize(10)
    .font('Helvetica')
    .text(`${gForm.clients.nomA ? gForm.clients.nomA : ''}`, 170, 152, {
      align: 'left'
    })
    .text(`${gForm.clients.nomB ? gForm.clients.nomB : ''}`, 170, 166, {
      align: 'left'
    })
    .text(`${gForm.clients.adresse ? gForm.clients.adresse : ''}`, 170, 180, {
      align: 'left'
    })
    .text(`${gForm.clients.codePostal ? gForm.clients.codePostal : ''} ${gForm.clients.ville ? gForm.clients.ville : ''}`, 170, 194, {
      align: 'left'
    })
    .text(`${gForm.clients.tel ? gForm.clients.tel : ''}`, 170, 208, {
      align: 'left'
    });

  doc
    .fontSize(10)
    .fillColor('black')
    .font('Helvetica-Bold')
    .text(`MODÈLE : `, 321, 152, {
      width: 121,
      align: 'right'
    })
    .text(`DATE : `, 321, 166, {
      width: 121,
      align: 'right'
    });

  doc
    .fontSize(14)
    .fillColor('blue')
    .font('Helvetica')
    .text(`${gForm.constructions.modele ? gForm.constructions.modele.toUpperCase() : ''}`, 452, 148, {
      width: 121,
      align: 'left',
      underline: true
    })
    .fontSize(12)
    .fillColor('black')
    .text(dateISO, 452, 165, {
      width: 121,
      align: 'left',
      underline: true
    });

  //PROJET DE CONSTRUCTION
  doc
    .fillColor('black')
    .fontSize(14)
    .font('Helvetica-Bold')
    .text('PROJET DE CONSTRUCTION', 0, 230, {
      align: 'center'
    });

  //SURFACES
  doc
    .fillColor('black')
    .fontSize(11)
    .font('Helvetica')
    .text('SURFACE GARAGE : ',
      170, 270, {
      width: 214,
      height: 20,
      align: 'right',
      baseline: 'middle'
    })
    .text(`${parseFloat(cData.electricite.pieces.surfaceGarage) ? parseFloat(cData.electricite.pieces.surfaceGarage).toFixed(2).replace('.', ',') : '0,00'} m²`,
      388, 270, {
      width: 112,
      height: 18,
      align: 'center',
      baseline: 'middle'
    }).lineWidth(0.5).rect(166, 260, 338, 18).stroke()
    .text('SURFACE RDC : ',
      170, 288, {
      width: 214,
      height: 20,
      align: 'right',
      baseline: 'middle'
    })
    .text(`${parseFloat(cData.videSanitaire.surfaceHabitable) ? parseFloat(cData.videSanitaire.surfaceHabitable).toFixed(2).replace('.', ',') : '0,00'} m²`,
      388, 288, {
      width: 112,
      height: 18,
      align: 'center',
      baseline: 'middle'
    }).lineWidth(0.5).rect(166, 278, 338, 18).stroke()
    .fillColor('black')
    .fontSize(11)
    .font('Helvetica-Bold')
    .text('SURFACE TOTALE : ',
      170, 306, {
      width: 214,
      height: 18,
      align: 'right',
      baseline: 'middle'
    })
    .text(`${parseFloat(cData.electricite.pieces.surfaceGarage) + parseFloat(cData.videSanitaire.surfaceHabitable) ? (parseFloat(cData.electricite.pieces.surfaceGarage) + parseFloat(cData.videSanitaire.surfaceHabitable)).toFixed(2).replace('.', ',') : '0,00'} m²`,
      388, 306, {
      width: 112,
      height: 20,
      align: 'center',
      baseline: 'middle'
    }).lineWidth(2).rect(166, 296, 338, 18).stroke()
    .fillColor('black')
    .fontSize(11)
    .font('Helvetica')
    .text('SURFACE D\'EMPRISE : ',
      170, 324, {
      width: 214,
      height: 18,
      align: 'right',
      baseline: 'middle'
    })
    .text(`${parseFloat(gForm.constructions.empriseSol) ? parseFloat(gForm.constructions.empriseSol).toFixed(2).replace('.', ',') : '0,00'} m²`,
      388, 324, {
      width: 112,
      height: 20,
      align: 'center',
      baseline: 'middle'
    }).lineWidth(0.5).rect(166, 314, 338, 18).stroke();

  //PRIX
    let prixTtcRcpAgco = parseFloat(cData.recap.ttcRcpAgco) ? Math.ceil(parseFloat(cData.recap.ttcRcpAgco)).toLocaleString('it-IT', {minimumFractionDigits: 0, maximumFractionDigits: 0}).replace('.', ' ') : '0';
    let prixDO = parseFloat(cData.recap.dommageOuvrableAgco) ? Math.ceil(parseFloat(cData.recap.dommageOuvrableAgco)).toLocaleString('it-IT', {minimumFractionDigits: 0, maximumFractionDigits: 0}).replace('.', ' ') : '0';
    let coefAjustement = parseFloat(gForm.ajustements.montant) ? (Math.ceil(parseFloat(gForm.ajustements.montant) / 100) / 10) : 0;
    coefAjustement = parseFloat(coefAjustement) === parseFloat(coefAjustement.toFixed(1)) ? coefAjustement : coefAjustement.toFixed(1);
    let labelOrientation = gForm.orientations.orientation.toString() ? gForm.orientations.orientation.toString() : '-';
    switch (labelOrientation) {
      case "nordEst":
        labelOrientation = "nord-est";
        break;
      
      case "nordOuest":
        labelOrientation = "nord-ouest";
        break;
        
      case "sudEst":
        labelOrientation = "sud-est";
        break;

      case "sudOuest":
        labelOrientation = "sud-ouest";
        break;
    
      default:
        break;
    }

    doc
    .fillColor('red')
    .fontSize(11)
    .font('Helvetica-Bold')
    .text('PRIX MAISON : ',
      39, 357, {
      width: 345,
      height: 18,
      align: 'right',
      baseline: 'middle'
    }).lineWidth(0.5).rect(35, 347, 353, 18).stroke()
    .text(`${prixTtcRcpAgco} €`,
      388, 357, {
      width: 110,
      height: 18,
      align: 'right',
      baseline: 'middle'
    }).lineWidth(0.5).rect(35, 347, 469, 18).stroke()
    .fillColor('black')
    .fontSize(11)
    .font('Helvetica-Bold')
    .text('ASSURANCES DOMMAGE-OUVRAGE : ',
      170, 375, {
      width: 214,
      height: 18,
      align: 'right',
      baseline: 'middle'
    }).lineWidth(0.5).rect(35, 365, 353, 18).stroke()
    .text(`${prixDO} €`,
      388, 375, {
      width: 110,
      height: 20,
      align: 'right',
      baseline: 'middle'
    }).lineWidth(0.5).rect(35, 365, 469, 18).stroke()
    .fillColor('black')
    .fontSize(10)
    .font('Helvetica')
    .text('OFFRE VALABLE 2 SEMAINES',
      0, 394, {
      width: 612,
      height: 20,
      align: 'center',
      baseline: 'middle'
    })
    .text(`ORIENTATION DE LA PORTE D'ENTRÉE : ${labelOrientation.toString().toUpperCase()}`,
      0, 408, {
      width: 612,
      height: 20,
      align: 'center',
      baseline: 'middle'
    });

  //OPTIONS
  let textEtudeSol = parseFloat(cData.videSanitaire.surfaceHabitable) < 150 ? texts.ETUDE_SOL_MOINS_150_TEXT : texts.ETUDE_SOL_PLUS_150_TEXT;
  let prixEtudeSol = parseFloat(cData.videSanitaire.surfaceHabitable) < 150 ? texts.ETUDE_SOL_MOINS_150_PRIX : texts.ETUDE_SOL_PLUS_150_PRIX;
  
  let textOptionCarrelage = '';
  let prixOptionCarrelage = '';
  let textOptionFaience   = '';
  let prixOptionFaience   = '';
  let textOptionParquet   = '';
  let prixOptionParquet   = '';
  let textOptionSanitaire = '';
  let prixOptionSanitaire = '';
  let textOptionPeinture  = '';
  let prixOptionPeinture  = '';
  let prixOptionParpaings = '';
  // let textOptionHybris    = '';
  // let prixOptionHybris    = '';
  
  if (parseFloat(cData.recap.options.carrelage)) {
    textOptionCarrelage = 'OPTION CARRELAGE : ';
    prixOptionCarrelage = Math.ceil(parseFloat(cData.recap.options.carrelage)).toLocaleString('it-IT', {minimumFractionDigits: 0, maximumFractionDigits: 0}).replace('.', ' ') + ' €';
  }
  if (parseFloat(cData.recap.options.faience)) {
    textOptionFaience = 'OPTION FAIENCE : ';
    prixOptionFaience = Math.ceil(parseFloat(cData.recap.options.faience)).toLocaleString('it-IT', {minimumFractionDigits: 0, maximumFractionDigits: 0}).replace('.', ' ') + ' €';
  }
  if (parseFloat(cData.recap.options.parquet)) {
    textOptionParquet = 'OPTION PARQUET : ';
    prixOptionParquet = Math.ceil(parseFloat(cData.recap.options.parquet)).toLocaleString('it-IT', {minimumFractionDigits: 0, maximumFractionDigits: 0}).replace('.', ' ') + ' €';
  }
  if (parseFloat(cData.recap.options.sanitaires)) {
    textOptionSanitaire = 'OPTION SANITAIRE : ';
    prixOptionSanitaire = Math.ceil(parseFloat(cData.recap.options.sanitaires)).toLocaleString('it-IT', {minimumFractionDigits: 0, maximumFractionDigits: 0}).replace('.', ' ') + ' €';
  }
  if (parseFloat(cData.recap.options.peinture)) {
    textOptionPeinture = 'OPTION PEINTURE : ';
    prixOptionPeinture = Math.ceil(parseFloat(cData.recap.options.peinture)).toLocaleString('it-IT', {minimumFractionDigits: 0, maximumFractionDigits: 0}).replace('.', ' ') + ' €';
  }
  if (parseFloat(cData.recap.options.parpaings)) {
    prixOptionParpaings = Math.ceil(parseFloat(cData.recap.options.parpaings)).toLocaleString('it-IT', {minimumFractionDigits: 0, maximumFractionDigits: 0}).replace('.', ' ') + ' €';
  }
  // if (parseFloat(cData.recap.options.hybris)) {
  //   textOptionHybris = 'Isolation des murs périphérique en HYBRIS';
  //   prixOptionHybris = Math.ceil(parseFloat(cData.recap.options.hybris)).toLocaleString('it-IT', {minimumFractionDigits: 0, maximumFractionDigits: 0}).replace('.', ' ') + ' €';
  // }

  doc
    .fillColor('black')
    .fontSize(11)
    .font('Helvetica-Bold')
    .text('OPTIONS',
      39, 430, {
      width: 536,
      height: 20,
      align: 'center',
      underline: true
    }).lineWidth(0.5).rect(35, 425, 542, 20).stroke()
    .fontSize(9)
    .font('Helvetica')
    .text('RACCORDEMENTS, EAUX PLUVIALES, CHEMIN D\'ACCES CHANTIER, CHEMIN FINAL ET PARKING :',
      39, 456, {
      width: 536,
      height: 20,
      align: 'left',
      baseline: 'middle'
    })
    .font('Helvetica-Bold')
    .text('NON INCLUS',
      474, 456, {
      width: 96,
      height: 20,
      align: 'center',
      baseline: 'middle'
    }).lineWidth(0.5).rect(35, 445, 542, 20).stroke()
    //1
    .font('Helvetica')
    .text(textEtudeSol,
      39, 475, {
      width: 536,
      height: 18,
      align: 'left',
      baseline: 'middle'
    })
    .font('Helvetica-Bold')
    .text(prixEtudeSol,
      474, 475, {
      width: 96,
      height: 18,
      align: 'right',
      baseline: 'middle'
    }).lineWidth(0.5).rect(35, 465, 542, 18).stroke()
    //2
    .font('Helvetica')
    .text('Prix d\'une rangées de parpaings supplémentaire dans le vide sanitaire',
      39, 493, {
      width: 536,
      height: 18,
      align: 'left',
      baseline: 'middle'
    })
    .font('Helvetica-Bold')
    .text(prixOptionParpaings,
      474, 493, {
      width: 96,
      height: 18,
      align: 'right',
      baseline: 'middle'
    }).lineWidth(0.5).rect(35, 483, 542, 18).stroke()
    //3
    .font('Helvetica')
    .text(textOptionCarrelage,
      39, 511, {
      width: 536,
      height: 18,
      align: 'left',
      baseline: 'middle'
    })
    .font('Helvetica-Bold')
    .text(prixOptionCarrelage,
      474, 511, {
      width: 96,
      height: 18,
      align: 'right',
      baseline: 'middle'
    }).lineWidth(0.5).rect(35, 501, 542, 18).stroke()
    //4
    .font('Helvetica')
    .text(textOptionFaience,
      39, 529, {
      width: 536,
      height: 18,
      align: 'left',
      baseline: 'middle'
    })
    .font('Helvetica-Bold')
    .text(prixOptionFaience,
      474, 529, {
      width: 96,
      height: 18,
      align: 'right',
      baseline: 'middle'
    }).lineWidth(0.5).rect(35, 519, 542, 18).stroke()
    //5
    .font('Helvetica')
    .text(textOptionParquet,
      39, 547, {
      width: 536,
      height: 18,
      align: 'left',
      baseline: 'middle'
    })
    .font('Helvetica-Bold')
    .text(prixOptionParquet,
      474, 547, {
      width: 96,
      height: 18,
      align: 'right',
      baseline: 'middle'
    }).lineWidth(0.5).rect(35, 537, 542, 18).stroke()
    //6
    .font('Helvetica')
    .text(textOptionSanitaire,
      39, 565, {
      width: 536,
      height: 18,
      align: 'left',
      baseline: 'middle'
    })
    .font('Helvetica-Bold')
    .text(prixOptionSanitaire,
      474, 565, {
      width: 96,
      height: 18,
      align: 'right',
      baseline: 'middle'
    }).lineWidth(0.5).rect(35, 555, 542, 18).stroke()
    //7
    .font('Helvetica')
    .text(textOptionPeinture,
      39, 583, {
      width: 536,
      height: 18,
      align: 'left',
      baseline: 'middle'
    })
    .font('Helvetica-Bold')
    .text(prixOptionPeinture,
      474, 583, {
      width: 96,
      height: 18,
      align: 'right',
      baseline: 'middle'
    }).lineWidth(0.5).rect(35, 573, 542, 18).stroke()
    //8
    .font('Helvetica')
    .text('',
      39, 601, {
      width: 536,
      height: 18,
      align: 'left',
      baseline: 'middle'
    })
    .font('Helvetica-Bold')
    .text('',
      474, 601, {
      width: 96,
      height: 18,
      align: 'right',
      baseline: 'middle'
    }).lineWidth(0.5).rect(35, 591, 542, 18).stroke()
    //9
    .font('Helvetica')
    .text('',
      39, 619, {
      width: 536,
      height: 18,
      align: 'left',
      baseline: 'middle'
    })
    .font('Helvetica-Bold')
    .text('',
      474, 619, {
      width: 96,
      height: 18,
      align: 'right',
      baseline: 'middle'
    }).lineWidth(0.5).rect(35, 609, 542, 18).stroke()
    //10
    .font('Helvetica')
    .text('',
      39, 637, {
      width: 536,
      height: 18,
      align: 'left',
      baseline: 'middle'
    })
    .font('Helvetica-Bold')
    .text('',
      474, 637, {
      width: 96,
      height: 18,
      align: 'right',
      baseline: 'middle'
    }).lineWidth(0.5).rect(35, 627, 542, 18).stroke();

  doc
    .lineWidth(0.5).rect(35, 675, 542, 58).fillAndStroke('#c6e0b4', 'black')
    .fillColor('#c65911')
    .fontSize(9)
    .font('Helvetica-Bold')
    .text('MAISONS À VIVRE - VIVRENBOIS SARL',
      35, 685, {
      width: 199,
      height: 18,
      align: 'center',
    })
    .text('RCS BLOIS 507 615 490 00038',
      35, 700, {
      width: 199,
      height: 18,
      align: 'center',
    })
    .text('Mail : ',
      35, 715, {
      width: 69,
      height: 18,
      align: 'center',
    })
    .fillColor('blue')
    .text('contact@maisons-avivre.com',
      58, 715, {
      width: 176,
      height: 18,
      align: 'center',
      underline: true
    })
    .fillColor('#c65911')
    .text('APE : 4120A',
      238, 700, {
      width: 136,
      height: 18,
      align: 'center',
    })
    .text('Site : ',
      238, 715, {
      width: 136,
      height: 18,
      align: 'left',
    })
    .fillColor('blue')
    .text('www.maisons-avivre.com',
      238, 715, {
      width: 136,
      height: 18,
      align: 'right',
      underline: true
    })
    .fillColor('#c65911')
    .text('27 RUE ANDRE BOULLE',
      378, 685, {
      width: 199,
      height: 18,
      align: 'center',
    })
    .text('41000 BLOIS',
    378, 700, {
      width: 199,
      height: 18,
      align: 'center',
    })
    .text('Tel : ',
    357, 715, {
      width: 178,
      height: 18,
      align: 'center',
    })
    .fillColor('blue')
    .text('09 80 80 90 56',
    399, 715, {
      width: 178,
      height: 18,
      align: 'center',
      underline: true
    });

  doc
    .fillColor('black')
    .fontSize(8)
    .font('Helvetica')
    .text(`A${coefAjustement}`,
      25, 760, {
      width: 45,
      height: 18,
      align: 'left',
      baseline: 'middle'
    });

  // end and display the document in the iframe to the right
  doc.end();
  stream.on('finish', function() {
    iframe.src = stream.toBlobURL('application/pdf');
  });
}