export const SKeys = Object.freeze({
  Departements: {
    INDRE_ET_LOIRE_37: 37,
    LOIR_ET_CHER_41:   41,
    LOIRET_45:         45
  },
  OriginesProspect: {
    AUCUN:      'aucun'     ,
    PARRAINAGE: 'parrainage',
    ANNONCES:   'annonces'  ,
    APPORTEURS: 'apporteurs'
  },
  ModelesMaison: {
    AMARANTE:  'amarante' ,
    AULNE:     'aulne'    ,
    CAMELIA:   'camelia'  ,
    CHARME:    'charme'   ,
    EVOLUTION: 'evolution',
    GLYCINE:   'glycine'  ,
    HORIZON:   'horizon'  ,
    LILAS:     'lilas'    ,
    ORME:      'orme'     ,
    PANORAMA:  'panorama' ,
    VISION:    'vision'
  },
  Orientations: {
    NORD:       'nord'      ,
    NORD_EST:   'nordEst'  ,
    EST:        'est'       ,
    SUD_EST:    'sudEst'   ,
    SUD:        'sud'       ,
    SUD_OUEST:  'sudOuest' ,
    OUEST:      'ouest'     ,
    NORD_OUEST: 'nordOuest'
  },
  TypesStructure: {
    PARPAINGS: 'parpaings'
  },
  RangsVideSanitaire: {
    SOUS_SOL:     'sousSol',
    TROIS_RANGS:  '3Rangs',
    QUATRE_RANGS: '4Rangs',
    CINQ_RANGS:   '5Rangs'
  },
  TypesRdc: {
    PLAIN_PIED: 'plainPied'
  },
  TypesCombles: {
    PERDUS:       'perdus'      ,
    AMENAGEABLES: 'amenageables'
  },
  TypesToit: {
    RECTANGLE:   'rectangle' ,
    L:           'l'         ,
    V:           'v'         ,
    U:           'u'         ,
    L_EVOLUTION: 'lEvolution'
  },
  InclinaisonsToit: {
    DEGRES_35: '35deg',
    DEGRES_40: '40deg',
    DEGRES_45: '45deg'
  },
  PentesToit: {
    UNE_PENTE:     '1pente',
    DEUX_PENTES:   '2pentes',
    QUATRE_PENTES: '4pentes'
  },
  TypesCouverture: {
    DOUBLE_ROMANE_ROUGE:          'doubleRomaneRouge'        ,
    DOUBLE_ROMANE_ARDOISEE:       'doubleRomaneArdoisee'     ,
    PLATE_NOBILEE_ROUGE_ASPECT:   'plateNobileeRougeAspect'  ,
    PLATE_NOBILEE_ARDOISE_ASPECT: 'plateNobileeArdoiseAspect',
    REGENCE_ROUGE:                'regenceRouge'             ,
    REGENCE_ARDOISEE:             'regenceArdoisee'          ,
    FONTENNELLE_ROUGE:            'fontennelleRouge'         ,
    FONTENNELLE_ARDOISEE:         'fontennelleArdoisee'      ,
    SIGNY_ROUGE:                  'signyRouge'               ,
    SIGNY_ARDOISEE:               'signyArdoisee'            ,
    PLATE_DE_PAYS:                'plateDePays'              ,
    NATURELLE_DESPAGNE:           'naturelleDEspagne'        ,
    ARTIFICIELLE_DROIT:           'artificeilleDroit'        ,
    ARTIFICIELLE_EPAUFRE_40_24:   'artificielleEpeaufre40X24',
    ARTIFICIELLE_EPAUFRE_33_23:   'artificielleEpeaufre33X23',
  },
  SpeciauxToit: {
    BAC_ACIER: 'bacAcier',
    BITUME:    'bitume'
  },
  TypesGouttiere: {
    ALU:  'alu',
    ZINC: 'zinc'
  },
  TypesConduit: {
    NON:             'non',
    POLYCOMBUSTIBLE: 'polycombustible'
  },
  MateriauxMenuiserie: {
    PVC:   'pvc'  ,
    ALU:   'alu'  ,
    PLAXE: 'plaxe',
    OB:    'ob'   
  },
  CouleursMenuiserie: {
    BLANC:           'blanc'         ,
    GRIS_ANTHARCITE: 'grisAntharcite',
    ABF:             'abf'
  },
  Facades: {
    AVANT:   'avant'  ,
    DROITE:  'droite' ,
    ARRIERE: 'arriere',
    GAUCHE:  'gauche'
  },
  ModelesPorteEntree: {
    GAMME_1: 'gamme1',
    GAMME_2: 'gamme2',
    GAMME_3: 'gamme3'
  },
  ModelesPorteService: {
    GAMME_1_PVC: 'gamme1Pvc',
    GAMME_2:     'gamme2'
  },
  OuvrantsPorte: {
    NORMAL: 'normal',
    TIERCE: 'tierce'
  },
  EmplacementsPorte: {
    HABITABLE: 'habitable',
    NON_HABITABLE: 'nonHabitable'
  },
  ModelesPorteGarage: {
    _902:        '902',
    SECTIONELLE: 'SECTIONELLE'
  },
  TypesPorteGarage: {
    MOTORISEE:     'motorisee',
    NON_MOTORISEE: 'nonMotorisee'
  },
  TaillesPorteGarage: {
    _240: '240',
    _300: '300'
  },
  TypesPlafondPlaco: {
    SOUFFLE: 'souffle',
    POSE:    'pose'
  },
  HauteursVideSousPlafond: {
    _2_5: '2.5',
    _2_7: '2.7'
  },
  EpaisseursPlaco: {
    _50: '50',
    _72: '72'
  },
  TypesElectricite: {
    DOOXIE:        'dooxie',
    SQUARE_HOMECO: 'squareHomeco'
  },
  GammesBlocPorte: {
    RIGHINI:    'righini' ,
    MF_GAMME_1: 'mfGamme1',
    MF_GAMME_2: 'mfGamme2'
  },
  ModelesBlocPorte: {
    _73:        '73'        ,
    _83:        '83'        ,
    _73_ISO:    '73Iso'     ,
    _83_ISO:    '83Iso'     ,
    COULISSANT: 'coulissant'
  },
  PiecesCarrelage: {
    CELLIER: 'localTechnique'  ,
    CUISINE:  'cuisine' ,
    WC:       'wc'      ,
    SDE:      'sde'     ,
    BAINS:    'bains'   ,
    CHAMBRES: 'chambres',
    DGT:      'dgt'     ,
    PALIER:   'palier'  ,
    GARAGE:   'garage'
  },
  GammesCarrelage: {
    INITIALE: 'initiale',
    PRESTIGE: 'prestige'
  },
  OuiNon: {
    OUI: 'oui',
    NON: 'non'
  },
  OuiOptionNon: {
    OUI:    'oui'   ,
    OPTION: 'option',
    NON:    'non'
  },
  TypesChauffage: {
    CLIM_RADIATEUR_ELEC:    'climRadiateurElectrique' ,
    POELE_RADIATEUR_ELEC:   'poeleRadiateurElectrique',
    PAC_PLANCHER_CHAUFFANT: 'pacPlancherChauffant'    ,
    PAC_RADIATEUR_EAU:      'pacRadiateurEau'         ,
    GAZ_PLANCHER_CHAUFFANT: 'gazPlancherChauffant'    ,
    GAZ_RADIATEUR_EAU:      'gazRadiateurEau'         ,
    PAC_GAINABLE:           'pacGainable'             ,
  },
  TypesChauffagePrix: {
    CLIM_MAX_35:        'climMax35'     ,
    CLIM_MAX_45:        'climMax45'     ,
    CLIM_MAX_65:        'climMax65'     ,
    POELE:              'poele'         ,
    GAZ_1Z_1SDB:        'gaz1z1sdb'     ,
    GAZ_1Z_2SDB:        'gaz1z2sdb'     ,
    GAZ_1Z_XSDB:        'gaz1zXsdb'     ,
    GAZ_2Z_1SDB:        'gaz2z1sdb'     ,
    GAZ_2Z_2SDB:        'gaz2z2sdb'     ,
    GAZ_2Z_XSDB:        'gaz2zXsdb'     ,
    PAC_1Z_100:         'pac1z100'      ,
    PAC_2Z_120:         'pac2z120'      ,
    PAC_2Z_140:         'pac2z140'      ,
    PAC_2Z_170:         'pac2z170'      ,
    PAC_2Z_190:         'pac2z190'      ,
    GAINABLE_7_1_100:   'gainable71100' ,
    GAINABLE_10_130:    'gainable10130' ,
    GAINABLE_12_5_190:  'gainable125190',
  },
  RevetementsFacade: {
    ENDUIT: 'enduit',
    BARDAGE: 'bardage'
  },
  TypesPieces: {
    PIECE_DE_VIE:    'pieceDeVie'  ,
    CUISINE:         'cuisine'     ,
    CELLIER:         'localTechnique'     ,
    DGT:             'dgt'         ,
    WC:              'wc'          ,
    SDE:             'sde'         ,
    CHAMBRES:        'chambres'    ,
    TERASSE_BETON:   'terasseBeton',
    GARAGE:          'garage'
  },
  TypesFenetre: {
    PETITE_FENETRE:      'petiteFenetre'     ,
    ABATTANT:            'abattant'          ,
    OEIL_DE_BOEUF:       'oeilDeBoeuf'       ,
    FENETRE_OUVRANTE:    'fenetreOuvrante'   ,
    FENETRE_COULISSANTE: 'fenetreCoulissante',
    FENETRE_FIXE:        'fenetreFixe'       ,
    PORTE_FENETRE:       'porteFenetre'      ,
    BAIE_COULISSANTE:    'baieCoulissante'
  },
  OptionsFenetre: {
    NORMAL:    'normal',
    GALANDAGE: 'galandage'
  },
  TypesSanitaires: {
    BAIGNOIRE:          'baignoire'       ,
    DOUCHE_ITAL:        'doucheItal'      ,
    BAC_A_DOUCHE:       'bacADouche'      ,
    DOUCHE_EXTRA_PLATE: 'doucheExtraPlate',
    WC_SIMPLE:          'wcSimple'        ,
    WC_SUSPENDU:        'wcSuspendu'      ,
    LAVABO:             'lavabo'          ,
    LAVE_MAINS:         'laveMains'
  },
  Fournisseurs: {
    MAV:    'mav', 
    CLIENT: 'client',
    OPTION: 'option'
  },
  TypesSurfaceHabitable: {
    MOINS_100: 'moinsDe100m2',
    PLUS_100:  'plusDe100m2'
  },
  CouleursSurfaceVitreeNord: {
    VERT: "vert",
    ORANGE: "orange",
    ROUGE: "rouge",
    NOIR: "noir",
    BLOQUE: "bloque",
  },
  PourcentagesMaxSurfaceVitreeNord: {
    VERT: 20,
    ORANGE: 25,
    ROUGE: 30,
    NOIR: 40,
  },
  CoeffMaxSurfaceVitreeNord: {
    VERT: 0.6,
    ORANGE: 0.8,
    ROUGE: 0.9,
    NOIR: 1,
  },
  CoeffMinSurfaceVitreeSud: {
    VERT: 45,
    ORANGE: 20,
    ROUGE: 0,
    NOIR: 0,
  },
  AutorisationSurfaceVitree: {
    VERT: 1,
    ORANGE: 2,
    ROUGE: 3,
    NOIR: 4,
    BLOQUE: 5
  }
});