

import { base64MavBackground }         from "./assets/images/mavBackground";
import { base64MavHeader }             from "./assets/images/mavHeader";
// import { base64MedailleOr }            from "./assets/images/medailleOr";
// import { base64HeadRaser }             from "./assets/images/p1HeadRaser";
// import { base64HeadRever }             from "./assets/images/p2HeadRever";
// import { base64VideSanitaire1 }        from "./assets/images/p2VideSanitaire1";
// import { base64VideSanitaire2 }        from "./assets/images/p2VideSanitaire2";
// import { base64VideSanitaire3 }        from "./assets/images/p2VideSanitaire3";
// import { base64Isolation1 }            from "./assets/images/p4Isolation1";
// import { base64Isolation2 }            from "./assets/images/p4Isolation2";
// import { base64Isolation3 }            from "./assets/images/p4Isolation3";
// import { base64IsolationPortes }       from "./assets/images/p4IsolationPortes";
// import { base64DomotiqueDooxie }       from "./assets/images/p5DomotiqueDooxie";
// import { base64DomotiqueSquareHomeco } from "./assets/images/p5DomotiqueSquareHomeco";
// import { base64AppareillageGPlay }     from "./assets/images/p5AppareillageGPlay";
// import { base64AppareillageAppStore }  from "./assets/images/p5AppareillageAppStore";
// import { base64AppareillageElec }      from "./assets/images/p5AppareillageElec";
// import { base64DomotiqueAlexa }        from "./assets/images/p5DomotiqueAlexa";
// import { base64DomotiqueGAssistant }   from "./assets/images/p5DomotiqueGAssistant";
// import { base64ChauffageBallon }       from "./assets/images/p6ChauffageBallon";
// import { base64ChauffageChaudiere }    from "./assets/images/p6ChauffageChaudiere";
// import { base64ChauffageMokai }        from "./assets/images/p6ChauffageMokai";
// import { base64ChauffagePhotoV }       from "./assets/images/p6ChauffagePhotoV";
// import { base64ChauffagePoele }        from "./assets/images/p6ChauffagePoele";
// import { base64ChauffageYutaki }       from "./assets/images/p6ChauffageYutaki";
// import { base64HeadBudget }            from "./assets/images/p7HeadBudget";



export const ImagesBase64 = Object.freeze({
  mavBackground:           base64MavBackground       ,
  mavHeader:               base64MavHeader           ,
  // medailleOr:              base64MedailleOr          ,
  // headRaser:               base64HeadRaser           ,
  // headRever:               base64HeadRever           ,
  // videSanitaire1:          base64VideSanitaire1      ,
  // videSanitaire2:          base64VideSanitaire2      ,
  // videSanitaire3:          base64VideSanitaire3      ,
  // isolation1:              base64Isolation1          ,
  // isolation2:              base64Isolation2          ,
  // isolation3:              base64Isolation3          ,
  // isolationPortes:         base64IsolationPortes     ,
  // electriciteDooxie:       base64DomotiqueDooxie     ,
  // electriciteSquareHomeco: base64DomotiqueSquareHomeco     ,
  // electriciteGPlay:        base64AppareillageGPlay   ,
  // electriciteAppStore:     base64AppareillageAppStore,
  // electriciteElec:         base64AppareillageElec    ,
  // electriciteAlexa:        base64DomotiqueAlexa      ,
  // electriciteGAssistant:   base64DomotiqueGAssistant ,
  // chauffageBallon:         base64ChauffageBallon     ,
  // chauffageChaudiere:      base64ChauffageChaudiere  ,
  // chauffageMokai:          base64ChauffageMokai      ,
  // chauffagePhotoV:         base64ChauffagePhotoV     ,
  // chauffagePoele:          base64ChauffagePoele      ,
  // chauffageYutaki:         base64ChauffageYutaki     ,
  // headBudget:              base64HeadBudget          
});

export const ListeImagesDevis = Object.freeze([
  {
    key: 'medailleOr',
    label: 'Médaille Or',
    page: 1
  },
  {
    key: 'headRaser',
    label: 'En-tête rasage',
    page: 1
  },
  {
    key: 'headRever',
    label: 'En-tête rêver',
    page: 2
  },
  {
    key: 'videSanitaire1',
    label: 'Vide sanitaire - gauche',
    page: 2
  },
  {
    key: 'videSanitaire2',
    label: 'Vide sanitaire - centre',
    page: 2
  },
  {
    key: 'videSanitaire3',
    label: 'Vide sanitaire - droite',
    page: 2
  },
  {
    key: 'portesEntree',
    label: 'Portes d\'entrée',
    page: 3
  },
  {
    key: 'portesService',
    label: 'Portes de service',
    page: 4
  },
  {
    key: 'isolation1',
    label: 'Isolation - gauche',
    page: 5
  },
  {
    key: 'isolation2',
    label: 'Isolation - centre',
    page: 5
  },
  {
    key: 'isolation3',
    label: 'Isolation - droite',
    page: 5
  },
  {
    key: 'isolationPortes',
    label: 'Portes intérieures',
    page: 5
  },
  {
    key: 'electriciteDooxie',
    label: 'Dooxie',
    page: 6
  },
  {
    key: 'electriciteSquareHomeco',
    label: 'Square Homeco',
    page: 6
  },
  {
    key: 'electriciteGPlay',
    label: 'Google Play',
    page: 6
  },
  {
    key: 'electriciteAppStore',
    label: 'App Store',
    page: 6
  },
  {
    key: 'electriciteElec',
    label: 'Prises électriques',
    page: 6
  },
  {
    key: 'electriciteAlexa',
    label: 'Alexa',
    page: 6
  },
  {
    key: 'electriciteGAssistant',
    label: 'Google Assistant',
    page: 6
  },
  {
    key: 'chauffageBallon',
    label: 'Chauffage ballon',
    page: 7
  },
  {
    key: 'chauffageChaudiere',
    label: 'Chaudière',
    page: 7
  },
  {
    key: 'chauffageMokai',
    label: 'Mokai',
    page: 7
  },
  {
    key: 'chauffagePhotoV',
    label: 'Panneau photovoltaïque',
    page: 7
  },
  {
    key: 'chauffagePoele',
    label: 'Chauffage Poêle',
    page: 7
  },
  {
    key: 'chauffageYutaki',
    label: 'Yutaki',
    page: 7
  },
  {
    key: 'chauffageGainable',
    label: 'Gainable',
    page: 7
  },
  {
    key: 'headBudget',
    label: 'En-tête budget',
    page: 7
  },
]);