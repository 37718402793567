export const PKeys = Object.freeze({
  ORIGINE_PROSPECT_PRIX:          'prixOrigineProspect'        ,
  SUPPLEMENT_REMY_PRIX:           'prixSupplementRemy'         ,
  DEPARTEMENT_COEFF:              'coeffDepartement'           ,
  BRANCHEMENT_PRIX:               'prixBranchement'            ,
  NIVEAU_M1_COEFF:                'coeffNiveauM1'              ,
  NIVEAU_P1_COEFF:                'coeffNiveauP1'              ,
  TYPE_TOIT_COEFF:                'coeffTypeToit'              ,
  PENETRATION_COEFF:              'coeffPenetration'           ,
  COS_INCLINAISON_TOIT:           'cosInclinaisonToit'         ,
  TAN_INCLINAISON_TOIT:           'tanInclinaisonToit'         ,
  COUVERTURE_PRIX:                'prixCouverture'             ,
  TOIT_SPECIAL_PRIX:              'prixToitSpecial'            ,
  COUVERTURE_SELON_COMBLES_COEFF: 'coeffCouvertureSelonCombles',
  COULEUR_MENUISERIE_COEFF:       'coeffCouleurMenuiserie'     ,
  PORTE_ENTREE_PRIX:              'prixPorteEntree'            ,
  TIERCE_SEMI_FIXE_PRIX:          'prixTierceSemiFixe'         ,
  PORTE_SERVICE_PRIX:             'prixPorteService'           ,
  PORTE_GARAGE_MODELE_PRIX:       'prixPorteGarageModele'      ,
  PORTE_GARAGE_MOTORISEE_PRIX:    'prixPorteGarageMotorisee'   ,
  PORTE_GARAGE_COULEUR_PRIX:      'prixPorteGarageCouleur'     ,
  CLOISON_PLACO_COEFF:            'coeffCloisonPlaco'          ,
  BLOC_PORTE_PRIX:                'prixBlocPorte'              ,
  RANGS_STRUCTURE_RDC:            'rangsStructureRdc'          ,
  HAUTEUR_PLACO_RDC:              'hauteurPlacoRdc'            ,
  EPAISSEUR_DOUBLAGE_PRIX:        'prixEpaisseurDoublage'      ,
  EPAISSEUR_CLOISON_PRIX:         'prixEpaisseurCloison'       ,
  GAMME_CARRELAGE_PRIX:           'prixGammeCarrelage'         ,
  GAMME_PLINTHE_PRIX:             'prixGammePlinthe'           ,
  TYPE_CHAUFFAGE_PRIX:            'prixTypeChauffage'          ,
  PETITE_FENETRE_PRIX:            'prixPetiteFenetre'          ,
  ABATTANT_PRIX:                  'prixAbattant'               ,
  OEIL_DE_BOEUF_PRIX:             'prixOeilDeBoeuf'            ,
  FENETRE_OUVRANTE_PRIX:          'prixFenetreOuvrante'        ,
  FENETRE_OUVRANTE_VR_PRIX:       'prixVRFenetreOuvrante'      ,
  FENETRE_COULISSANTE_PRIX:       'prixFenetreCoulissante'     ,
  FENETRE_COULISSANTE_VR_PRIX:    'prixVRFenetreCoulissante'   ,
  FENETRE_FIXE_PRIX:              'prixFenetreFixe'            ,
  PORTE_FENETRE_PRIX:             'prixPorteFenetre'           ,
  PORTE_FENETRE_VR_PRIX:          'prixVRPorteFenetre'         ,
  BAIE_VITREE_PRIX:               'prixBaieVitree'             ,
  BAIE_VITREE_VR_PRIX:            'prixVRBaieVitree'           ,
  HAUSSE_DE_PRIX_COEFF:           'coeffHausseDePrix'          ,
  TOTAL_HT_INTERNE_COEFF:         'coeffTotalHtInterne'        ,
  TOTAL_HT_AGCO_COEFF:            'coeffTotalHtAgco'           ,
  DOMMAGE_OUVRABLE_PRIX:          'dommageOuvrablePrix'        ,
  RECAP_TERRASSEMENT_HAUSSE:      'recapTerrassementHausse'    ,
  RECAP_VS_SOUSSOL_HAUSSE:        'recapVsSousSolHausse'       ,
  RECAP_RDC_HAUSSE:               'recapRdcHausse'             ,
  RECAP_ETAGE_HAUSSE:             'recapEtageHausse'           ,
  RECAP_PIGNON_RDC_HAUSSE:        'recapPignonRdcHausse'       ,
  RECAP_TOITURE_RDC_HAUSSE:       'recapToitureRdcHausse'      ,
  RECAP_MENUISERIE_HAUSSE:        'recapMenuiserieHausse'      ,
  RECAP_PLACO_HAUSSE:             'recapPlacoHausse'           ,
  RECAP_REVET_INT_HAUSSE:         'recapRevetIntHausse'        ,
  RECAP_ELEC_HAUSSE:              'recapElecHausse'            ,
  RECAP_REVET_EXT_HAUSSE:         'recapRevetExtHausse'        ,
  RECAP_PLOMBERIE_HAUSSE:         'recapPlomberieHausse'       ,
  RECAP_CHAUFFAGE_HAUSSE:         'recapChauffageHausse'       ,
  RECAP_PEINTURE_HAUSSE:          'recapPeintureHausse'        ,
  RECAP_DIAG_BBC_HAUSSE:          'recapDiagBbcHausse'         ,
  RECAP_ORIGINE_PROSPECT_HAUSSE:  'recapOrigineProspectHausse' ,
  RECAP_AJUSTEMENT_HAUSSE:        'recapAjustementHausse'      ,
  RECAP_ARCHITECTE_HAUSSE:        'recapArchitecteHausse'      ,
  SURFACE_VITREE_NORD_COEFF:      'surfaceVitreeNordCoeff'     ,
  FAIENCE_PRIX:                   'prixFaience'                ,
});