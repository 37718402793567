//import { queryOneParamValue } from "../api/queries";
//import { SKeys } from "../param/selectKeys";
//import { PKeys } from "../param/parametersKeys";

import { validFloat } from "./helpers"

export const ComblesParams = Object.freeze({
  PRIX_ENCUVEMENT: 50
});

export function addEncuvement(targetObj, encuvement, facadeCombles, pignonCombles, decrocheCombles) {
  let tmpEncuvement = 2 * validFloat(encuvement) * (validFloat(facadeCombles) + validFloat(pignonCombles) + validFloat(decrocheCombles));
  // targetObj.combles.encuvement = parseFloat(tmpEncuvement.toFixed(2));
  targetObj.combles.encuvement = parseFloat(tmpEncuvement);
  targetObj.combles.ttlPrixCombles += validFloat(ComblesParams.PRIX_ENCUVEMENT) * validFloat(tmpEncuvement);
}