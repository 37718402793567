// import { queryOneParamValue } from "../api/queries";
import { SKeys } from "../param/selectKeys";
// import { PKeys } from "../param/parametersKeys";

import { validFloat } from "./helpers"

export const RdcParams = Object.freeze({
  PRIX_STRUCTURE: 35.1,
  PRIX_SEUIL_APPUI: 50,
  PRIX_VOLET: 32,
  PRIX_POUTRE_1: 45,
  PRIX_POUTRE_2: 25,
  PRIX_POTEAU: 65,
  PRIX_PLANCHER_BOIS: 16,
  //OLD PARAMS
  RangsStructureRdc: {
    _2_50: 2.7,
    _2_70: 2.9
  }
});

export function computeParamsRdc(targetObj, nombrePEntree, nombrePService, nombrePGarage, fenetres) {
  targetObj.rdc.nombreOuvertures += validFloat(nombrePEntree) + validFloat(nombrePService) + validFloat(nombrePGarage);
  fenetres.forEach(fenetre => {
    if (fenetre.type && fenetre.dimensions && fenetre.emplacement && fenetre.nombre) {
      targetObj.rdc.nombreOuvertures += validFloat(fenetre.nombre);
      if (fenetre.type === SKeys.TypesFenetre.FENETRE_OUVRANTE || fenetre.type === SKeys.TypesFenetre.FENETRE_COULISSANTE || fenetre.type === SKeys.TypesFenetre.PORTE_FENETRE || fenetre.type === SKeys.TypesFenetre.BAIE_COULISSANTE) {
        targetObj.rdc.nombreVolets += validFloat(fenetre.nombre);
      }
    }
  });
}

export async function addStructureRdc(targetObj, videSousPlafond, facadeRdc, pignonRdc, decrocheRdc) {
  let coeffStructure = 0;
  if (videSousPlafond && videSousPlafond.toString() === SKeys.HauteursVideSousPlafond._2_5) {
    coeffStructure = RdcParams.RangsStructureRdc._2_50;
  }
  else if (videSousPlafond && videSousPlafond.toString() === SKeys.HauteursVideSousPlafond._2_7) {
    coeffStructure = RdcParams.RangsStructureRdc._2_70;
  }

  let tmpStructure = validFloat(coeffStructure) * 2 * (validFloat(facadeRdc) + validFloat(pignonRdc) + validFloat(decrocheRdc));
  // targetObj.rdc.structure = parseFloat(tmpStructure.toFixed(2));
  targetObj.rdc.structure = tmpStructure;
  targetObj.rdc.ttlPrixRdc += validFloat(RdcParams.PRIX_STRUCTURE) * validFloat(tmpStructure);
}

export function addSeuilAppuis(targetObj, nombreOuvertures) {
  targetObj.rdc.ttlPrixRdc += validFloat(RdcParams.PRIX_SEUIL_APPUI) * validFloat(nombreOuvertures);
}

export function addVolets(targetObj, nombreVolets) {
  targetObj.rdc.ttlPrixRdc += validFloat(RdcParams.PRIX_VOLET) * validFloat(nombreVolets);
}

export function addPoutres(targetObj, nombrePoutres) {
  targetObj.rdc.ttlPrixRdc += validFloat(RdcParams.PRIX_POUTRE_1) * validFloat(nombrePoutres) * 5 + validFloat(nombrePoutres) * validFloat(RdcParams.PRIX_POUTRE_2);
}

export function addPoteaux(targetObj, nombrePoteaux) {
  targetObj.rdc.ttlPrixRdc += validFloat(RdcParams.PRIX_POTEAU) * validFloat(nombrePoteaux) * 3;
}

// export function addPlancherBois(targetObj, typeCombles, facadeToit, lrgPlancherCombles) {
//   //Type Combles === 2 ?!?
//   if (typeCombles === 2) {
//     targetObj.rdc.ttlPrixRdc += validFloat(RdcParams.PRIX_PLANCHER_BOIS) * validFloat(facadeToit) * validFloat(lrgPlancherCombles);
//   }
// }