import { queryOneParamValue } from "../api/queries";
import { SKeys } from "../param/selectKeys";
import { PKeys } from "../param/parametersKeys";

import { validFloat } from "./helpers"

export const PlacoParams = Object.freeze({
  PRIX_PLAFOND_SOUFFLE: 29.3,
  PRIX_PLAFOND_POSE: 29.28,
  PRIX_PLAFOND_SOUS_PLANCHER: 24.16,
  PRIX_DOUBLAGE_1: 14,
  PRIX_DOUBLAGE_2: 14.09,
  PRIX_BANDES: 3.1,
  LONGUEUR_BANDES_ARMEES: 90,
  //OLD PARAMS
  HauteurPlacoRdc: {
    _2_50: 2.6,
    _2_70: 2.8
  }
});

export async function computeParamsPlaco(targetObj, typePlafond, typeCombles, ratioComblesAmenageables, facadeRdc, pignonRdc, facadeVS, pignonVS, empriseSol, pieces, doublageRdc, cloisonRdc, videSousPlafond) {
  //SURFACE PERDUE
  targetObj.placo.surfacePerdue = (validFloat(facadeVS) * validFloat(pignonVS)) - validFloat(empriseSol);
  //SURFACE GARAGE
  let ttlSurfaceGarage = 0;
  pieces.forEach(piece => {
    if (piece.type === SKeys.TypesPieces.GARAGE) {
      ttlSurfaceGarage += piece.surface;
    }
  });
  //PLAFOND SOUFFLE
  if (typePlafond === SKeys.TypesPlafondPlaco.SOUFFLE) {
    let tmpRatioCombles = typeCombles === SKeys.TypesCombles.AMENAGEABLES ? 1 : (1 - validFloat(ratioComblesAmenageables));
    targetObj.placo.plafondSouffle = validFloat(tmpRatioCombles) * (validFloat(facadeRdc) - 0.4) * (validFloat(pignonRdc) - 0.4) - validFloat(ttlSurfaceGarage) - validFloat(targetObj.placo.surfacePerdue);
  }
  //PLAFOND POSE
  if (typePlafond === SKeys.TypesPlafondPlaco.POSE) {
    let tmpRatioCombles = typeCombles === SKeys.TypesCombles.AMENAGEABLES ? 1 : (1 - validFloat(ratioComblesAmenageables));
    let ttlSurfaceGarage = 0;
    pieces.forEach(piece => {
      if (piece.type === SKeys.TypesPieces.GARAGE) {
        ttlSurfaceGarage += piece.surface;
      }
    });
    targetObj.placo.plafondPose = validFloat(tmpRatioCombles) * (validFloat(facadeRdc) - 0.4) * (validFloat(pignonRdc) - 0.4) - validFloat(ttlSurfaceGarage) - validFloat(targetObj.placo.surfacePerdue);
  }
  //PLAFOND SOUS PLANCHER BOIS
  // if (typeCombles === 2) {
  //   targetObj.placo.plafondSousPlancherBois = validFloat(largeurPlancherCombles) * validFloat(facadeToit);
  // }
  //DOUBLAGE & CLOISON
  let coeffHauteurPlaco = 0;
  if (videSousPlafond && videSousPlafond.toString() === SKeys.HauteursVideSousPlafond._2_5) {
    coeffHauteurPlaco = PlacoParams.HauteurPlacoRdc._2_50;
  }
  else if (videSousPlafond && videSousPlafond.toString() === SKeys.HauteursVideSousPlafond._2_7) {
    coeffHauteurPlaco = PlacoParams.HauteurPlacoRdc._2_70;
  }

  targetObj.placo.doublage = validFloat(coeffHauteurPlaco) * validFloat(doublageRdc);
  targetObj.placo.cloison = validFloat(coeffHauteurPlaco) * validFloat(cloisonRdc);
  //BANDES
  targetObj.placo.bandes += validFloat(targetObj.placo.plafondSouffle);
  targetObj.placo.bandes += validFloat(targetObj.placo.plafondPose);
  targetObj.placo.bandes += validFloat(targetObj.placo.plafondSousPlancherBois);
  targetObj.placo.bandes += validFloat(targetObj.placo.doublage);
  targetObj.placo.bandes += validFloat(targetObj.placo.cloison) * 2;
}

export function addPlafondSouffle(targetObj) {
  targetObj.placo.ttlPrixPlaco += validFloat(PlacoParams.PRIX_PLAFOND_SOUFFLE) * validFloat(targetObj.placo.plafondSouffle);
}

export function addPlafondPose(targetObj) {
  targetObj.placo.ttlPrixPlaco += validFloat(PlacoParams.PRIX_PLAFOND_POSE) * validFloat(targetObj.placo.plafondPose);
}

export function addPlafondSousPlancherBois(targetObj) {
  targetObj.placo.ttlPrixPlaco += validFloat(PlacoParams.PRIX_PLAFOND_SOUS_PLANCHER) * validFloat(targetObj.placo.plafondSousPlancherBois);
}

export async function addDoublage(targetObj) {
  const prixDoublageKey = PKeys.EPAISSEUR_DOUBLAGE_PRIX;
  let prixDoublage;
  if (prixDoublageKey) {
    prixDoublage = await queryOneParamValue(prixDoublageKey);
  }
  let resDoublage = validFloat(PlacoParams.PRIX_DOUBLAGE_1) + ((validFloat(PlacoParams.PRIX_DOUBLAGE_2) + validFloat(prixDoublage)) * validFloat(targetObj.placo.doublage));
  targetObj.placo.ttlPrixPlaco += resDoublage;

  //HYBRIS
  // targetObj.placo.doublageHybris = (1.2 - 1) * validFloat(resDoublage);
}

export async function addCloison(targetObj, epaisseurCloison) {
  const prixCloisonKey = PKeys.EPAISSEUR_CLOISON_PRIX;
  let prixCloison;
  if (epaisseurCloison && prixCloisonKey) {
    prixCloison = await queryOneParamValue(prixCloisonKey, epaisseurCloison);
  }
  let tmpAddPrice = epaisseurCloison.toString() === SKeys.EpaisseursPlaco._72.toString() ? 5 : 0;
  let resCloison = validFloat(tmpAddPrice) + (validFloat(prixCloison) * validFloat(targetObj.placo.cloison));
  targetObj.placo.ttlPrixPlaco += resCloison;

  // //HYBRIS
  // if (epaisseurCloison.toString() === SKeys.EpaisseursPlaco._72.toString()) {
  //   targetObj.placo.cloisonHybris = (1.2 - 1) * validFloat(resCloison);
  // }
  // else {
  //   targetObj.placo.cloisonHybris = 0;
  // }

  // targetObj.placo.ttlPrixHybrisOption = validFloat(targetObj.placo.doublageHybris) + validFloat(targetObj.placo.cloisonHybris);
}

export function addBandes(targetObj) {
  targetObj.placo.ttlPrixPlaco += validFloat(PlacoParams.PRIX_BANDES) * validFloat(targetObj.placo.bandes);
}

export function addBandesArmees(targetObj) {
  targetObj.placo.ttlPrixPlaco += validFloat(PlacoParams.LONGUEUR_BANDES_ARMEES) * validFloat(PlacoParams.PRIX_BANDES);
}

export async function addBlocPorte(targetObj, gamme, modele1, modele2, nombre1, nombre2, nombreCoulissant) {
  const prixKey = PKeys.BLOC_PORTE_PRIX;
  if (prixKey && gamme) {
    if (modele1) {
      let prix1 = await queryOneParamValue(prixKey, gamme, modele1);
      targetObj.placo.ttlPrixPlaco += validFloat(prix1) * validFloat(nombre1);
    }
    if (modele2) {
      let prix2 = await queryOneParamValue(prixKey, gamme, modele2);
      targetObj.placo.ttlPrixPlaco += validFloat(prix2) * validFloat(nombre2);
    }
    let prixCoulissant = await queryOneParamValue(prixKey, gamme, SKeys.ModelesBlocPorte.COULISSANT);
    targetObj.placo.ttlPrixPlaco += validFloat(prixCoulissant) * validFloat(nombreCoulissant);
  }
}