import { queryOneParamValue } from "../api/queries";
//import { SKeys } from "../param/selectKeys";
import { PKeys } from "../param/parametersKeys";

import { validFloat } from "./helpers"

export const AutreParams = Object.freeze({
});

export async function addOrigineProspect(targetObj, origineProspect) {
  const origineProspectKey = PKeys.ORIGINE_PROSPECT_PRIX;
  if (origineProspect && origineProspectKey) {
    let prix = await queryOneParamValue(origineProspectKey, origineProspect);
    prix = validFloat(prix);
    targetObj.origineProspect.ttlPrixOrigineProspect += prix;
    // targetObj.origineProspect.ttlPrixOrigineProspect += parseFloat(prix.toFixed(2));
  }
}

export function addAjustement(targetObj, ajustement) {
  targetObj.ajustement.ttlPrixAjustement += validFloat(ajustement);
  // targetObj.ajustement.ttlPrixAjustement = parseFloat(targetObj.ajustement.ttlPrixAjustement.toFixed(2));
}

export function addArchitecte(targetObj) {
  if (validFloat(targetObj.videSanitaire.surfaceHabitable) >= 150) {
    targetObj.architecte.ttlPrixArchitecte += validFloat(targetObj.videSanitaire.surfaceHabitable) * 10;
    // targetObj.architecte.ttlPrixArchitecte = parseFloat(targetObj.architecte.ttlPrixArchitecte.toFixed(2));
  }
}