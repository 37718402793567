<template>
  <div id="the-footer" class="flex-cnt-row align-center justify-between">
    <div>
      Maisons à vivre
    </div>
    <div>
      version : {{version}}
    </div>
    <div>
      User : {{getAuthData.userName ? getAuthData.userName : ''}}
    </div>
  </div>
</template>

<script>
import { version } from '../../package'
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['getAuthData'])
  },
  data() {
    return {
      version: version
    }
  }
}
</script>

<style>
  #the-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30px;
    padding: 0px 50px;
    box-sizing: border-box;
    background-color: rgb(55, 71, 71);
    color: white;
    font-family: "OpenSans400";
    font-size: 12px;
  }
</style>