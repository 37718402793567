//Input types for validation
export const InputTypes = Object.freeze({
  STRING:                 1,
  INTEGER:                2,
  INTEGER_POS:            3,
  FLOAT:                  4,
  DATE:                   5,
  TELEPHONE:              6,
  PASSWORD:               7,
  CODE_POSTAL:            8,
  USERNAME:               9,
  ROLE:                   10,
  TEXT_500:               11,
});

export function isValid(type, value) {
  let regex;
  switch (type) {
    case InputTypes.STRING:
        if (value === null || String(value).length < 255) {
          return true;
        }
      break;

    case InputTypes.INTEGER:
        if (value === null || Number.isInteger(parseFloat(value)) ) {
          return true;
        }
      break;

    case InputTypes.INTEGER_POS:
        if (value === null || ( Number.isInteger(parseFloat(value)) && Number.parseInt(value) >= 0 )) {
          return true;
        }
      break;

    case InputTypes.FLOAT:
        if (value === null || parseFloat(value) || parseFloat(value) === 0) {
          return true;
        }
      break;

    case InputTypes.DATE:
        if (value === null || new Date(value).getTime()) {
          return true;
        }
      break;

    case InputTypes.TELEPHONE:
        regex = /^([+0-9][0-9]{7,15})$/;
        if (value === null || regex.test(value)) {
          return true;
        }
      break;

    case InputTypes.PASSWORD:
        regex = /^[0-9a-zA-Z!@#$%^&*]{6,}$/;
        if (value === null || regex.test(value)) {
          return true;
        }
      break;

    case InputTypes.CODE_POSTAL:
        regex = /^[0-9]{5}$/;
        if (value === null || regex.test(value)) {
          return true;
        }
      break;

    case InputTypes.USERNAME:
        regex = /^\w{3,}$/;
        if (value.length > 2 && value.length < 256 && regex.test(value)) {
          return true;
        }
      break;

    case InputTypes.ROLE:
      if (Number.parseInt(value) === 1 || Number.parseInt(value) === 2 || Number.parseInt(value) === 3) {
        return true;
      }
      break;

    case InputTypes.TEXT_500:
        if (value === null || String(value).length < 500) {
          return true;
        }
      break;
  
    default:
      break;
  }
  return false;
}