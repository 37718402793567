import { SKeys } from "./selectKeys"

export const Civilites = Object.freeze([
  {index: 0, label: 'M'   , value: 'M'   },
  {index: 1, label: 'Mlle', value: 'Mlle'},
  {index: 2, label: 'Mme' , value: 'Mme' }
]);

export const Departements = Object.freeze([
  {index: 0, label: 'INDRE ET LOIRE 37', value: SKeys.Departements.INDRE_ET_LOIRE_37},
  {index: 1, label: 'LOIR ET CHER 41'  , value: SKeys.Departements.LOIR_ET_CHER_41  },
  {index: 2, label: 'LOIRET 45'        , value: SKeys.Departements.LOIRET_45        }
]);

export const BranchementFournisseurs = Object.freeze([
  {index: 0, label: 'CLIENT', value: SKeys.Fournisseurs.CLIENT}
]);

export const BranchementLongueurs = Object.freeze([
  {index: 0, label: '7' , value: '7' },
  // {index: 1, label: '10', value: '10'},
  // {index: 2, label: '15', value: '15'},
  // {index: 3, label: '20', value: '20'},
  // {index: 4, label: '30', value: '30'},
  // {index: 5, label: '40', value: '40'},  
  // {index: 6, label: '50', value: '50'},
  // {index: 7, label: '75', value: '75'}
]);

export const OriginesProspect = Object.freeze([
  {index: 0, label: 'AUCUN'     , value: SKeys.OriginesProspect.AUCUN     },
  {index: 1, label: 'PARRAINAGE', value: SKeys.OriginesProspect.PARRAINAGE},
  // {index: 3, label: 'ANNONCES'  , value: SKeys.OriginesProspect.ANNONCES  },
  {index: 2, label: 'APPORTEURS', value: SKeys.OriginesProspect.APPORTEURS}
]);

export const MaisonModeles = Object.freeze([
  {index: 0 , label: 'AMARANTE' , value: SKeys.ModelesMaison.AMARANTE },
  {index: 1 , label: 'AULNE'    , value: SKeys.ModelesMaison.AULNE    },
  {index: 2 , label: 'CAMELIA'  , value: SKeys.ModelesMaison.CAMELIA  },
  {index: 3 , label: 'CHARME'   , value: SKeys.ModelesMaison.CHARME   },
  {index: 4 , label: 'EVOLUTION', value: SKeys.ModelesMaison.EVOLUTION},
  {index: 5 , label: 'GLYCINE'  , value: SKeys.ModelesMaison.GLYCINE  },
  {index: 6 , label: 'HORIZON'  , value: SKeys.ModelesMaison.HORIZON  },
  {index: 7 , label: 'LILAS'    , value: SKeys.ModelesMaison.LILAS    },
  {index: 8 , label: 'ORME'     , value: SKeys.ModelesMaison.ORME     },
  {index: 9 , label: 'PANORAMA' , value: SKeys.ModelesMaison.PANORAMA },
  {index: 10, label: 'VISION'   , value: SKeys.ModelesMaison.VISION   }
]);

export const Orientations = Object.freeze([
  {index: 0, label: 'NORD'      , value: SKeys.Orientations.NORD      },
  {index: 1, label: 'NORD-EST'  , value: SKeys.Orientations.NORD_EST  },
  {index: 2, label: 'EST'       , value: SKeys.Orientations.EST       },
  {index: 3, label: 'SUD-EST'   , value: SKeys.Orientations.SUD_EST   },
  {index: 4, label: 'SUD'       , value: SKeys.Orientations.SUD       },
  {index: 5, label: 'SUD-OUEST' , value: SKeys.Orientations.SUD_OUEST },
  {index: 6, label: 'OUEST'     , value: SKeys.Orientations.OUEST     },
  {index: 7, label: 'NORD-OUEST', value: SKeys.Orientations.NORD_OUEST}
]);

export const StructureTypes = Object.freeze([
  {index: 0, label: 'PARPAINGS', value: SKeys.TypesStructure.PARPAINGS}
]);

export const VideSanitaireRangs = Object.freeze([
  {index: 0, label: '3 RANGS', value: SKeys.RangsVideSanitaire.TROIS_RANGS },
  {index: 1, label: '4 RANGS', value: SKeys.RangsVideSanitaire.QUATRE_RANGS},
  {index: 2, label: '5 RANGS', value: SKeys.RangsVideSanitaire.CINQ_RANGS  }
]);

export const DetailRdcTypes = Object.freeze([
  {index: 0, label: 'PLAIN-PIED', value: SKeys.TypesRdc.PLAIN_PIED},
]);

export const ComblesTypes = Object.freeze([
  {index: 0, label: 'PERDUS'      , value: SKeys.TypesCombles.PERDUS      },
  {index: 1, label: 'AMENAGEABLES', value: SKeys.TypesCombles.AMENAGEABLES}
]);

export const ToitRdcTypes = Object.freeze({
  "amarante": [
    {index: 0, label: 'RECTANGLE'  , value: SKeys.TypesToit.RECTANGLE  }
  ],
  "aulne": [
    {index: 0, label: 'RECTANGLE'  , value: SKeys.TypesToit.RECTANGLE  },
    {index: 1, label: 'L'          , value: SKeys.TypesToit.L          },
    {index: 2, label: 'V'          , value: SKeys.TypesToit.V          },
    {index: 3, label: 'U'          , value: SKeys.TypesToit.U          },
    {index: 4, label: 'L EVOLUTION', value: SKeys.TypesToit.L_EVOLUTION}
  ],
  "camelia": [
    {index: 0, label: 'RECTANGLE'  , value: SKeys.TypesToit.RECTANGLE  },
    {index: 1, label: 'L'          , value: SKeys.TypesToit.L          },
    {index: 2, label: 'V'          , value: SKeys.TypesToit.V          },
    {index: 3, label: 'U'          , value: SKeys.TypesToit.U          },
    {index: 4, label: 'L EVOLUTION', value: SKeys.TypesToit.L_EVOLUTION}
  ],
  "charme": [
    {index: 0, label: 'RECTANGLE'  , value: SKeys.TypesToit.RECTANGLE  },
    {index: 1, label: 'L'          , value: SKeys.TypesToit.L          },
    {index: 2, label: 'V'          , value: SKeys.TypesToit.V          },
    {index: 3, label: 'U'          , value: SKeys.TypesToit.U          },
    {index: 4, label: 'L EVOLUTION', value: SKeys.TypesToit.L_EVOLUTION}
  ],
  "evolution": [
    {index: 0, label: 'L EVOLUTION', value: SKeys.TypesToit.L_EVOLUTION}
  ],
  "glycine": [
    {index: 0, label: 'RECTANGLE'  , value: SKeys.TypesToit.RECTANGLE  },
    {index: 1, label: 'L'          , value: SKeys.TypesToit.L          },
    {index: 2, label: 'V'          , value: SKeys.TypesToit.V          },
    {index: 3, label: 'U'          , value: SKeys.TypesToit.U          },
    {index: 4, label: 'L EVOLUTION', value: SKeys.TypesToit.L_EVOLUTION}
  ],
  "horizon": [
    {index: 0, label: 'RECTANGLE'  , value: SKeys.TypesToit.RECTANGLE  },
    {index: 1, label: 'L'          , value: SKeys.TypesToit.L          },
    {index: 2, label: 'V'          , value: SKeys.TypesToit.V          },
    {index: 3, label: 'U'          , value: SKeys.TypesToit.U          },
    {index: 4, label: 'L EVOLUTION', value: SKeys.TypesToit.L_EVOLUTION}
  ],
  "lilas": [
    {index: 0, label: 'RECTANGLE'  , value: SKeys.TypesToit.RECTANGLE  },
    {index: 1, label: 'L'          , value: SKeys.TypesToit.L          },
    {index: 2, label: 'V'          , value: SKeys.TypesToit.V          },
    {index: 3, label: 'U'          , value: SKeys.TypesToit.U          },
    {index: 4, label: 'L EVOLUTION', value: SKeys.TypesToit.L_EVOLUTION}
  ],
  "orme": [
    {index: 0, label: 'RECTANGLE'  , value: SKeys.TypesToit.RECTANGLE  },
    {index: 1, label: 'L'          , value: SKeys.TypesToit.L          },
    {index: 2, label: 'V'          , value: SKeys.TypesToit.V          },
    {index: 3, label: 'U'          , value: SKeys.TypesToit.U          },
    {index: 4, label: 'L EVOLUTION', value: SKeys.TypesToit.L_EVOLUTION}
  ],
  "panorama": [
    {index: 0, label: 'L'          , value: SKeys.TypesToit.L          },
    {index: 1, label: 'U'          , value: SKeys.TypesToit.U          }
  ],
  "vision": [
    {index: 0, label: 'V'          , value: SKeys.TypesToit.V          }
  ]
});

export const ToitRdcInclinaisons = Object.freeze([
  {index: 0, label: '35°', value: SKeys.InclinaisonsToit.DEGRES_35},
  {index: 1, label: '40°', value: SKeys.InclinaisonsToit.DEGRES_40},
  {index: 2, label: '45°', value: SKeys.InclinaisonsToit.DEGRES_45}
]);

export const ToitRdcPentes = Object.freeze([
  // {index: 0, label: '1 PENTE' , value: SKeys.PentesToit.UNE_PENTE   },
  {index: 0, label: '2 PENTES', value: SKeys.PentesToit.DEUX_PENTES },
  // {index: 2, label: '4 PENTES', value: SKeys.PentesToit.QUATRE_PENTES}
]);

export const ToitRdcCouvertures = Object.freeze([
  {index: 0 , label: 'DOUBLE ROMANE ROUGE 10 u/m2'          , value: SKeys.TypesCouverture.DOUBLE_ROMANE_ROUGE        },
  {index: 1 , label: 'DOUBLE ROMANE ARDOISEE 10 u/m2'       , value: SKeys.TypesCouverture.DOUBLE_ROMANE_ARDOISEE     },
  {index: 2 , label: 'PLATE NOBILEE ROUGE ASPECT 20 u/m2'   , value: SKeys.TypesCouverture.PLATE_NOBILEE_ROUGE_ASPECT  },
  {index: 3 , label: 'PLATE NOBILEE ARDOISEE ASPECT 20 u/m2', value: SKeys.TypesCouverture.PLATE_NOBILEE_ARDOISE_ASPECT},
  {index: 4 , label: 'REGENCE ROUGE 20 u/m2'                , value: SKeys.TypesCouverture.REGENCE_ROUGE             },
  {index: 5 , label: 'REGENCE ARDOISEE 20 u/m2'             , value: SKeys.TypesCouverture.REGENCE_ARDOISEE          },
  {index: 6 , label: 'FONTENNELLE ROUGE 10 u/m2'            , value: SKeys.TypesCouverture.FONTENNELLE_ROUGE         },
  {index: 7 , label: 'FONTENNELLE ARDOISEE 20 u/m2'         , value: SKeys.TypesCouverture.FONTENNELLE_ARDOISEE      },
  {index: 8 , label: 'SIGNY ROUGE 10 u/m2'                  , value: SKeys.TypesCouverture.SIGNY_ROUGE               },
  {index: 9 , label: 'SIGNY ARDOISEE 10 u/m2'               , value: SKeys.TypesCouverture.SIGNY_ARDOISEE            },
  {index: 10, label: 'PLATE DE PAYS 62 u/m2'                , value: SKeys.TypesCouverture.PLATE_DE_PAYS              },
  {index: 11, label: 'NATURELLE D\'ESPAGNE 32x22'           , value: SKeys.TypesCouverture.NATURELLE_DESPAGNE        },
  {index: 12, label: 'ARTIFICIELLE DROIT 40x24'             , value: SKeys.TypesCouverture.ARTIFICIELLE_DROIT        },
  {index: 13, label: 'ARTIFICIELLE EPEAUFRE 40x24'          , value: SKeys.TypesCouverture.ARTIFICIELLE_EPAUFRE_40_24},
  {index: 14, label: 'ARTIFICIELLE EPEAUFRE 33x23'          , value: SKeys.TypesCouverture.ARTIFICIELLE_EPAUFRE_33_23}
]);

export const ToitRdcSpeciaux = Object.freeze([
  {index: 0, label: 'BAC ACIER', value: SKeys.SpeciauxToit.BAC_ACIER},
  {index: 1, label: 'BITUME'   , value: SKeys.SpeciauxToit.BITUME   }
]);

export const ToitRdcRatios = Object.freeze({
  "perdus": [],
  "amenageables": [
    {index: 0  , label: '0'  , value: 0   },
    {index: 1  , label: '10' , value: 0.1 },
    {index: 2  , label: '20' , value: 0.2 },
    {index: 3  , label: '30' , value: 0.3 },
    {index: 4  , label: '40' , value: 0.4 },
    {index: 5  , label: '50' , value: 0.5 },
    {index: 6  , label: '60' , value: 0.6 },
    {index: 7  , label: '70' , value: 0.7 },
    {index: 8  , label: '80' , value: 0.8 },
    {index: 9  , label: '90' , value: 0.9 },
    {index: 10 , label: '100', value: 1   }
  ]
});

export const GouttiereTypes = Object.freeze([
  {index: 0, label: 'ALU' , value: SKeys.TypesGouttiere.ALU },
  {index: 1, label: 'ZINC', value: SKeys.TypesGouttiere.ZINC}
]);

export const ConduitTypes = Object.freeze([
  {index: 0, label: 'NON'            , value: SKeys.TypesConduit.NON            },
  {index: 1, label: 'POLYCOMBUSTIBLE', value: SKeys.TypesConduit.POLYCOMBUSTIBLE}
]);

export const MenuiserieMateriaux = Object.freeze([
  {index: 0, label: 'PVC'  , value: SKeys.MateriauxMenuiserie.PVC  },
  {index: 1, label: 'ALU'  , value: SKeys.MateriauxMenuiserie.ALU  },
  {index: 2, label: 'PLAXE', value: SKeys.MateriauxMenuiserie.PLAXE}
]);

export const MenuiserieCouleursGlobal = Object.freeze({
  "pvc": [
    {index: 0, label: 'BLANC'          , value: SKeys.CouleursMenuiserie.BLANC          },
  ],
  "alu": [
    {index: 0, label: 'BLANC'          , value: SKeys.CouleursMenuiserie.BLANC          },
    {index: 1, label: 'GRIS ANTHRACITE', value: SKeys.CouleursMenuiserie.GRIS_ANTHARCITE},
    {index: 2, label: 'ABF'            , value: SKeys.CouleursMenuiserie.ABF            }
  ],
  "plaxe": [
    {index: 0, label: 'BLANC'          , value: SKeys.CouleursMenuiserie.BLANC          },
    {index: 1, label: 'GRIS ANTHRACITE', value: SKeys.CouleursMenuiserie.GRIS_ANTHARCITE},
    {index: 2, label: 'ABF'            , value: SKeys.CouleursMenuiserie.ABF            }
  ],
});

export const MenuiserieCouleurs = Object.freeze([
  {index: 0, label: 'BLANC'          , value: SKeys.CouleursMenuiserie.BLANC          },
  {index: 1, label: 'GRIS ANTHRACITE', value: SKeys.CouleursMenuiserie.GRIS_ANTHARCITE},
  {index: 2, label: 'ABF'            , value: SKeys.CouleursMenuiserie.ABF            }
]);

export const Facades = Object.freeze([
  {index: 0, label: 'AVANT'  , value: SKeys.Facades.AVANT  },
  {index: 1, label: 'DROITE' , value: SKeys.Facades.DROITE },
  {index: 2, label: 'ARRIERE', value: SKeys.Facades.ARRIERE},
  {index: 3, label: 'GAUCHE' , value: SKeys.Facades.GAUCHE }
]);

export const FacadesEntree = Object.freeze([
  {index: 0, label: 'AVANT'  , value: SKeys.Facades.AVANT  },
]);

export const PortesEntreeModeles = Object.freeze([
  {index: 0, label: 'GAMME 1', value: SKeys.ModelesPorteEntree.GAMME_1},
  // {index: 1, label: 'GAMME 2', value: SKeys.ModelesPorteEntree.GAMME_2},
  // {index: 2, label: 'GAMME 3', value: SKeys.ModelesPorteEntree.GAMME_3}
]);

export const PortesServiceModeles = Object.freeze([
  {index: 0, label: 'GAMME 1 PVC', value: SKeys.ModelesPorteService.GAMME_1_PVC},
  // {index: 1, label: 'GAMME 2'    , value: SKeys.ModelesPorteService.GAMME_2    }
]);

export const PortesOuvrants = Object.freeze([
  {index: 0, label: 'NORMAL', value: SKeys.OuvrantsPorte.NORMAL},
  {index: 1, label: 'TIERCE', value: SKeys.OuvrantsPorte.TIERCE}
]);

export const PortesEmplacements = Object.freeze([
  {index: 0, label: 'HABITABLE'    , value: SKeys.EmplacementsPorte.HABITABLE    },
  {index: 1, label: 'NON HABITABLE', value: SKeys.EmplacementsPorte.NON_HABITABLE}
]);

export const PortesGarageTailles = Object.freeze([
  {index: 0, label: '240', value: SKeys.TaillesPorteGarage._240},
  {index: 1, label: '300', value: SKeys.TaillesPorteGarage._300}
]);

export const PortesGarageModeles = Object.freeze([
  {index: 0, label: '902'         , value: SKeys.ModelesPorteGarage._902         },
  {index: 1, label: 'SECTIONNELLE', value: SKeys.ModelesPorteGarage.SECTIONELLE}
]);

export const PortesGarageTypes = Object.freeze([
  {index: 0, label: 'NON MOTORISEE', value: SKeys.TypesPorteGarage.NON_MOTORISEE},
  {index: 1, label: 'MOTORISEE'    , value: SKeys.TypesPorteGarage.MOTORISEE    }
]);

export const PlacoPlafondTypes = Object.freeze([
  {index: 0, label: 'SOUFFLE', value: SKeys.TypesPlafondPlaco.SOUFFLE},
  // {index: 1, label: 'POSE'   , value: SKeys.TypesPlafondPlaco.POSE   }
]);

export const PlacoPlafondCloisonEpaisseurs = Object.freeze([
  {index: 0, label: '50', value: SKeys.EpaisseursPlaco._50},
  {index: 1, label: '72', value: SKeys.EpaisseursPlaco._72}
]);

export const PlacoVidesSousPlafond = Object.freeze([
  {index: 0, label: '2,50', value: SKeys.HauteursVideSousPlafond._2_5},
  {index: 1, label: '2,70', value: SKeys.HauteursVideSousPlafond._2_7}
]);

export const ElectriciteTypes = Object.freeze([
  {index: 0, label: 'DOOXIE'          , value: SKeys.TypesElectricite.DOOXIE          },
  {index: 1, label: 'SQUARE ** HOMECO', value: SKeys.TypesElectricite.SQUARE_HOMECO}
]);

export const BlocPortesGammes = Object.freeze([
  // {index: 0, label: 'RIGHINI'   , value: SKeys.GammesBlocPorte.RIGHINI   },
  {index: 1, label: 'MF GAMME 1', value: SKeys.GammesBlocPorte.MF_GAMME_1},
  // {index: 2, label: 'MF GAMME 2', value: SKeys.GammesBlocPorte.MF_GAMME_2}
]);

export const BlocPortesModeles = Object.freeze([
  {index: 0, label: '73'    , value: SKeys.ModelesBlocPorte._73    },
  {index: 1, label: '83'    , value: SKeys.ModelesBlocPorte._83    },
  {index: 2, label: '73 ISO', value: SKeys.ModelesBlocPorte._73_ISO},
  {index: 3, label: '83 ISO', value: SKeys.ModelesBlocPorte._83_ISO}
]);

export const CarrelagePieces = Object.freeze([
  {index: 0, label: 'LOCAL TECHNIQUE', value: SKeys.PiecesCarrelage.CELLIER },
  {index: 1, label: 'CUISINE'        , value: SKeys.PiecesCarrelage.CUISINE },
  {index: 2, label: 'WC'             , value: SKeys.PiecesCarrelage.WC      },
  {index: 3, label: 'SdE'            , value: SKeys.PiecesCarrelage.SDE     },
  {index: 4, label: 'BAINS'          , value: SKeys.PiecesCarrelage.BAINS   },
  {index: 5, label: 'CHAMBRE'        , value: SKeys.PiecesCarrelage.CHAMBRES},
  {index: 6, label: 'DGT'            , value: SKeys.PiecesCarrelage.DGT     },
  {index: 7, label: 'PALIER'         , value: SKeys.PiecesCarrelage.PALIER  },
  {index: 8, label: 'GARAGE'         , value: SKeys.PiecesCarrelage.GARAGE  }
]);

export const CarrelageGammes = Object.freeze([
  {index: 0, label: 'INITIALE', value: SKeys.GammesCarrelage.INITIALE},
  {index: 1, label: 'PRESTIGE', value: SKeys.GammesCarrelage.PRESTIGE},
]);

export const OuiNon = Object.freeze([
  {index: 0, label: 'OUI', value: SKeys.OuiNon.OUI},
  {index: 1, label: 'NON', value: SKeys.OuiNon.NON},
]);

export const ChauffageTypes = Object.freeze([
  {index: 0, label: 'CLIM + RADIATEUR ELECTRIQUE'     , value: SKeys.TypesChauffage.CLIM_RADIATEUR_ELEC   },
  {index: 1, label: 'POELE A GRANULE + RAD ELECTRIQUE', value: SKeys.TypesChauffage.POELE_RADIATEUR_ELEC  },
  {index: 2, label: 'PAC AIR/EAU PLANCHER CHAUFFANT'  , value: SKeys.TypesChauffage.PAC_PLANCHER_CHAUFFANT},
  {index: 3, label: 'PAC AIR/EAU RADIATEUR A EAU'     , value: SKeys.TypesChauffage.PAC_RADIATEUR_EAU     },
  {index: 4, label: 'PAC AIR/AIR GAINABLE'            , value: SKeys.TypesChauffage.PAC_GAINABLE          },
  // {index: 4, label: 'GAZ PLANCHER CHAUFFANT'          , value: SKeys.TypesChauffage.GAZ_PLANCHER_CHAUFFANT},
  // {index: 5, label: 'GAZ RADIATEUR A EAU'             , value: SKeys.TypesChauffage.GAZ_RADIATEUR_EAU     }
]);

export const FacadeRevetements = Object.freeze([
  {index: 0, label: 'ENDUIT', value: SKeys.RevetementsFacade.ENDUIT}
]);

export const PieceTypes = Object.freeze([
  {index: 0, label: 'PIECE DE VIE'   , value: SKeys.TypesPieces.PIECE_DE_VIE },
  {index: 1, label: 'CUISINE'        , value: SKeys.TypesPieces.CUISINE      },
  {index: 2, label: 'LOCAL TECHNIQUE', value: SKeys.TypesPieces.CELLIER      },
  {index: 3, label: 'DGT'            , value: SKeys.TypesPieces.DGT          },
  {index: 4, label: 'WC'             , value: SKeys.TypesPieces.WC           },
  {index: 5, label: 'SDE'            , value: SKeys.TypesPieces.SDE          },
  {index: 6, label: 'CHAMBRES'       , value: SKeys.TypesPieces.CHAMBRES     },
  {index: 7, label: 'GARAGE'         , value: SKeys.TypesPieces.GARAGE       },
  {index: 8, label: 'TERASSE BETON'  , value: SKeys.TypesPieces.TERASSE_BETON}
]);

export const FenetreTypes = Object.freeze([
  {index: 0, label: 'PETITE FENETRE'     , value: SKeys.TypesFenetre.PETITE_FENETRE     },
  {index: 1, label: 'ABATTANT'           , value: SKeys.TypesFenetre.ABATTANT          },
  {index: 2, label: 'OEIL DE BOEUF'      , value: SKeys.TypesFenetre.OEIL_DE_BOEUF       },
  {index: 3, label: 'FENETRE OUVRANTE'   , value: SKeys.TypesFenetre.FENETRE_OUVRANTE   },
  {index: 4, label: 'FENETRE COULISSANTE', value: SKeys.TypesFenetre.FENETRE_COULISSANTE},
  {index: 5, label: 'FENETRE FIXE'       , value: SKeys.TypesFenetre.FENETRE_FIXE       },
  {index: 6, label: 'PORTE FENETRE'      , value: SKeys.TypesFenetre.PORTE_FENETRE      },
  {index: 7, label: 'BAIE COULISSANTE'   , value: SKeys.TypesFenetre.BAIE_COULISSANTE   }
]);

export const FenetreDimensions = Object.freeze({
  "petiteFenetre": [
    {index: 0, label: '40 X 75', value: '40X75'},
    {index: 1, label: '50 X 75', value: '50X75'},
    {index: 2, label: '60 X 75', value: '60X75'},
    {index: 3, label: '80 X 75', value: '80X75'},
    {index: 4, label: '60 X 95', value: '60X95'}
  ],
  "abattant": [
    {index: 0, label: '100 X 75', value: '100X75'},
    {index: 1, label: '120 X 75', value: '120X75'},
    {index: 2, label: '140 X 75', value: '140X75'}
  ],
  "oeilDeBoeuf": [
    {index: 0, label: '60 X 60', value: '60X60'},
    {index: 1, label: '80 X 80', value: '80X80'}
  ],
  "fenetreOuvrante": [
    {index: 0 , label: '80 X 85'  , value: '80X85'  },
    {index: 1 , label: '100 X 85' , value: '100X85' },
    {index: 2 , label: '120 X 85' , value: '120X85' },
    {index: 3 , label: '80 X 105' , value: '80X105'},
    {index: 4 , label: '90 X 105' , value: '90X105'},
    {index: 5 , label: '100 X 105', value: '100X105'},
    {index: 6 , label: '120 X 105', value: '120X105'},
    {index: 7 , label: '140 X 105', value: '140X105'},
    {index: 8 , label: '100 X 115', value: '100X115'},
    {index: 9 , label: '120 X 115', value: '120X115'},
    {index: 10, label: '140 X 115', value: '140X115'},
    {index: 11, label: '100 X 125', value: '100X125'},
    {index: 12, label: '120 X 125', value: '120X125'},
    {index: 13, label: '140 X 125', value: '140X125'},
    {index: 14, label: '100 X 135', value: '100X135'},
    {index: 15, label: '120 X 135', value: '120X135'},
    {index: 16, label: '140 X 135', value: '140X135'},
    {index: 17, label: '100 X 105', value: '100X155'},
    {index: 18, label: '120 X 105', value: '120X155'},
    {index: 19, label: '140 X 105', value: '140X155'}
  ],
  "fenetreCoulissante": [
    {index: 0 , label: '140 X 85' , value: '140X85' },
    {index: 1 , label: '160 X 85' , value: '160X85' },
    {index: 2 , label: '180 X 85' , value: '180X85' },
    {index: 3 , label: '200 X 85' , value: '200X85' },
    {index: 4 , label: '220 X 85' , value: '220X85' },
    {index: 5 , label: '240 X 85' , value: '240X85' },
    {index: 6 , label: '260 X 85' , value: '260X85' },
    {index: 7 , label: '280 X 85' , value: '280X85' },
    {index: 8 , label: '300 X 85' , value: '300X85' },
    {index: 9 , label: '140 X 105', value: '140X105'},
    {index: 10, label: '160 X 105', value: '160X105'},
    {index: 11, label: '180 X 105', value: '180X105'},
    {index: 12, label: '200 X 105', value: '200X105'},
    {index: 13, label: '220 X 105', value: '220X105'},
    {index: 14, label: '240 X 105', value: '240X105'},
    {index: 15, label: '300 X 105', value: '300X105'},
    {index: 16, label: '140 X 115', value: '140X115'},
    {index: 17, label: '140 X 125', value: '140X125'},
    {index: 18, label: '140 X 135', value: '140X135'},
    {index: 19, label: '140 X 155', value: '140X155'},
    {index: 20, label: '140 X 175', value: '140X175'}
  ],
  "fenetreFixe": [
    {index: 0, label: '50 X 395', value: '50X395'},
    {index: 1, label: '50 X 215', value: '50X215'},
    {index: 2, label: '60 X 215', value: '60X215'}
  ],
  "porteFenetre": [
    {index: 0, label: '90 X 215' , value: '90X215' },
    {index: 1, label: '100 X 215', value: '100X215'},
    {index: 2, label: '120 X 215', value: '120X215'},
    {index: 3, label: '130 X 215', value: '130X215'},
    {index: 4, label: '140 X 215', value: '140X215'},
    {index: 5, label: '150 X 215', value: '150X215'}
  ],
  "baieCoulissante": [
    {index: 0, label: '160 X 215', value: '160X215'},
    {index: 1, label: '180 X 215', value: '180X215'},
    {index: 2, label: '200 X 215', value: '200X215'},
    {index: 3, label: '220 X 215', value: '220X215'},
    {index: 4, label: '240 X 215', value: '240X215'},
    {index: 5, label: '260 X 215', value: '260X215'},
    {index: 6, label: '280 X 215', value: '280X215'},
    {index: 7, label: '300 X 215', value: '300X215'}
  ],
});

export const FenetreOptions = Object.freeze({
  "petiteFenetre": [
  ],
  "abattant": [
  ],
  "oeilDeBoeuf": [
  ],
  "fenetreOuvrante": [
  ],
  "fenetreCoulissante": [
  ],
  "fenetreFixe": [
  ],
  "porteFenetre": [
  ],
  "baieCoulissante": [
    {index: 0, label: 'NORMAL'   , value: SKeys.OptionsFenetre.NORMAL   },
    {index: 1, label: 'GALANDAGE', value: SKeys.OptionsFenetre.GALANDAGE}
  ],
});

export const FenetreEmplacements = Object.freeze([
  {index: 0, label: 'FACADE AVANT'  , value: SKeys.Facades.AVANT  },
  {index: 1, label: 'PIGNON DROIT'  , value: SKeys.Facades.DROITE },
  {index: 2, label: 'FACADE ARRIERE', value: SKeys.Facades.ARRIERE},
  {index: 3, label: 'PIGNON GAUCHE' , value: SKeys.Facades.GAUCHE }
]);

export const SanitaireTypes = Object.freeze([
  {index: 0, label: 'BAIGNOIRE'         , value: SKeys.TypesSanitaires.BAIGNOIRE         },
  {index: 1, label: 'DOUCHE ITAL'       , value: SKeys.TypesSanitaires.DOUCHE_ITAL       },
  {index: 2, label: 'BAC A DOUCHE'      , value: SKeys.TypesSanitaires.BAC_A_DOUCHE      },
  {index: 3, label: 'DOUCHE EXTRA PLATE', value: SKeys.TypesSanitaires.DOUCHE_EXTRA_PLATE},
  {index: 4, label: 'WC SIMPLE'         , value: SKeys.TypesSanitaires.WC_SIMPLE         },
  {index: 5, label: 'WC SUSPENDU'       , value: SKeys.TypesSanitaires.WC_SUSPENDU       },
  {index: 6, label: 'LAVABO'            , value: SKeys.TypesSanitaires.LAVABO            },
  {index: 7, label: 'LAVE MAINS'        , value: SKeys.TypesSanitaires.LAVE_MAINS        }
]);

export const SanitaireFournisseurs = Object.freeze([
  {index: 0, label: 'MAV'   , value: SKeys.Fournisseurs.MAV   },
  {index: 1, label: 'CLIENT', value: SKeys.Fournisseurs.CLIENT},
  {index: 2, label: 'OPTION', value: SKeys.Fournisseurs.OPTION}
]);

export const FaienceOptions = Object.freeze({
  "baignoire": [
    {index: 0, label: 'OUI'   , value: SKeys.OuiOptionNon.OUI   },
    {index: 1, label: 'NON'   , value: SKeys.OuiOptionNon.NON   },
    {index: 2, label: 'OPTION', value: SKeys.OuiOptionNon.OPTION}
  ],
  "doucheItal": [
    {index: 0, label: 'OUI'   , value: SKeys.OuiOptionNon.OUI   },
    {index: 1, label: 'NON'   , value: SKeys.OuiOptionNon.NON   },
    {index: 2, label: 'OPTION', value: SKeys.OuiOptionNon.OPTION}
  ],
  "bacADouche": [
    {index: 0, label: 'OUI'   , value: SKeys.OuiOptionNon.OUI   },
    {index: 1, label: 'NON'   , value: SKeys.OuiOptionNon.NON   },
    {index: 2, label: 'OPTION', value: SKeys.OuiOptionNon.OPTION}
  ],
  "doucheExtraPlate": [
    {index: 0, label: 'OUI'   , value: SKeys.OuiOptionNon.OUI   },
    {index: 1, label: 'NON'   , value: SKeys.OuiOptionNon.NON   },
    {index: 2, label: 'OPTION', value: SKeys.OuiOptionNon.OPTION}
  ],
  "wcSimple": [
  ],
  "wcSuspendu": [
  ],
  "lavabo": [
  ],
  "laveMains": [
  ]
});

export const UserRoles = Object.freeze([
  {index: 0, label: 'Administrateur', value: 2},
  {index: 1, label: 'Utilisateur'   , value: 3}
]);
