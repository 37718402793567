import { queryOneParamValue } from "../api/queries";
import { SKeys } from "../param/selectKeys";
import { PKeys } from "../param/parametersKeys";

import { validFloat } from "./helpers"

export const RevetementIntParams = Object.freeze({
  PRIX_CARRELAGE_INIT: 29,
  PRIX_CARRELAGE_INIT_OPTION: 27,
  PRIX_CARRELAGE_PRESTIGE: 34,
  PRIX_PLINTHE_INIT: 4.5,
  PRIX_PLINTHE_PRESTIGE: 4.5,
  PRIX_FAIENCE_1: 10.5,
  PRIX_FAIENCE_2: 39.4,
  PRIX_CHAPE: 14,
  PRIX_PARQUET: 36,
  PRIX_PLINTHE_PARQUET: 2.5,
  PRIX_TRAPPE_AIMANT: 100,
  PRIX_DOUCHE_ITAL: 200,
  PRIX_REPOSE_BP: 40
});

export async function computeParamsRevetementInt(targetObj, pieces, gammePrincipales, optionPrincipales, surfaceAutre, gammeAutre, optionAutre, sanitaires, chapeSurface2, parquetOption, parquetSurface) {
  let surfacePrincipales = 0;
  let chapeSurface1 = 0;
  pieces.forEach(piece => {
    if (piece.type && piece.surface && (piece.type === SKeys.TypesPieces.PIECE_DE_VIE || piece.type === SKeys.TypesPieces.CUISINE || piece.type === SKeys.TypesPieces.CELLIER || piece.type === SKeys.TypesPieces.DGT || piece.type === SKeys.TypesPieces.WC || piece.type === SKeys.TypesPieces.SDE)) {
      surfacePrincipales += validFloat(piece.surface);
    }    
    else if (piece.type && piece.surface && piece.type === SKeys.TypesPieces.CHAMBRES) {
      chapeSurface1 += validFloat(piece.surface);
    }    
  });
  chapeSurface1 -= validFloat(surfaceAutre);
  //CARRELAGE INITIAL
  if (gammePrincipales === SKeys.GammesCarrelage.INITIALE) {
    if (optionPrincipales === SKeys.OuiNon.OUI) {
      targetObj.revetementInt.carrelageInitialOption += validFloat(surfacePrincipales) * 1.2;
    }
    else {
      targetObj.revetementInt.carrelageInitial += validFloat(surfacePrincipales) * 1.2;
    }
  }
  if (gammeAutre === SKeys.GammesCarrelage.INITIALE) {
    if (optionAutre === SKeys.OuiNon.OUI) {
      targetObj.revetementInt.carrelageInitialOption += validFloat(surfaceAutre) * 1.2;
    }
    else {
      targetObj.revetementInt.carrelageInitial += validFloat(surfaceAutre) * 1.2;
    }
  }
  // targetObj.revetementInt.carrelageInitial = parseFloat(targetObj.revetementInt.carrelageInitial.toFixed(2));
  // targetObj.revetementInt.carrelageInitialOption = parseFloat(targetObj.revetementInt.carrelageInitialOption.toFixed(2));
  //CARRELAGE PRESTIGE
  if (gammePrincipales === SKeys.GammesCarrelage.PRESTIGE) {
    if (optionPrincipales === SKeys.OuiNon.OUI) {
      targetObj.revetementInt.carrelagePrestigeOption += validFloat(surfacePrincipales) * 1.2;
    }
    else {
      targetObj.revetementInt.carrelagePrestige += validFloat(surfacePrincipales) * 1.2;
    }
  }
  if (gammeAutre === SKeys.GammesCarrelage.PRESTIGE) {
    if (optionAutre === SKeys.OuiNon.OUI) {
      targetObj.revetementInt.carrelagePrestigeOption += validFloat(surfaceAutre) * 1.2;
    }
    else {
      targetObj.revetementInt.carrelagePrestige += validFloat(surfaceAutre) * 1.2;
    }
  }
  // targetObj.revetementInt.carrelagePrestige = parseFloat(targetObj.revetementInt.carrelagePrestige.toFixed(2));
  // targetObj.revetementInt.carrelagePrestigeOption = parseFloat(targetObj.revetementInt.carrelagePrestigeOption.toFixed(2));
  //FAIENCE
  sanitaires.forEach(element => {
    if (element.fournisseur === SKeys.Fournisseurs.MAV || element.fournisseur === SKeys.Fournisseurs.OPTION) {
      if (element.faience === SKeys.OuiOptionNon.OUI) {
        targetObj.revetementInt.faience += 20 * validFloat(element.nombre);
      }
      else if (element.faience === SKeys.OuiOptionNon.OPTION) {
        targetObj.revetementInt.faienceOption += 20 * validFloat(element.nombre);
      }
    }
  });
  // targetObj.revetementInt.faience = parseFloat(targetObj.revetementInt.faience.toFixed(2));
  // targetObj.revetementInt.faienceOption = parseFloat(targetObj.revetementInt.faienceOption.toFixed(2));
  //CHAPE
  targetObj.revetementInt.chape = (1.2 * (validFloat(chapeSurface1) + validFloat(chapeSurface2))) + validFloat(targetObj.revetementInt.carrelageInitialOption) + validFloat(targetObj.revetementInt.carrelagePrestigeOption);
  // targetObj.revetementInt.chape = parseFloat(targetObj.revetementInt.chape.toFixed(2));
  //PARQUET
  if (parquetOption === SKeys.OuiNon.NON) {
    targetObj.revetementInt.parquet = 1.2 * validFloat(parquetSurface);
    // targetObj.revetementInt.parquet = parseFloat(targetObj.revetementInt.parquet.toFixed(2));
  }
  else if (parquetOption === SKeys.OuiNon.OUI) {
    targetObj.revetementInt.parquetOption = 1.2 * validFloat(parquetSurface);
    // targetObj.revetementInt.parquetOption = parseFloat(targetObj.revetementInt.parquetOption.toFixed(2));
  }
}

export async function addCarrelageInit(targetObj) {
  const prixCarrelageKey = PKeys.GAMME_CARRELAGE_PRIX;
  const gammeCarrelage = SKeys.GammesCarrelage.INITIALE;
  let prixCarrelage;
  if (gammeCarrelage && prixCarrelageKey) {
    prixCarrelage = await queryOneParamValue(prixCarrelageKey, gammeCarrelage);
  }
  targetObj.revetementInt.ttlPrixRevetementInt += (validFloat(RevetementIntParams.PRIX_CARRELAGE_INIT) + validFloat(prixCarrelage)) * validFloat(targetObj.revetementInt.carrelageInitial);
  targetObj.revetementInt.ttlPrixCarrelageOption += validFloat(RevetementIntParams.PRIX_CARRELAGE_INIT_OPTION) * validFloat(targetObj.revetementInt.carrelageInitialOption);
}

export async function addCarrelagePrestige(targetObj) {
  const prixCarrelageKey = PKeys.GAMME_CARRELAGE_PRIX;
  const gammeCarrelage = SKeys.GammesCarrelage.PRESTIGE;
  let prixCarrelage;
  if (gammeCarrelage && prixCarrelageKey) {
    prixCarrelage = await queryOneParamValue(prixCarrelageKey, gammeCarrelage);
  }
  targetObj.revetementInt.ttlPrixRevetementInt += (validFloat(RevetementIntParams.PRIX_CARRELAGE_PRESTIGE) + validFloat(prixCarrelage)) * validFloat(targetObj.revetementInt.carrelagePrestige);
  targetObj.revetementInt.ttlPrixCarrelageOption += (validFloat(RevetementIntParams.PRIX_CARRELAGE_PRESTIGE) + validFloat(prixCarrelage)) * validFloat(targetObj.revetementInt.carrelagePrestigeOption);
}

export async function addPlintheInit(targetObj) {
  const prixPlintheKey = PKeys.GAMME_PLINTHE_PRIX;
  const gammePlinthe = SKeys.GammesCarrelage.INITIALE;
  let prixPlinthe;
  if (gammePlinthe && prixPlintheKey) {
    prixPlinthe = await queryOneParamValue(prixPlintheKey, gammePlinthe);
  }
  let tmpCarrelage = validFloat(targetObj.revetementInt.carrelageInitial) ? validFloat(targetObj.revetementInt.carrelageInitial) + 10 : 0;
  let tmpCarrelageOption = validFloat(targetObj.revetementInt.carrelageInitialOption) ? validFloat(targetObj.revetementInt.carrelageInitialOption) + 10 : 0;
  targetObj.revetementInt.ttlPrixRevetementInt += (validFloat(RevetementIntParams.PRIX_PLINTHE_INIT) + validFloat(prixPlinthe)) * validFloat(tmpCarrelage);
  targetObj.revetementInt.ttlPrixCarrelageOption += (validFloat(RevetementIntParams.PRIX_PLINTHE_INIT) + validFloat(prixPlinthe)) * validFloat(tmpCarrelageOption);
}

export async function addPlinthePrestige(targetObj) {
  const prixPlintheKey = PKeys.GAMME_PLINTHE_PRIX;
  const gammePlinthe = SKeys.GammesCarrelage.PRESTIGE;
  let prixPlinthe;
  if (gammePlinthe && prixPlintheKey) {
    prixPlinthe = await queryOneParamValue(prixPlintheKey, gammePlinthe);
  }
  let tmpCarrelage = validFloat(targetObj.revetementInt.carrelagePrestige) ? validFloat(targetObj.revetementInt.carrelagePrestige) + 10 : 0;
  let tmpCarrelageOption = validFloat(targetObj.revetementInt.carrelagePrestigeOption) ? validFloat(targetObj.revetementInt.carrelagePrestigeOption) + 10 : 0;
  targetObj.revetementInt.ttlPrixRevetementInt += (validFloat(RevetementIntParams.PRIX_PLINTHE_PRESTIGE) + validFloat(prixPlinthe)) * validFloat(tmpCarrelage);
  targetObj.revetementInt.ttlPrixCarrelageOption += (validFloat(RevetementIntParams.PRIX_PLINTHE_PRESTIGE) + validFloat(prixPlinthe)) * validFloat(tmpCarrelageOption);
}

export async function addFaience(targetObj) {
  const prixFaienceKey = PKeys.FAIENCE_PRIX;
  let prixFaience;
  if (prixFaienceKey) {
    prixFaience = await queryOneParamValue(prixFaienceKey);
  }
  targetObj.revetementInt.ttlPrixRevetementInt += prixFaience * validFloat(targetObj.revetementInt.faience);
  targetObj.revetementInt.ttlPrixFaienceOption += prixFaience * validFloat(targetObj.revetementInt.faienceOption);
}

export function addChape(targetObj) {
  targetObj.revetementInt.ttlPrixRevetementInt += validFloat(RevetementIntParams.PRIX_CHAPE) * validFloat(targetObj.revetementInt.chape);
}

export function addParquet(targetObj) {
  targetObj.revetementInt.ttlPrixRevetementInt += validFloat(RevetementIntParams.PRIX_PARQUET) * validFloat(targetObj.revetementInt.parquet);
  targetObj.revetementInt.ttlPrixParquetOption += validFloat(RevetementIntParams.PRIX_PARQUET) * validFloat(targetObj.revetementInt.parquetOption);
}

export function addPlintheParquet(targetObj) {
  let tmpParquet = validFloat(targetObj.revetementInt.parquet) ? validFloat(targetObj.revetementInt.parquet) + 10 : 0;
  let tmpParquetOption = validFloat(targetObj.revetementInt.parquetOption) ? validFloat(targetObj.revetementInt.parquetOption) + 10 : 0;
  targetObj.revetementInt.ttlPrixRevetementInt += validFloat(RevetementIntParams.PRIX_PLINTHE_PARQUET) * validFloat(tmpParquet);
  targetObj.revetementInt.ttlPrixParquetOption += validFloat(RevetementIntParams.PRIX_PLINTHE_PARQUET) * validFloat(tmpParquetOption);
}

export function addTrappeAimant(targetObj, sanitaires) {
  let nbBaignoire = 0;
  sanitaires.forEach(element => {
    if (element.type === SKeys.TypesSanitaires.BAIGNOIRE && element.fournisseur === SKeys.Fournisseurs.MAV) {
      nbBaignoire += element.nombre;
    }
  });
  targetObj.revetementInt.ttlPrixRevetementInt += validFloat(RevetementIntParams.PRIX_TRAPPE_AIMANT) * validFloat(nbBaignoire);
}

export function addDoucheItal(targetObj, sanitaires) {
  let nbDoucheItal = 0;
  let nbDoucheItalOption = 0;
  sanitaires.forEach(element => {
    if (element.type === SKeys.TypesSanitaires.DOUCHE_ITAL) {
      if (element.fournisseur === SKeys.Fournisseurs.MAV) {
        nbDoucheItal += element.nombre;
      }
      else if (element.fournisseur === SKeys.Fournisseurs.OPTION) {
        nbDoucheItalOption += element.nombre;
      }
    }
  });
  targetObj.revetementInt.ttlPrixRevetementInt += validFloat(RevetementIntParams.PRIX_DOUCHE_ITAL) * validFloat(nbDoucheItal);
  targetObj.revetementInt.ttlPrixDoucheItalOption += validFloat(RevetementIntParams.PRIX_DOUCHE_ITAL) * validFloat(nbDoucheItalOption);
}

export function addReposeBP(targetObj) {
  if (validFloat(targetObj.revetementInt.carrelageInitial) || validFloat(targetObj.revetementInt.carrelagePrestige) || validFloat(targetObj.revetementInt.faience) || validFloat(targetObj.revetementInt.chape) || validFloat(targetObj.revetementInt.parquet)) {
    targetObj.revetementInt.ttlPrixRevetementInt += validFloat(RevetementIntParams.PRIX_REPOSE_BP);
  }
}