<template>
  <div class="utilisateurs single-view flex-cnt-row justify-center">
    <div class="main-users flex-cnt-col justify-between">
      <div class="view-header flex-cnt-row flex-center">
        <h2>{{("Utilisateurs").toUpperCase()}}</h2>
      </div>
      <div class="flex-cnt-row align-center">
        <button 
          class="addel-btn" 
          v-on:click="addMode = true" 
          v-if="!addMode"
        >
          + Nouvel utilisateur
        </button>
        <button 
          class="addel-btn cancel-btn" 
          v-on:click="resetAddForm" 
          v-if="addMode"
        >
          × Annuler
        </button>
        <button 
          class="addel-btn add-btn" 
          v-on:click="createUser" 
          v-if="addMode" 
          :disabled="!validForm"
        >
          ✓ Ajouter
        </button>
      </div>
      <div class="add-section flex-cnt-row align-center" v-if="addMode">
        <div class="add-cell flex-cnt-row flex-center">
          <input v-model="newUsername" placeholder="Identifiant" v-bind:class="{'invalid': !(!newUsername.length||validUsername)}" >
        </div>
        <div class="add-cell flex-cnt-row flex-center">
          <input v-model="newPassword" placeholder="Mot de passe" v-bind:class="{'invalid': !(!newPassword.length||validPassword)}">
        </div>
        <div class="add-cell flex-cnt-row flex-center">
          <select v-model="newRole">
            <option disabled value="">Rôle</option>
            <option v-for="option in optionsRole"
              :key="option.index" 
              :value="option.value"
            >
              {{ option.label }}
            </option>
          </select>
        </div>
      </div>
      <div class="unit-users flx1 flex-cnt-col justify-start align-center">
        <UsersTable
          v-bind:reset="reset"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { queryPost } from '../api/queries'
import { InputTypes, isValid } from '../api/validation'
import UsersTable from "../components/forms/UsersTable.vue"

import { UserRoles } from '../param/selectOptions'


export default {
  components: {
    UsersTable
  },
  data: function () {
    return {
      optionsRole: UserRoles,
      addMode: false,
      newUsername: '',
      newPassword: '',
      newRole: '',
      reset: false
    }
  },
  computed: {
    validUsername: function () {
      return isValid(InputTypes.USERNAME, this.newUsername);
    },
    validPassword: function () {
      return isValid(InputTypes.PASSWORD, this.newPassword);
    },
    validRole: function () {
      return isValid(InputTypes.ROLE, this.newRole);
    },
    validForm: function () {
      return this.validUsername && this.validPassword && this.validRole;
    },
  },
  methods: {
    async createUser() {
      if (this.validUsername && this.validPassword && this.validRole) {
        let result = await queryPost('users', {
          username: this.newUsername,
          password: this.newPassword,
          role: this.newRole
        });
        if (result) {
          this.resetAddForm();
          this.reset = !this.reset;
        }
      }
    },
    resetAddForm() {
      this.addMode = false;
      this.newUsername = '';
      this.newPassword = '';
      this.newRole = '';
    }
  },
  mounted() {
  }
}
</script>

<style scoped>
.utilisateurs {
  min-width: 800px;
}

.single-view {
  padding: 0;
}

.main-users {
  box-sizing: border-box;
  padding: 20px 40px;
  min-width: 700px;
}

.unit-users{
  justify-content: start;
  box-sizing: border-box;
  width: fit-content;
}

.unit-table{
  width: fit-content;
}

.addel-btn {
  width: fit-content;
  padding: 0px 10px;
}

.cancel-btn {
  background: rgb(245, 197, 197);
  margin-right: 2px;
}

.cancel-btn:hover {
  background: rgb(230, 180, 180);
}

.add-btn {
  background: rgb(177, 226, 184);
}
.add-btn:hover {
  background: rgb(158, 207, 165);
}
.add-btn:disabled {
  opacity: 0.4;
  background: #dee3e6;
  cursor: normal;
  transition: 0.25s;
}

.add-section {
  margin-top: 20px;
  border: 1px solid #c6d2d9;
  background: rgb(228, 234, 238);
  padding: 5px 10px;
  box-sizing: border-box;
}

.add-cell {
  padding: 0px 5px;
  min-width: 230px;
  box-sizing: border-box;
}

input.invalid, select.invalid {
  border-color: red;
  border-width: 2px;
  border-radius: 3px;
  outline: 0;
}
</style>
