<template>
  <div id="the-header" class="flex-cnt-row align-center justify-between">
    <div class="logo-content flex-cnt-row align-center justify-start">
      <img class="header-logo" src="@/assets/logos/logo-mav.png" alt="Logo MAV">
    </div>
    <TheNavMenu />
    <div class="flex-cnt-row align-center justify-end">
      <div class="login-content flex-cnt-row flex-center" v-on:click="logout">
        <div class="logout-title">
          Déconnexion
        </div>
        <img class="logout-icon" src="@/assets/icons/logout-icon.png" alt="logout icon"> 
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapMutations } from 'vuex';

import TheNavMenu from "./TheNavMenu.vue";

export default {
  components: {
    TheNavMenu
  },
  methods: {
    logout() {
      if (confirm("Voulez-vous vous déconnecter ?")) {


        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
        delete axios.defaults.headers.common['Authorization']
        
        
        // this.logOut();
        this.$router.push('/login');
      }
    },
    ...mapMutations(["logOut"])
  }
}
</script>

<style scoped>
  #the-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 75px;
    background: #fff;
    border-bottom: 1px solid #c6d2d9;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    z-index: 9999;
  }

  #the-header > div {
    box-sizing: border-box;
    height: 100%;
  }

  .header-logo{
    padding: 0px 50px;
    height: 55px;
    box-sizing: border-box;
  }

  .logo-content {
    width: 180px;
  }

  .login-content {
    height: 100%;
    width: 180px;
    box-sizing: border-box;
    padding: 5px 30px;
    background: #d2dfe7;
  }
  .login-content:hover {
    background: #c6d2d9;
    cursor: pointer;
  }

  .logout-title {
    display: block;
    padding: 0px 8px;
  }

  .logout-icon {
    height: 16px;
  }
  
@media only screen and (max-width: 1000px) {
  .logo-content {
    width: 70px;
  }

  .header-logo {
    padding: 0px 0px 0px 30px;
  }

  .login-content {
    width: 70px;
  }

  .logout-title {
    display: none;
  }
}

@media only screen and (max-width: 640px) {
  #the-header {
    height: 60px;
  }

  .logo-content {
    width: 70px;
  }

  .header-logo {
    height: 45px;
    padding: 0px 0px 0px 15px;
  }

  .login-content {
    width: 55px;
    padding: 0;
  }

  .logout-icon {
    height: 14px;
  }
}
</style>