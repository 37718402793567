import Vue       from 'vue'
import VueRouter from 'vue-router'

import store from "../store/index";

import MainView      from '../views/MainView.vue';
// import Home          from '../views/Home.vue'
import Documents     from '../views/Documents.vue'
import Descriptif    from '../views/Descriptif.vue'
import Parameters    from '../views/Parameters.vue'
import Utilisateurs  from '../views/Utilisateurs.vue'
import Textes        from '../views/Textes.vue'
import Images        from '../views/Images.vue'
import Recapitulatif from '../views/Recapitulatif.vue'
import Generation    from '../views/Generation.vue'
import Login         from '../views/Login.vue'

// import axios from 'axios';


Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/',
    component: MainView,
    children: [/*
      {
        path: '',
        name: 'Home',
        component: Home,
        meta: {
          requiresAuth: true
        }
      },*/
      {
        // path: '/documents',
        path: '',
        name: 'Documents',
        component: Documents,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/recapitulatif',
        name: 'Recapitulatif',
        component: Recapitulatif,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/generation',
        name: 'Generation',
        component: Generation,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/parameters',
        name: 'Parameters',
        component: Parameters,
        meta: {
          requiresAuth: true,
          isAdmin: true
        }
      },
      {
        path: '/utilisateurs',
        name: 'Utilisateurs',
        component: Utilisateurs,
        meta: {
          requiresAuth: true,
          isAdmin: true
        }
      },
      {
        path: '/textes',
        name: 'Textes',
        component: Textes,
        meta: {
          requiresAuth: true,
          isAdmin: true
        }
      },
      {
        path: '/images',
        name: 'Images',
        component: Images,
        meta: {
          requiresAuth: true,
          isAdmin: true
        }
      },
      {
        path: '/descriptif',
        name: 'Descriptif',
        component: Descriptif,
        meta: {
          requiresAuth: true
        }
      },
    ]
  },
]

const router = new VueRouter({
  routes
});

router.beforeEach(async (to, from, next) => {
  if(!store.getters["getAuthData"].token){
      const access_token = localStorage.getItem("access_token");
      const refresh_token = localStorage.getItem("refresh_token");
      if(access_token){
          const data = {
              token:   access_token,
              refresh: refresh_token
          };
          store.commit('saveTokenData', data);
      }
  }

  let auth = store.getters['getAuthData'].tokenExp * 1000 < Date.now() ? false : true;
  
  /*
  if (!auth) {
    const authData = store.getters["getAuthData"];
    if (authData.token) {
      const payload = {
        access_token: authData.token,
        refresh_token: authData.refresh_token,
      };
      const refreshResponse = await axios.post(
        "http://localhost:3000/users/refreshtoken",
        payload
      );
      store.commit("saveTokenData", refreshResponse.data);
      auth = true;
    }
  }
  */

  if (to.matched.some(record => record.meta.requiresAuth)) {
    //PB CACHE A REGLER !!!
    if(!auth){
       return next({path: '/login'});
    }
  }
  if (to.matched.some(record => record.meta.isAdmin)) {
    const admin = store.getters['getAuthData'].isAdmin;
    if(admin != 1 && admin != 2){
       return next({path: '/'});
    }
  }
  return  next();
});

export default router
