<template>
  <div class="text-table flx1 flex-cnt-col align-center justify-start">
    <table>
      <thead>
        <tr>
          <th>
            Label
          </th>
          <th>
            Valeur
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="text in listTexts" :key="text.index">
          <td>
            {{text.label}}
          </td>
          <td>
            <TextInput
              v-bind:isEditMode="true"
              v-bind:queryTable="'texts'"
              v-bind:queryId="text.queryId"
              v-bind:initialValue="text.value"
              v-bind:inputTag="'textarea'"
              v-bind:queryKey="'value'"
              v-bind:validType="validType"
              v-bind:placeholder="'Texte...'"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import TextInput from "./FormInput.vue";
import { queryGetByParamKey, queryPost } from '../../api/queries';
import { InputTypes } from '../../api/validation';

export default {
  components: {
    TextInput
  },
  data: function () {
    return {
      listTexts: null,
      validType: InputTypes.TEXT_500
    }
  },
  props: {
    initTexts: {
      type: Array,
      required: false,
      default: null
    }
  },
  watch: {
    initTexts: function () {
      this.formatlistTexts();
      this.queryTextsValue();
    }
  },
  methods: {
    formatlistTexts() {
      this.listTexts = [];
      let indexCnt = 0;
      this.initTexts.forEach(text => {
        this.listTexts.push({
          index: indexCnt++, 
          key: text.key, 
          label: text.label,
          initValue: text.initValue
        });
      });
    },
    async queryTextsValue() {
      this.listTexts.forEach(async text => {
        let qResult = await queryGetByParamKey('texts', text.key);
        if (qResult && qResult.length && qResult[0]) {
          this.$set(text, 'queryId', qResult[0].id);
          this.$set(text, 'value', qResult[0].value);
        }
        else {
          let cResult = await queryPost('texts', {
            key: text.key,
            value: text.initValue
          });
          if (Number.parseInt(cResult)) {
            this.$set(text, 'queryId', cResult);
            this.$set(text, 'value', null);
          }
        }
      });
    }
  },
  mounted() {
    this.formatlistTexts();
    this.queryTextsValue();
  }
}
</script>

<style scoped>
.text-table {
  margin-top: 20px;
}

.text-key {
  margin-bottom: 20px;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #c6d2d9;
  background: #fff;
  text-align: center;
  text-transform: uppercase;
}

table {
  border-collapse: collapse;
  border: 1px solid #c6d2d9;
  margin-bottom: 40px;
}

thead {
  min-height: 30px;
}

th {
  min-width: 150px;
  padding: 5px 10px;
  box-sizing: border-box;
  border: 1px solid #c6d2d9;
  background: rgb(228, 234, 238);
  font-size: 14px;
  font-family: "OpenSans400";
  text-transform: uppercase;
}

tr:nth-child(2n+1) {
  background: #fff;
}
tr:nth-child(2n) {
  background: transparent;
}

td {
  padding: 10px 10px;
  font-size: 13px;
  font-family: "OpenSans400";
  border-left: 1px solid #c6d2d9;
}
</style>