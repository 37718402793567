<template>
  <div class="form-container flex-cnt-col align-center">
    <div class="title-cnt">
      <h4>
        {{formTitle.toUpperCase()}}
      </h4>
    </div>
    <div class="indic-section flex-cnt-row flex-center" v-if="queryTable === 'menuiseries' || queryTable === 'portes'">
      <div class="indic-label">
        Surface vitrée : 
      </div>
      <div class="indic-value validate-color" v-bind:class="{'valid': parseFloat(indicData) >= 0}">
        {{indicData ? indicData : '0'}}
      </div>
    </div>
    <div class="indic-section flex-cnt-row flex-center" v-if="queryTable === 'revetementsSols'">
      <div class="indic-label">
        Pièces principales : 
      </div>
      <div class="indic-value">
        {{indicData ? indicData : '0'}} m²
      </div>
    </div>
    <div class="indic-section flex-cnt-row flex-center" v-if="queryTable === 'chapes'">
      <div class="indic-label">
        Surface chambres : 
      </div>
      <div class="indic-value">
        {{indicData ? indicData : '0'}} m²
      </div>
    </div>
    <div class="input-cnt flex-cnt-col">
      <FormInput 
        v-for="input in listeInputs"
        :key="input.index"
        
        v-bind:isEditMode="isEditMode"
        v-bind:queryTable="queryTable"
        v-bind:queryId="queryId"
        v-bind:initialValue="qForm[input.queryKey]"
        v-bind:inputTag="input.inputTag"
        v-bind:label="input.label" 
        v-bind:queryKey="input.queryKey"
        v-bind:inputType="input.inputType"
        v-bind:validType="input.validType"
        v-bind:placeholder="input.placeholder"
        v-bind:inputUnit="input.inputUnit"
        v-bind:showLabel="input.showLabel"
        v-bind:spaceBefore="input.spaceBefore"
        v-bind:required="input.required"
        v-bind:options="getOptions(input.inputTag, input.hasPreOptionKey, input.options)"
        v-bind:hasPreOptionKey="input.hasPreOptionKey"
        v-bind:hasPreOptionMatchValue="input.hasPreOptionMatchValue"
        v-bind:hasPreOptionValue="getPreOptionValue(input.hasPreOptionKey)"
        v-bind:isPreOptionTable="input.isPreOptionTable"
        v-bind:isPreOptionKeys="input.isPreOptionKeys"
        v-bind:reset="getPreOptionReset(input.hasPreOptionKey, input.queryKey)"
        v-bind:defaultValue="input.defaultValue"
        v-bind:showResetIconIfSelect="input.showResetIconIfSelect"
        v-bind:helpImgUrl="input.helpImgUrl"
        v-bind:blockedNomDoc="blockedNomDoc"
        v-bind:blockedVersion="blockedVersion"

        v-on:updateValue="updateValue"
        v-on:updatePreOption="updatePreOption"
        v-on:validateRequired="updateRequired"
      />
      <div class="fixed-cnt flex-cnt-row justify-center" v-if="queryTable === 'orientations'">
        <img class="fixed-img" src="@/assets/images/boussole.png">
      </div>
    </div>
  </div>
</template>

<script>
//components
import FormInput from './FormInput.vue'
//functions
import { queryGet, queryGetByClientId } from '../../api/queries'
//parameters & constants
import { InputTypes } from "../../api/validation";

export default {
  components: {
    FormInput
  },
  data: function () {
    return {
      queryId: null,
      qForm: {},
      ValidTypes: InputTypes,
      listValid: {}
    }
  },
  props: {
    formIndex: {
      type: Number,
      required: true
    },
    activeIndex: {
      type: Number,
      required: true
    },
    isEditMode: {
      type: Boolean, 
      required: false, 
      default: false
    },
    formTitle: {
      type: String,
      required: false
    },
    clientId: {
      type: Number,
      required: true
    },
    queryTable: {
      type: String,
      required: true
    },
    listeInputs: {
      type: Array,
      required: true
    },
    //CLIENT
    blockedNomDoc: {
      type: String,
      required: false,
      default: null
    },
    blockedVersion: {
      type: Number,
      required: false,
      default: null
    },
    //PRE OPTION
    hasPreOptionForm: {
      type: Object,
      required: false,
      default: null
    },
    resetFlag: {
      type: Number,
      required: false,
      default: null
    },
    resetKeys: {
      type: Array,
      required: false,
      default: null
    },
    indicData: [String, Number]
  },
  watch: {
    clientId: async function () {
      if (this.isEditMode) {
        this.qForm = {};
        this.queryId = null;
        if (this.queryTable === 'clients') {
          let response = await queryGet(this.queryTable, this.clientId);
          if (response) {
            this.qForm = response;
            this.queryId = response.id;
            this.$emit('updateForm', this.queryTable, this.qForm);
          }
        }
        else {
          let response = await queryGetByClientId(this.queryTable, this.clientId);
          if (response.length) {
            this.qForm = response[0];
            this.queryId = response[0].id;
            this.$emit('updateForm', this.queryTable, this.qForm);
          }
        }
      }
    },
    activeIndex: function () {
      if (this.formIndex === this.activeIndex) {
        this.enableNext();
      }
    }
  },
  methods: {
    updateValue(key, value) {
      if (value === '') {
        value = null;
      }
      this.$set(this.qForm, key, value);
      this.$emit('updateForm', this.queryTable, this.qForm);
    },
    updatePreOption(table, keys) {
      this.$emit('updatePreOption', table, keys);
    },
    getPreOptionValue(key) {
      return this.hasPreOptionForm ? this.hasPreOptionForm[key] : null;
    },
    getOptions(tag, key, allOptions) {
      if (tag === 'select') {
        if (key) {
          return allOptions[this.getPreOptionValue(key)];
        }
        else {
          return allOptions;
        }
      }
      else {
        return null;
      }
    },
    getPreOptionReset(hasPreOption, inputKey) {
      return hasPreOption && this.resetKeys && this.resetKeys.includes(inputKey) ? this.resetFlag : null;
    },
    updateRequired(key, valid) {
      this.listValid[key] = valid;
      this.enableNext();
    },
    enableNext () {
      if (this.formIndex === this.activeIndex) {
        let flagValid = true;
        Object.entries(this.listValid).forEach(([, isValid]) => {
          if (!isValid) {
            flagValid = false;
          }
        });
        if (flagValid) {
        this.$emit('enableNext', true);
        }
        else {
        this.$emit('enableNext', false);
        }
      }
    }
  },
  mounted() {
    this.listeInputs.forEach(input => {
      if (input.required) {
        this.listValid[input.queryKey] = false;
      }
    });
  }
}
</script>

<style scoped>
.form-container {
  width: 100%;
  min-height: fit-content;
  margin-bottom: 20px;
  box-sizing: border-box;
}

.title-cnt {
  margin-bottom: 10px;
}

h4 {
  text-align: center;
}

.indic-section {
  font-size: 13px;
  text-align: center;
  background: rgb(228, 234, 238);
  padding: 5px 10px;
}

.indic-value {
  margin-left: 5px;
  padding: 3px 10px;
  border: 1px solid #64696b;
  background: #ffffff;
}
.indic-value.validate-color {
  background: rgb(245, 197, 197);
}
.indic-value.validate-color.valid {
  background: rgb(177, 226, 184);
}

.input-cnt {
  flex: 1;
  width: 100%;
  margin-top: 10px;
  overflow: visible;
}

.fixed-cnt {
  margin-top: 40px;
}

.fixed-img {
  height: 200px;
}
</style>