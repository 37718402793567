import { SKeys } from "../param/selectKeys";

import { validFloat } from "./helpers"

export const PeintureParams = Object.freeze({
  PRIX_MUR_PLAFOND: 12.5,
  PRIX_PORTES:      75
});

export async function computeParamsPeinture(targetObj, peintureOption, nombreBP1, nombreBP2, nombreBPCoulissant) {
  if (peintureOption === SKeys.OuiNon.OUI) {
    //MUR & PLAFOND
    targetObj.peinture.murPlafond = validFloat(targetObj.placo.plafondSouffle) + validFloat(targetObj.placo.plafondPose) + validFloat(targetObj.placo.plafondSousPlancherBois) + validFloat(targetObj.placo.doublage) + (validFloat(targetObj.placo.cloison) * 2);
    // targetObj.peinture.murPlafond = parseFloat(targetObj.peinture.murPlafond.toFixed(2));
    //PORTES
    targetObj.peinture.portes = validFloat(nombreBP1) + validFloat(nombreBP2) + validFloat(nombreBPCoulissant);
    // targetObj.peinture.portes = parseFloat(targetObj.peinture.portes.toFixed(2));
  }
  if (peintureOption === SKeys.OuiOptionNon.OPTION) {
    //MUR & PLAFOND
    targetObj.peinture.murPlafondOption = validFloat(targetObj.placo.plafondSouffle) + validFloat(targetObj.placo.plafondPose) + validFloat(targetObj.placo.plafondSousPlancherBois) + validFloat(targetObj.placo.doublage) + (validFloat(targetObj.placo.cloison) * 2);
    // targetObj.peinture.murPlafondOption = parseFloat(targetObj.peinture.murPlafondOption.toFixed(2));
    //PORTES
    targetObj.peinture.portesOption = validFloat(nombreBP1) + validFloat(nombreBP2) + validFloat(nombreBPCoulissant);
    // targetObj.peinture.portesOption = parseFloat(targetObj.peinture.portesOption.toFixed(2));
  }
}

export function addPeinture(targetObj) {
  targetObj.peinture.ttlPrixPeinture += validFloat(targetObj.peinture.murPlafond) * validFloat(PeintureParams.PRIX_MUR_PLAFOND);
  targetObj.peinture.ttlPrixPeinture += validFloat(targetObj.peinture.portes) * validFloat(PeintureParams.PRIX_PORTES);
  // targetObj.peinture.ttlPrixPeinture = parseFloat(targetObj.peinture.ttlPrixPeinture.toFixed(2));
  //OPTION
  targetObj.peinture.ttlPrixPeintureOption += validFloat(targetObj.peinture.murPlafondOption) * validFloat(PeintureParams.PRIX_MUR_PLAFOND);
  targetObj.peinture.ttlPrixPeintureOption += validFloat(targetObj.peinture.portesOption) * validFloat(PeintureParams.PRIX_PORTES);
  // targetObj.peinture.ttlPrixPeintureOption = parseFloat(targetObj.peinture.ttlPrixPeintureOption.toFixed(2));
}