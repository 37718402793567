<template>
  <div class="document-container flx1 flex-cnt-col align-center">
    <div class="dcnt-header flex-cnt-row align-center justify-between">
      <div>
        ./{{selectUserName ? selectUserName + '/' : ''}} {{selectClientName ? selectClientName : ''}}
      </div>
      <div class="back-cnt flex-cnt-row flex-center" v-if="selectUserId && backAble" v-on:click="backToFolder">
        <img class="back-icon" src="@/assets/icons/arrow-back.png">
      </div>
      <div v-else class="choice-text flex-cnt-row align-center">
        CHOISSISSEZ UN DOSSIER
      </div>
    </div>
    <div class="dcnt-content flx1 flex-cnt-col">
      <div v-if="stateDoc === 'version'">
        <div class="doc-title-row flx1 flex-cnt-row align-center justify-between">
          <div class="client-cell text-left">
            Client
          </div>
          <div class="tel-cell flx1 text-center">
            Téléphone
          </div>
          <div class="date-cell flx1 text-center">
            Date création
          </div>
          <div class="btn-cell flx1 flex-cnt-row align-center justify-end text-right">
          </div>
        </div>
        <DocumentSingle
          v-for="doc in listSelectVersions"
          :key="doc.id"
          v-bind:doc="doc"

          v-on:refreshDocuments="refreshDocuments"
        />
      </div>
      <div v-else-if="stateDoc === 'client'">
        <DocumentFolder
          v-for="client in listSelectClients"
          :key="client"
          v-bind:userId="selectUserId"
          v-bind:userName="selectUserName"
          v-bind:clientName="client"
          v-bind:stateDoc="stateDoc"
          v-on:selectClient="selectClient"
        />
      </div>
      <div v-else-if="stateDoc === 'user'">
        <DocumentFolder
          v-for="user in listUsers"
          :key="user.userId"
          v-bind:userId="user.userId"
          v-bind:userName="user.userName"
          v-bind:clientName="selectClientName"
          v-bind:stateDoc="stateDoc"
          v-on:selectUser="selectUser"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import DocumentSingle from "./DocumentSingle.vue";
import DocumentFolder from "./DocumentFolder.vue";

export default {
  components: {
    DocumentSingle,
    DocumentFolder
  },
  data() {
    return {
      selectUserName: null,
      selectUserId: null,
      selectClientName: null,
      listSelectClients: [],
      listSelectVersions: []
    }
  },
  props: {
    stateDoc: {
      type: String,
      required: true
    },
    listUsers: {
      type: Array,
      required: false,
      default: null
    },
    listDocuments: {
      type: Array,
      required: false,
      default: null
    }
  },
  watch: {
    listDocuments: function () {
      if (this.getAuthData.isAdmin === 3 && this.getAuthData.userId && this.getAuthData.userName) {
        this.selectUser(this.getAuthData.userId, this.getAuthData.userName);
      }
      else if (this.selectUserName && this.selectUserId) {
        this.selectUser(this.selectUserId, this.selectUserName);
      }
    }
  },
  computed: {
    backAble: function () {
      let res = true;
      if (this.stateDoc === 'client' && this.getAuthData.isAdmin === 3) {
        return false;
      }
      return res;
    },
    ...mapGetters(['getAuthData'])
  },
  methods: {
    refreshDocuments() {
      this.$emit('refreshDocuments');
      this.$emit('updateNextVersion', null);
    },
    selectUser(userId, userName) {
      this.formatListSelectClients(userId);
      this.selectUserName = userName;
      this.selectUserId = userId;
      this.$emit('updateUser', userId, userName)
      this.$emit('updateState', 'client');
    },
    selectClient(clientName) {
      this.formatListSelectVersions(clientName);
      this.selectClientName = clientName;
      this.$emit('updateClient', clientName)
      this.$emit('updateState', 'version');
    },
    formatListSelectClients(userId) {
      this.listSelectClients = [];
      this.listDocuments.forEach(client => {
        if (client.userId === userId && !this.listSelectClients.includes(client.nomDocument)) {
          this.listSelectClients.push(client.nomDocument);
        }
      });
    },
    formatListSelectVersions(clientName) {
      let maxVersion = 0;
      this.listSelectVersions = [];
      this.listDocuments.forEach(client => {
        if (client.userId === this.selectUserId && client.nomDocument === clientName) {
          this.listSelectVersions.push(client);
          if (client.version > maxVersion) {
            maxVersion = client.version;
          }
        }
      });
      let nextVersion = maxVersion + 1;
      this.$emit('updateNextVersion', nextVersion);
    },
    backToFolder() {
      if (this.stateDoc === 'client') {
        this.selectUserId = null;
        this.selectUserName = null;
        this.listSelectClients = [];
        this.$emit('updateUser', null, null);
        this.$emit('updateState', 'user');
      }
      else if (this.stateDoc === 'version') {
        this.selectClientName = null;
        this.listSelectVersions = [];
        this.$emit('updateClient', null);
        this.$emit('updateState', 'client');
        this.$emit('updateNextVersion', null);
      }
    }
  }
}
</script>

<style scoped>
.document-container {
  margin-top: 20px;
  border: 1px solid #c6d2d9;
  overflow: hidden;
}

.dcnt-header {
  background: rgb(228, 234, 238);
  height: 30px;
  width: 100%;
  box-sizing: border-box;
  padding-left: 10px;
  border-bottom: 1px solid #c6d2d9;
}

.dcnt-content {
  background: rgb(255, 255, 255);
  width: 100%;
  box-sizing: border-box;
  overflow: auto;
}

.back-cnt {
  box-sizing: border-box;
  height: 30px;
  width: 35px;
  background-color: transparent;
}
.back-cnt:hover {
  background-color: rgb(214, 220, 224);
  transition: background-color 0.15s;
  cursor: pointer;
}

.back-icon {
  height: 20px;
}

.choice-text {
  margin-right: 10px;
}

.doc-title-row {
  height: 30px;
  padding: 0px 20px;
  margin: 0px 30px;
  box-sizing: border-box;
  border-bottom: 1px solid #e7edf0;
  overflow: hidden;
  color: #748790;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
}

.client-cell {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex: 2;
  min-width: 115px;
  position: relative;
  left: -15px;
}

.date-cell {
  min-width: 115px;
}

.tel-cell {
  min-width: 115px;
}

.btn-cell {
  min-width: 274px;
}
</style>