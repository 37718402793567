import { InputTypes } from "../api/validation";
import { PKeys } from "./parametersKeys";
//choices list
import { 
  BlocPorteGammes, 
  OriginesProspect,
  Departements,
  BranchementLongueurs,
  CouvertureTypes,
  CouvertureComblesTypes,
  MenuiserieCouleurs,
  PlacoPlafondCloisonEpaisseurs,
  CarrelageGammes,
  ChauffageTypes,
  PetiteFenetreDimensions,
  AbattantDimensions,
  OeilDeBoeufDimensions,
  FenetreOuvranteDimensions,
  VRFenetreOuvranteDimensions,
  FenetreCoulissanteDimensions,
  VRFenetreCoulissanteDimensions,
  FenetreFixeDimensions,
  PorteFenetreDimensions,
  VRPorteFenetreDimensions,
  BaieVitreeDimensions,
  VRBaieVitreeDimensions,
  PorteEntreeGammes,
  PorteServiceGammes,
  PorteGarageDimensions,
  // ToitSpeciauxTypes,
  PorteGarageModeles,
  SurfaceVitreeCouleurs
} from "./parametersChoices";

export const ListParameters = Object.freeze([
  //GENERAL
  {
    index:          0, 
    isSectionFirst: true,
    section:        'Général',
    key:            PKeys.ORIGINE_PROSPECT_PRIX,
    title:          'Origine prospect',
    inputType:      'number',
    validType:      InputTypes.FLOAT,
    placeholder:    'Prix',
    listChoices:    OriginesProspect
  },
  {
    index:          1, 
    isSectionFirst: false,
    section:        'Général',
    key:            PKeys.SUPPLEMENT_REMY_PRIX,
    title:          'Supplément origine prospect',
    inputType:      'number',
    validType:      InputTypes.FLOAT,
    placeholder:    'Prix',
    listChoices:    OriginesProspect
  },
  {
    index:          2, 
    isSectionFirst: false,
    section:        'Général',
    key:            PKeys.DEPARTEMENT_COEFF,
    title:          'Lieu de construction',
    inputType:      'number',
    validType:      InputTypes.FLOAT,
    placeholder:    'Coefficient',
    listChoices:    Departements
  },
  {
    index:          3, 
    isSectionFirst: false,
    section:        'Général',
    key:            PKeys.BRANCHEMENT_PRIX,
    title:          'Prix branchement',
    inputType:      'number',
    validType:      InputTypes.FLOAT,
    placeholder:    'Prix',
    listChoices:    BranchementLongueurs
  },
  //COUVERTURE
  {
    index:          10, 
    isSectionFirst: true,
    section:        'Couverture',
    key:            PKeys.COUVERTURE_PRIX,
    title:          'Prix couverture',
    inputType:      'number',
    validType:      InputTypes.FLOAT,
    placeholder:    'Prix',
    listChoices:    CouvertureTypes
  },
  {
    index:          11, 
    isSectionFirst: false,
    section:        'Couverture',
    key:            PKeys.COUVERTURE_SELON_COMBLES_COEFF,
    title:          'Ratio selon le type de combles',
    inputType:      'number',
    validType:      InputTypes.FLOAT,
    placeholder:    'Coefficient',
    listChoices:    CouvertureComblesTypes
  },
  // {
  //   index:          12, 
  //   isSectionFirst: false,
  //   section:        'Couverture',
  //   key:            PKeys.TOIT_SPECIAL_PRIX,
  //   title:          'Prix toit spécial',
  //   inputType:      'number',
  //   validType:      InputTypes.FLOAT,
  //   placeholder:    'Prix',
  //   listChoices:    ToitSpeciauxTypes
  // },
  //PORTES
  {
    index:          13, 
    isSectionFirst: true,
    section:        'Portes',
    key:            PKeys.PORTE_ENTREE_PRIX,
    title:          'Prix porte d\'entrée',
    inputType:      'number',
    validType:      InputTypes.FLOAT,
    placeholder:    'Prix',
    listChoices:    PorteEntreeGammes
  },
  {
    index:          14, 
    isSectionFirst: false,
    section:        'Portes',
    key:            PKeys.TIERCE_SEMI_FIXE_PRIX,
    title:          'Prix tierce semi-fixe',
    inputType:      'number',
    validType:      InputTypes.FLOAT,
    placeholder:    'Prix',
    listChoices:    null
  },
  {
    index:          15, 
    isSectionFirst: false,
    section:        'Portes',
    key:            PKeys.PORTE_SERVICE_PRIX,
    title:          'Prix porte de service',
    inputType:      'number',
    validType:      InputTypes.FLOAT,
    placeholder:    'Prix',
    listChoices:    PorteServiceGammes
  },
  {
    index:          16, 
    isSectionFirst: false,
    section:        'Portes',
    key:            PKeys.PORTE_GARAGE_MODELE_PRIX,
    title:          'Prix porte garage / modèle',
    inputType:      'number',
    validType:      InputTypes.FLOAT,
    placeholder:    'Prix',
    listChoices:     PorteGarageModeles
  },
  {
    index:          17, 
    isSectionFirst: false,
    section:        'Portes',
    key:            PKeys.PORTE_GARAGE_MOTORISEE_PRIX,
    title:          'Prix porte garage / motorisée',
    inputType:      'number',
    validType:      InputTypes.FLOAT,
    placeholder:    'Prix',
    listChoices:    PorteGarageDimensions
  },
  {
    index:          18, 
    isSectionFirst: false,
    section:        'Portes',
    key:            PKeys.PORTE_GARAGE_COULEUR_PRIX,
    title:          'Prix porte garage / couleur',
    inputType:      'number',
    validType:      InputTypes.FLOAT,
    placeholder:    'Prix',
    listChoices:    MenuiserieCouleurs
  },
  //PLACO
  {
    index:          20, 
    isSectionFirst: true,
    section:        'Placo',
    key:            PKeys.BLOC_PORTE_PRIX,
    title:          'Prix bloc-porte',
    inputType:      'number',
    validType:      InputTypes.FLOAT,
    placeholder:    'Prix',
    listChoices:    BlocPorteGammes
  },
  {
    index:          23, 
    isSectionFirst: false,
    section:        'Placo',
    key:            PKeys.EPAISSEUR_DOUBLAGE_PRIX,
    title:          'Prix épaisseur doublage',
    inputType:      'number',
    validType:      InputTypes.FLOAT,
    placeholder:    'Prix',
  },
  {
    index:          24, 
    isSectionFirst: false,
    section:        'Placo',
    key:            PKeys.EPAISSEUR_CLOISON_PRIX,
    title:          'Prix épaisseur cloison',
    inputType:      'number',
    validType:      InputTypes.FLOAT,
    placeholder:    'Prix',
    listChoices:    PlacoPlafondCloisonEpaisseurs
  },
  //REVETEMENT SOL
  {
    index:          25, 
    isSectionFirst: true,
    section:        'Carrelage',
    key:            PKeys.GAMME_CARRELAGE_PRIX,
    title:          'Gamme carrelage',
    inputType:      'number',
    validType:      InputTypes.FLOAT,
    placeholder:    'Prix',
    listChoices:    CarrelageGammes
  },
  {
    index:          26, 
    isSectionFirst: false,
    section:        'Carrelage',
    key:            PKeys.GAMME_PLINTHE_PRIX,
    title:          'Gamme plinthe',
    inputType:      'number',
    validType:      InputTypes.FLOAT,
    placeholder:    'Prix',
    listChoices:    CarrelageGammes
  },
  //CHAUFFAGE
  {
    index:          27, 
    isSectionFirst: true,
    section:        'Chauffage',
    key:            PKeys.TYPE_CHAUFFAGE_PRIX,
    title:          'Prix chauffage',
    inputType:      'number',
    validType:      InputTypes.FLOAT,
    placeholder:    'Prix',
    listChoices:    ChauffageTypes
  },
  //FENETRES
  {
    index:          28, 
    isSectionFirst: true,
    section:        'Fenêtres',
    key:            PKeys.PETITE_FENETRE_PRIX,
    title:          'Petite fenêtre',
    inputType:      'number',
    validType:      InputTypes.FLOAT,
    placeholder:    'Prix',
    listChoices:    PetiteFenetreDimensions
  },
  {
    index:          29, 
    isSectionFirst: false,
    section:        'Fenêtres',
    key:            PKeys.ABATTANT_PRIX,
    title:          'Abattant',
    inputType:      'number',
    validType:      InputTypes.FLOAT,
    placeholder:    'Prix',
    listChoices:    AbattantDimensions
  },
  {
    index:          30, 
    isSectionFirst: false,
    section:        'Fenêtres',
    key:            PKeys.OEIL_DE_BOEUF_PRIX,
    title:          'Oeil de boeuf',
    inputType:      'number',
    validType:      InputTypes.FLOAT,
    placeholder:    'Prix',
    listChoices:    OeilDeBoeufDimensions
  },
  {
    index:          31, 
    isSectionFirst: false,
    section:        'Fenêtres',
    key:            PKeys.FENETRE_OUVRANTE_PRIX,
    title:          'Fenêtre ouvrante',
    inputType:      'number',
    validType:      InputTypes.FLOAT,
    placeholder:    'Prix',
    listChoices:    FenetreOuvranteDimensions
  },
  {
    index:          32, 
    isSectionFirst: false,
    section:        'Fenêtres',
    key:            PKeys.FENETRE_OUVRANTE_VR_PRIX,
    title:          'Fenêtre ouvrante VR',
    inputType:      'number',
    validType:      InputTypes.FLOAT,
    placeholder:    'Prix',
    listChoices:    VRFenetreOuvranteDimensions
  },
  {
    index:          33, 
    isSectionFirst: false,
    section:        'Fenêtres',
    key:            PKeys.FENETRE_COULISSANTE_PRIX,
    title:          'Fenêtre coulissante',
    inputType:      'number',
    validType:      InputTypes.FLOAT,
    placeholder:    'Prix',
    listChoices:    FenetreCoulissanteDimensions
  },
  {
    index:          34, 
    isSectionFirst: false,
    section:        'Fenêtres',
    key:            PKeys.FENETRE_COULISSANTE_VR_PRIX,
    title:          'Fenêtre coulissante VR',
    inputType:      'number',
    validType:      InputTypes.FLOAT,
    placeholder:    'Prix',
    listChoices:    VRFenetreCoulissanteDimensions
  },
  {
    index:          35, 
    isSectionFirst: false,
    section:        'Fenêtres',
    key:            PKeys.FENETRE_FIXE_PRIX,
    title:          'Fenêtre fixe',
    inputType:      'number',
    validType:      InputTypes.FLOAT,
    placeholder:    'Prix',
    listChoices:    FenetreFixeDimensions
  },
  {
    index:          36, 
    isSectionFirst: false,
    section:        'Fenêtres',
    key:            PKeys.PORTE_FENETRE_PRIX,
    title:          'Porte fenêtre',
    inputType:      'number',
    validType:      InputTypes.FLOAT,
    placeholder:    'Prix',
    listChoices:    PorteFenetreDimensions
  },
  {
    index:          37, 
    isSectionFirst: false,
    section:        'Fenêtres',
    key:            PKeys.PORTE_FENETRE_VR_PRIX,
    title:          'Porte fenêtre VR',
    inputType:      'number',
    validType:      InputTypes.FLOAT,
    placeholder:    'Prix',
    listChoices:    VRPorteFenetreDimensions
  },
  {
    index:          38, 
    isSectionFirst: false,
    section:        'Fenêtres',
    key:            PKeys.BAIE_VITREE_PRIX,
    title:          'Baie vitrée',
    inputType:      'number',
    validType:      InputTypes.FLOAT,
    placeholder:    'Prix',
    listChoices:    BaieVitreeDimensions
  },
  {
    index:          39, 
    isSectionFirst: false,
    section:        'Fenêtres',
    key:            PKeys.BAIE_VITREE_VR_PRIX,
    title:          'Baie vitrée VR',
    inputType:      'number',
    validType:      InputTypes.FLOAT,
    placeholder:    'Prix',
    listChoices:    VRBaieVitreeDimensions
  },
  //RECAP
  {
    index:          40, 
    isSectionFirst: true,
    section:        'Récap',
    key:            PKeys.HAUSSE_DE_PRIX_COEFF,
    title:          'Hausse de prix globale',
    inputType:      'number',
    validType:      InputTypes.FLOAT,
    placeholder:    'Coeff',
    listChoices:    null
  },
  {
    index:          41, 
    isSectionFirst: false,
    section:        'Récap',
    key:            PKeys.TOTAL_HT_INTERNE_COEFF,
    title:          'Total HT interne',
    inputType:      'number',
    validType:      InputTypes.FLOAT,
    placeholder:    'Coeff',
    listChoices:    null
  },
  {
    index:          42, 
    isSectionFirst: false,
    section:        'Récap',
    key:            PKeys.TOTAL_HT_AGCO_COEFF,
    title:          'Total HT AGCO',
    inputType:      'number',
    validType:      InputTypes.FLOAT,
    placeholder:    'Coeff',
    listChoices:    null
  },
  {
    index:          43, 
    isSectionFirst: false,
    section:        'Récap',
    key:            PKeys.DOMMAGE_OUVRABLE_PRIX,
    title:          'DOMMAGE OUVRABLE',
    inputType:      'number',
    validType:      InputTypes.FLOAT,
    placeholder:    'Prix',
    listChoices:    null
  },
  //RECAP - SECTIONS
  {
    index:          44, 
    isSectionFirst: true,
    section:        'Récap - Sections',
    key:            PKeys.RECAP_TERRASSEMENT_HAUSSE,
    title:          'Hausse Terrassement',
    inputType:      'number',
    validType:      InputTypes.FLOAT,
    placeholder:    'Coeff',
    listChoices:    null
  },
  {
    index:          45, 
    isSectionFirst: false,
    section:        'Récap - Sections',
    key:            PKeys.RECAP_VS_SOUSSOL_HAUSSE,
    title:          'Hausse vs/sous-sol',
    inputType:      'number',
    validType:      InputTypes.FLOAT,
    placeholder:    'Coeff',
    listChoices:    null
  },
  {
    index:          46, 
    isSectionFirst: false,
    section:        'Récap - Sections',
    key:            PKeys.RECAP_RDC_HAUSSE,
    title:          'Hausse rdc',
    inputType:      'number',
    validType:      InputTypes.FLOAT,
    placeholder:    'Coeff',
    listChoices:    null
  },
  {
    index:          47, 
    isSectionFirst: false,
    section:        'Récap - Sections',
    key:            PKeys.RECAP_ETAGE_HAUSSE,
    title:          'Hausse étage',
    inputType:      'number',
    validType:      InputTypes.FLOAT,
    placeholder:    'Coeff',
    listChoices:    null
  },
  {
    index:          48, 
    isSectionFirst: false,
    section:        'Récap - Sections',
    key:            PKeys.RECAP_PIGNON_RDC_HAUSSE,
    title:          'Hausse pignon rdc',
    inputType:      'number',
    validType:      InputTypes.FLOAT,
    placeholder:    'Coeff',
    listChoices:    null
  },
  {
    index:          49, 
    isSectionFirst: false,
    section:        'Récap - Sections',
    key:            PKeys.RECAP_TOITURE_RDC_HAUSSE,
    title:          'Hausse toiture rdc',
    inputType:      'number',
    validType:      InputTypes.FLOAT,
    placeholder:    'Coeff',
    listChoices:    null
  },
  {
    index:          50, 
    isSectionFirst: false,
    section:        'Récap - Sections',
    key:            PKeys.RECAP_MENUISERIE_HAUSSE,
    title:          'Hausse menuiserie',
    inputType:      'number',
    validType:      InputTypes.FLOAT,
    placeholder:    'Coeff',
    listChoices:    null
  },
  {
    index:          51, 
    isSectionFirst: false,
    section:        'Récap - Sections',
    key:            PKeys.RECAP_PLACO_HAUSSE,
    title:          'Hausse placo',
    inputType:      'number',
    validType:      InputTypes.FLOAT,
    placeholder:    'Coeff',
    listChoices:    null
  },
  {
    index:          52, 
    isSectionFirst: false,
    section:        'Récap - Sections',
    key:            PKeys.RECAP_REVET_INT_HAUSSE,
    title:          'Hausse revêtement intérieur',
    inputType:      'number',
    validType:      InputTypes.FLOAT,
    placeholder:    'Coeff',
    listChoices:    null
  },
  {
    index:          53, 
    isSectionFirst: false,
    section:        'Récap - Sections',
    key:            PKeys.RECAP_ELEC_HAUSSE,
    title:          'Hausse électricité',
    inputType:      'number',
    validType:      InputTypes.FLOAT,
    placeholder:    'Coeff',
    listChoices:    null
  },
  {
    index:          54, 
    isSectionFirst: false,
    section:        'Récap - Sections',
    key:            PKeys.RECAP_REVET_EXT_HAUSSE,
    title:          'Hausse revêtement extérieur',
    inputType:      'number',
    validType:      InputTypes.FLOAT,
    placeholder:    'Coeff',
    listChoices:    null
  },
  {
    index:          55, 
    isSectionFirst: false,
    section:        'Récap - Sections',
    key:            PKeys.RECAP_PLOMBERIE_HAUSSE,
    title:          'Hausse plomberie',
    inputType:      'number',
    validType:      InputTypes.FLOAT,
    placeholder:    'Coeff',
    listChoices:    null
  },
  {
    index:          56, 
    isSectionFirst: false,
    section:        'Récap - Sections',
    key:            PKeys.RECAP_CHAUFFAGE_HAUSSE,
    title:          'Hausse chauffage',
    inputType:      'number',
    validType:      InputTypes.FLOAT,
    placeholder:    'Coeff',
    listChoices:    null
  },
  {
    index:          57, 
    isSectionFirst: false,
    section:        'Récap - Sections',
    key:            PKeys.RECAP_PEINTURE_HAUSSE,
    title:          'Hausse peinture',
    inputType:      'number',
    validType:      InputTypes.FLOAT,
    placeholder:    'Coeff',
    listChoices:    null
  },
  {
    index:          58, 
    isSectionFirst: false,
    section:        'Récap - Sections',
    key:            PKeys.RECAP_DIAG_BBC_HAUSSE,
    title:          'Hausse diagnostique bbc',
    inputType:      'number',
    validType:      InputTypes.FLOAT,
    placeholder:    'Coeff',
    listChoices:    null
  },
  {
    index:          59, 
    isSectionFirst: false,
    section:        'Récap - Sections',
    key:            PKeys.RECAP_ORIGINE_PROSPECT_HAUSSE,
    title:          'Hausse origine prospect',
    inputType:      'number',
    validType:      InputTypes.FLOAT,
    placeholder:    'Coeff',
    listChoices:    null
  },
  {
    index:          60, 
    isSectionFirst: false,
    section:        'Récap - Sections',
    key:            PKeys.RECAP_AJUSTEMENT_HAUSSE,
    title:          'Hausse ajustement prix',
    inputType:      'number',
    validType:      InputTypes.FLOAT,
    placeholder:    'Coeff',
    listChoices:    null
  },
  {
    index:          61, 
    isSectionFirst: false,
    section:        'Récap - Sections',
    key:            PKeys.RECAP_ARCHITECTE_HAUSSE,
    title:          'Hausse frais architecte',
    inputType:      'number',
    validType:      InputTypes.FLOAT,
    placeholder:    'Coeff',
    listChoices:    null
  },
  //MENUISERIES
  {
    index:          62, 
    isSectionFirst: true,
    section:        'Menuiseries',
    key:            PKeys.SURFACE_VITREE_NORD_COEFF,
    title:          'Coefficient surface vitrée',
    inputType:      'number',
    validType:      InputTypes.FLOAT,
    placeholder:    'Coeff',
    listChoices:    SurfaceVitreeCouleurs
  },
  //SANITAIRES
  {
    index:          63, 
    isSectionFirst: true,
    section:        'Sanitaires',
    key:            PKeys.FAIENCE_PRIX,
    title:          'Prix faience',
    inputType:      'number',
    validType:      InputTypes.FLOAT,
    placeholder:    'Prix',
  },
]);