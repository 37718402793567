import { queryOneParamValue } from "../api/queries";
import { SKeys } from "../param/selectKeys";
import { PKeys } from "../param/parametersKeys";

import { validFloat } from "./helpers"

const OuvertureParams = Object.freeze({
  //PORTE ENTREE
  PORTE_ENTREE_HAUTEUR: 215,
  PORTE_ENTREE_LARGEUR: 90,
  //TIERCE SEMI-FIXE
  TIERCE_HAUTEUR: 215,
  TIERCE_LARGEUR: 40,
  //PORTE SERVICE
  PORTE_SERVICE_HAUTEUR: 215,
  PORTE_SERVICE_LARGEUR: 90,
  //
  PRIX_GALANDAGE: 1000,
  //
  PRIX_UNIT: 50
});

export function computeFenetres(targetObj, fenetres) {
  fenetres.forEach(fenetre => {
    switch (fenetre.type) {
      case SKeys.TypesFenetre.FENETRE_COULISSANTE:
      case SKeys.TypesFenetre.BAIE_COULISSANTE:
        targetObj.ouverture.nombreBaiesCoulissants++;
        break;
    
      default:
        break;
    }
  });
}

export async function ouvertureAddPorteEntree(targetObj, nombre, facade, modele, materiau, couleur) {
  const prixKey = PKeys.PORTE_ENTREE_PRIX;
  if (prixKey && modele && materiau) {
    let prixUnit = await queryOneParamValue(prixKey, modele, materiau);
    if (validFloat(prixUnit)) {
      if (couleur === SKeys.CouleursMenuiserie.ABF) {
        targetObj.ouverture.ttlPrixOuverture += validFloat(nombre) * validFloat(prixUnit) * 1.3;
      }
      else {
        targetObj.ouverture.ttlPrixOuverture += validFloat(nombre) * validFloat(prixUnit);
      }
    }
  }
  addSurfaceVitree(targetObj, OuvertureParams.PORTE_ENTREE_LARGEUR, OuvertureParams.PORTE_ENTREE_HAUTEUR, nombre, facade);
}

export async function ouvertureAddTierce(targetObj, typeOuvrant, nombre, facade, couleur) {
  if (typeOuvrant === SKeys.OuvrantsPorte.TIERCE) {
    const prixKey = PKeys.TIERCE_SEMI_FIXE_PRIX;
    if (prixKey) {
      let prixUnit = await queryOneParamValue(prixKey);
      if (validFloat(prixUnit)) {
        if (couleur === SKeys.CouleursMenuiserie.ABF) {
          targetObj.ouverture.ttlPrixOuverture += validFloat(nombre) * validFloat(prixUnit) * 1.3;
        }
        else {
          targetObj.ouverture.ttlPrixOuverture += validFloat(nombre) * validFloat(prixUnit);
        }
      }
    }
    addSurfaceVitree(targetObj, OuvertureParams.TIERCE_LARGEUR, OuvertureParams.TIERCE_HAUTEUR, nombre, facade);
  }
}

export async function ouvertureAddPorteService(targetObj, nombre, facade, modele, materiau, couleur) {
  const prixKey = PKeys.PORTE_SERVICE_PRIX;
  if (prixKey && modele && materiau) {
    let prixUnit = await queryOneParamValue(prixKey, modele, SKeys.MateriauxMenuiserie.ALU);
    if (materiau === SKeys.MateriauxMenuiserie.PLAXE) {
      let prixUnitPlaxe = await queryOneParamValue(prixKey, modele, SKeys.MateriauxMenuiserie.PLAXE);
      if (validFloat(prixUnit) && validFloat(prixUnitPlaxe)) {
        targetObj.ouverture.ttlPrixOuverture += validFloat(nombre) * ( validFloat(prixUnit) + validFloat(prixUnitPlaxe) );
      }
    }
    else if (validFloat(prixUnit)) {
      if (couleur === SKeys.CouleursMenuiserie.ABF) {
        targetObj.ouverture.ttlPrixOuverture += validFloat(nombre) * validFloat(prixUnit) * 1.3;
      }
      else {
        targetObj.ouverture.ttlPrixOuverture += validFloat(nombre) * validFloat(prixUnit);
      }
    }
  }
  addSurfaceVitree(targetObj, OuvertureParams.PORTE_SERVICE_LARGEUR, OuvertureParams.PORTE_SERVICE_HAUTEUR, nombre, facade);
}

export async function ouvertureAddPorteGarage(targetObj, couleur, taille, modele, nombre, type, couleurGlobal) {
  const couleurKey = PKeys.PORTE_GARAGE_COULEUR_PRIX;
  const modeleKey = PKeys.PORTE_GARAGE_MODELE_PRIX;
  const motoriseeKey = PKeys.PORTE_GARAGE_MOTORISEE_PRIX;

  let prixCouleur, prixModele, prixMotorisee;
  let tmpMotorisee = 0;

  if (taille && modele && modeleKey) {
    prixModele = await queryOneParamValue(modeleKey, modele, taille);
  }
  if (couleur && couleurKey) {
    prixCouleur = await queryOneParamValue(couleurKey, couleur);
  }
  if (taille && couleurKey) {
    prixMotorisee = await queryOneParamValue(motoriseeKey, taille);
  }

  if (type === SKeys.TypesPorteGarage.MOTORISEE) {
    tmpMotorisee = prixMotorisee
  }
  if (couleurGlobal === SKeys.CouleursMenuiserie.ABF) {
    targetObj.ouverture.ttlPrixOuverture += validFloat(nombre) * (validFloat(prixModele) + validFloat(prixCouleur) + validFloat(tmpMotorisee) + 50) * 1.3;
  }
  else {
    targetObj.ouverture.ttlPrixOuverture += validFloat(nombre) * (validFloat(prixModele) + validFloat(prixCouleur) + validFloat(tmpMotorisee) + 50);
  }
}

export async function ouvertureAddFenetre(targetObj, typeFenetre, nombre, largeur, hauteur, facade, dimensions, materiau, couleur) {
  addPrixFenetre(typeFenetre, targetObj, nombre, dimensions, materiau, couleur)
  addSurfaceVitree(targetObj, largeur, hauteur, nombre, facade);
}

export function ouvertureAddNombreMenuiserie(targetObj) {
  targetObj.ouverture.ttlPrixOuverture += validFloat(OuvertureParams.PRIX_UNIT) * validFloat(targetObj.rdc.nombreOuvertures);

}

async function addPrixFenetre(typeFenetre, targetObj, nombre, dimensions, materiau, couleur, option) {
  let prixUnit, prixVRUnit;
  let prixGalandage = 0;
  let materiauVR = materiau;
  switch (typeFenetre) {
    case SKeys.TypesFenetre.PETITE_FENETRE:
    case SKeys.TypesFenetre.ABATTANT:
    case SKeys.TypesFenetre.OEIL_DE_BOEUF:
    case SKeys.TypesFenetre.FENETRE_FIXE:
      if (dimensions && materiau) {
        prixUnit = await queryOneParamValue(getFenetreKeyByType(typeFenetre), dimensions, materiau);
        if (validFloat(prixUnit)) {
          if (couleur === SKeys.CouleursMenuiserie.ABF) {
            targetObj.ouverture.ttlPrixOuverture += validFloat(nombre) * validFloat(prixUnit) * 1.3;
          }
          else {
            targetObj.ouverture.ttlPrixOuverture += validFloat(nombre) * validFloat(prixUnit);
          }
        }      
      }
      break;

    case SKeys.TypesFenetre.FENETRE_OUVRANTE:
    case SKeys.TypesFenetre.PORTE_FENETRE:
      if (dimensions && materiau) {
        prixUnit = await queryOneParamValue(getFenetreKeyByType(typeFenetre), dimensions, materiau);
        if (materiau === SKeys.MateriauxMenuiserie.PLAXE) {
          materiauVR = SKeys.MateriauxMenuiserie.ALU;
        }
        prixVRUnit = await queryOneParamValue(getVRKeyByType(typeFenetre), dimensions, materiauVR)
        if (validFloat(prixUnit) && validFloat(prixVRUnit)) {
          if (couleur === SKeys.CouleursMenuiserie.ABF) {
            targetObj.ouverture.ttlPrixOuverture += validFloat(nombre) * (validFloat(prixUnit) + validFloat(prixVRUnit)) * 1.3;
          }
          else {
            targetObj.ouverture.ttlPrixOuverture += validFloat(nombre) * (validFloat(prixUnit) + validFloat(prixVRUnit));
          }
        }      
      }
      break;

    case SKeys.TypesFenetre.FENETRE_COULISSANTE: 
    case SKeys.TypesFenetre.BAIE_COULISSANTE:
      prixGalandage = option === SKeys.OptionsFenetre.GALANDAGE ? OuvertureParams.PRIX_GALANDAGE : 0;
      if (dimensions) {
        prixUnit = await queryOneParamValue(getFenetreKeyByType(typeFenetre), dimensions, SKeys.MateriauxMenuiserie.ALU);
        prixVRUnit = await queryOneParamValue(getVRKeyByType(typeFenetre), dimensions, SKeys.MateriauxMenuiserie.ALU)
        if (validFloat(prixUnit) && validFloat(prixVRUnit)) {
          if (couleur === SKeys.CouleursMenuiserie.ABF) {
            targetObj.ouverture.ttlPrixOuverture += (validFloat(nombre) * (validFloat(prixUnit) + validFloat(prixVRUnit)) + validFloat(prixGalandage)) * 1.3;

          }
          else {
            targetObj.ouverture.ttlPrixOuverture += validFloat(nombre) * (validFloat(prixUnit) + validFloat(prixVRUnit)) + validFloat(prixGalandage);
          }
        }      
      }
      break;
  
    default:
      break;
  }
}

function addSurfaceVitree(targetObj, largeur, hauteur, nombre, facade) {
  let surface = validFloat(largeur)/100 * validFloat(hauteur)/100 * validFloat(nombre);
  if (validFloat(surface)) {
    targetObj.ouverture.ttlSurfaceVitree += validFloat(surface);
    switch (facade) {
      case SKeys.Facades.AVANT:
        targetObj.ouverture.surfaceVitreeAvant += validFloat(surface);
        break;

      case SKeys.Facades.DROITE:
        targetObj.ouverture.surfaceVitreeDroite += validFloat(surface);
        break;

      case SKeys.Facades.ARRIERE:
        targetObj.ouverture.surfaceVitreeArriere += validFloat(surface);
        break;

      case SKeys.Facades.GAUCHE:
        targetObj.ouverture.surfaceVitreeGauche += validFloat(surface);
        break;
    
      default:
        break;
    }
  }
}

function getFenetreKeyByType(type) {
  switch (type) {
    case SKeys.TypesFenetre.PETITE_FENETRE:
      return PKeys.PETITE_FENETRE_PRIX;
  
    case SKeys.TypesFenetre.ABATTANT:
      return PKeys.ABATTANT_PRIX;
  
    case SKeys.TypesFenetre.OEIL_DE_BOEUF:
      return PKeys.OEIL_DE_BOEUF_PRIX;
  
    case SKeys.TypesFenetre.FENETRE_OUVRANTE:
      return PKeys.FENETRE_OUVRANTE_PRIX;
  
    case SKeys.TypesFenetre.FENETRE_COULISSANTE:
      return PKeys.FENETRE_COULISSANTE_PRIX;
  
    case SKeys.TypesFenetre.FENETRE_FIXE:
      return PKeys.FENETRE_FIXE_PRIX;
  
    case SKeys.TypesFenetre.PORTE_FENETRE:
      return PKeys.PORTE_FENETRE_PRIX;
  
    case SKeys.TypesFenetre.BAIE_COULISSANTE:
      return PKeys.BAIE_VITREE_PRIX;
  
    default:
      return null;
  }
}

function getVRKeyByType(type) {
  switch (type) {
    case SKeys.TypesFenetre.FENETRE_OUVRANTE:
      return PKeys.FENETRE_OUVRANTE_VR_PRIX;
  
    case SKeys.TypesFenetre.FENETRE_COULISSANTE:
      return PKeys.FENETRE_COULISSANTE_VR_PRIX;

    case SKeys.TypesFenetre.PORTE_FENETRE:
      return PKeys.PORTE_FENETRE_VR_PRIX;
  
    case SKeys.TypesFenetre.BAIE_COULISSANTE:
      return PKeys.BAIE_VITREE_VR_PRIX;
  
    default:
      return null;
  }
}

export function getFenetreLargeur(dimensions) {
  let splitDim = dimensions ? dimensions.split('X') : null;
  if (splitDim && splitDim.length === 2 && Number.parseInt(splitDim[0])) {
    return splitDim[0];
  }
  return null;
}

export function getFenetreHauteur(dimensions) {
  let splitDim = dimensions ? dimensions.split('X') : null;
  if (splitDim && splitDim.length === 2 && Number.parseInt(splitDim[1])) {
    return splitDim[1];
  }
  return null;
}

export function computeSurfaceVitreeNord(targetObj, orientation) {
  let sum = 0;
  if (orientation) {
    switch (orientation) {
      case SKeys.Orientations.NORD:
        sum += parseFloat(targetObj.ouverture.surfaceVitreeAvant);
        break;
    
      case SKeys.Orientations.OUEST:
        sum += parseFloat(targetObj.ouverture.surfaceVitreeGauche);
        break;
    
      case SKeys.Orientations.SUD:
        sum += parseFloat(targetObj.ouverture.surfaceVitreeArriere);
        break;
    
      case SKeys.Orientations.EST:
        sum += parseFloat(targetObj.ouverture.surfaceVitreeDroite);
        break;
    
      case SKeys.Orientations.NORD_OUEST:
        sum += parseFloat(targetObj.ouverture.surfaceVitreeAvant) + parseFloat(targetObj.ouverture.surfaceVitreeGauche);
        break;
    
      case SKeys.Orientations.SUD_OUEST:
        sum += parseFloat(targetObj.ouverture.surfaceVitreeArriere) + parseFloat(targetObj.ouverture.surfaceVitreeGauche);
        break;
    
      case SKeys.Orientations.NORD_EST:
        sum += parseFloat(targetObj.ouverture.surfaceVitreeAvant) + parseFloat(targetObj.ouverture.surfaceVitreeDroite);
        break;
    
      case SKeys.Orientations.SUD_EST:
        sum += parseFloat(targetObj.ouverture.surfaceVitreeArriere) + parseFloat(targetObj.ouverture.surfaceVitreeDroite);
        break;
    
      default:
        break;
    }
  }
  targetObj.ouverture.surfaceVitreeNord = parseFloat(sum) * 100 / parseFloat(targetObj.ouverture.ttlSurfaceVitree);
}

export function computeSurfaceVitreeSud(targetObj, orientation) {
  let sum = 0;
  if (orientation) {
    switch (orientation) {
      case SKeys.Orientations.NORD:
        sum += parseFloat(targetObj.ouverture.surfaceVitreeArriere);
        break;
    
      case SKeys.Orientations.OUEST:
        sum += parseFloat(targetObj.ouverture.surfaceVitreeDroite);
        break;
    
      case SKeys.Orientations.SUD:
        sum += parseFloat(targetObj.ouverture.surfaceVitreeAvant);
        break;
    
      case SKeys.Orientations.EST:
        sum += parseFloat(targetObj.ouverture.surfaceVitreeGauche);
        break;
    
      case SKeys.Orientations.NORD_OUEST:
        sum += parseFloat(targetObj.ouverture.surfaceVitreeArriere) + parseFloat(targetObj.ouverture.surfaceVitreeDroite);
        break;
    
      case SKeys.Orientations.SUD_OUEST:
        sum += parseFloat(targetObj.ouverture.surfaceVitreeAvant) + parseFloat(targetObj.ouverture.surfaceVitreeDroite);
        break;
    
      case SKeys.Orientations.NORD_EST:
        sum += parseFloat(targetObj.ouverture.surfaceVitreeArriere) + parseFloat(targetObj.ouverture.surfaceVitreeGauche);
        break;
    
      case SKeys.Orientations.SUD_EST:
        sum += parseFloat(targetObj.ouverture.surfaceVitreeAvant) + parseFloat(targetObj.ouverture.surfaceVitreeGauche);
        break;
    
      default:
        break;
    }
  }
  targetObj.ouverture.surfaceVitreeSud = parseFloat(sum) * 100 / parseFloat(targetObj.ouverture.ttlSurfaceVitree);
}

export function computeSurfaceVitreeOuest(targetObj, orientation) {
  let sum = 0;
  if (orientation) {
    switch (orientation) {
      case SKeys.Orientations.NORD:
        sum += parseFloat(targetObj.ouverture.surfaceVitreeDroite);
        break;
    
      case SKeys.Orientations.OUEST:
        sum += parseFloat(targetObj.ouverture.surfaceVitreeAvant);
        break;
    
      case SKeys.Orientations.SUD:
        sum += parseFloat(targetObj.ouverture.surfaceVitreeGauche);
        break;
    
      case SKeys.Orientations.EST:
        sum += parseFloat(targetObj.ouverture.surfaceVitreeArriere);
        break;
    
      default:
        break;
    }
  }
  targetObj.ouverture.surfaceVitreeOuest = parseFloat(sum) * 100 / parseFloat(targetObj.ouverture.ttlSurfaceVitree);
}

export function computeSurfaceVitreeEst(targetObj, orientation) {
  let sum = 0;
  if (orientation) {
    switch (orientation) {
      case SKeys.Orientations.NORD:
        sum += parseFloat(targetObj.ouverture.surfaceVitreeGauche);
        break;
    
      case SKeys.Orientations.OUEST:
        sum += parseFloat(targetObj.ouverture.surfaceVitreeArriere);
        break;
    
      case SKeys.Orientations.SUD:
        sum += parseFloat(targetObj.ouverture.surfaceVitreeDroite);
        break;
    
      case SKeys.Orientations.EST:
      sum += parseFloat(targetObj.ouverture.surfaceVitreeAvant);
        break;
    
      default:
        break;
    }
  }
  targetObj.ouverture.surfaceVitreeEst = parseFloat(sum) * 100 / parseFloat(targetObj.ouverture.ttlSurfaceVitree);
}

export function computeSurfaceVitreeColor(targetObj) {
  if ( !checkValue(targetObj.ouverture.surfaceVitreeNord) || !checkValue(targetObj.ouverture.surfaceVitreeSud) || !checkValue(targetObj.ouverture.surfaceVitreeOuest) || !checkValue(targetObj.ouverture.surfaceVitreeEst) ) {
    targetObj.ouverture.surfaceVitreeColor = SKeys.CouleursSurfaceVitreeNord.BLOQUE;     
  }
  else if ( checkBlocked1(targetObj.ouverture.surfaceVitreeNord, targetObj.ouverture.surfaceVitreeSud) || checkBlocked2(targetObj.ouverture.surfaceVitreeNord, targetObj.ouverture.surfaceVitreeOuest, targetObj.ouverture.surfaceVitreeEst, targetObj.ouverture.surfaceVitreeSud) ) {
    targetObj.ouverture.surfaceVitreeColor = SKeys.CouleursSurfaceVitreeNord.BLOQUE;
  }
  else if ( checkBlack1(targetObj.ouverture.surfaceVitreeNord, targetObj.ouverture.surfaceVitreeOuest, targetObj.ouverture.surfaceVitreeEst, targetObj.ouverture.surfaceVitreeSud) || checkBlack2(targetObj.ouverture.surfaceVitreeNord, targetObj.ouverture.surfaceVitreeSud) ) {
    targetObj.ouverture.surfaceVitreeColor = SKeys.CouleursSurfaceVitreeNord.NOIR;     
  }
  else if ( checkRed1(targetObj.ouverture.surfaceVitreeSud) || checkRed2(targetObj.ouverture.surfaceVitreeNord, targetObj.ouverture.surfaceVitreeSud) ) {
    targetObj.ouverture.surfaceVitreeColor = SKeys.CouleursSurfaceVitreeNord.ROUGE;     
  }
  else if ( checkOrange1(targetObj.ouverture.surfaceVitreeSud) || checkOrange2(targetObj.ouverture.surfaceVitreeNord, targetObj.ouverture.surfaceVitreeSud) ) {
    targetObj.ouverture.surfaceVitreeColor = SKeys.CouleursSurfaceVitreeNord.ORANGE;     
  }
  else if ( checkGreen1(targetObj.ouverture.surfaceVitreeSud) || checkGreen2(targetObj.ouverture.surfaceVitreeNord, targetObj.ouverture.surfaceVitreeSud) ) {
    targetObj.ouverture.surfaceVitreeColor = SKeys.CouleursSurfaceVitreeNord.VERT;     
  }
  else {
    targetObj.ouverture.surfaceVitreeColor = SKeys.CouleursSurfaceVitreeNord.VERT;      
  }
}

function checkValue(val) {
  return parseFloat(val) || parseFloat(val) === 0;
}

function checkBlocked1(nord, sud) {
  if (parseFloat(nord) > parseFloat(sud)) {
    return true
  }
  else {
    return false;
  }
}

function checkBlocked2(nord, ouest, est, sud) {
  if ( !parseFloat(nord) && !parseFloat(ouest) && !parseFloat(est) && !parseFloat(sud) ) {
    return true
  }
  else {
    return false;
  }
}

function checkBlack1(nord, ouest, est, sud) {
  if ( (parseFloat(ouest) > parseFloat(nord)) && (parseFloat(ouest) > parseFloat(sud)) && (parseFloat(est) > parseFloat(nord)) && (parseFloat(est) > parseFloat(sud)) ) {
    return true
  }
  else {
    return false;
  }
}

function checkBlack2(nord, sud) {
  if ( (parseFloat(nord) >= parseFloat(sud) * SKeys.CoeffMaxSurfaceVitreeNord.ROUGE) && (parseFloat(nord) <= parseFloat(sud) * SKeys.CoeffMaxSurfaceVitreeNord.NOIR) ) {
    return true
  }
  else {
    return false;
  }
}

function checkRed1(sud) {
  if ( (parseFloat(sud) >= SKeys.CoeffMinSurfaceVitreeSud.ROUGE) && (parseFloat(sud) <= SKeys.CoeffMinSurfaceVitreeSud.ORANGE) ) {
    return true
  }
  else {
    return false;
  }
}

function checkRed2(nord, sud) {
  if ( (parseFloat(nord) >= parseFloat(sud) * SKeys.CoeffMaxSurfaceVitreeNord.ORANGE) && (parseFloat(nord) < parseFloat(sud) * SKeys.CoeffMaxSurfaceVitreeNord.ROUGE) ) {
    return true
  }
  else {
    return false;
  }
}

function checkOrange1(sud) {
  if ( (parseFloat(sud) >= SKeys.CoeffMinSurfaceVitreeSud.ORANGE) && (parseFloat(sud) < SKeys.CoeffMinSurfaceVitreeSud.VERT) ) {
    return true
  }
  else {
    return false;
  }
}

function checkOrange2(nord, sud) {
  if ( (parseFloat(nord) >= parseFloat(sud) * SKeys.CoeffMaxSurfaceVitreeNord.VERT) && (parseFloat(nord) < parseFloat(sud) * SKeys.CoeffMaxSurfaceVitreeNord.ORANGE) ) {
    return true
  }
  else {
    return false;
  }
}

function checkGreen1(sud) {
  if ( parseFloat(sud) >= SKeys.CoeffMinSurfaceVitreeSud.VERT ) {
    return true
  }
  else {
    return false;
  }
}

function checkGreen2(nord, sud) {
  if ( parseFloat(nord) < parseFloat(sud) * SKeys.CoeffMaxSurfaceVitreeNord.VERT ) {
    return true
  }
  else {
    return false;
  }
}

// export async function ouvertureAddPrixABF(targetObj, couleurMenuiserie) {
  // console.log(targetObj.ouverture.ttlPrixOuverture)
  // if (couleurMenuiserie === SKeys.CouleursMenuiserie.ABF) {
  //   targetObj.ouverture.ttlPrixOuverture = targetObj.ouverture.ttlPrixOuverture * 1.30;
  // }
  // console.log(targetObj.ouverture.ttlPrixOuverture)
// }