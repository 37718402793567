<template>
  <div class="form-input" :class="{'space-before': spaceBefore}">
    <label :for="label" v-if="isLabelToShow">
      {{ label }}
      <span v-if="isUnitToShow" class="input-unit">
        {{ inputUnit ? `(${inputUnit})` : '' }}
      </span>
    </label>
    <div class="input-container" v-if="queryTable === 'clients' && queryKey === 'nomDocument' && blockedNomDoc">
      <input v-if="inputTag === 'input' && valueMatchesIfPreOption"
        :type="inputType" 
        :name="label" 
        :placeholder="placeholder"
        :disabled="true"
        v-model="inputValue"
      />
    </div>
    <div class="input-container" v-else-if="queryTable === 'clients' && queryKey === 'version'">
      <input v-if="inputTag === 'input' && valueMatchesIfPreOption"
        :type="inputType" 
        :name="label" 
        :placeholder="placeholder"
        :disabled="true"
        v-model="inputValue"
      />
    </div>
    <div class="input-container" v-else :class="{'invalid': valid === false}">
      <input v-if="inputTag === 'input' && valueMatchesIfPreOption"
        :type="inputType" 
        :name="label" 
        :placeholder="placeholder"
        :disabled="disabledInput"
        v-model="inputValue"
        v-on:change="updateValue"
      />
      <select v-if="isToShowIfPreOption"
        :name="label" 
        :disabled="disabledInput"
        v-model="inputValue" 
        v-on:change="updateValue"
      >
        <option disabled value="">{{ placeholder }}</option>
        <option v-for="option in options"
          :key="option.index" 
          :value="option.value"
        >
          {{ option.label }}
        </option>
      </select>
      <textarea 
        v-if="inputTag === 'textarea'"
        :placeholder="placeholder" 
        cols="60" 
        rows="3"
        v-model="inputValue"
        v-on:change="updateValue"
      >
      </textarea>
      <div class="required-cnt flex-cnt-row align-start justify-start">
        <span v-if="required" class="required-mark">
          *
        </span>
      </div>
      <span v-if="showUnitOnly" class="input-unit-only">
        {{ inputUnit }}
      </span>
      <img 
        class="reset-icon reset-btn" 
        src="@/assets/icons/refresh-icon.png" 
        alt="refresh"
        v-if="inputTag === 'select' && isToShowIfPreOption && showResetIconIfSelect" 
        v-on:click="resetValue" 
      >
      <div class="help-cnt flex-cnt-row justify-start">
        <div 
          class="help-trigger"
          v-bind:class="{'help-on': showHelp}"
          v-on:click="showHelp = !showHelp"
          v-if="helpImgUrl && (isToShowIfPreOption || valueMatchesIfPreOption)"  
        >
          ?
        </div>
        <img 
          class="help-img"
          v-bind:class="{'show-help': showHelp}"
          :src="getHelpSrc(helpImgUrl)" 
          v-if="showHelp && (isToShowIfPreOption || valueMatchesIfPreOption)" 
        >
      </div>
    </div>
  </div>
</template>

<script>
import { queryPut } from "../../api/queries";
import { isValid } from "../../api/validation";

export default {
  data: function() {
    return {
      inputValue: '',
      valid: null,
      showHelp: false,
      helpSrc: null
    }
  },
  props: {
    isEditMode: {
      type: Boolean,
      required: false,
      default: false
    },
    queryTable: {
      type: String,
      required: true
    },
    queryId: {
      type: Number,
      required: false
    },
    initialValue: {
      required: false,
      default: null
    },
    inputTag: {
      type: String,
      required: false,
      default: 'input'
    },
    label: {
      type: String,
      required: false
    },
    queryKey: {
      type: String,
      required: true
    },
    queryIndex: {
      type: Number,
      required: false
    },
    inputType: {
      type: String,
      required: false
    },
    validType: {
      type: Number,
      required: true
    },
    placeholder: {
      type: String,
      required: false
    },
    inputUnit: {
      type: String,
      required: false,
      default: null
    },
    showUnitOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    showLabel: {
      type: Boolean,
      required: false,
      default: false
    },
    spaceBefore: {
      type: Boolean,
      required: false,
      default: false
    },
    disabledInput: {
      type: Boolean,
      required: false,
      default: false
    },
    //SELECT
    options: {
      type: Array,
      required: false
    },
    hasPreOptionKey: {
      type: String,
      required: false,
      default: null
    },
    hasPreOptionMatchValue: {
      type: String,
      required: false,
      default: null
    },
    hasPreOptionValue: {
      type: String,
      required: false,
      default: null
    },
    isPreOptionTable: {
      type: String,
      required: false, 
      default: null
    },
    isPreOptionKeys: {
      type: Array,
      required: false, 
      default: null
    },
    reset: {
      type: Number, 
      required: false, 
      default: null
    },
    showResetIconIfSelect: {
      type: Boolean,
      required: false,
      default: true
    },
    //
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    defaultValue: {
      type: [String, Number],
      required: false,
      default: null
    },
    helpImgUrl: {
      type: String,
      required: false,
      default: null
    },
    //CLIENT BLOCKED
    blockedNomDoc: {
      type: String,
      required: false,
      default: null
    },
    blockedVersion: {
      type: Number,
      required: false,
      default: null
    }
  },
  watch: {
    blockedNomDoc: function (val) {
      if (val && this.queryKey === 'nomDocument' && this.queryTable === 'clients') {
        this.inputValue = val;
        this.updateValue();
      }
    },
    blockedVersion: function (val) {
      if (val && this.queryKey === 'version' && this.queryTable === 'clients') {
        this.inputValue = val;
        this.updateValue();
      }
    },
    initialValue: function (val) {
      if (val) {
        this.inputValue = val;
      }
      else if (this.hasPreOptionKey || this.queryTable === 'parameters') {
        this.inputValue = '';
      }
    },
    queryId: function() {
      if (this.queryTable === 'parameters' && !this.initialValue) {
        this.inputValue = '';
      }
    },
    reset: function (val) {
      if (val) {
        this.resetValue();
      }
    },
    inputValue: function (val) {
      if (this.required) {
        if (!val) {
          this.$emit('validateRequired', this.queryKey, false)
        }
        else if (isValid(this.validType, this.inputValue)) {
          this.$emit('validateRequired', this.queryKey, true)
        }
        else {
          this.$emit('validateRequired', this.queryKey, false)
        }
      }
    },
  },
  computed: {
    valueMatchesIfPreOption: function () {
      return this.inputTag === 'input' && (this.hasPreOptionKey === null || (this.hasPreOptionMatchValue && this.hasPreOptionValue && this.hasPreOptionMatchValue.toString() === this.hasPreOptionValue.toString()));
    },
    isToShowIfPreOption: function () {
      return this.inputTag === 'select' && this.options && this.options.length && (this.hasPreOptionKey === null || (this.hasPreOptionKey !== null && this.hasPreOptionValue !== null));
    },
    isLabelToShow: function () {
      return this.showLabel && (this.valueMatchesIfPreOption || this.isToShowIfPreOption);
    },
    isUnitToShow: function () {
      return this.inputTag === 'input' || this.isToShowIfPreOption;
    },
    isPreOptionInput: function () {
      return this.isPreOptionTable && this.isPreOptionKeys && this.isPreOptionKeys.length;
    }
  },
  methods: {
    async updateValue() {
      if (this.inputValue === '') {
        this.inputValue = null;
      }
      if (isValid(this.validType, this.inputValue)) {
        this.valid = true;
        if (this.isEditMode) {
          let data = {
            id: this.queryId,
            [this.queryKey]: this.inputValue
          }
          let response = await queryPut(this.queryTable, this.queryId, data);
          if (Number.parseInt(response) === this.queryId) {
            this.$emit('updateValue', this.queryKey, this.inputValue, this.queryIndex);
          }
        }
        else {
          this.$emit('updateValue', this.queryKey, this.inputValue, this.queryIndex);
        }
        if (this.isPreOptionInput) {
          this.updatePreOption();
        }
      }
      else {
        this.valid = false;
        if (!this.isEditMode) {
          this.$emit('updateValue', this.queryKey, null, this.queryIndex);
          if (this.isPreOptionInput) {
            this.updatePreOption();
          }
        }
      }
    },
    async resetValue() {
      if (this.isEditMode) {
        let rstValue = this.defaultValue ? this.defaultValue : null;
        let data = {
          id: this.queryId,
          [this.queryKey]: rstValue
        }
        let response = await queryPut(this.queryTable, this.queryId, data);
        if (Number.parseInt(response) === this.queryId) {
          this.inputValue = this.defaultValue ? this.defaultValue : '';
          this.$emit('updateValue', this.queryKey, this.inputValue, this.queryIndex);
        }
      }
      else {
        this.inputValue = this.defaultValue ? this.defaultValue : '';
        this.$emit('updateValue', this.queryKey, this.inputValue, this.queryIndex);
      }
      if (this.isPreOptionInput) {
        this.updatePreOption();
      }
    },
    updatePreOption() {
      this.$emit('updatePreOption', this.isPreOptionTable, this.isPreOptionKeys, this.queryIndex);
    },
    getHelpSrc(url) {
      if (url) {
        return require(`@/assets/images/${url}`);
      }
      else {
        return '';
      }
    }
  },
  mounted() {
    if (this.initialValue) {
      this.inputValue = this.initialValue;
    }
    else if (this.defaultValue && isValid(this.validType, this.defaultValue)) {
      if (this.hasPreOptionKey) {
        if (this.isToShowIfPreOption || this.valueMatchesIfPreOption) {
          this.inputValue = this.defaultValue;
          this.updateValue();
        }
      }
      else {
        this.inputValue = this.defaultValue;
        this.updateValue();
      }
    }
  }
}
</script>

<style scoped>
.form-input {
  display: flex;
  align-content: center;
  justify-content: center;
  margin-bottom: 2px;
}
  
.input-container{
  display: flex;
  align-items: center;
  min-width: calc(50% + 75px);
}

.input-unit {
  margin-left: 3px;
}

input {
  min-width: 172px;
  min-height: 14px;
}

select {
  min-width: 180px;
  min-height: 20px;
}

label{
  width: calc(50% - 102px);
  padding-right: 5px;
  box-sizing: border-box;
  text-align: end;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  white-space: nowrap;
}

.input-unit {
  display: inline-block;
  font-size: 12px;
  min-width: 20px;
}

.input-unit-only {
  font-size: 12px;
  width: fit-content;
}

.space-before {
  margin-top: 20px;
}

.invalid input, .invalid select {
  border-color: red;
  border-width: 2px;
  border-radius: 3px;
}

.reset-btn:hover {
  transform: scale(1.175);
  transition: 0.1s;
}

.reset-icon {
  max-height: 15px;
  margin-left: 4px;
}

.required-cnt {
  width: fit-content;
  min-width: 7px;
  padding-left: 1px;
  margin-top: -3px;
  height: 100%;
  box-sizing: border-box;
}

.required-mark {
  font-size: 12px;
  color: red;
}

.help-cnt {
  position: relative;
}

input[type="checkbox"] {
  min-width: 0;
}

.help-trigger {
  font-size: 13px;
  background: white;
  border: 1px solid #c6d2d9;
  height: 18px;
  width: 18px;
  text-align: center;
  line-height: 20px;
  border-radius: 100px;
}
.help-trigger:hover {
  background: rgb(233, 219, 156);
  cursor: help;
}

.help-trigger.help-on {
  background: rgb(255, 240, 171);
  cursor: help;
}
.help-trigger.help-on:hover {
  background: rgb(233, 219, 156);
  cursor: help;
}

.help-img {
  opacity: 0;
  position: absolute;
  max-height: 500px;
  max-width: 600px;
  top: 22px;
  right: -180px;
  overflow: visible;
  border: 1px solid #c6d2d9;
}

.help-img.show-help {
  opacity: 1;
  transition: 0.3s;
  z-index: 999;
}
</style>