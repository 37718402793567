<template>
  <div class="form-indic flex-cnt-col align-center">
    <div class="indic-title">
      <h4>
        {{'Répartition'.toUpperCase()}}
      </h4>
    </div>

    <div class="indic-cnt flex-cnt-col align-center etiquette-cnt">
      <div class="indic-section flex-cnt-row flex-center">
        <div class="indic-label">
          OBJECTIF
        </div>
        <div class="indic-value fixed-red">
          10%
        </div>
      </div>
      <div class="indic-section flex-cnt-row flex-center">
        <div class="indic-label">
          ACTUEL
        </div>
        <div class="indic-value">
          {{surfaceVitreeNord ? surfaceVitreeNord : '0'}} %
        </div>
      </div>
      <div class="etiquette-couleur" v-bind:class="this.isColor">
      
      </div>
    </div>


    <div class="flex-cnt-row flex-center">
      <div class="indic-cnt flex-cnt-col justify-center">
        <div class="indic-section flex-cnt-row flex-center">
          <div class="indic-label">
            OBJECTIF
          </div>
          <div class="indic-value fixed-orange">
            15%
          </div>
        </div>
        <div class="indic-section flex-cnt-row flex-center">
          <div class="indic-label">
            ACTUEL
          </div>
          <div class="indic-value">
            {{surfaceVitreeOuest ? surfaceVitreeOuest : '0'}} %
          </div>
        </div>
      </div>
      <div>
        <img class="indic-img" src="@/assets/images/boussole.png" height="150px">
      </div>
        <div class="indic-cnt flex-cnt-col justify-center">
          <div class="indic-section flex-cnt-row flex-center">
            <div class="indic-label">
              OBJECTIF
            </div>
            <div class="indic-value fixed-orange">
              15%
            </div>
          </div>
          <div class="indic-section flex-cnt-row flex-center">
            <div class="indic-label">
              ACTUEL
            </div>
            <div class="indic-value">
              {{surfaceVitreeEst ? surfaceVitreeEst : '0'}} %
            </div>
          </div>
        </div>
    </div>
    <div class="indic-cnt flex-cnt-col align-center">
      <div class="indic-section flex-cnt-row flex-center">
        <div class="indic-label">
          OBJECTIF
        </div>
        <div class="indic-value fixed-green">
          60%
        </div>
      </div>
      <div class="indic-section flex-cnt-row flex-center">
        <div class="indic-label">
          ACTUEL
        </div>
        <div class="indic-value">
          {{surfaceVitreeSud ? surfaceVitreeSud : '0'}} %
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { queryPut } from '../../api/queries';
import { SKeys } from '../../param/selectKeys';
export default {
  props: {
    formTitle: {
      type: String,
      required: false
    },
    formIndex: {
      type: Number,
      required: true
    },
    activeIndex: {
      type: Number,
      required: true
    },
    surfaceVitreeNord: {
      required: false,
      default: 0
    },
    surfaceVitreeSud: {
      required: false,
      default: 0
    },
    surfaceVitreeEst: {
      required: false,
      default: 0
    },
    surfaceVitreeOuest: {
      required: false,
      default: 0
    },
    isEditMode: {
      type: Boolean, 
      required: false, 
      default: false
    },
    clientId: {
      type: Number, 
      required: false, 
      default: 0
    },
  },
  watch: {
    isColor: function () {
      let intVal = this.getIntFromColor(this.isColor);
      this.$emit("updateSurfaceVitreeColorInt", intVal);
      if (this.isEditMode === true && this.clientId) {
        let data = {
          id: this.clientId,
          surfaceVitreeNord: intVal
        }
        queryPut("clients", this.clientId, data);
      }
    },
  },
  computed: {
    isColor: function () {
      if ( !this.checkValue(this.surfaceVitreeNord) || !this.checkValue(this.surfaceVitreeSud) || !this.checkValue(this.surfaceVitreeOuest) || !this.checkValue(this.surfaceVitreeEst) ) {
        this.enableNext(false);
        return 'c-blocked';
      }
      else if ( this.checkBlocked1(this.surfaceVitreeNord, this.surfaceVitreeSud) || this.checkBlocked2(this.surfaceVitreeNord, this.surfaceVitreeOuest, this.surfaceVitreeEst, this.surfaceVitreeSud) ) {
        this.enableNext(false);
        return 'c-blocked';
      }
      else if ( this.checkBlack1(this.surfaceVitreeNord, this.surfaceVitreeOuest, this.surfaceVitreeEst, this.surfaceVitreeSud) || this.checkBlack2(this.surfaceVitreeNord, this.surfaceVitreeSud) ) {
        this.enableNext(true);
        return 'c-black';        
      }
      else if ( this.checkRed1(this.surfaceVitreeSud) || this.checkRed2(this.surfaceVitreeNord, this.surfaceVitreeSud) ) {
        this.enableNext(true);
        return 'c-red';        
      }
      else if ( this.checkOrange1(this.surfaceVitreeSud) || this.checkOrange2(this.surfaceVitreeNord, this.surfaceVitreeSud) ) {
        this.enableNext(true);
        return 'c-orange';        
      }
      else if ( this.checkGreen1(this.surfaceVitreeSud) || this.checkGreen2(this.surfaceVitreeNord, this.surfaceVitreeSud) ) {
        this.enableNext(true);
        return 'c-green';        
      }
      else {
        this.enableNext(true);
        return 'c-green';      
      }
    },
  },
  methods: {
    enableNext (val) {
      if (this.formIndex === this.activeIndex) {
        this.$emit('enableNext', val);
      }
    },
    //QUERY
    getIntFromColor(color) {
      if (color === 'c-blocked') {
        return 5;
      }
      else if (color === 'c-black') {
        return 4;
      }
      else if (color === 'c-red') {
        return 3;
      }
      else if (color === 'c-orange') {
        return 2;
      }
      else if (color === 'c-green') {
        return 1;
      }
    },
    //CALCULS
    checkValue(val) {
      return parseFloat(val) || parseFloat(val) === 0;
    },
    checkBlocked1(nord, sud) {
      if (parseFloat(nord) > parseFloat(sud)) {
        return true
      }
      else {
        return false;
      }
    },
    checkBlocked2(nord, ouest, est, sud) {
      if ( !parseFloat(nord) && !parseFloat(ouest) && !parseFloat(est) && !parseFloat(sud) ) {
        return true
      }
      else {
        return false;
      }
    },
    checkBlack1(nord, ouest, est, sud) {
      if ( (parseFloat(ouest) > parseFloat(nord)) && (parseFloat(ouest) > parseFloat(sud)) && (parseFloat(est) > parseFloat(nord)) && (parseFloat(est) > parseFloat(sud)) ) {
        return true
      }
      else {
        return false;
      }
    },
    checkBlack2(nord, sud) {
      if ( (parseFloat(nord) >= parseFloat(sud) * SKeys.CoeffMaxSurfaceVitreeNord.ROUGE) && (parseFloat(nord) <= parseFloat(sud) * SKeys.CoeffMaxSurfaceVitreeNord.NOIR) ) {
        return true
      }
      else {
        return false;
      }
    },
    checkRed1(sud) {
      if ( (parseFloat(sud) >= SKeys.CoeffMinSurfaceVitreeSud.ROUGE) && (parseFloat(sud) <= SKeys.CoeffMinSurfaceVitreeSud.ORANGE) ) {
        return true
      }
      else {
        return false;
      }
    },
    checkRed2(nord, sud) {
      if ( (parseFloat(nord) >= parseFloat(sud) * SKeys.CoeffMaxSurfaceVitreeNord.ORANGE) && (parseFloat(nord) < parseFloat(sud) * SKeys.CoeffMaxSurfaceVitreeNord.ROUGE) ) {
        return true
      }
      else {
        return false;
      }
    },
    checkOrange1(sud) {
      if ( (parseFloat(sud) >= SKeys.CoeffMinSurfaceVitreeSud.ORANGE) && (parseFloat(sud) < SKeys.CoeffMinSurfaceVitreeSud.VERT) ) {
        return true
      }
      else {
        return false;
      }
    },
    checkOrange2(nord, sud) {
      if ( (parseFloat(nord) >= parseFloat(sud) * SKeys.CoeffMaxSurfaceVitreeNord.VERT) && (parseFloat(nord) < parseFloat(sud) * SKeys.CoeffMaxSurfaceVitreeNord.ORANGE) ) {
        return true
      }
      else {
        return false;
      }
    },
    checkGreen1(sud) {
      if ( parseFloat(sud) >= SKeys.CoeffMinSurfaceVitreeSud.VERT ) {
        return true
      }
      else {
        return false;
      }
    },
    checkGreen2(nord, sud) {
      if ( parseFloat(nord) < parseFloat(sud) * SKeys.CoeffMaxSurfaceVitreeNord.VERT ) {
        return true
      }
      else {
        return false;
      }
    },
  }
}
</script>

<style>
.indic-title {
  margin-bottom: 30px;
}

.indic-section {
  font-size: 12px;
  text-align: center;
  padding: 5px 10px;
}

.indic-cnt {
  border: 1px solid #c6d2d9;
  height: fit-content;
}

.indic-label {
  min-width: 80px;
}

.indic-value {
  margin-left: 5px;
  padding: 3px 10px;
  border: 1px solid #64696b;
  background: #ffffff;
  min-width: 30px;
}
.indic-value.fixed-red {
  background: rgb(236, 2, 2);
  color: white;
  font-weight: 900;
}
.indic-value.fixed-orange {
  background: rgb(231, 150, 0);
  color: white;
  font-weight: 900;
}
.indic-value.fixed-green {
  background: green;
  color: white;
  font-weight: 900;
}

.indic-img {
  margin: 20px 50px;
}

.margin-right-40 {
  margin-right: 40px;
}

.etiquette-cnt {
  position: relative;
}

.etiquette-couleur {
  width: 40px;
  height: 100%;
  border: 1px solid #c6d2d9;
  position: absolute;
  bottom: 0px;
  right: -50px;
}

.etiquette-couleur.c-red {
  background: rgb(236, 2, 2);
}
.etiquette-couleur.c-green {
  background: green;
}
.etiquette-couleur.c-orange {
  background: rgb(231, 150, 0);
}
.etiquette-couleur.c-black {
  background: rgb(17, 16, 16);
}
.etiquette-couleur.c-blocked {
  background: rgb(17, 16, 16);
}
</style>